import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BreadCrumbs from '../components/molecules/tailwind/BreadCrumbs';
import CustomBasicContainerForContent from '../components/molecules/tailwind/CustomBasicContainerForContent';
import CustomContainer from '../components/molecules/tailwind/CustomContainer';
import SettingsContentLayout from '../components/molecules/tailwind/SettingsContentLayout';
import SettingsLayout from '../components/molecules/tailwind/SettingsLayout';
import SettingsSidebar from '../components/molecules/tailwind/SettingsSidebar';
import ViewLayout from '../components/molecules/tailwind/ViewLayout';
import ForbiddenAlert from '../components/organisms/tailwind/ForbiddenAlert';
import ApiManager from '../components/organisms/tailwind/userSettings/ApiManager';
import ChangePassword from '../components/organisms/tailwind/userSettings/ChangePassword';
import EditProfile from '../components/organisms/tailwind/userSettings/EditProfile';
import useOrganizations from '../hooks/useOrganizations';
import useUserData from '../hooks/useUserData';
import { selectUserData } from '../reducers/userSlice';

/**
 * Renders the user settings view
 */
const UserSettings = () => {
  const { organizationId, userId } = useParams();
  const { t } = useTranslation();
  const user = useSelector(selectUserData);
  const navigate = useNavigate();
  const location = useLocation();
  const { getOrgById } = useOrganizations(organizationId);
  const { updateUserProfile } = useUserData();

  const [selectedItem, setSelectedItem] = useState('general');

  /**
   * Manual breadcrumbs setup for the user settings page.
   * They overwrite the breadcrumbs generated by the BreadCrumbs component.
   * -> This is necessary because we do not have a page of the single user (/organizations/1/users/1)
   *
   */
  const breadcrumbs = [
    {
      name: getOrgById(organizationId)?.name || '',
      path: `/organizations/${organizationId}/projects`,
    },
    {
      name: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : '',
      path: location.pathname,
    },
    {
      name: t('userSettings.settings'),
      path: location.pathname,
    },
  ];

  const sidebarElements = [
    {
      name: t('userSettings.sidebar.general'),
      current: selectedItem === 'general',
      item: 'general',
      content: (
        <EditProfile
          userFirstName={user?.firstName}
          userLastName={user?.lastName}
          updateUserProfile={updateUserProfile}
        />
      ),
    },
    {
      name: t('userSettings.sidebar.security'),
      current: selectedItem === 'security',
      item: 'security',
      content: <ChangePassword />,
    },
    {
      name: t('userSettings.sidebar.apiManager'),
      current: selectedItem === 'apiManager',
      item: 'apiManager',
      content: <ApiManager />,
    },
  ];

  /**
   * Renders the content of the sidebar views
   * @returns
   */
  const renderSidebarContent = () => {
    // Iterate over the sidebar elements and render the content of the selected item
    return sidebarElements.map((element) => {
      // If the element is the selected item, render the content
      if (element.item === selectedItem) {
        return (
          <SettingsContentLayout
            title={element.name}
            subtitle={element.name}
            content={element.content}
            buttonAction={() => {
              navigate(`/organizations/${organizationId}/projects`);
            }}
          />
        );
      }

      // If the element has children, iterate over them and render the content of the selected item
      if (element.children !== undefined && element.children !== null) {
        return element.children.map((child) => {
          // If the child is the selected item, render the content
          if (child.item === selectedItem) {
            return (
              <SettingsContentLayout
                title={child.name}
                content={child.content}
                buttonAction={() => {
                  navigate(`/organizations/${organizationId}`);
                }}
              />
            );
          }
          return null;
        });
      }

      return null;
    });
  };

  /**
   * Handles the click on a sidebar item
   * @param {*} event Click event
   * @param {*} item The item which was clicked
   */
  const handleItemClick = (event, item) => {
    setSelectedItem(item);
    event.preventDefault();
  };

  /**
   * Checks if the user owns the profile (is the same user)
   * @returns True if the user owns the profile, false otherwise
   */
  const checkIfUserOwnsProfile = () => {
    try {
      return user.id === parseInt(userId, 10);
    } catch (e) {
      return false;
    }
  };

  return (
    <CustomBasicContainerForContent>
      {checkIfUserOwnsProfile() ? (
        <ViewLayout
          breadCrumbs={<BreadCrumbs overwriteBreadcrumbs={breadcrumbs} />}
          title={t('userSettings.settings')}
          content={
            <SettingsLayout
              settingsSidebar={
                <SettingsSidebar navigation={sidebarElements} handleItemClick={handleItemClick} />
              }
              content={renderSidebarContent()}
            />
          }
        />
      ) : (
        <CustomContainer>
          <ForbiddenAlert organizationId={organizationId} />
        </CustomContainer>
      )}
    </CustomBasicContainerForContent>
  );
};

export default UserSettings;
