import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLayout from '../../../hooks/useLayout';
import VScrollable from '../../molecules/VScrollable';
import { contentHeight } from '../Layout';
import BboxCardsArea from './BboxCardsArea';

/**
 * Displays the right menu of the image annotation with tabs for bounding boxes and labels
 * @returns
 */
const ImageAnnotationRightMenu = ({
  activeImage,
  labels,
  renderableBboxs,
  renderableDetections,
  handleCreateBbox,
  isLabelInImage,
  isLabelActive,
  isCreatingBbox,
  isEditingBbox,
  cancelDrawing,
  isMovingImageButtonMode,
  setLabelIsHidden,
  activeBbIdx,
  isBboxActive,
  setBboxActive,
  onChangeLabelName,
  getNumberOfBboxesOfLabel,
}) => {
  const { t } = useTranslation();
  const { mainMenuHeight } = useLayout();

  return activeImage !== null && typeof activeImage !== 'undefined' ? (
    <Box bg="gray.100" padding={2} height="100%">
      <Tabs isFitted>
        <TabList>
          <Tab>{t('imageAnnotation.bboxs')}</Tab>
        </TabList>

        <VScrollable
          overflowY="auto"
          height={`calc(${contentHeight} - ${mainMenuHeight} - 4*1rem)`} // TODO: Compute height dynamically by subtracting 66.5px*parseInt(tags.lenght/10)
        >
          <TabPanels>
            <TabPanel>
              <BboxCardsArea
                renderableBboxs={renderableBboxs}
                renderableDetections={renderableDetections}
                labels={labels}
                activeBbIdx={activeBbIdx}
                handleCreateBbox={handleCreateBbox}
                isLabelInImage={isLabelInImage}
                isLabelActive={isLabelActive}
                isCreatingBbox={isCreatingBbox}
                isEditingBbox={isEditingBbox}
                cancelDrawing={cancelDrawing}
                isMovingImageButtonMode={isMovingImageButtonMode}
                setLabelIsHidden={setLabelIsHidden}
                isBboxActive={isBboxActive}
                setBboxActive={setBboxActive}
                onChangeLabelName={onChangeLabelName}
                getNumberOfBboxesOfLabel={getNumberOfBboxesOfLabel}
              />
            </TabPanel>
          </TabPanels>
        </VScrollable>
      </Tabs>
    </Box>
  ) : null;
};

export default ImageAnnotationRightMenu;
