import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CustomCard from '../components/molecules/tailwind/CustomCard';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';

import doccapeLogo from '../assets/DocCape_Favicon.svg';

/**
 * View, which is rendered when the user inputs an unavailable url
 */
const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ExternalViewLayout>
      <CustomCard
        image={
          <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
        }
        title={t('notFoundAlert.title')}
        subtitle={t('notFoundAlert.subtitle')}
        buttonText={t('notFoundAlert.button')}
        buttonAction={() => navigate(`/`)}
        content={null}
      />
    </ExternalViewLayout>
  );
};

export default NotFoundPage;
