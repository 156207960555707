import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transformData } from '../../../services/documentTagKeyValuesService';

/**
 * Renders a component that allows the user to select an option from a list of options by typing in a text box
 * @paramm {Object[]} items List of items that the user can select from (format: [{key: string, value: string}])
 * @param {Function} onChange Function that is called when the user selects an option
 * @param {string} placeholder Placeholder text for the text box
 */
const ComboBoxAutoComplete = ({ items, onChange, placeholder }) => {
  const { t } = useTranslation();

  const documentTagValues = transformData(items);

  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState('');

  /**
   * Function which moves the selection on top of the options so when the combo box is open the current selection is on top
   * @param {Object} selectedElement Element that is selected within the combo box by the user
   */
  const handleSelection = (selectedElement) => {
    const selectedOptionByUser = [{ key: items[0].key, value: selectedElement }];
    setSelected(selectedOptionByUser);
    onChange(selectedOptionByUser);
  };

  /**
   * Filters the document tag values based on the input query
   * @returns {Object[]} List of document tag values that match the query
   */
  const filterDocumentTagValues = () => {
    if (documentTagValues === undefined) {
      return [];
    }
    if (query === '') {
      return documentTagValues;
    }
    return documentTagValues.filter((tagValue) =>
      tagValue.value
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.toLowerCase().replace(/\s+/g, '')),
    );
  };
  const filteredDocumentTagValues = filterDocumentTagValues();

  return (
    <div className="min-w-full">
      <Combobox value={selected} onChange={handleSelection} nullable>
        <div className="relative">
          <div className="relative flex min-w-full cursor-default items-center justify-between rounded-xl bg-white text-left text-base text-blue-2 shadow-sm ring-1 ring-babyBlue-1 focus:outline-none active:ring-babyBlue-1">
            <Combobox.Input
              className="text-md h-[34px] w-full rounded-xl border-none text-blue-2 ring-0 ring-babyBlue-1 placeholder:text-blue-2 focus:outline-none"
              displayValue={(documentTagValue) =>
                documentTagValue ? documentTagValue[0].value : ''
              }
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-grey-1" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-babyBlue-1 focus:outline-none sm:text-sm">
              {filteredDocumentTagValues.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-grey-1">
                  {t('documentSelection.comboBoxAutoComplete.notFound')}
                </div>
              ) : (
                filteredDocumentTagValues.map((documentTagValue) => (
                  <Combobox.Option
                    key={`${(documentTagValue.key, documentTagValue.value)}`}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-3 pr-4 rounded-md ${
                        active ? 'bg-babyBlue-3 text-blue-2' : 'text-blue-2'
                      }`
                    }
                    value={documentTagValue.value}
                  >
                    {({ selected }) => (
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                      >
                        {documentTagValue.value}
                      </span>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default ComboBoxAutoComplete;
