import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScoresTabContent from './ScoresTabContent';

const ScoresDialogBody = ({ scores }) => {
  const { t } = useTranslation();

  const hasScores = (type) => {
    const byLabel = scores?.[type]?.byLabel;
    return byLabel && Object.keys(byLabel).length > 0;
  };

  const createHeaderForTabs = (scoresOfSelectedDocs) => {
    const tabHeaders = [];
    Object.keys(scoresOfSelectedDocs).forEach((type) => {
      if (type === 'textScores' && hasScores(type)) {
        tabHeaders.push(
          <Tab key={`ScoresTabHeader-${type}`}>{t('documentSelection.scores.text.header')}</Tab>,
        );
      }
      if (type === 'imageScores' && hasScores(type)) {
        tabHeaders.push(
          <Tab key={`ScoresTabHeader-${type}`}>{t('documentSelection.scores.image.header')}</Tab>,
        );
      }
    });
    return tabHeaders;
  };

  const createContentForTabs = (scoresOfSelectedDocs) => {
    const tabContent = [];
    Object.keys(scoresOfSelectedDocs).forEach((type) => {
      if (type === 'textScores' && hasScores(type)) {
        tabContent.push(
          <ScoresTabContent
            key={`ScoresTabContent-${type}`}
            scores={scoresOfSelectedDocs}
            type={type}
            withDuplicatesSwitch
          />,
        );
      }
      if (type === 'imageScores' && hasScores(type)) {
        tabContent.push(
          <ScoresTabContent
            key={`ScoresTabContent-${type}`}
            scores={scoresOfSelectedDocs}
            type={type}
            withDuplicatesSwitch={false}
          />,
        );
      }
    });
    return tabContent;
  };

  const tabsHeaders = scores ? createHeaderForTabs(scores) : [];
  const tabsContent = scores ? createContentForTabs(scores) : [];
  return (
    <div>
      <p>
        {t('annotation.scoresDescription')}
        <br />
        {t('annotation.scoresNote')}
      </p>
      <br />
      {tabsHeaders.length > 0 && (
        <Tabs variant="enclosed">
          <TabList>{tabsHeaders}</TabList>
          <TabPanels>{tabsContent}</TabPanels>
        </Tabs>
      )}
    </div>
  );
};

export default ScoresDialogBody;
