import React from 'react';
import Button from '../../atoms/tailwind/Button';

/**
 * Custom dialog component that can display different contents for a dialog
 * @param {String} title - Title of the dialog
 * @param {String} subtitle - Subtitle of the dialog
 * @param {JSX} content - Content of the dialog
 * @param {String} buttonText - Text of the button
 * @param {Function} buttonAction - Action of the button
 *
 * Can be used in combination with the CustomForm component and
 * **should**! be used in combination with the CustomDialog component
 *
 * @example
 *
 * const form = (
 *  <CustomForm inputFields={loginInputFields} additionalContent={loginAdditionalContent} />
 * );
 *
 * <CustomDialog isOpen={isShown} onClose={closeModalWithoutSaving}>
 *  <CustomDialogContent
 *       title={t('projectMngmt.overview.createProjectModal.title')}
 *       subtitle={t('projectMngmt.overview.createProjectModal.subtitle')}
 *       buttonText={t('projectMngmt.overview.createProjectModal.submit')}
 *       buttonAction={closeAndSaveModal}
 *       content={form}
 *   />
 * </CustomDialog>
 */
const CustomDialogContent = ({
  title,
  subtitle,
  content,
  buttonText,
  buttonAction,
  isLoading = null,
}) => {
  return (
    <div className="p-2">
      {/* 
        Header section of the card
      */}
      <p className="mb-2 text-3xl font-bold text-blue-2">{title}</p>
      <p className="text-base text-grey-1">{subtitle}</p>

      <br />

      {/* 
        Content section of the card
      */}
      {content}

      <br />

      {/* 
        Action section of the card
      */}
      <div className="h-8 px-24">
        <Button
          buttonText={buttonText}
          fullWidth
          buttonAction={buttonAction}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default CustomDialogContent;
