import { useDispatch } from 'react-redux';
import { logout } from '../reducers/authSlice';
import { BroadcastChannel } from 'broadcast-channel';

export const logoutChannel = new BroadcastChannel('logout');

export const useLogout = () => {
  const dispatch = useDispatch();

  const performLogout = () => {
    dispatch(logout());
    logoutChannel.postMessage("Logout");
  };

  /**
   * Listen to logout channel
   * and perform logout when message is received.
   * Necessary to logout from all browser tabs.
   */
  const initLogoutChannel = () => {
    logoutChannel.onmessage = () => {
      console.info('Received logout message. Start to logout');
      dispatch(logout());
      logoutChannel.close();
    }
  };

  return { performLogout, initLogoutChannel };
};
