import React from 'react';

const DeleteIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="M13.5105 12.1902H23.9805C24.9505 12.1902 24.9505 10.6902 23.9805 10.6902H20.9305C20.9605 10.6102 20.9805 10.5202 20.9805 10.4302C20.9605 10.0202 20.6505 9.68018 20.2305 9.68018H17.2705C16.8805 9.68018 16.5005 10.0202 16.5205 10.4302C16.5205 10.5202 16.5505 10.6102 16.5805 10.6902H13.5205C12.5505 10.6902 12.5505 12.1902 13.5205 12.1902H13.5105Z" />
      <path d="M24.6098 14.21C24.6098 14.15 24.5998 14.09 24.5798 14.04C24.5098 13.74 24.2898 13.48 23.8898 13.48H13.4198C13.0198 13.48 12.7998 13.74 12.7298 14.04C12.7098 14.09 12.7098 14.15 12.6998 14.21C12.6998 14.22 12.6998 14.23 12.6998 14.24C12.6998 14.3 12.6898 14.36 12.6998 14.42C13.1798 16.93 13.6698 19.45 14.1498 21.96C14.2198 22.32 14.2898 22.69 14.3598 23.05C14.4298 23.43 14.7098 23.58 14.9998 23.58C15.0298 23.58 15.0498 23.6 15.0798 23.6H22.2298C22.2298 23.6 22.2798 23.59 22.3098 23.58C22.5998 23.58 22.8798 23.43 22.9498 23.05C23.4298 20.54 23.9198 18.02 24.3998 15.51C24.4698 15.15 24.5398 14.78 24.6098 14.42C24.6198 14.35 24.6098 14.3 24.6098 14.24C24.6098 14.23 24.6098 14.22 24.6098 14.21ZM15.9698 22.11H15.6998C15.2498 19.78 14.7998 17.45 14.3498 15.12C14.3498 15.07 14.3298 15.03 14.3198 14.98H22.9798C22.5598 17.18 22.1298 19.38 21.7098 21.57C21.6798 21.75 21.6398 21.93 21.6098 22.11H15.9698Z" />
    </svg>
  );
};

export default DeleteIcon;
