import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils/tailwindUtils';
import MoreIcon from '../atoms/icons/MoreIcon';
import Button from '../atoms/tailwind/Button';
import Dropdown from '../atoms/tailwind/Dropdown';
import IconButton from '../atoms/tailwind/IconButton';
import MenuButton from '../atoms/tailwind/MenuButton';
import LoadingPage from '../organisms/LoadingPage';

/**
 * Displays table with Users and the corresponding actions
 * @param {array} orgUsers array of objects which describe users {id, email, firstName, lastName, version, organizationRole, isEmailVeryfied}
 * @param {array} userRoles array of objects which describe the possible roles {id, name, value}
 * @param {function} updateUserRole function which handles the update of the role of a corresponding user
 * @param {function} deleteUser function which handles the deletion of the corresponding user
 * @param {boolean} isLoadingUsers state from the reducer which shows whether the user fetch is finished
 * @param {integer} organizationId integer of the organizationId
 */
const UserTable = ({
  orgUsers,
  userRoles,
  updateUserRole,
  deleteUser,
  isLoadingUsers,
  refreshInvitationTokenByAdmin,
  canAdminResendInvitation = true,
}) => {
  const { t } = useTranslation();

  /**
   * Quick actions for the project card
   */

  /**
   * Deletes the user
   * @param {number} id database id of the user which should be deleted
   */
  const handleDeletion = (id) => {
    deleteUser(id);
  };

  /**
   * Changes the role of the corresponding user
   * @param {object} user the user which should be updated
   * @param {object} newRole the new role
   */
  const handleRoleChange = (user, newRole) => {
    updateUserRole(user, newRole);
  };

  /**
   * Since the menu button component needs the dynamic user id it
   * needs to be generated in render time
   * @param {number} userId Id of the user which is rendered
   * @returns MenuButton component with the configuration for each user
   */
  const renderMenuButtonForUser = (userId) => {
    const quickActions = [
      {
        name: 'Benutzer Löschen',
        onClick: () => handleDeletion(userId),
        disabled: false,
      },
    ];

    return (
      <MenuButton
        menuItems={quickActions}
        button={<IconButton icon={<MoreIcon />} />}
        menuPosition="right"
      />
    );
  };

  /**
   * Renders the button an admin can use to resend an invitation email to an user
   * @param {number} orgId id of the organization the user was invited to
   * @param {number} userId Id of the user to be invited
   * @param {boolean} emailVerified is the user already verified
   * @returns
   */
  const renderInvitationButtonForUser = (userId, emailVerified) => {
    return (
      <Button
        buttonText={t('organizationMngmt.userMngmt.resendEmailInvitation.buttonName')}
        color="blue"
        buttonAction={() => refreshInvitationTokenByAdmin(userId)}
        disabled={emailVerified}
      />
    );
  };

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full px-8 py-2 align-middle text-blue-2">
          <table className="min-w-full">
            <thead className="border-b border-blue-2/30">
              <tr>
                <th scope="col" className="min-w-[12rem] px-3 py-3.5 text-left text-lg">
                  {t('projectMngmt.userMngmt.tableHead.name')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-lg">
                  {t('projectMngmt.userMngmt.tableHead.email')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-lg">
                  {t('projectMngmt.userMngmt.tableHead.role')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-lg">
                  {' '}
                </th>
                <th scope="col" className="px-3 py-3.5 text-right text-lg">
                  {t('projectMngmt.userMngmt.tableHead.status')}
                </th>
              </tr>
            </thead>
            {isLoadingUsers === false ? (
              <tbody>
                {orgUsers.map((user) => (
                  <tr key={user.id} className="hover:bg-grey-4">
                    <td className="whitespace-nowrap px-3 py-4 text-base font-medium">
                      {`${user.firstName} ${user.lastName}`}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-base">
                      <div className="flex justify-between">
                        {user.email}
                        {canAdminResendInvitation
                          ? renderInvitationButtonForUser(user.id, user.isEmailVerified)
                          : null}
                      </div>
                    </td>
                    <td className="w-px whitespace-nowrap px-3 py-4">
                      <Dropdown
                        selectedItem={userRoles.find(
                          (role) => role.value === (user.projectRole || user.organizationRole),
                        )}
                        items={userRoles}
                        onChange={(selection) => handleRoleChange(user, selection.value)}
                      />
                    </td>
                    <td>
                      <div className="flex justify-center">{renderMenuButtonForUser(user.id)}</div>
                    </td>
                    <td
                      className={classNames(
                        'whitespace-nowrap px-3 py-4 text-right text-base',
                        user.isEmailVerified ? 'text-green-1' : 'text-red-2',
                      )}
                    >
                      {user.isEmailVerified ? (
                        <>{t('projectMngmt.userMngmt.status.verified')}</>
                      ) : (
                        <>{t('projectMngmt.userMngmt.status.notVerified')}</>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <div className="flex items-center justify-center">
                      <LoadingPage />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
