import { Box, Button, Tooltip } from '@chakra-ui/react';
import React from 'react';

const ButtonWithTooltip = ({
  leftIcon,
  isDisabled,
  isLoading,
  onClick,
  children,
  colorScheme = 'gray',
  tooltipLabel,
}) => {
  return (
    <Tooltip label={tooltipLabel}>
      <Box>
        <Button
          size="sm"
          leftIcon={leftIcon}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={onClick}
          colorScheme={colorScheme}
        >
          {children}
        </Button>
      </Box>
    </Tooltip>
  );
};

export default ButtonWithTooltip;
