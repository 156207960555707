import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectUserData } from '../../../reducers/userSlice';
import { checkProjectRole } from '../../../services/authService';
import { createHumanReadableRepresentationDate } from '../../../services/utils';
import SettingsIcon from '../../atoms/icons/SettingsIcon';
import Button from '../../atoms/tailwind/Button';
import IconButton from '../../atoms/tailwind/IconButton';
import SeparatorHorizontal from '../../atoms/tailwind/SeparatorHorizontal';

const CustomProjectCard = ({ project }) => {
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const { id, name, lastUpdatedDocumentDate } = project;
  const navigate = useNavigate();
  const user = useSelector(selectUserData);

  return (
    <li
      key={project.id}
      className="col-span-1 flex flex-col divide-y divide-grey-1 rounded-xl bg-white p-8 shadow"
    >
      <div className="flex flex-1 grow flex-col ">
        <div className="mb-2 flex flex-row items-center justify-between">
          <h3 className="mr-2 truncate text-2xl font-semibold text-blue-3">{name}</h3>
          {user && checkProjectRole(user, id, 'ADMIN') ? (
            <IconButton
              icon={<SettingsIcon />}
              color="white"
              buttonAction={() => {
                navigate(`/organizations/${organizationId}/projects/${id}/settings`);
              }}
            />
          ) : null}
        </div>
        <SeparatorHorizontal />
        <div className="mt-2 space-y-2">
          <div className="flex justify-between text-sm text-blue-3">
            <p className="w-1/2">{t('projectMngmt.overview.projectCard.documents')}:</p>
            <p className="w-1/2">{project.documentCount}</p>
          </div>
          <div className="flex justify-between text-sm text-blue-3">
            <p className="w-1/2">{t('projectMngmt.overview.projectCard.lastModified')}:</p>
            <p className="w-1/2">
              {createHumanReadableRepresentationDate(lastUpdatedDocumentDate, true)}
            </p>
          </div>
          <div className="flex justify-between text-sm text-babyBlue-2">
            <p className="w-1/2">{t('projectMngmt.overview.projectCard.projectId')}:</p>
            <p className="w-1/2">{id}</p>
          </div>
          {/* <div className="flex justify-between text-sm text-babyBlue-2">
            <p className="w-1/2">Angelegt von:</p>
            <p className="w-1/2">
              // TODO: Get creator of project
              A.B.
            </p>
          </div> */}
          <div className="flex flex-row place-content-between justify-end pt-3">
            <Button
              buttonText={t('projectMngmt.overview.projectCard.openProjectButton')}
              buttonAction={() => {
                navigate(`/organizations/${organizationId}/projects/${id}`);
              }}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default CustomProjectCard;
