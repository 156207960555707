import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Spinner,
  Tooltip,
  theme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import { FiSave } from 'react-icons/fi';
import { VscChromeClose } from 'react-icons/vsc';
import ActionPopover from '../../../molecules/ActionPopover';
import SmallIconButton from '../../../molecules/SmallIconButton';

// TODO: Move into hook
const mainMenuHeight = `(2rem + 1*${theme.space[2]})`; // Button height + 2*2*padding

const ImageAnnotationTopRightMenu = ({
  onSaveDocument,
  isSaving,
  finalizeDocument,
  isFinalizing,
  onCloseDocument,
  isPreviewLoading,
}) => {
  const { t } = useTranslation();
  const [isFinalizingPopOverOpen, setIsFinalizingPopOverOpen] = useState(false);

  const renderDisplaySaveButton = () => {
    return (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.saveTooltip')}>
        <Box>
          <SmallIconButton
            onClick={onSaveDocument}
            disabled={isPreviewLoading || isSaving}
            icon={isSaving ? <Spinner size="sm" /> : <FiSave size={19} />}
          />
        </Box>
      </Tooltip>
    );
  };

  const renderDisplayFinalizeButton = () => {
    return !isPreviewLoading ? (
      <PopoverTrigger>
        <div>
          <Tooltip label={t('mainMenu.finalizeTooltip')}>
            <Box>
              <SmallIconButton
                onClick={() => setIsFinalizingPopOverOpen(true)}
                icon={isFinalizing ? <Spinner size="sm" /> : <BsFileEarmarkCheck />}
              />
            </Box>
          </Tooltip>
        </div>
      </PopoverTrigger>
    ) : (
      <Box>
        <SmallIconButton disabled icon={<BsFileEarmarkCheck />} />
      </Box>
    );
  };

  return (
    <Box height={`calc(${mainMenuHeight})`} bg="gray.300">
      <Flex flexDirection="row" padding="2">
        <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(1, 1fr)" width="100%">
          <GridItem />
          <GridItem>
            <Flex flexDir="row">
              <Spacer />
              <HStack>
                {renderDisplaySaveButton()}
                <Popover isOpen={isFinalizingPopOverOpen}>
                  {renderDisplayFinalizeButton()}
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={() => setIsFinalizingPopOverOpen(false)} />
                    <PopoverHeader fontWeight="medium">
                      {t('mainMenu.finalizePopoverHeader')}
                    </PopoverHeader>
                    <PopoverBody>{t('mainMenu.finalizePopoverBody')}</PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button
                          colorScheme="green"
                          onClick={async () => {
                            setIsFinalizingPopOverOpen(false);
                            finalizeDocument();
                          }}
                        >
                          {t('mainMenu.finalizePopoverButton')}
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
                <ActionPopover
                  isLoading={isPreviewLoading}
                  triggerButtonIcon={<VscChromeClose />}
                  headerContent={t('mainMenu.closePopoverHeader')}
                  bodyContent={t('mainMenu.closePopoverBody')}
                  closeButtonContent={t('mainMenu.closeButton')}
                  onClose={onCloseDocument}
                  tooltip={t('mainMenu.closeTooltip')}
                  actionButtonContent={t('mainMenu.saveAndCloseButton')}
                  onAction={() => {
                    onSaveDocument();
                    onCloseDocument();
                  }}
                />
              </HStack>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  );
};

export default ImageAnnotationTopRightMenu;
