import React from 'react';

/**
 * Displays a single row of the edit area of the image or text labels
 * @param {string} title - The title of the row
 * @param {React.ReactNode} children - The children to be displayed in the row
 */
const LabelEditRow = ({ title, children }) => {
  return (
    <div className="grid grid-cols-3">
      <div className="pr-5 text-right align-top text-xl text-blue-2">{title}</div>
      <div className="col-span-2">{children}</div>
    </div>
  );
};

export default LabelEditRow;
