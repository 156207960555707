import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import doccapeLogo from '../../../assets/DocCape_Favicon.svg';
import CustomCard from '../../molecules/tailwind/CustomCard';

/**
 * Displays a message that the user is not allowed to access the page
 * This can be used in views in combination with the checkOrgRole or checkProjectRole function
 *
 * @example `(from src/views/UserOverviewOrganization.jsx)`
 *
 *   const user = useSelector(selectUserData);
 *   return (
 *   <CustomBasicContainerForContent>
 *     {user && checkOrgRole(user, organizationId, 'ADMIN') ? (
 *       <ViewLayout
 *         breadCrumbs={<BreadCrumbs urlNames={urlNames} urlPath={location.pathname} />}
 *         title={t('organizationMngmt.userMngmt.heading')}
 *         content={contentUserOverview()}
 *       />
 *     ) : (
 *       <CustomContainer>
 *         <ForbiddenAlert />
 *       </CustomContainer>
 *     )}
 *   </CustomBasicContainerForContent>
 * );
 */
const ForbiddenAlert = ({ organizationId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  return (
    <CustomCard
      image={logoImage}
      title={t('forbiddenAlert.title')}
      content={null}
      buttonText="Zurück"
      buttonAction={() => navigate(`/organizations/${organizationId}/projects`)}
    />
  );
};

export default ForbiddenAlert;
