import { API } from './axios';

/**
 * Fetch the current version and build of the application
 * @returns Promise
 */
export const getBackendVersion = () => {
  return API.get(`system/version`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetch the current available Features of the instance of the current user
 * @returns Promise
 */
export const getAvailableFeatures = () => {
  return API.get(`system/config`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetch the current public available Features of the instance of the current user
 * @returns Promise
 */
export const getPublicAvailableFeatures = () => {
  return API.get(`public/system/config`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetch the current load status of the system
 * @returns Promise
 */
export const getLoadStatus = () => {
  return API.get(`system/load-status`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};
