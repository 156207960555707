import { setItemAtIndex } from './utils';

/**
 * Returns the color corresponding to the specified tag index
 * @param {number} labelId The database ID of the korresponding Label
 * @returns The color as string
 */
export const createLabelColor = (labelId) => {
  const colors = [
    'lightPurple',
    'purple',
    'deepPurple',
    'deepPink',
    'pink',
    'lightPink',
    'deepOrange',
    'orange',
    'green',
    'deepGreen',
    'lightGreen',
    'teal',
    'lightTeal',
    'blue',
    'lightBlue',
    'deepBlue',
    'lightGrey',
    'grey',
    'black',
    'brown',
  ];
  const colorID = labelId % colors.length;
  return colors[colorID];
};

/**
 * Update image label with given idx and return new image labels array
 * @param {Array} imageLabels Array of image labels
 * @param {Number} labelIdx Index of the label to update
 * @param {Object} updatedLabel Updated label
 * @returns {Array} newImageLabels New image labels array or the old one if labelIdx < 0
 */
export const updateImageLabel = (imageLabels, labelIdx, updatedLabel) => {
  if (labelIdx >= 0 && labelIdx < imageLabels.length) {
    const newImageLabels = setItemAtIndex(imageLabels, labelIdx, updatedLabel);
    return newImageLabels;
  }
  throw new Error(
    `Image Label index out of bounds: ${labelIdx} not in range 0..${imageLabels.length - 1}`,
  );
};

/**
 * Converts the textLabels to a format that is used by the slice
 * @param {object[]} imageLabels - The image labels to convert
 * @returns {object[]} - The converted image labels
 */
export const transformToSliceData = (imageLabels) => {
  return Object.values(imageLabels).map((imageLabel) => {
    const { color, ...rest } = imageLabel; // remove additional hook properties
    return rest;
  });
};
