/*
 * Renders the Verify User View
 */
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';
import AlreadyUsedTokenCard from '../components/organisms/tailwind/registration/AlreadyUsedTokenCard';
import ExpiredTokenCard from '../components/organisms/tailwind/registration/ExpiredTokenCard';
import NonExistingOrOutdatedTokenCard from '../components/organisms/tailwind/registration/NotExistingOrOutdatedTokenCard';
import NotValidTokenCard from '../components/organisms/tailwind/registration/NotValidTokenCard';
import UnknownErrorCard from '../components/organisms/tailwind/registration/UnknownErrorCard';
import useInvitationUser from '../hooks/useInvitationUser';

const VerifyUserAfterEmailInvite = () => {
  const { verifyEmailToken } = useParams();
  const { t } = useTranslation();
  const {
    verifyToken,
    refreshInvitationToken,
    isVerifyInvitationTokenNotValid,
    isVerifyInvitationTokenExpired,
    isVerifyInvitationTokenAlreadyUsed,
    doesVerifyInvitationTokenNotExistOrIsOutdated,
    isUnknownError,
  } = useInvitationUser();

  const [isLinkSentSuccesful, setIsLinkSentSuccesful] = React.useState(false);

  useEffect(() => {
    verifyToken(verifyEmailToken);
  }, []);

  const handleRefreshToken = async () => {
    const succesful = await refreshInvitationToken(verifyEmailToken);
    if (succesful) {
      setIsLinkSentSuccesful(true);
    }
  };

  /**
   * Renders the content depending on the state of the verification token (valid, expired, already used)
   */
  const renderContent = () => {
    if (isVerifyInvitationTokenNotValid) {
      return (
        <NotValidTokenCard
          title={t('organizationMngmt.invitationUserNotValidToken.heading')}
          subtitle={t('organizationMngmt.invitationUserNotValidToken.subHeading')}
          info={t('organizationMngmt.invitationUserNotValidToken.infoText')}
        />
      );
    }

    if (isVerifyInvitationTokenExpired) {
      return (
        <ExpiredTokenCard
          title={t('organizationMngmt.invitationUserExpiredToken.heading')}
          sendingLinkHeading={t(
            'organizationMngmt.invitationUserExpiredToken.sendingLinkSuccesfulHeading',
          )}
          sendLinkInfo={t('organizationMngmt.invitationUserExpiredToken.sendLinkInfo')}
          handleRefreshToken={handleRefreshToken}
          isLinkSentSuccesful={isLinkSentSuccesful}
        />
      );
    }

    if (isVerifyInvitationTokenAlreadyUsed) {
      return (
        <AlreadyUsedTokenCard
          title={t('organizationMngmt.invitationUserAlreadyUsedToken.heading')}
          subtitle={t('organizationMngmt.invitationUserAlreadyUsedToken.subHeading')}
        />
      );
    }

    if (doesVerifyInvitationTokenNotExistOrIsOutdated) {
      return (
        <NonExistingOrOutdatedTokenCard
          title={t('organizationMngmt.invitationUserNonExistingOrOutdatedToken.heading')}
          subtitle={t('organizationMngmt.invitationUserNonExistingOrOutdatedToken.subHeading')}
          info={t('organizationMngmt.invitationUserNonExistingOrOutdatedToken.infoText')}
        />
      );
    }

    if (isUnknownError) {
      return <UnknownErrorCard />;
    }

    return (
      <CustomCard
        content={
          <p className="text-center text-sm">{t('organizationMngmt.tokenVerification.loading')}</p>
        }
      />
    );
  };

  return <ExternalViewLayout>{renderContent()}</ExternalViewLayout>;
};

export default VerifyUserAfterEmailInvite;
