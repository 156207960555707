import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../molecules/tailwind/CustomDialog';
import CustomDialogContent from '../../../molecules/tailwind/CustomDialogContent';
import CustomForm from '../../../molecules/tailwind/CustomForm';

const AddUserToProjDialog = ({
  open,
  closeModalWithoutSaving,
  closeAndSaveModal,
  handleUserEmailInput,
  roles,
  setSelectedRole,
  selectableUsers,
}) => {
  const { t } = useTranslation();

  const addUserInputFields = [
    {
      id: 'userId',
      name: t('organizationMngmt.invitationUser.email'),
      type: 'select',
      options: selectableUsers.map((user) => (
        <option key={user.email} value={user.id}>
          {user.email}
        </option>
      )),
      onChange: (event) => handleUserEmailInput(event.target.value),
      required: true,
    },
    {
      id: 'userRole',
      name: t('projectMngmt.userMngmt.addUserDialog.role'),
      type: 'select',
      options: roles.map((role) => (
        <option key={role.name} value={role.name}>
          {role.name}
        </option>
      )),
      onChange: (event) => {
        setSelectedRole(event.target.value);
      },
      required: true,
    },
  ];
  return (
    <CustomDialog isOpen={open} onClose={closeModalWithoutSaving}>
      <CustomDialogContent
        title={t('projectMngmt.userMngmt.addUserDialog.heading')}
        subtitle={t('projectMngmt.userMngmt.addUserDialog.subtitle')}
        buttonText={t('projectMngmt.userMngmt.addUserDialog.button')}
        buttonAction={closeAndSaveModal}
        content={<CustomForm inputFields={addUserInputFields} />}
      />
    </CustomDialog>
  );
};

export default AddUserToProjDialog;
