import React from 'react';

/**
 * Loading page component that displays a loading animation
 *
 * Can be used to display a loading page while the authentication is being checked
 *
 * @returns {React.ReactNode} Loading page
 * @example
 *  // If the check is still running, show a loading page
 *   if (isCheckingAuth) {
 *     return <LoadingPage />;
 *   }
 */
const LoadingPage = () => {
  return (
    // Uses the loading spinner style classes from index.css
    <div className="loader-container">
      <div className="loader" />
    </div>
  );
};

export default LoadingPage;
