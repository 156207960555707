import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getBlacklist, putBlacklist } from '../api/project';

const initialState = {
  data: [],
  status: '',
  error: '',
};

/**
 * Fetches the blacklist
 */
export const fetchBlacklist = createAsyncThunk('blacklist/fetch', async ({ projectId }) => {
  const response = await getBlacklist(projectId);
  return response.data;
});

export const updateBlacklist = createAsyncThunk(
  'blacklist/update',
  async ({ projectId, blacklist }) => {
    const response = await putBlacklist(projectId, blacklist);
    return response.data;
  },
);

/**
 * The slice of the blacklist state
 */
export const blacklistSlice = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {
    setBlacklist(state, action) {
      return action.payload;
    },
    clearBlacklist() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBlacklist.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchBlacklist.fulfilled, (state, action) => {
        // Save fetched blacklist in store
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchBlacklist.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectBlacklist = createSelector(
  (state) => state.blacklist,
  (blacklist) => blacklist.data,
);
export const selectBlacklistStatus = createSelector(
  (state) => state.blacklist,
  (blacklist) => blacklist.status,
);
export const selectBlacklistError = createSelector(
  (state) => state.blacklist,
  (blacklist) => blacklist.error,
);

export const { clearBlacklist } = blacklistSlice.actions;

export default blacklistSlice.reducer;
