export const createAnnotation = ({
  start,
  end,
  startChar,
  endChar,
  textLabelName,
  text,
  paragraphId = 0,
  pseudonym = '',
  score = 1.0,
  documentStart = start,
  documentEnd = end,
  crId = null,
  locationInOriginalDocument = null,
  locationInAnonymizedDocument = null,
}) => {
  return {
    start, // references the first token in the annoation
    end, // references the last token in the annotation
    documentStart, // start w.r.t. the whole document
    documentEnd, // end w.r.t. the whole document
    startChar, // start char idx in the paragraph
    endChar, // end char idx in the paragraph
    textLabelName, // references the textLabel
    text, // orginal text
    pseudonym, // used as replacement text
    crId, // Uniquely identifies the coreferences (basis for the pseudonymization and cr)
    score, // reprensents the certainty of the ai backend
    paragraphId, // references the paragraph
    id: `${start}-${end}-${paragraphId}`, // a unique id over all paragraphs
    type: 'annotation', // useful for fast check if object is an annotation
    locationInOriginalDocument, // location in the original doocument
    locationInAnonymizedDocument, // location in the anonymized document
  };
};

export const isAnnotation = (object) => {
  return typeof object === 'object' && 'type' in object && object.type === 'annotation';
};
