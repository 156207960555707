import React from 'react';

/**
 * Displays a link text for translations. It should be used in combination with i18n's `Trans` component.
 * @source https://www.createit.com/blog/i18next-react-links-inside-translations/
 *
 * @param {string} props.to - The link to go to
 * @param {string} props.title - The title of the link
 * @param {string} props.children - The text to display
 * @example
 * <Trans
 *       i18nKey="gdpr_policy_links"
 *       components={{
 *           link1: <LinkText to={link1} title="My link1" />,
 *           link2: <LinkText to={link2} title="Another link" />
 *       }}
 *   />
 */
const LinkText = ({ to, title, children }) => {
  return (
    <a className="underline" href={to || '#'} target="_blank" title={title || ''} rel="noreferrer">
      {children}
    </a>
  );
};

export default LinkText;
