import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCreatingBbox: false,
  isEditingBbox: false,
  isMovingImageButtonMode: false,
  activeLabel: null,
  activeBbox: null,
};

export const imageAnnotationSlice = createSlice({
  name: 'imageAnnotation',
  initialState,
  reducers: {
    setIsCreatingBbox(state, action) {
      return { ...state, isCreatingBbox: action.payload };
    },
    setIsEditingBbox(state, action) {
      return { ...state, isEditingBbox: action.payload };
    },
    setIsMovingImageButtonMode(state, action) {
      return { ...state, isMovingImageButtonMode: action.payload };
    },
    setActiveLabel(state, action) {
      return { ...state, activeLabel: action.payload };
    },
    setActiveBbox(state, action) {
      if (action.payload === state.activeBbox) {
        return { ...state, activeBbox: null };
      }
      return { ...state, activeBbox: action.payload };
    },
    beginEditingBbox(state) {
      return { ...state, isEditingBbox: true };
    },
    cancelDrawing(state) {
      return { ...state, isCreatingBbox: false, activeLabel: null };
    },
    cancelEditingBbox(state) {
      return { ...state, isEditingBbox: false };
    },
    clearActiveBbox(state) {
      return { ...state, activeBbox: null };
    },
  },
});

export const selectImageAnnotation = (state) => state.imageAnnotation;
export const selectActiveBbox = (state) => state.imageAnnotation.activeBbox;

export const {
  setIsCreatingBbox,
  setIsEditingBbox,
  setIsMovingImageButtonMode,
  setActiveLabel,
  setActiveBbox,
  clearActiveBbox,
  cancelDrawing,
  beginEditingBbox,
  cancelEditingBbox,
} = imageAnnotationSlice.actions;

export default imageAnnotationSlice.reducer;
