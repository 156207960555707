const translations = {
  general: {
    copy: 'Kopieren',
    copied: 'Kopiert',
  },
  about: {
    and: 'und',
    description1: ' ist eins der vom ',
    description2: ' geförderten Projekte.',
  },
  footer: {
    support: 'Support',
    licence: 'Lizenz',
    termsOfUse: 'Nutzungsbestimmungen',
    termsAndConditions: 'Allgemeine Geschäftsbedingungen',
    privacyNotice: 'Datenschutzerklärung',
    website: 'Webseite',
    apiDocs: 'API-Dokumentation',
    version: {
      link: 'Version',
      doccape: 'doccape Version',
      preprocessor: 'Preprocessor Version',
      textAi: 'Text AI Version',
      imageAi: 'Image AI Version',
      frontend: 'Frontend Version',
    },
  },
  subscription: {
    navButton: 'Enterprise buchen',
    more: 'Sie Brauchen Mehr?',
    test: 'Testen Sie doccape',
    text: 'Textanonymisierung',
    face: 'Gesichtserkennung',
    basicAI: 'Basic KI Modell',
    resume: 'Weiter Testen',
    productive: 'Setzen Sie doccape produktiv ein',
    textPicture: 'Erkennen von Text im Bild',
    licencePlate: 'Kennzeichenerkennung',
    ocr: 'OCR-Erkennung gescannter Dokumente',
    ownAI: 'Eigenes KI Modell mit definierten Labels',
    appointment: 'Termin vereinbaren',
    writeMail: 'Oder schreiben Sie uns eine',
  },
  resetPassword: {
    headline: 'Passwort zurücksetzen',
    email: 'Email Adresse',
    emailNotValid: 'Bitte geben Sie eine valide Email Adresse ein',
    headerText: 'Bitte geben Sie Ihre Email Adresse ein, um Ihr Passwort zurückzusetzen',
    buttonText: 'Email anfordern',
    resetSuccess: 'Sie erhalten in Kürze eine Email, um Ihr Passwort zurücksetzen zu lassen',
    resetSuccessSubtext: 'Bitte überprüfen Sie ebenso den Spam-Ordner',
    backToLoginButton: 'Zurück zum Login',
    setNewPasswordTitle: 'Neuer Versuch!',
    setNewPassword: 'Neues Passwort festlegen',
    confirmNewPassword: 'Neues Passwort wiederholen',
    passwordsDoNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein',
    tokenInvalid:
      'Das Token ist ungültig bitte fordern Sie eine neue Email zum zurücksetzen des Passworts an',
    passwordTooShort:
      'Das eingegebene Passwort ist zu kurz. Es sollte mindestens 8 Zeichen enthalten.',
    serverError:
      'Ihre Anfrage konnte nicht verarbeitet werden. Kontaktieren Sie einen Administrator und versuchen Sie es später nochmal.',
    send: 'Senden',
    cancel: 'Abbrechen',
  },
  annotation: {
    textLabelsLabel: 'Verfügbare Kategorien:',
    browse: 'Computer durchsuchen',
    drop: 'Ziehe eine %{formats} Datei hierhin.',
    metric: {
      f1: 'F1-Maß',
      f2: 'F2-Maß',
      falseNegatives: 'Falsch Negativ',
      falsePositives: 'Falsch Positiv',
      precision: 'Genauigkeit',
      recall: 'Trefferquote',
      truePositives: 'Richtig Positiv',
    },
    na: 'n.a.',
    or: 'oder',
    scoresHeader: 'Metriken',
    scoresDescription:
      'Die folgenden Metriken bewerten die automatische Erkennung von personenbezogenen Daten im Vergleich zu Ihren manuellen Anpassungen.',
    scoresNote: 'Beachte, dass die Aussagekraft der Metriken von deinen Anpassungen abhängt.',
    scoresDuplicateSwitchText: 'Duplikate berücksichtigen',
    noScoresText: 'Es liegen keine Metriken vor.',
    changeTextLabel: 'Typ ändern:',
    delete: 'Markierung löschen:',
    create: 'Markierung erstellen:',
    options: 'Optionen:',
    deleteHere: 'Nur hier',
    deleteHereDescription: 'Löscht diese Markierung',
    deleteAllText: 'Alle mit selbem Text',
    deleteAllTextDescription: 'Alle Markierungen mit selbem Text werden auch gelöscht',
    deleteAllTextLabelAndText: 'Alle mit selbem Typ & Text',
    deleteAllTextLabelAndTextDescription:
      'Alle Markierungen mit selbem Typ und Text werden auch gelöscht',
    addHere: 'Nur hier',
    addHereDescription: 'Markierung wird nur an dieser Stelle gesetzt',
    addAllText: 'Alle Unmarkierten Markieren',
    addAllTextDescription: 'Alle weiteren Vorkommen im Dokument werden auch markiert',
    applyToAllText: 'Auf alle Anwenden',
    applyToAllTextDescription: 'Übernimmt den Typ in allen Markierungen mit selbem Text',
    changeHere: 'Nur hier',
    changeHereDescription: 'Ändert den Typ der ausgewählten Markierung',
    changeAllText: 'Alle mit selbem Text',
    changeAllTextDescription: 'Typänderung wirkt sich auf alle Markierungen mit selbem Text aus',
    changeAllTextLabelAndText: 'Alle mit selbem Typ & Text',
    changeAllTextLabelAndTextDescription:
      'Typänderung wirkt sich auf alle Markierungen mit selbem Typ und Text aus',
    applyPseuodnymToAllWithSameTextLabelAndText: 'Auf alle Anwenden',
    applyPseuodnymToAllWithSameTextLabelAndTextDescription:
      'Pseudonym für alle Markierungen mit selbem Text und Typ übernehmen',
  },
  anonymization: {
    anonymization: 'Anonymisierung',
    advanced_settings: 'Mechanismus pro Kategorie',
    do_not_anonymize: 'Nicht anonymisieren',
    default: 'Mechanismus',
    default_tooltip:
      'Wähle den Default Mechanismus zur Anonymisierung der personenbezogenen Daten aus. Gehe mit dem Mauszeiger über die Mechanismen um mehr Informationen zu erhalten.',
    use_default: 'Default benutzen',
    generalization: {
      name: 'Generalisierung',
      replacement: 'Ersetzen mit',
      tooltip: 'Ersetze mit einem allgemeineren Ausdruck',
    },
    laplace_noise: {
      epsilon: 'Epsilon',
      epsilon_hint: 'Epsilon muss größer als 0 sein',
      epsilon_tooltip:
        'Das Epsilon kontrolliert wie viel der Wert verrauscht wird. Ein kleineres Epsilon erhöht die Privatsphäre.',
      name: 'Laplace Verrauschung',
    },
    options: 'Optionen',
    pseudonymization: {
      name: 'Pseudonymisierung',
      format_string: 'Ersetzen mit',
      format_string_hint: 'benutze {} einmal als Platzhalter',
      counter_value: 'Initialer Wert',
      counter_value_hint: 'gib eine Zahl größer 0 sein',
      tooltip: 'Ersetze mit einem Pseudonym',
      pii: 'PII',
      pseudonym: 'Pseudonym',
      wholeDocument: 'Gesamtes Dokument',
      onlyPage: 'Nur diese Seite',
      exportButton: 'Exportieren',
      export: 'Tabelle als CSV exportieren',
      replacement: 'Ersetzung',
    },
    randomized_response: {
      name: 'Zufällige Antwort',
      no_config: 'Dieser Mechanismus ist für differentielle Privatsphäre vorkonfiguriert',
    },
    stateful: 'Zustandsbehaftet',
    suppression: {
      as_original: 'wie im Original',
      name: 'Schwärzen',
      custom_length: 'Länge',
      redact: 'Schwärzen',
      suppression_char: 'Ersetzen mit',
      custom_length_hint: 'lasse das Feld leer oder gib eine Zahl größer 0 sein',
      tooltip: 'Ersetze jedes Zeichen mit einem anderen',
    },
  },
  app: {
    fetching_recognizers_failed_toast:
      'Das Laden der verfügbaren Erkennungsmechanismen ist fehlgeschlagen.',
    fetching_textLabels_failed_toast:
      'Das Laden der TextLabelklassennamen für die manuelle Annotation ist fehlgeschlagen.',
    fetching_documents_failed_toast: 'Das Laden der Dokumente ist fehlgeschlagen',
    fetching_image_anonymization_labels_failed_toast:
      'Das Laden der Einstellungen für die Bildanonymisierung ist fehlgeschlagen',
    fetching_image_anonymization_settings_failed_toast:
      'Das Laden der Labels für die Bildanonymisierung ist fehlgeschlagen',
    network_error_toast: 'Der Server ist nicht erreichbar.',
    authorization_error_toast: 'Authorisierung fehlgeschlagen.',
    rendering_error: 'Unerwarteter Fehler',
    rendering_error_action: 'Bitte versuche die Seite erneut zu laden.',
    save_success_toast: 'Erfolgreich gespeichert',
    search: 'Suche',
    unsavedChangesHandler: {
      navigationWarning:
        'Sie haben ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?',
      stateChangeWarning:
        'Sie haben ungespeicherte Änderungen. Wollen Sie wirklich ohne zu speichern fortfahren?',
    },
  },
  disclaimer: {
    text: 'Bitte nutzen Sie diese Software nicht zur Anonymisierung von Dokumenten. Bitte benutzen Sie aus dieser Software heruntergeladene Dokumente nicht weiter.',
    title: 'Dies ist ein Prototyp',
  },
  documentSelection: {
    heading: 'Dokumentenübersicht',
    settings: 'Einstellungen',
    uploadButton: 'Hochladen',
    dropzone: {
      wrongDocumentFormat: 'ist nicht in einem unterstützten Format. Bitte laden Sie nur PDFs hoch',
    },
    loadStatus: {
      low: {
        text: 'Niedrige Last',
        tooltip: 'Das System steht aktuell unter geringer Last.',
      },
      medium: {
        text: 'Mittlere Last',
        tooltip: 'Das System steht aktuell unter mittlerer Last.',
      },
      high: {
        text: 'Hohe Last',
        tooltip: 'Das System ist ausgelastet. Die Verarbeitung von Dokumenten kann länger dauern.',
      },
    },
    upload: 'Dateien hochladen',
    uploadLoading: 'Dateien werden hochgeladen',
    uploadSuccess: 'Dateien erfolgreich hochgeladen',
    uploadFailed: 'Dateien konnten nicht hochgeladen werden',
    exportButton: 'Export Übersicht',
    export: 'Dokumente als CSV exportieren',
    userMngmtButton: 'Benutzerverwaltung',
    userMngmtTooltip: 'Auflistung aller Projektbenutzer',
    inviteUserButton: 'Nutzer einladen',
    inviteUserTooltip: 'Neuen Nutzer zu diesem Projekt einladen',
    refreshButton: 'Aktualisieren',
    refresh: 'Liste aktualisieren',
    reset: 'Dokument in den Originalzustand versetzen',
    resetFailed: 'Dokument in den Originalzustand versetzen fehlgeschlagen',
    resetPopoverHeader: 'Bestätigung',
    resetPopoverContent:
      'Wollen Sie das Dokument wirklich in den Originalzustand versetzen? Dabei werden alle Anpassungen unumkehrbar gelöscht.',
    delete: 'Dokument löschen',
    deleteFailed: 'Dokument konnte nicht gelöscht werden',
    deleteSelectedButton: 'Löschen',
    deleteSelectedTooltip: 'Auswahl löschen',
    cannotDeleteSelectedTooltip: 'Auswahl enthält nicht vorverarbeitete Dokumente',
    deletePopoverHeader: 'Bestätigung',
    deletePopoverContent: 'Wollen Sie das Dokument wirklich löschen?',
    priorizePreprocessing: 'Als nächstes Vorverarbeiten',
    disablePreprocessing: 'Vorverarbeitung deaktivieren',
    enablePreprocessing: 'Vorverarbeitung aktivieren',
    startReview: 'Manuelle Korrektur starten',
    noData: 'Keine Dokumente vorhanden',
    downloadButton: 'Herunterladen',
    downloadPopoverHeader: 'Bestätigung',
    downloadPopoverContent:
      'Das Dokument wurde noch nicht vollständig bearbeitet. Wollen Sie das Dokument trotzdem herunterladen?',
    download: 'Anonymisierte Version Herunterladen',
    downloadFailed: 'Dokument konnte nicht heruntergeladen werden',
    unfinalize: 'Erneut zur Bearbeitung öffnen',
    unfinalizeFailedToast: 'Dokument konnte nicht wieder geöffnet werden',
    archived: 'Dokument archivieren',
    unarchived: 'Dokument wiederherstellen',
    headerButtons: {
      upload: 'Dokument hinzufügen',
      analyze: 'Metrik / Analyse',
      export: 'Export Übersicht',
      download: 'Download',
      delete: 'Löschen',
    },
    filter: {
      fromDateLabel: 'bearbeitet von',
      toDateLabel: 'bearbeitet bis',
    },
    comboBoxAutoComplete: {
      placeholder: 'Fallnummer',
      notFound: 'Suchanfrage nicht gefunden',
    },
    status: {
      ALL: 'Alle Dokumente',
      UNPROCESSED: 'Offen',
      FINALIZED: 'Korrektur abgeschlossen',
      PROCESSING: 'In Bearbeitung',
      COMPLETED: 'Verarbeitet',
      ERROR: 'Fehler',
      PREVIEW_ERROR: 'Vorschau Fehler',
      ARCHIVED: 'Archiviert',
    },
    header: {
      name: 'Name',
      size: 'Größe',
      lastModified: 'Zuletzt Bearbeitet',
      status: 'Status',
      documentTagValue: 'Gruppe',
    },
    downloadSelectedButton: 'Herunterladen',
    downloadSelectedTooltip: 'Auswahl herunterladen',
    cannotDownloadSelectedTooltip: 'Auswahl enthält nicht vorverarbeitete Dokumente',
    showModelQualitySelectedTooltip: 'Modellqualität anzeigen',
    cannotShowModelQualitySelectedTooltip: 'Auswahl enthält nicht abgeschlossene Dokumente',
    showModelQualityloadSelectedButton: 'Modellqualität',
    scores: {
      image: {
        header: 'Bild',
      },
      text: {
        header: 'Text',
      },
    },
    documentSummary: {
      loading: {
        titleFirstPart: 'Dokument',
        titleSecondPart: 'wird zusammengefasst...',
        subtitle: '',
        buttonText: 'Abbrechen',
      },
      loadingFinished: {
        title: 'Dokument',
        subtitle: 'Zusammenfassung',
        buttonText: 'Schliessen',
      },
    },
  },
  help: {
    intro: 'Die folgenden Schritte skizzieren kurz den Anonymisierungs-Prozess:',
    item1: 'Dokument hochladen, das anonymisiert werden soll.',
    item2:
      'Erweitern und korrigieren Sie was automatisch als personenbezogene Daten erkannt wurde in der linken Dokumentenansicht.',
    item3: 'Konfigurieren Sie die Anonymisierung nach Ihren Anforderungen.',
    item4:
      'Überprüfen Sie die Vorschau der Anonymisierung in der rechten Dokumentenansicht und laden Sie Ihr anonymisiertes Dokument herunter.',
    tip: 'Tipp: Viele Elemente zeigen einen Hilfetext an, wenn Sie mit dem Mauszeiger über sie gehen.',
  },
  main: {
    anonymize_file_failed_toast: 'Erstellen der anonymisierten Datei ist fehlgeschlagen',
    compile_anonymize_file_failed_toast: 'Erstellen der anonymisierten Datei ist fehlgeschlagen',
    anonymizing_piis_failed_toast: 'Anonymisieren von personenbezogenen Daten ist fehlgeschlagen',
    fetching_scores_failed_toast: 'Das Laden der Daten für die Modellqualität ist fehlgeschlagen',
    create_text_datasets_failed_toast: 'Erstellung der Text-Datensätze ist fehlgeschlagen',
    create_image_datasets_failed_toast: 'Erstellung der Bild-Datensätze ist fehlgeschlagen',
    find_piis_failed_toast: 'Verarbeitung der Datei ist fehlgeschlagen',
    finalizeFailedToast: 'Datei konnte nicht abgeschlossen werden',
    finalizeSuccessToast: 'Datei erfolgreich abgeschlossen',
    saveFailedToast: 'Speichern fehlgeschlagen',
    documentIsLockedMessage: 'Jemand anderes hat das Dokument editiert. Bitte laden Sie es neu.',
    reload: 'Dokument neu laden',
    toastMessages: {
      documentCompilationSuccess: 'Dokument erfolgreich aktualisiert',
      documentCompilationFailed: 'Aktualisierung des Dokuments fehlgeschlagen',
      documentSaveSuccess: 'Dokument erfolgreich gespeichert',
    },
    documentFinalized:
      'Dieses Dokument wurde bereits abgeschlossen und kann nicht mehr bearbeitet werden.',
    documentFinalizedAlertTooltip: 'Dokument schließen',
  },
  mainMenu: {
    saveTooltip: 'Speichern',
    createDatasetTooltip: 'Erstelle Datensatz',
    compileTooltip: 'Aktualisieren',
    finalizeTooltip: 'Korrektur abschließen',
    saveAndCloseButton: 'Speichern u. Schließen',
    closeTooltip: 'Schließen',
    closeButton: 'Schließen',
    closePopoverHeader: 'Zurück zur Dokumentenauswahl',
    closePopoverBody: 'Möchten Sie, dass Ihre Änderung gespeichert werden?',
    scoresTooltip: 'Metriken',
    finalizePopoverHeader: 'Korrektur abschließen',
    finalizePopoverBody:
      'Das Dokument wird für die Weiterverarbeitung freigegeben. Möchten Sie speichern und die Korrektur abschließen?',
    finalizePopoverButton: 'Ok',
    displayPseudonyms: 'Pseudonyme anzeigen',
    hidePseudonyms: 'Personenbezogene Daten anzeigen',
    autofillTooltip: 'Pseudonyme automatisch setzen',
    documentModifiedTooltip: 'Zuletzt Bearbeitet: ',
    undo: 'Rückgängig',
    redo: 'Wiederholen',
    undoRedoActions: {
      undoNotPossible: 'Rückgängig nicht möglich',
      redoNotPossible: 'Wiederholen nicht möglich',
      addAnnotation: 'Markierung hinzufügen',
      removeAllAnnotationsWithTextLabel: 'Alle Markierungen mit selbem Typ löschen',
      removeAllAnnotationsWithSameTextLabelAndText:
        'Alle Markierungen mit selbem Typ & Text löschen',
      removeAllAnnotationsWithSameText: 'Alle Markierungen mit selbem Text löschen',
      annotateAllUnmarked: 'Alle Unmarkierten Markieren',
      setCrIdAndPseudonymOfAnnotation: 'Zuordnung der Markierung setzen',
      setCrIdAndPseudonymOfAnnotations: 'Zuordnung der Markierungen setzen',
      changeTextLabelNameOfAllAnnotationsWithSameText:
        'Typ aller Markierungen mit selbem Text ändern',
      changeCrIdOfAllAnnotationsWithSameTextLabelAndText:
        'Zuordnung aller Markierungen mit selbem Typ & Text ändern',
      changeTextLabelNameOfAnnotation: 'Typ der Markierung ändern',
      changeCrIdOfAnnotation: 'Zuordnung der Markierung ändern',
      findAndRemoveAnnotation: 'Markierung löschen',
      deleteBboxInImage: 'Box löschen',
      addBbox: 'Box hinzufügen',
      updateBbox: 'Box bearbeiten',
      changeLabelOfBbox: 'Label der Box ändern',
    },
  },
  nav: {
    about: 'Über doccape',
    help: 'Hilfe',
    logout: 'Abmelden',
    settings: 'Einstellungen',
  },
  breadcrumbs: {
    organizations: 'Organisationen',
    project: 'Projekt',
    projects: 'Projekte',
    documents: 'Dokumente',
    settings: 'Einstellungen',
    users: 'Benutzerverwaltung',
  },
  preview: {
    warning: 'Das Dokument wird gerade aktualisiert. Bitte warten Sie einen Moment.',
    error: 'Das Dokument konnte nicht aktualisiert werden. Der angezeigte Inhalt ist veraltet.',
    outdated:
      'Sie haben Änderungen an dem Dokument vorgenommen. Die Vorschau ist veraltet. Bitte aktualisieren Sie das Dokument.',
  },
  sidebar: {
    views: {
      pseudonymization: {
        tooltip: 'Pseudonymisierung',
      },
      textLabelSelection: {
        tooltip: 'Markierungen',
      },
      imageAnonymization: {
        tooltip: 'Bildanonymisierung',
        tooltipWithoutImgs:
          'Keine Bilder gefunden oder Dokument wurde ohne Bildanonymisierung erstellt',
        tooltipSettingsDisabled: 'Bildanonymisierung in den Einstellungen deaktiviert',
        page: 'Seite',
      },
    },
    clickables: {
      settings: {
        tooltip: 'Einstellungen',
      },
    },
  },
  textLabelSelection: {
    options: 'Optionen',
    deleteAllMarksPrefix: 'Alle Markierungen zu Label (',
    deleteAllMarksSuffix: ') löschen',
  },
  imageAnnotation: {
    noImagesInDocument: 'Dieses Dokument enthält keine Bilder.',
    disabledLabelTooltip: 'Aktivieren Sie die Labels in den Einstellungen, um die Boxen anzuzeigen',
    bboxs: 'Boxen',
    labels: 'Labels',
    cancelDrawingTooltip: 'Zeichnen abbrechen (ESC)',
    hideLabel: 'Label verstecken',
    addBbox: 'Box hinzufügen',
    mainMenu: {
      saveTooltip: 'Speichern',
      deleteTooltip: 'Box löschen',
      editTooltip: 'Box bearbeiten',
      cancelEditingTooltip: 'Bearbeiten beenden',
      zoomInTooltip: 'Größer',
      zoomOutTooltip: 'Kleiner',
      centerTooltip: 'Zentrieren',
      moveTooltip: 'Bild verschieben (STRG/CMD)',
      cancelMoveTooltip: 'Bildverschieben verlassen (ESC)',
    },
  },
  settings: {
    recognizers: {
      description:
        'Aktivieren Sie Erkenner um nach personenbezogene Daten für eine Kategorie zu suchen.',
      names: {
        number_recognizer: 'Suche nach Zahlen',
        de_country_recognizer: 'Suche nach Namen von Ländern',
        de_date_recognizer: 'Suche nach Datumsangaben',
        email_recognizer: 'Suche nach E-Mail Adressen',
        phone_number_recognizer: 'Suche nach Telefonnummern',
        money_recognizer: 'Suche nach Geldbeträgen',
        statistical_recognizer:
          'Nutzen Sie statistische Modelle, um nach Personen, Orten, Organisationen und anderen Entitäten zu suchen',
      },
      title: 'Kategorien',
    },
    title: 'Einstellungen',
    save: 'Speichern',
    cancel: 'Abbrechen',
    reset: 'Zurücksetzen',
    textLabels: {
      title: 'TextLabels',
      selectionTitle: 'Text Labels',
      editing: 'Bearbeiten',
      description: 'Beschreibung',
      pseudonymization: 'Pseudonymisierung',
      hide: 'Verstecken',
      delete: 'Text -- Label löschen',
      isRequired: 'Wird benötigt',
      alreadyUsed: 'Bereits vergeben',
      add: 'Neu',
      name: 'Bezeichnung',
      maxNameChars: 'Maximal 6 Zeichen',
      newTextLabel: 'Neu',
      replaceWith: 'Ersetzen mit',
      replacementOptions: {
        pseudonym: 'Pseudonym',
        suppression: 'Schwärzen',
        rule: 'Regel',
      },
      ruleSettings: 'Regel',
      ruleOptions: {
        noRule: 'Keine Regel',
        partialReplacement: 'Teilersetzung',
        dateReplacement: 'Datumsersetzung',
      },
      preview: 'Vorschau',
      previewPlaceholder: 'Text eingeben',
      pseudonymizationExtended: 'Erweiterung',
      partialReplacementTitle: 'Teil ersetzen',
      partialReplacementStart: 'von Buchstabe/Ziffer',
      partialReplacementStartDescription:
        'Ab einschließlich diesem Zeichen (Negative Werte zählen von hinten, eine 0 entspricht auch ab dem ersten Zeichen)',
      partialReplacementStop: 'bis Buchstabe/Ziffer',
      partialReplacementStopDescription:
        'Bis einschließlich zu diesem Zeichen (Negative Werte zählen von hinten, eine 0 entspricht auch bis zum letzten Zeichen)',
      partialReplacementError: 'Beginn der Ersetzung muss vor oder gleich dem Ende sein',
      datePartialReplacementTitle: 'Datumsteil ersetzen',
      replaceDay: 'Tag',
      replaceDayDescription: 'Tag ersetzen',
      replaceMonth: 'Monat',
      replaceMonthDescription: 'Monat ersetzen',
      replaceYear: 'Jahr',
      replaceYearDescription: 'Jahr ersetzen',
      useNumericPartialReplacement: 'Nur Zahlen',
      useNumericPartialReplacementDescription:
        'Ignoriert alle nicht numerischen Zeichen bei der Teilmaskierung z.B. wird bei D4F45G nur die Zahl 445 maskiert',
      showWeekdayTitle: 'Wochentag anzeigen',
      dateLocale: 'Sprache',
      dateLocaleTooltip:
        'Legt die Formatierung des Datums fest. Bei auto wird das Format automatisch erkannt.',
      noExtendedReplacement: 'Keine',
      updatingTextLabelsSuccessToast: 'Änderungen erfolgreich übernommen',
      updatingTextLabelsFailedToast: 'Das Speichern der TextLabels ist fehlgeschlagen',
    },
    listing: {
      title: 'Black- & Whitelisting',
      filter: {
        search: 'Suche',
      },
      textelements: {
        title: 'Text Elemente',
        subtitle: 'Wählen Sie ein Textelement',
        placeholder: 'Neues Text Element',
      },
      whitelist: {
        title: 'Wird angezeigt',
        subtitle: 'wenn erkannt als',
      },
      blacklist: {
        title: 'Wird pseudonymisiert',
        subtitle: 'und ersetzt durch',
      },
      whitelistHelpText:
        'Liste von Textelementen, die nicht anonymisiert werden sollen. Wird ein Label ausgewählt, werden als solche klassifizierten Textelemente nicht anonymisiert. Wird die Option "Alle Labels" ausgewählt, wird das Textelement nicht anonymisiert, ungeachtet, mit welchem Label es klassifiziert wurde.',
      blacklistHelpText:
        'Liste von Textelementen, die immer mit ausgewähltem Label klassifiziert werden sollen.',
      addItem: 'Hinzufügen',
      blacklistItemPlaceholder: 'Bitte Label wählen',
      whitelistItemPlaceholder: 'Alle Labels',
      removeItemTooltip: 'Element entfernen',
      addWhitelistItemTooltip: 'Element zur Whitelist hinzufügen',
      addBlacklistItemTooltip: 'Element zur Blacklist hinzufügen',
      removeAllItems: 'Alle entfernen',
      removeAllItemsPopoverHeader: 'Alle Elemente entfernen',
      removeAllItemsPopoverBody: 'Sind Sie sicher, dass Sie alle Elemente entfernen möchten?',
      importTooltip:
        'Die CSV Datei muss das Format `value;label` besitzen (Semikolon als Trennzeichen). Die Namen der Überschriften müssen `value` und `label` sein.',
      importButton: 'CSV Importieren',
      error: {
        addWhitelistItem: 'Hinzufügen fehlgeschlagen',
        addBlacklistItem: 'Hinzufügen fehlgeschlagen',
        updateWhitelistItem: 'Aktualisierung fehlgeschlagen',
        updateBlacklistItem: 'Aktualisierung fehlgeschlagen',
        deleteWhitelistItem: 'Löschen fehlgeschlagen',
        deleteBlacklistItem: 'Löschen fehlgeschlagen',
        csvFormat: 'CSV Datei besitzt das falsche Format',
      },
    },
    about: {
      title: 'Über',
      system_header: 'System',
      fetching_version_failed_toast: 'Das Laden der Versionsnummern ist fehlgeschlagen',
    },
    imageAnonymization: {
      title: 'Bildanonymisierung',
      selectionTitle: 'Bild Labels',
      editing: 'Bearbeiten',
      enable: 'Aktivieren',
      name: 'Bezeichnung',
      maxNameChars: 'Maximal 11 Zeichen',
      newImageLabel: 'Neu',
      updatingImageLabelsSuccessToast: 'Änderungen erfolgreich übernommen',
      updatingImageLabelsFailedToast: 'Das Speichern der Bild Labels ist fehlgeschlagen',
      isRequired: 'Wird benötigt',
      alreadyUsed: 'Bereits vergeben',
      delete: 'Bild Label löschen',
      textAnonymization: 'Textanonymisierung',
      faceDetection: 'Gesichtserkennung',
      anonymizationType: 'Anonymisierung',
      imageAnonymizationTypeMASK: 'Schwärzen',
      updatingImageAnonymizationSettingsFailedToast:
        'Das Speichern der Einstellungen für die Bildanonymisierung ist fehlgeschlagen',
      updatingImageAnonymizationLabelSettingsFailedToast:
        'Das Speichern der Label Einstellungen für die Bildanonymisierung ist fehlgeschlagen',
    },
  },
  userSettings: {
    settings: 'Profileinstellungen',
    sidebar: {
      apiManager: 'API Manager',
      general: 'Persönliche Daten',
      security: 'Sicherheit',
    },
    apiManager: {
      description: 'Hier können Sie einen API-Key erstellen und teilen',
      buttonName: 'API-Key erstellen',
      generateApiKey: 'API-Key erstellen',
      copiedToClipboard: 'Erfolgreich kopiert',
      apiKey: 'API-Key',
      documentation: 'Hier finden Sie die API-Dokumentation',
      copyNotice:
        'Ihr generierter API-Key ist nur einmal einsehbar - bitte kopieren und speichern Sie ihn.',
      generationNotice: 'Das Generieren eines neuen API-Key macht den alten Key ungültig.',
    },
    general: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      updateUserData: 'Daten aktualisieren',
      successUpdateUserData: 'Änderungen erfolgreich gespeichert',
      failedUpdateUserData: 'Änderungen konnte nicht gespeichert werden',
      error: {
        firstNameEmpty: 'Der Vorname darf nicht leer sein',
        lastNameEmpty: 'Der Nachname darf nicht leer sein',
      },
    },
    security: {
      oldPassword: 'Altes Passwort',
      newPassword: 'Neues Passwort',
      confirmNewPassword: 'Neues Passwort bestätigen',
      changePassword: 'Passwort ändern',
      confirmPasswordMissmatch: 'Die angegebenen Passwörter stimmen nicht überein',
      passwordLengthError:
        'Das angegebene Passwort ist zu kurz. Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen.',
      successPasswordChange: 'Das Passwort wurde erfolgreich geändert',
    },
  },
  textLabels: {
    date: 'Datumsangaben',
    mail: 'E-mail Adressen',
    loc: 'Orte',
    org: 'Organisationen',
    per: 'Personen',
    tel: 'Telefonnummern',
    bic: 'Bank Identifier Codes',
    blz: 'Bankleitzahlen',
    fax: 'Faxnummern',
    fin: 'Fahrzeugidentifikationsnummern',
    gan: 'Gutachtennummern',
    iban: 'International Bank Account Numbers',
    inet: 'Internetadressen',
    kfz: 'Kraftfahrzeugkennzeichen',
    knr: 'Kontonummern',
    oth: 'Sonstiges',
    plz: 'Postleitzahlen',
    str: 'Straßennamen',
  },
  login: {
    header: 'Loggen Sie sich in Ihren Account ein',
    email: 'Email-Adresse',
    username: 'Benutzername',
    password: 'Passwort',
    show: 'show',
    hide: 'hide',
    forgotpw: 'Passwort vergessen?',
    rememberMe: 'Angemeldet bleiben',
    login: 'Login',
    registration: 'Registrierung',
    footer: {
      copyright: '2024 scitlab UG, alle Rechte vorbehalten.',
    },
    alert: 'Email oder Passwort nicht korrekt',
  },
  projectMngmt: {
    overview: {
      title: 'Projektübersicht',
      addProject: 'Projekt anlegen',
      noProjects: {
        heading: 'Es gibt noch keine Projekte',
        content: 'Bitte wenden Sie sich an einen Administrator',
        contentAdmin: 'Erstellen Sie ein Projekt um loszulegen',
      },
      createProjectModal: {
        title: 'Projekt anlegen',
        subtitle: 'Legen Sie ein neues Projekt an',
        projectName: 'Name',
        projectDescription: 'Beschreibung',
        submit: 'Bestätigen',
        errorsMsg: {
          projectAlreadyExists: 'Der Projektname existiert bereits',
          projectNameMustNotEmpty: 'Der Name eines Projekts darf nicht leer sein',
        },
      },
      projectCard: {
        heading: 'Projekt',
        documents: 'Dokumente',
        projectId: 'Projekt ID',
        lastModified: 'Letzte Dok.-Änderung',
        openProjectButton: 'Projekt öffnen',
      },
    },
    userMngmt: {
      heading: 'Nutzerübersicht',
      subHeading: 'Eine Liste aller Benutzer von dem Projekt',
      addUserDialog: {
        heading: 'Nutzer zum Projekt hinzufügen',
        subtitle:
          'Bitte geben Sie die Email Adresse der Person ein, die Sie zum Projekt hinzufügen möchten.',
        button: 'Nutzer hinzufügen',
        role: 'Rolle',
        success: 'Nutzer erfolgreich eingeladen',
        errorMsg: {
          inviteNotPossible: 'Nutzer konnte nicht eingeladen werden',
        },
      },
      changeUserRole: {
        success: 'Rolle erfolgreich geändert',
      },
      deleteUser: {
        success: 'Nutzer wurde aus dem Projekt entfernt',
        errorMsg: 'Nutzer konnte nicht aus dem Projekt entfernt werden',
      },
      tableHead: {
        name: 'Name',
        email: 'Email',
        status: 'Status',
        role: 'Rolle',
      },
      status: {
        verified: 'Verifiziert',
        notVerified: 'Nicht Verifiziert',
      },
    },
  },
  projectSettings: {
    settings: 'Projekteinstellungen',
    redirectButton: 'Zurück',
    sidebar: {
      generalSettings: 'Allgemeine Einstellungen',
      userMngmt: 'Benutzerverwaltung',
      pseudonymization: 'Pseudonymisierung',
      textLabels: 'TextLabels',
      textLabelsFromModel: 'Automatische Text Labels',
      textLabelsOwn: 'Eigene Text Labels',
      blackAndWhite: 'Black- & Whitelisting',
      rules: 'Regeln',
      imageLabels: 'Bild Labels',
      imageLabelsFromModel: 'Automatische Bild Labels',
      imageLabelsOwn: 'Eigene Bild Labels',
      deleteProject: 'Projekt löschen',
      confirmationDeleteProject: 'Wollen Sie dieses Projekt wirklich löschen?',
    },
  },
  organizationMngmt: {
    tokenVerification: {
      loading: 'Einen Moment...',
    },
    invitationUser: {
      email: 'Email-Adresse',
      inviteUser: 'Nutzer einladen',
      subtitle:
        'Bitte geben Sie die Email Adresse der Person ein, die Sie in die Organisation einladen möchten.',
      success: 'Nutzer erfolgreich eingeladen',
      errorMsg: {
        duplicateUser: 'Die Email Adresse existiert bereits',
        invalidEmail: 'Die Email Adresse ist ungültig',
      },
    },
    registrationUser: {
      heading: 'Willkommen',
      subHeading: 'Erstellen Sie Ihr Konto',
      userData: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'Email-Adresse',
        password: 'Passwort festlegen',
        repeatPassword: 'Passwort wiederholen',
        invalidInputs: {
          invalidFirstName: 'Der Vorname darf nicht leer sein',
          invalidLastName: 'Der Nachname darf nicht leer sein',
          invalidEmail: 'Die Email Adresse ist ungültig',
          passwordsNoMatch: 'Die beiden Passwörter stimmen nicht überein.',
          passwordTooShort: 'Das Passwort muss mindestens 8 Zeichen haben.',
        },
        infoText: `Mit der Registrierung stimmen Sie unserer <linkPN>Datenschutzerklärung</linkPN> von doccape zu.`,
      },
      successRegistration: 'Erfolgreich registriert',
      errorRegistration: 'Die Registrierung konnte nicht durchgeführt werden.',
      createAccountButton: 'Konto anlegen',
    },
    registrationUserSuccess: {
      heading: 'Wir gratulieren!',
      subHeading: 'Sie wurden erfolgreich registriert',
      infoText: `Sie haben eine Email erhalten, um Ihre Registrierung zu bestätigen. Bitte überprüfen Sie Ihr Postfach.`,
      toLoginButton: 'Zum Login',
    },
    verifyUserSuccess: {
      heading: 'Willkommen bei doccape!',
      subHeading: 'Ihr Konto wurde erfolgreich verifiziert',
      toLoginButton: 'Zum Login',
    },
    invitationUserNotValidToken: {
      heading: 'Das funktioniert nicht!',
      subHeading: 'Fehlerhafter Einladungslink.',
      infoText: `Registrierung kann nicht abgeschlossen werden. Bitte wenden Sie sich an den Administrator.`,
    },
    invitationUserExpiredToken: {
      heading: 'Hoppla! Zu spät!',
      sendingLinkSuccesfulHeading: 'Einladungslink versendet',
      subHeading: 'Dieser Einladungsslink ist abgelaufen',
      sendLinkAgainButton: 'Link erneut senden',
      sendLinkInfo:
        'Wir haben Ihnen eine Email mit einem neuen Einladungslink geschickt. Bitte überprüfen Sie Ihr Postfach.',
      sendingLinkFailed: 'Link konnte nicht erneut versendet werden.',
    },
    invitationUserAlreadyUsedToken: {
      heading: 'Sie haben sich bereits registriert!',
      subHeading: 'Dieser Einladungslink wurde bereits verwendet',
      sendLinkAgainButton: 'Zum Login',
    },
    invitationUserNonExistingOrOutdatedToken: {
      heading: 'Das funktioniert nicht!',
      subHeading: 'Token existiert nicht.',
      infoText: `Entweder wurde ein falscher Token verwendet oder der Token ist nicht mehr gültig. Es könnte sein, dass mittlerweile ein aktuellerer Token existiert. Bitte überprüfen Sie hierzu Ihr Postfach.`,
    },
    invitationUnknonError: {
      heading: 'Es ist ein Fehler aufgetreten.',
      subHeading: 'Bitte wenden Sie sich an den Administrator.',
    },
    userMngmt: {
      heading: 'Benutzerverwaltung',
      deleteUser: {
        success: 'Nutzer wurde gelöscht',
        errorMsg: 'Nutzer konnte nicht gelöscht werden',
      },
      changeUserRole: {
        success: 'Rolle erfolgreich geändert',
        errorMsg: 'Rolle konnte nicht geändert werden',
      },
      resendEmailInvitation: {
        buttonName: 'Link erneut senden',
        success: 'Link erfolgreich gesendet',
      },
    },
  },
  verifyEmail: {
    emailVerificationNotValidToken: {
      heading: 'Das funktioniert nicht!',
      subHeading: 'Fehlerhafter Verifizierungslink.',
      infoText: `Verifizierung kann nicht abgeschlossen werden. Bitte wenden Sie sich an support@doccape.de.`,
    },
    emailVerificationExpiredToken: {
      heading: 'Hoppla! Zu spät!!',
      sendingLinkSuccesfulHeading: 'Verifizierungslink versendet',
      subHeading: 'Dieser Verifizierungslink ist abgelaufen',
      sendLinkAgainButton: 'Link erneut senden',
      sendLinkInfo:
        'Wir haben Ihnen eine E-mail mit einem neuen Verifizierungslink geschickt. Bitte überprüfen Sie Ihr Postfach.',
      sendingLinkFailed: 'Link konnte nicht erneut versendet werden.',
    },
    emailVerificationAlreadyUsedToken: {
      heading: 'Sie haben Sich bereits verifiziert!',
      subHeading: 'Dieser Verifizierungslink wurde bereits verwendet',
      sendLinkAgainButton: 'Zum Login',
    },
    emailVerificationNonExistingTokenOrOutdated: {
      heading: 'Ups! Das funktioniert nicht!',
      subHeading: 'Token existiert nicht.',
      infoText: `Entweder wurde ein falscher Token verwendet oder der Token ist nicht mehr gültig. Es könnte sein, dass mittlerweile ein aktuellerer Token existiert. Bitte überprüfen Sie hierzu Ihr Postfach.`,
    },
    verifyEmailSuccess: {
      heading: 'Willkommen bei doccape!',
      subHeading: 'Ihre E-mail wurde erfolgreich verifiziert',
      toLoginButton: 'Zum Login',
    },
  },
  forbiddenAlert: {
    title: 'Fehler',
    subtitle: 'Diese Seite existiert nicht oder Sie haben nicht die erforderlichen Berechtigungen',
    button: 'Zurück zur Startseite',
  },
  notFoundAlert: {
    title: 'Fehler',
    subtitle: 'Diese Seite existiert nicht',
    button: 'Zurück',
  },
  tablePaginationFooter: {
    locationInfo: '{{from}} bis {{to}} von {{count}}',
    prev: 'Vorherige',
    next: 'Nächste',
  },
  errors: {
    default: { message: 'Es ist ein Fehler aufgetreten' },
    'api-key': {
      post: {
        '': {
          400: 'Beim Erstellen des API-Keys ist ein Fehler aufgetreten',
          403: 'Beim Erstellen des API-Keys ist ein Fehler aufgetreten',
          404: 'Beim Erstellen des API-Keys ist ein Fehler aufgetreten',
          409: 'Beim Erstellen des API-Keys ist ein Fehler aufgetreten',
          422: 'Beim Erstellen des API-Keys ist ein Fehler aufgetreten',
          429: 'Beim Erstellen des API-Keys ist ein Fehler aufgetreten',
          500: 'Beim Erstellen des API-Keys ist ein Fehler aufgetreten',
        },
      },
    },
    auth: {
      put: {
        'users/resend-invitation-email/#': {
          400: 'Beim erneuten Versenden der Einladungsmail ist ein Fehler aufgetreten',
          403: 'Beim erneuten Versenden der Einladungsmail ist ein Fehler aufgetreten',
          404: 'Beim erneuten Versenden der Einladungsmail ist ein Fehler aufgetreten',
          409: 'Beim erneuten Versenden der Einladungsmail ist ein Fehler aufgetreten',
          422: 'Beim erneuten Versenden der Einladungsmail ist ein Fehler aufgetreten',
          429: 'Beim erneuten Versenden der Einladungsmail ist ein Fehler aufgetreten',
          500: 'Beim erneuten Versenden der Einladungsmail ist ein Fehler aufgetreten',
        },
        '/auth/resend-email-verification/#': {
          400: 'Beim erneuern des Tokens ist ein Fehler aufgetreten',
          403: 'Beim erneuern des Tokens ist ein Fehler aufgetreten',
          404: 'Beim erneuern des Tokens ist ein Fehler aufgetreten',
          409: 'Beim erneuern des Tokens ist ein Fehler aufgetreten',
          422: 'Beim erneuern des Tokens ist ein Fehler aufgetreten',
          429: 'Beim erneuern des Tokens ist ein Fehler aufgetreten',
          500: 'Beim erneuern des Tokens ist ein Fehler aufgetreten',
        },
      },
    },
    documents: {
      put: {
        'images/pii-regions/#': {
          400: 'Beim Speichern der Bounding Boxen ist ein Fehler aufgetreten',
          403: 'Beim Speichern der Bounding Boxen ist ein Fehler aufgetreten',
          404: 'Beim Speichern der Bounding Boxen ist ein Fehler aufgetreten',
          409: 'Beim Speichern der Bounding Boxen ist ein Fehler aufgetreten',
          422: 'Beim Speichern der Bounding Boxen ist ein Fehler aufgetreten',
          429: 'Beim Speichern der Bounding Boxen ist ein Fehler aufgetreten',
          500: 'Beim Speichern der Bounding Boxen ist ein Fehler aufgetreten',
        },
      },
      post: {
        'compile/#': {
          400: 'Beim Aktualisieren des pdf-Dokuments ist ein Fehler aufgetreten',
          403: 'Beim Aktualisieren des pdf-Dokuments ist ein Fehler aufgetreten',
          404: 'Beim Aktualisieren des pdf-Dokuments ist ein Fehler aufgetreten',
          409: 'Beim Aktualisieren des pdf-Dokuments ist ein Fehler aufgetreten',
          422: 'Beim Aktualisieren des pdf-Dokuments ist ein Fehler aufgetreten',
          429: 'Beim Aktualisieren des pdf-Dokuments ist ein Fehler aufgetreten',
          500: 'Beim Aktualisieren des pdf-Dokuments ist ein Fehler aufgetreten',
        },
        'create-text-datasets/#': {
          400: 'Beim Erstellen des Text Datensatzes ist ein Fehler aufgetreten',
          403: 'Beim Erstellen des Text Datensatzes ist ein Fehler aufgetreten',
          404: 'Beim Erstellen des Text Datensatzes ist ein Fehler aufgetreten',
          409: 'Beim Erstellen des Text Datensatzes ist ein Fehler aufgetreten',
          422: 'Beim Erstellen des Text Datensatzes ist ein Fehler aufgetreten',
          429: 'Beim Erstellen des Text Datensatzes ist ein Fehler aufgetreten',
          500: 'Beim Erstellen des Text Datensatzes ist ein Fehler aufgetreten',
        },
        'create-image-datasets/#': {
          400: 'Beim Erstellen des Bild Datensatzes ist ein Fehler aufgetreten',
          403: 'Beim Erstellen des Bild Datensatzes ist ein Fehler aufgetreten',
          404: 'Beim Erstellen des Bild Datensatzes ist ein Fehler aufgetreten',
          409: 'Beim Erstellen des Bild Datensatzes ist ein Fehler aufgetreten',
          422: 'Beim Erstellen des Bild Datensatzes ist ein Fehler aufgetreten',
          429: 'Beim Erstellen des Bild Datensatzes ist ein Fehler aufgetreten',
          500: 'Beim Erstellen des Bild Datensatzes ist ein Fehler aufgetreten',
        },
      },
    },
    projects: {
      get: {
        '#/settings/#/text-labels': {
          400: 'Beim Laden der Text Labels ist ein Fehler aufgetreten',
          403: 'Beim Laden der Text Labels ist ein Fehler aufgetreten',
          404: 'Beim Laden der Text Labels ist ein Fehler aufgetreten',
          409: 'Beim Laden der Text Labels ist ein Fehler aufgetreten',
          422: 'Beim Laden der Text Labels ist ein Fehler aufgetreten',
          429: 'Beim Laden der Text Labels ist ein Fehler aufgetreten',
          500: 'Beim Laden der Text Labels ist ein Fehler aufgetreten',
        },
        '#/settings/#/image-labels': {
          400: 'Beim Laden der Bild Labels ist ein Fehler aufgetreten',
          403: 'Beim Laden der Bild Labels ist ein Fehler aufgetreten',
          404: 'Beim Laden der Bild Labels ist ein Fehler aufgetreten',
          409: 'Beim Laden der Bild Labels ist ein Fehler aufgetreten',
          422: 'Beim Laden der Bild Labels ist ein Fehler aufgetreten',
          429: 'Beim Laden der Bild Labels ist ein Fehler aufgetreten',
          500: 'Beim Laden der Bild Labels ist ein Fehler aufgetreten',
        },
        '#/settings': {
          400: 'Beim Laden der Einstellungen ist ein Fehler aufgetreten',
          403: 'Beim Laden der Einstellungen ist ein Fehler aufgetreten',
          404: 'Beim Laden der Einstellungen ist ein Fehler aufgetreten',
          409: 'Beim Laden der Einstellungen ist ein Fehler aufgetreten',
          422: 'Beim Laden der Einstellungen ist ein Fehler aufgetreten',
          429: 'Beim Laden der Einstellungen ist ein Fehler aufgetreten',
          500: 'Beim Laden der Einstellungen ist ein Fehler aufgetreten',
        },
        '#/settings/blacklists': {
          400: 'Beim Laden der Blacklist ist ein Fehler aufgetreten',
          403: 'Beim Laden der Blacklist ist ein Fehler aufgetreten',
          404: 'Beim Laden der Blacklist ist ein Fehler aufgetreten',
          409: 'Beim Laden der Blacklist ist ein Fehler aufgetreten',
          422: 'Beim Laden der Blacklist ist ein Fehler aufgetreten',
          429: 'Beim Laden der Blacklist ist ein Fehler aufgetreten',
          500: 'Beim Laden der Blacklist ist ein Fehler aufgetreten',
        },
        '#/settings/whitelists': {
          400: 'Beim Laden der Whitelist ist ein Fehler aufgetreten',
          403: 'Beim Laden der Whitelist ist ein Fehler aufgetreten',
          404: 'Beim Laden der Whitelist ist ein Fehler aufgetreten',
          409: 'Beim Laden der Whitelist ist ein Fehler aufgetreten',
          422: 'Beim Laden der Whitelist ist ein Fehler aufgetreten',
          429: 'Beim Laden der Whitelist ist ein Fehler aufgetreten',
          500: 'Beim Laden der Whitelist ist ein Fehler aufgetreten',
        },
        '#/documents/#/anonymized/download': {
          400: 'Beim Herunterladen des anonymisierten Dokuments ist ein Fehler aufgetreten',
          403: 'Beim Herunterladen des anonymisierten Dokuments ist ein Fehler aufgetreten',
          404: 'Beim Herunterladen des anonymisierten Dokuments ist ein Fehler aufgetreten',
          409: 'Beim Herunterladen des anonymisierten Dokuments ist ein Fehler aufgetreten',
          422: 'Beim Herunterladen des anonymisierten Dokuments ist ein Fehler aufgetreten',
          429: 'Beim Herunterladen des anonymisierten Dokuments ist ein Fehler aufgetreten',
          500: 'Beim Herunterladen des anonymisierten Dokuments ist ein Fehler aufgetreten',
        },
        '#/documents': {
          400: 'Beim Laden der Dokumente ist ein Fehler aufgetreten',
          403: 'Beim Laden der Dokumente ist ein Fehler aufgetreten',
          404: 'Beim Laden der Dokumente ist ein Fehler aufgetreten',
          409: 'Beim Laden der Dokumente ist ein Fehler aufgetreten',
          422: 'Beim Laden der Dokumente ist ein Fehler aufgetreten',
          429: 'Beim Laden der Dokumente ist ein Fehler aufgetreten',
          500: 'Beim Laden der Dokumente ist ein Fehler aufgetreten',
        },
        '#/documents/#/piis': {
          400: 'Beim Laden der Daten des Dokuments ist ein Fehler aufgetreten',
          403: 'Beim Laden der Daten des Dokuments ist ein Fehler aufgetreten',
          404: 'Beim Laden der Daten des Dokuments ist ein Fehler aufgetreten',
          409: 'Beim Laden der Daten des Dokuments ist ein Fehler aufgetreten',
          422: 'Beim Laden der Daten des Dokuments ist ein Fehler aufgetreten',
          429: 'Beim Laden der Daten des Dokuments ist ein Fehler aufgetreten',
          500: 'Beim Laden der Daten des Dokuments ist ein Fehler aufgetreten',
        },
        '#/documents/#/original/base64': {
          400: 'Beim Laden des nicht anonymisierten Dokuments ist ein Fehler aufgetreten',
          403: 'Beim Laden des nicht anonymisierten Dokuments ist ein Fehler aufgetreten',
          404: 'Beim Laden des nicht anonymisierten Dokuments ist ein Fehler aufgetreten',
          409: 'Beim Laden des nicht anonymisierten Dokuments ist ein Fehler aufgetreten',
          422: 'Beim Laden des nicht anonymisierten Dokuments ist ein Fehler aufgetreten',
          429: 'Beim Laden des nicht anonymisierten Dokuments ist ein Fehler aufgetreten',
          500: 'Beim Laden des nicht anonymisierten Dokuments ist ein Fehler aufgetreten',
        },
        '#/documents/#/anonymized/base64': {
          400: 'Beim Laden des anonymisierten Dokuments ist ein Fehler aufgetreten',
          403: 'Beim Laden des anonymisierten Dokuments ist ein Fehler aufgetreten',
          404: 'Beim Laden des anonymisierten Dokuments ist ein Fehler aufgetreten',
          409: 'Beim Laden des anonymisierten Dokuments ist ein Fehler aufgetreten',
          422: 'Beim Laden des anonymisierten Dokuments ist ein Fehler aufgetreten',
          429: 'Beim Laden des anonymisierten Dokuments ist ein Fehler aufgetreten',
          500: 'Beim Laden des anonymisierten Dokuments ist ein Fehler aufgetreten',
        },
        '#/documents/#/images/#/#/image_file': {
          400: 'Beim Laden der Bilder ist ein Fehler aufgetreten',
          403: 'Beim Laden der Bilder ist ein Fehler aufgetreten',
          404: 'Beim Laden der Bilder ist ein Fehler aufgetreten',
          409: 'Beim Laden der Bilder ist ein Fehler aufgetreten',
          422: 'Beim Laden der Bilder ist ein Fehler aufgetreten',
          429: 'Beim Laden der Bilder ist ein Fehler aufgetreten',
          500: 'Beim Laden der Bilder ist ein Fehler aufgetreten',
        },
      },
      put: {
        '#/documents/#/piis': {
          400: 'Fehler beim Speichern der Datei',
          403: 'Fehler beim Speichern der Datei',
          404: 'Fehler beim Speichern der Datei',
          409: 'Fehler beim Speichern der Datei',
          422: 'Fehler beim Speichern der Datei',
          429: 'Fehler beim Speichern der Datei',
          500: 'Fehler beim Speichern der Datei',
        },
        '#/settings/text-labels': {
          400: 'Beim Speichern der Text Labels ist ein Fehler aufgetreten',
          403: 'Beim Speichern der Text Labels ist ein Fehler aufgetreten',
          404: 'Beim Speichern der Text Labels ist ein Fehler aufgetreten',
          409: 'Beim Speichern der Text Labels ist ein Fehler aufgetreten',
          422: 'Beim Speichern der Text Labels ist ein Fehler aufgetreten',
          429: 'Beim Speichern der Text Labels ist ein Fehler aufgetreten',
          500: 'Beim Speichern der Text Labels ist ein Fehler aufgetreten',
        },
        '#/settings/#/image-labels': {
          400: 'Beim Speichern der Bild Labels ist ein Fehler aufgetreten',
          403: 'Beim Speichern der Bild Labels ist ein Fehler aufgetreten',
          404: 'Beim Speichern der Bild Labels ist ein Fehler aufgetreten',
          409: 'Beim Speichern der Bild Labels ist ein Fehler aufgetreten',
          422: 'Beim Speichern der Bild Labels ist ein Fehler aufgetreten',
          429: 'Beim Speichern der Bild Labels ist ein Fehler aufgetreten',
          500: 'Beim Speichern der Bild Labels ist ein Fehler aufgetreten',
        },
        '#/settings/blacklists': {
          400: 'Beim Speichern der Blacklist ist ein Fehler aufgetreten',
          403: 'Beim Speichern der Blacklist ist ein Fehler aufgetreten',
          404: 'Beim Speichern der Blacklist ist ein Fehler aufgetreten',
          409: 'Beim Speichern der Blacklist ist ein Fehler aufgetreten',
          422: 'Beim Speichern der Blacklist ist ein Fehler aufgetreten',
          429: 'Beim Speichern der Blacklist ist ein Fehler aufgetreten',
          500: 'Beim Speichern der Blacklist ist ein Fehler aufgetreten',
        },
        '#/settings/whitelists': {
          400: 'Beim Speichern der Whitelist ist ein Fehler aufgetreten',
          403: 'Beim Speichern der Whitelist ist ein Fehler aufgetreten',
          404: 'Beim Speichern der Whitelist ist ein Fehler aufgetreten',
          409: 'Beim Speichern der Whitelist ist ein Fehler aufgetreten',
          422: 'Beim Speichern der Whitelist ist ein Fehler aufgetreten',
          429: 'Beim Speichern der Whitelist ist ein Fehler aufgetreten',
          500: 'Beim Speichern der Whitelist ist ein Fehler aufgetreten',
        },
        '#/documents/#/reset': {
          400: 'Beim Zurücksetzen des Dokuments in den Originalzustand ist ein Fehler aufgetreten',
          403: 'Beim Zurücksetzen des Dokuments in den Originalzustand ist ein Fehler aufgetreten',
          404: 'Beim Zurücksetzen des Dokuments in den Originalzustand ist ein Fehler aufgetreten',
          409: 'Beim Zurücksetzen des Dokuments in den Originalzustand ist ein Fehler aufgetreten',
          422: 'Beim Zurücksetzen des Dokuments in den Originalzustand ist ein Fehler aufgetreten',
          429: 'Beim Zurücksetzen des Dokuments in den Originalzustand ist ein Fehler aufgetreten',
          500: 'Beim Zurücksetzen des Dokuments in den Originalzustand ist ein Fehler aufgetreten',
        },
        '#/documents/#/finalize': {
          400: 'Beim Abschließen des Dokuments ist ein Fehler aufgetreten',
          403: 'Beim Abschließen des Dokuments ist ein Fehler aufgetreten',
          404: 'Beim Abschließen des Dokuments ist ein Fehler aufgetreten',
          409: 'Beim Abschließen des Dokuments ist ein Fehler aufgetreten',
          422: 'Beim Abschließen des Dokuments ist ein Fehler aufgetreten',
          429: 'Beim Abschließen des Dokuments ist ein Fehler aufgetreten',
          500: 'Beim Abschließen des Dokuments ist ein Fehler aufgetreten',
        },
        '#/documents/#/unfinalize': {
          400: 'Beim Versuch das abgeschlossene Dokument erneut zu öffnen ist ein Fehler aufgetreten',
          403: 'Unzureichende Berechtigungen zum erneuten Öffnen des Dokuments',
          404: 'Beim Versuch das abgeschlossene Dokument erneut zu öffnen ist ein Fehler aufgetreten',
          409: 'Beim Versuch das abgeschlossene Dokument erneut zu öffnen ist ein Fehler aufgetreten',
          422: 'Beim Versuch das abgeschlossene Dokument erneut zu öffnen ist ein Fehler aufgetreten',
          429: 'Beim Versuch das abgeschlossene Dokument erneut zu öffnen ist ein Fehler aufgetreten',
          500: 'Beim Versuch das abgeschlossene Dokument erneut zu öffnen ist ein Fehler aufgetreten',
        },
      },
      post: {
        '#/documents/scores': {
          400: 'Beim Laden der Modellqualität ist ein Fehler aufgetreten',
          403: 'Beim Laden der Modellqualität ist ein Fehler aufgetreten',
          404: 'Beim Laden der Modellqualität ist ein Fehler aufgetreten',
          409: 'Beim Laden der Modellqualität ist ein Fehler aufgetreten',
          422: 'Beim Laden der Modellqualität ist ein Fehler aufgetreten',
          429: 'Beim Laden der Modellqualität ist ein Fehler aufgetreten',
          500: 'Beim Laden der Modellqualität ist ein Fehler aufgetreten',
        },
        '#/documents/anonymized/download': {
          400: 'Beim Herunterladen von mehreren anonymisierten Dokumenten ist ein Fehler aufgetreten',
          403: 'Beim Herunterladen von mehreren anonymisierten Dokumenten ist ein Fehler aufgetreten',
          404: 'Beim Herunterladen von mehreren anonymisierten Dokumenten ist ein Fehler aufgetreten',
          409: 'Beim Herunterladen von mehreren anonymisierten Dokumenten ist ein Fehler aufgetreten',
          422: 'Beim Herunterladen von mehreren anonymisierten Dokumenten ist ein Fehler aufgetreten',
          429: 'Beim Herunterladen von mehreren anonymisierten Dokumenten ist ein Fehler aufgetreten',
          500: 'Beim Herunterladen von mehreren anonymisierten Dokumenten ist ein Fehler aufgetreten',
        },
        '#/documents': {
          400: 'Beim Hochladen der Dokumente ist ein Fehler aufgetreten',
          403: 'Beim Hochladen der Dokumente ist ein Fehler aufgetreten',
          404: 'Beim Hochladen der Dokumente ist ein Fehler aufgetreten',
          409: 'Beim Hochladen der Dokumente ist ein Fehler aufgetreten',
          422: 'Beim Hochladen der Dokumente ist ein Fehler aufgetreten',
          429: 'Beim Hochladen der Dokumente ist ein Fehler aufgetreten',
          500: 'Beim Hochladen der Dokumente ist ein Fehler aufgetreten',
        },
        '#/documents/#/priorize-preprocessing': {
          400: 'Beim Priorisieren der Vorverarbeitung ist ein Fehler aufgetreten',
          403: 'Beim Priorisieren der Vorverarbeitung ist ein Fehler aufgetreten',
          404: 'Beim Priorisieren der Vorverarbeitung ist ein Fehler aufgetreten',
          409: 'Beim Priorisieren der Vorverarbeitung ist ein Fehler aufgetreten',
          422: 'Beim Priorisieren der Vorverarbeitung ist ein Fehler aufgetreten',
          429: 'Beim Priorisieren der Vorverarbeitung ist ein Fehler aufgetreten',
          500: 'Beim Priorisieren der Vorverarbeitung ist ein Fehler aufgetreten',
        },
        '#/documents/#/enable-preprocessing': {
          400: 'Beim Aktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          403: 'Beim Aktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          404: 'Beim Aktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          409: 'Beim Aktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          422: 'Beim Aktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          429: 'Beim Aktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          500: 'Beim Aktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
        },
        '#/documents/#/disable-preprocessing': {
          400: 'Beim Deaktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          403: 'Beim Deaktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          404: 'Beim Deaktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          409: 'Beim Deaktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          422: 'Beim Deaktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          429: 'Beim Deaktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
          500: 'Beim Deaktivieren der Vorverarbeitung ist ein Fehler aufgetreten',
        },
        '#/documents/#/piis': {
          400: 'Beim Speichern der persönlich identifizierbaren Informationen ist ein Fehler aufgetreten',
          403: 'Beim Speichern der persönlich identifizierbaren Informationen ist ein Fehler aufgetreten',
          404: 'Beim Speichern der persönlich identifizierbaren Informationen ist ein Fehler aufgetreten',
          409: 'Beim Speichern der persönlich identifizierbaren Informationen ist ein Fehler aufgetreten',
          422: 'Beim Speichern der persönlich identifizierbaren Informationen ist ein Fehler aufgetreten',
          429: 'Beim Speichern der persönlich identifizierbaren Informationen ist ein Fehler aufgetreten',
          500: 'Beim Speichern der persönlich identifizierbaren Informationen ist ein Fehler aufgetreten',
        },
      },
      delete: {
        '#/documents/#': {
          400: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten',
          403: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten',
          404: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten',
          409: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten',
          422: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten',
          429: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten',
          500: 'Beim Löschen des Dokuments ist ein Fehler aufgetreten',
        },
        '#/documents': {
          400: 'Beim Löschen der Dokumente ist ein Fehler aufgetreten',
          403: 'Beim Löschen der Dokumente ist ein Fehler aufgetreten',
          404: 'Beim Löschen der Dokumente ist ein Fehler aufgetreten',
          409: 'Beim Löschen der Dokumente ist ein Fehler aufgetreten',
          422: 'Beim Löschen der Dokumente ist ein Fehler aufgetreten',
          429: 'Beim Löschen der Dokumente ist ein Fehler aufgetreten',
          500: 'Beim Löschen der Dokumente ist ein Fehler aufgetreten',
        },
      },
    },
    orgs: {
      get: {
        '#/projects/me': {
          400: 'Beim Laden der Projekte ist ein Fehler aufgetreten',
          403: 'Beim Laden der Projekte ist ein Fehler aufgetreten',
          404: 'Beim Laden der Projekte ist ein Fehler aufgetreten',
          409: 'Beim Laden der Projekte ist ein Fehler aufgetreten',
          422: 'Beim Laden der Projekte ist ein Fehler aufgetreten',
          429: 'Beim Laden der Projekte ist ein Fehler aufgetreten',
          500: 'Beim Laden der Projekte ist ein Fehler aufgetreten',
        },
        '/me': {
          400: 'Beim Laden der Organisationen ist ein Fehler aufgetreten',
          403: 'Beim Laden der Organisationen ist ein Fehler aufgetreten',
          404: 'Beim Laden der Organisationen ist ein Fehler aufgetreten',
          409: 'Beim Laden der Organisationen ist ein Fehler aufgetreten',
          422: 'Beim Laden der Organisationen ist ein Fehler aufgetreten',
          429: 'Beim Laden der Organisationen ist ein Fehler aufgetreten',
          500: 'Beim Laden der Organisationen ist ein Fehler aufgetreten',
        },
      },
      put: {
        '#/users/#': {
          400: 'Beim Ändern der Nutzerrolle ist ein Fehler aufgetreten',
          403: 'Beim Ändern der Nutzerrolle ist ein Fehler aufgetreten',
          404: 'Beim Ändern der Nutzerrolle ist ein Fehler aufgetreten',
          409: 'Beim Ändern der Nutzerrolle ist ein Fehler aufgetreten',
          422: 'Beim Ändern der Nutzerrolle ist ein Fehler aufgetreten',
          429: 'Beim Ändern der Nutzerrolle ist ein Fehler aufgetreten',
          500: 'Beim Ändern der Nutzerrolle ist ein Fehler aufgetreten',
        },
        '#/projects/#/users/#': {
          400: 'Beim Hinzufügen des Nutzers / Ändern der Benutzerrolle ist ein Fehler aufgetreten',
          403: 'Beim Hinzufügen des Nutzers / Ändern der Benutzerrolle ist ein Fehler aufgetreten',
          404: 'Beim Hinzufügen des Nutzers / Ändern der Benutzerrolle ist ein Fehler aufgetreten',
          409: 'Beim Hinzufügen des Nutzers / Ändern der Benutzerrolle ist ein Fehler aufgetreten',
          422: 'Beim Hinzufügen des Nutzers / Ändern der Benutzerrolle ist ein Fehler aufgetreten',
          500: 'Beim Hinzufügen des Nutzers / Ändern der Benutzerrolle ist ein Fehler aufgetreten',
        },
      },
      post: {
        '#/projects': {
          400: 'Beim Anlegen des Projekts ist ein Fehler aufgetreten',
          403: 'Beim Anlegen des Projekts ist ein Fehler aufgetreten',
          404: 'Beim Anlegen des Projekts ist ein Fehler aufgetreten',
          409: 'Beim Anlegen des Projekts ist ein Fehler aufgetreten',
          422: 'Beim Anlegen des Projekts ist ein Fehler aufgetreten',
          429: 'Beim Anlegen des Projekts ist ein Fehler aufgetreten',
          500: 'Beim Anlegen des Projekts ist ein Fehler aufgetreten',
        },
      },
      delete: {
        '#/projects/#': {
          400: 'Beim Löschen des Projekts ist ein Fehler aufgetreten',
          403: 'Sie haben keine Berechtigung das Projekt zu löschen',
          404: 'Beim Löschen des Projekts ist ein Fehler aufgetreten',
          409: 'Beim Löschen des Projekts ist ein Fehler aufgetreten',
          422: 'Beim Löschen des Projekts ist ein Fehler aufgetreten',
          429: 'Beim Löschen des Projekts ist ein Fehler aufgetreten',
          500: 'Beim Löschen des Projekts ist ein Fehler aufgetreten',
        },
        '#/projects/#/users/#': {
          400: 'Beim Entfernen des Nutzers aus dem Projekt ist ein Fehler aufgetreten',
          403: 'Beim Entfernen des Nutzers aus dem Projekt ist ein Fehler aufgetreten',
          404: 'Beim Entfernen des Nutzers aus dem Projekt ist ein Fehler aufgetreten',
          409: 'Beim Entfernen des Nutzers aus dem Projekt ist ein Fehler aufgetreten',
          422: 'Beim Entfernen des Nutzers aus dem Projekt ist ein Fehler aufgetreten',
          429: 'Beim Entfernen des Nutzers aus dem Projekt ist ein Fehler aufgetreten',
          500: 'Beim Entfernen des Nutzers aus dem Projekt ist ein Fehler aufgetreten',
        },
        '#/users/#': {
          400: 'Beim Löschen des Users ist ein Fehler aufgetreten',
          403: 'Sie haben keine Berechtigung den User zu löschen',
          404: 'Beim Löschen des Users ist ein Fehler aufgetreten',
          409: 'Beim Löschen des Users ist ein Fehler aufgetreten',
          422: 'Beim Löschen des Users ist ein Fehler aufgetreten',
          429: 'Beim Löschen des Users ist ein Fehler aufgetreten',
          500: 'Beim Löschen des Users ist ein Fehler aufgetreten',
        },
      },
    },
    system: {
      get: {
        system: {
          '/system/version': {
            400: 'Beim Laden der Versionsnummer ist ein Fehler aufgetreten',
            403: 'Beim Laden der Versionsnummer ist ein Fehler aufgetreten',
            404: 'Beim Laden der Versionsnummer ist ein Fehler aufgetreten',
            409: 'Beim Laden der Versionsnummer ist ein Fehler aufgetreten',
            422: 'Beim Laden der Versionsnummer ist ein Fehler aufgetreten',
            429: 'Beim Laden der Versionsnummer ist ein Fehler aufgetreten',
            500: 'Beim Laden der Versionsnummer ist ein Fehler aufgetreten',
          },
        },
      },
    },
    users: {
      put: {
        'change-password': {
          400: 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
          403: 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
          404: 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
          409: 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
          422: 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
          429: 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
          500: 'Beim Ändern des Passworts ist ein Fehler aufgetreten',
        },
      },
    },
    problemCodes: {
      INCORRECT_OLD_PASSWORD: 'Das alte Passwort ist inkorrekt',
      NEW_PASSWORD_SAME_AS_OLD_PASSWORD:
        'Das neue Passwort ist identisch mit dem bisherigen Passwort. Bitte wählen Sie ein anderes Passwort.',
      LAST_ADMIN_DEMOTION: 'Es muss mindestens ein Administrator existieren',
      OPTIMISTIC_LOCK:
        'Die Daten wurden in der Zwischenzeit geändert. Bitte laden Sie die Seite neu.',
      TOKEN_LIMIT_EXCEEDED:
        'Sie haben das Limit an Tokens erreicht. Bitte melden Sie sich bei support@doccape.de',
      RATE_LIMIT_EXCEEDED: 'Sie haben das Limit an Anfragen erreicht.',
      INVALID_PDF_FILE: 'Das Dateiformat wird nicht unterstützt',
      DOCUMENT_TOO_BIG: 'Die Dateigröße überschreitet das Limit',
    },
  },
};

export default translations;
