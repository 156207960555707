import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../../../atoms/icons/SearchIcon';
import Dropdown from '../../../../atoms/tailwind/Dropdown';
import IconInput from '../../../../atoms/tailwind/IconInput';

/**
 * Displays search and filter components for the black and white list
 */
const BlackWhiteListFilter = ({
  getFilteredTextElements,
  setDisplayedTextElements,
  setSelectedTextElement,
}) => {
  const { t } = useTranslation();

  const handleSearch = (event) => {
    const searchString = event.target.value;
    const filteredTextElements = getFilteredTextElements(searchString);
    setDisplayedTextElements(filteredTextElements);
    setSelectedTextElement(null);
  };

  const searchFilterElements = [
    {
      name: 'Search',
      id: 'input-text',
      icon: <SearchIcon color="blue-2" />,
      type: 'text',
      placeholder: t('settings.listing.filter.search'),
      value: '',
      onChange: handleSearch,
    },
    // TODO: Add filters and filter fucntionality
    // {
    //   name: 'Whitelist Filter',
    //   id: 'input-text',
    //   type: 'text',
    //   placeholter: 'Whitelist', // TODO: Translations
    //   value: '',
    //   onChange: () => {},
    // },
    // {
    //   name: 'Blacklist Filter',
    //   id: 'input-text',
    //   type: 'select',
    //   placeholder: 'Blacklist', // TODO: Translations
    //   value: null,
    //   options: [
    //     {
    //       id: 'all-blacklist',
    //       name: 'All', // TODO: Translations
    //       value: 'all-blacklist', // TODO: Translations
    //     },
    //     {
    //       id: 'blacklist-1',
    //       name: 'Blacklist 1', // TODO: Translations
    //       value: 'blacklist-1', // TODO: Translations
    //     },
    //     // textLables,
    //   ],
    //   onChange: () => {},
    // },
  ];

  return (
    <ul className="grid grid-cols-1 justify-items-center gap-4 border-b border-babyBlue-1 py-3.5 md:grid-cols-3 lg:gap-20">
      {searchFilterElements.map((element) => {
        if (element.type === 'text') {
          return (
            <li className="col-span-1 w-full">
              <IconInput
                id={element.id}
                name={element.name}
                type={element.type}
                value={element.value}
                onChange={element.onChange}
                icon={element.icon}
              />
            </li>
          );
        }

        if (element.type === 'select') {
          return (
            <li className="col-span-1 w-full">
              <Dropdown
                selectedItem={element.value}
                items={element.options}
                onChange={element.onChange}
              />
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
};

export default BlackWhiteListFilter;
