import { Center, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NoDocumentsInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <br />
      <br />
      <br />
      <Center>
        <Text fontSize="2xl" fontWeight="bold" color="gray.300">
          {t('documentSelection.noData')}
        </Text>
      </Center>
    </>
  );
};

export default NoDocumentsInfo;
