import React from 'react';

const TrashCanIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m13.51,12.19h10.47c.97,0,.97-1.5,0-1.5h-3.05c.03-.08.05-.17.05-.26-.02-.41-.33-.75-.75-.75h-2.96c-.39,0-.77.34-.75.75,0,.09.03.18.06.26h-3.06c-.97,0-.97,1.5,0,1.5Z" />
      <path d="m24.61,14.21c0-.06-.01-.12-.03-.17-.07-.3-.29-.56-.69-.56h-10.47c-.4,0-.62.26-.69.56-.02.05-.02.11-.03.17,0,.01,0,.02,0,.03,0,.06-.01.12,0,.18.48,2.51.97,5.03,1.45,7.54.07.36.14.73.21,1.09.07.38.35.53.64.53.03,0,.05.02.08.02h7.15s.05-.01.08-.02c.29,0,.57-.15.64-.53.48-2.51.97-5.03,1.45-7.54.07-.36.14-.73.21-1.09.01-.07,0-.12,0-.18,0-.01,0-.02,0-.03Zm-8.64,7.9h-.27c-.45-2.33-.9-4.66-1.35-6.99,0-.05-.02-.09-.03-.14h8.66c-.42,2.2-.85,4.4-1.27,6.59-.03.18-.07.36-.1.54h-5.64Z" />
    </svg>
  );
};

export default TrashCanIcon;
