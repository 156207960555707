import { useState } from 'react';
import { postDocumentSummary } from '../api/document';

const useDocument = (projectId) => {
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [documentSummary, setDocumentSummary] = useState('');

  const [isDocumentSummaryOpen, setIsDocumentSummaryOpen] = useState(false);

  const onDocumentSummary = async (projectId, documentId) => {
    setIsSummaryLoading(true);
    await postDocumentSummary(projectId, documentId).then((res) => {
      setDocumentSummary(res.data);
    });
    setIsSummaryLoading(false);
  };

  return {
    isSummaryLoading,
    onDocumentSummary,
    documentSummary,
    isDocumentSummaryOpen,
    setIsDocumentSummaryOpen,
  };
};

export default useDocument;
