import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrganizations,
  fetchOrganizations,
  selectOrganizationsData,
  selectOrganizationsError,
  selectOrganizationsStatus,
} from '../reducers/organizationsSlice';

const useOrganizations = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);

  const sliceOrganizationsData = useSelector(selectOrganizationsData);
  const fetchStatus = useSelector(selectOrganizationsStatus); // TODO: change naming for all different slices
  const fetchError = useSelector(selectOrganizationsError);

  const initSlice = () => {
    dispatch(fetchOrganizations()).unwrap();
  };

  const transformSliceData = () => {
    setIsLoading(true);
    if (fetchStatus === 'succeeded') {
      setOrganizations(sliceOrganizationsData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initSlice();

    return () => {
      // Clear organizations state when unmounting
      dispatch(clearOrganizations());
    };
  }, []);

  useEffect(() => {
    transformSliceData();
  }, [sliceOrganizationsData]);

  const getOrgById = (orgId) => {
    return organizations.find((org) => org.id === parseInt(orgId, 10));
  };

  return { getOrgById, organizations, isLoading };
};

export default useOrganizations;
