import axios from 'axios';
import queryString from 'query-string';

export const getBaseURL = () => {
  if (process.env.REACT_APP_API_BASE_URL) {
    return process.env.REACT_APP_API_BASE_URL;
  }
  return '/api';
};

export const API = axios.create({
  baseURL: getBaseURL(),
  headers: {},
  paramsSerializer: queryString.stringify,
});
