/**
 * DEPRICATED: REMOVE ASAP
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useUsersProject from '../hooks/useUsersProject';

const UserOverviewProjec = () => {
  const { t } = useTranslation();
  const { organizationId, projectId } = useParams();
  const { users } = useUsersProject(projectId, organizationId);

  return (
    <div className="mx-auto max-w-7xl p-4 sm:p-6 lg:p-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base leading-6">{t('projectMngmt.userMngmt.heading')}</h1>
          <p className="mt-2 text-sm">
            {t('projectMngmt.userMngmt.subHeading')} {projectId}.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
            <table className="min-w-full divide-y ">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-0">
                    {t('projectMngmt.userMngmt.tableHead.name')}
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm lg:table-cell">
                    {t('projectMngmt.userMngmt.tableHead.email')}
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm sm:table-cell">
                    {t('projectMngmt.userMngmt.tableHead.status')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm">
                    {t('projectMngmt.userMngmt.tableHead.role')}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span>Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y bg-white ">
                {users.map((user) => (
                  <tr key={user.email}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-0">
                      {user.firstName} {user.lastName}
                      <dl className="font-normal lg:hidden">
                        <dt>{t('projectMngmt.userMngmt.tableHead.email')}</dt>
                        <dd className="mt-1 truncate">{user.email}</dd>
                        <dt className="sm:hidden">
                          {t('projectMngmt.userMngmt.tableHead.status')}
                        </dt>
                        <dd className="mt-1 truncate sm:hidden">
                          {user.isEmailVerified.toString()}
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm lg:table-cell">{user.email}</td>
                    <td className="hidden px-3 py-4 text-sm sm:table-cell">
                      {user.isEmailVerified.toString()}
                    </td>
                    <td className="px-3 py-4 text-sm">{user.projectRole}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOverviewProjec;
