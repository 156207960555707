import { useEffect, useRef } from 'react';

/**
 * Custom hook for polling. This hook is used to poll a function at a given interval.
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param {Function} callback function to be called on each interval
 * @param {number} interval in milliseconds
 */
const usePolling = (callback, delay) => {
  const savedCallback = useRef();
  const intervalId = useRef(); // id of the interval to be used for clearing

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  /**
   * Stops polling by clearing the interval
   */
  const stopPolling = () => {
    clearInterval(intervalId.current);
  };

  /**
   * Starts polling by initializing the interval with the provided delay and the callback function
   */
  const startPolling = () => {
    // Clear any existing interval before starting a new one
    clearInterval(intervalId.current);
    intervalId.current = setInterval(async () => {
      const isPollingDone = await savedCallback.current();
      if (isPollingDone) {
        stopPolling();
      }
    }, delay);
  };

  return { startPolling };
};

export default usePolling;
