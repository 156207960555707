import { Select } from '@chakra-ui/react';
import React from 'react';

/**
 * Renders the selector for the label of an item in the blacklist
 *
 * @param {array} labels The labels to select from
 * @param {string} label The current label
 * @param {function} handleItemLabelChange The function to handle the change of the tag
 * @returns
 */
const BlacklistItemLabelSelector = ({ label, labels, handleItemLabelChange }) => {
  return (
    <Select value={label} onChange={handleItemLabelChange}>
      {Object.keys(labels).map((labelName) => {
        return (
          <option value={labelName} key={labelName}>
            {labelName}
          </option>
        );
      })}
    </Select>
  );
};

export default BlacklistItemLabelSelector;
