import { Box as ChakraBox } from '@chakra-ui/react';
import React from 'react';

const Box = (props) => {
  const {
    className,
    overflowY,
    bg,
    pt,
    pl,
    pr,
    pb,
    rounded,
    height,
    width,
    onClick,
    sx,
    onMouseUp,
    children,
  } = props;

  return (
    <ChakraBox
      className={className}
      overflowY={overflowY}
      bg={bg}
      rounded={rounded}
      pt={pt}
      pl={pl}
      pr={pr}
      pb={pb}
      height={height}
      wdith={width}
      onClick={onClick}
      onMouseUp={onMouseUp}
      sx={sx}
    >
      {children}
    </ChakraBox>
  );
};

export default Box;
