/**
 * Slice where all public configuration data is stored as well as the current version
 */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getPublicAvailableFeatures } from '../api/system';

const initialState = {
  data: {
    registrationEnabled: false,
  },
  status: '',
  error: '',
};

export const fetchPublicAvailableFeatures = createAsyncThunk(
  'fetch/publicAvailableFeatures',
  async () => {
    const response = await getPublicAvailableFeatures();
    return response.data;
  },
);

export const systemPublicSlice = createSlice({
  name: 'systemPublicFeatures',
  initialState,
  reducers: {
    /**
     * Clears the systemPublicFeatures state by setting it to the initial state
     */
    clearSystemPublicFeatures() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPublicAvailableFeatures.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchPublicAvailableFeatures.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPublicAvailableFeatures.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const selectPublicAvailableFeatures = createSelector(
  (state) => state.systemPublicFeatures,
  (systemPublicFeatures) => systemPublicFeatures.data,
);
export const selectPublicAvailableFeaturesStatus = createSelector(
  (state) => state.systemPublicFeatures,
  (systemPublicFeatures) => systemPublicFeatures.status,
);

export const { clearSystemPublicFeatures } = systemPublicSlice.actions;

export default systemPublicSlice.reducer;
