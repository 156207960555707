import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { getOrganizations } from '../../api/organization';
import useUserData from '../../hooks/useUserData';
import { refreshToken, selectIsAuthenticated, selectToken } from '../../reducers/authSlice';
import AppToaster from '../molecules/AppToaster';
import LoadingPage from './LoadingPage';

/**
 * Custom route component, which renders the children according to if the4 user is authenticated or not.
 * It should only be used for routes that are not protected by authentication.
 * It displays a loading page while the authentication is being checked.
 * Use this component inside of the actual route.
 *
 * @returns {React.ReactNode} Children or navigate component
 * @example
 * <Route exact path="/"
 *   element={
 *   <PublicRoute>
 *     <HomePage />
 *   </PublicRoute>
 *   }
 *   />
 */
const PublicRoute = React.memo(({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);
  const { getUser, clearUser } = useUserData();

  const [isCheckingAuth, setIsCheckingAuth] = React.useState(false);
  const [orgID, setOrgID] = React.useState(null);

  // The token should only be refreshed on the first render, if this check is not present,
  // accessing the login page will result in a endless refresh loop
  React.useEffect(() => {
    /**
     * Checks if the user is (still) authenticated
     */
    async function checkAuth() {
      try {
        setIsCheckingAuth(true);
        if (token === '') {
          await dispatch(refreshToken()).unwrap();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.info("Couldn't refresh token");
      } finally {
        setIsCheckingAuth(false);
      }
    }

    checkAuth();
  }, []);

  /**
   * Retrieves the user data from the backend and saves it in the store
   * if the user is authenticated
   */
  useEffect(() => {
    if (auth) {
      getUser();
    }

    return () => {
      clearUser();
    };
  }, [auth]);

  useEffect(() => {
    if (auth) {
      getOrganizations().then((res) => {
        if (res.data.length === 0) {
          AppToaster({
            description: t('errors.orgs.get./me.400'),
            status: 'error',
          });
        }
        // Always navigate to the first organization (TODO: Implement organization selection if design is finalized)
        const organizationId = res.data[0]?.id;
        setOrgID(organizationId);
      });
    }
  }, [auth]);

  // If the check is still running or the orgID is not yet fetched when authenticated, show a loading page
  if (isCheckingAuth || (auth && orgID === null)) {
    return <LoadingPage />;
  }

  // Construct the redirection path (the url to which the user is routed if authenticated)
  const redirection = `/organizations/${orgID}/projects`;

  // If not authenticated, return an outlet that will render child elements
  // If authenticated, return element that will navigate to the given redirection
  const location = localStorage.getItem('location');
  return !auth ? children : <Navigate to={location || redirection} />;
});

export default PublicRoute;
