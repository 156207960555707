import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import CloseIcon from '../../atoms/icons/CloseIcon';

/**
 * Custom dialog component which can be used in to display a dialog
 *
 * Can be used in combination with the CustomDialogContent component
 * @param {JSX} children - Content of the dialog
 * @param {Boolean} isOpen - Whether the dialog is open
 * @param {Function} onClose - Function to close the dialog
 * @example
 *
 * const form = (
 *  <CustomForm inputFields={loginInputFields} additionalContent={loginAdditionalContent} />
 * );
 *
 * <CustomDialog isOpen={isShown} onClose={closeModalWithoutSaving}>
 *  <CustomDialogContent
 *       title={t('projectMngmt.overview.createProjectModal.title')}
 *       subtitle={t('projectMngmt.overview.createProjectModal.subtitle')}
 *       buttonText={t('projectMngmt.overview.createProjectModal.submit')}
 *       buttonAction={closeAndSaveModal}
 *       content={form}
 *   />
 * </CustomDialog>
 */
const CustomDialog = ({ children, isOpen, onClose, isDialogCloseButton = true }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" static onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-darkGrey-2/75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-11/12 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="flex justify-end text-3xl font-bold uppercase leading-6 text-blue-2"
                >
                  {isDialogCloseButton ? (
                    <button type="button" onClick={onClose}>
                      <CloseIcon />
                    </button>
                  ) : null}
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomDialog;
