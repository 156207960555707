import React from 'react';

/**
 * Custom container for mainly structuring project and document view
 */
const ViewLayout = ({ breadCrumbs, title, content, additionalInfo }) => {
  return (
    <div>
      {/* Header consists of breadbrumbs and title of project */}
      <div>{breadCrumbs}</div>
      <br />
      <div className="flex items-center justify-start space-x-2">
        <p className="font-futura-fat text-4xl text-blue-2">{title}</p>
        {additionalInfo}
      </div>

      <br />
      {/* Content depends on views (project overview or document overview) */}
      <div>{content}</div>
    </div>
  );
};

export default ViewLayout;

