// selection: Selection
export const selectionIsEmpty = (selection) => {
  if (selection.anchorNode) {
    const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);

    return position === 0 && selection.focusOffset === selection.anchorOffset;
  }
  return true;
};

// selection: Selection
export const selectionIsBackwards = (selection) => {
  if (selectionIsEmpty(selection)) return false;

  const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);

  let backward = false;
  if (
    (!position && selection.anchorOffset > selection.focusOffset) ||
    position === Node.DOCUMENT_POSITION_PRECEDING
  )
    backward = true;

  return backward;
};

/**
 * Transforms the color [x,y,z] into rgba string rgba(x,y,z, opacity)
 * @param {*} color
 * @param {*} opacity
 * @returns rgba string
 */
// eslint-disable-next-line import/prefer-default-export
export const transformToRGBA = (color, opacity) => {
  const rgbaColor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
  return rgbaColor;
};

/**
 * Shortens a given text when it is longer than the given `maxLength`
 * @param {string} text text which should be shortened
 * @param {number} maxLength max number of visible charakters of text
 * @returns {string} string with max length + 3 due to "..."
 */
export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

/**
 * Returns the color code (hex) of the given color name (string).
 * This function is used to get the color code of the chakra-ui color names.
 * TODO: As soon as chakra-ui is not used anymore, this function can be removed.
 *
 * @param {string | undefined} colorName The name of the color
 * @returns {string} The color code (hex) of the given color name
 */
export const getColorCodeByName = (colorName) => {
  switch (colorName) {
    case 'lightPurple':
      return '#BCABFF';
    case 'purple':
      return '#7551FF';
    case 'deepPurple':
      return '#D251FF';
    case 'deepPink':
      return '#FF5487';
    case 'pink':
      return '#FFA0C5';
    case 'lightPink':
      return '#FFB9B2';
    case 'deepOrange':
      return '#FB7B05';
    case 'orange':
      return '#FBBC05';
    case 'green':
      return '#01B574';
    case 'deepGreen':
      return '#3EA101';
    case 'lightGreen':
      return '#92D25F';
    case 'teal':
      return '#01AAB5';
    case 'lightTeal':
      return '#70E6D9';
    case 'blue':
      return '#86ABE3';
    case 'lightBlue':
      return '#77A9F4';
    case 'deepBlue':
      return '#003EB9';
    case 'lightGrey':
      return '#7D95B3';
    case 'grey':
      return '#626188';
    case 'black':
      return '#0A1A39';
    case 'brown':
      return '#823408';

    // returns black as default so we can ensure that everything is readable
    default:
      return '#0A1A39';
  }
};
