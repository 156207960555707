import React from 'react';

const LockIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m23.91,15.22h-8.2c0-.15-.01-.29,0-.44,0-.1.01-.2.02-.3,0-.01,0,0,0-.02,0,0,0,0,0,0,.02-.07.02-.14.04-.21.02-.12.05-.24.09-.35.02-.07.04-.13.06-.19,0-.03.01-.04.01-.05,0,0,0,0,0-.02.06-.13.13-.26.21-.38,0-.01.05-.07.07-.11.03-.03.1-.12.13-.15.05-.06.11-.11.17-.17.02-.02.05-.04.07-.06,0,0,0,0,0,0,.14-.1.29-.19.45-.27.08-.04.21-.09.41-.15.19-.05.47-.11.6-.12.52-.06,1.07-.06,1.59.05.48.1.93.3,1.22.56.07.07.14.14.21.21.02.03.05.05.07.08.01.02.04.06.05.07.05.09.11.17.16.26.02.04.04.08.06.12.01.02.01.02.02.03,0,0,0,0,.01.03.15.38.5.64.92.52.36-.1.67-.54.52-.92-.41-1.02-1.12-1.78-2.15-2.2-1.13-.45-2.54-.48-3.71-.13-1.65.49-2.61,1.88-2.78,3.55-.02.25-.02.49,0,.74h-.78c-.38,0-.75.24-.75.72v7.11c0,.18.06.31.14.42.11.2.31.34.61.34h10.47c.29,0,.49-.15.6-.34.08-.11.15-.25.15-.43v-7.11c0-.48-.38-.72-.75-.72Z" />
    </svg>
  );
};

export default LockIcon;
