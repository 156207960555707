import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSystemVersion,
  fetchBackendVersion,
  selectBackendVersion,
  selectBackendVersionStatus,
} from '../reducers/systemVersionSlice';

/**
 * Hook which provides the configuration of the application as well as the current version
 */
const useSystemVersion = () => {
  const dispatch = useDispatch();

  const [backendVersion, setBackendVersion] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const sliceBackendVersion = useSelector(selectBackendVersion);
  const fetchStatus = useSelector(selectBackendVersionStatus);

  const initSlice = () => {
    dispatch(fetchBackendVersion()).unwrap();
  };

  const transformSliceData = () => {
    setIsLoading(true);
    if (fetchStatus === 'succeeded') {
      setBackendVersion(sliceBackendVersion);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clear system version state when unmounting
      dispatch(clearSystemVersion());
    };
  }, []);

  useEffect(() => {
    transformSliceData();
  }, [sliceBackendVersion]);

  return {
    backendVersion,
    isLoading,
  };
};

export default useSystemVersion;
