import React from 'react';
import CustomProjectCard from './CustomProjectCard';

/**
 * Custom container for displaying a grid list.
 * For example fisplaying all projects from a organization
 */
const CustomProjectContainer = ({ projects }) => {
  if (Array.isArray(projects) === false) {
    console.error('Property `projects` for component CustomProjectContainer should be an array!');
    return null;
  }
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {projects.map((project) => (
        <CustomProjectCard key={project.name} project={project} />
      ))}
    </ul>
  );
};

export default CustomProjectContainer;
