import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearProjectsData,
  createProjectDB,
  deleteProjectDB,
  fetchProjectsData,
  selectProjectsData,
  selectProjectsDataError,
  selectProjectsDataStatus,
} from '../reducers/projectDataSlice';
import useUserData from './useUserData';

const useProjects = (organizationId) => {
  const dispatch = useDispatch();
  const { getUser } = useUserData();
  const [isLoading, setIsLoading] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [isValidProjectName, setIsValidProjectName] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [projectNameError, setProjectNameError] = useState(false);
  const [projects, setProjects] = useState([]);

  // ------------- SLICE ACCESS
  const sliceProjectsData = useSelector(selectProjectsData);
  const fetchStatus = useSelector(selectProjectsDataStatus); // TODO: change naming for all different slices
  const fetchError = useSelector(selectProjectsDataError);

  const initSlice = () => {
    dispatch(fetchProjectsData(organizationId)).unwrap();
  };

  const transformSliceData = () => {
    if (fetchStatus === 'pending') {
      setIsLoading(true);
    }
    if (fetchStatus === 'succeeded') {
      setProjects(sliceProjectsData);
      setIsLoading(false);
    }
    if (fetchStatus === 'failed') {
      setIsLoading(false);
    }
  };

  /**
   * Filters projects based on search term
   * @param {string} searchTerm - search term
   */
  const filterProjects = (searchTerm) => {
    const filtered = sliceProjectsData.filter((project) => {
      const projectName = project.name.toLowerCase();
      return projectName.includes(searchTerm.toLowerCase());
    });
    setProjects(filtered);
  };

  const onAddProject = async (projectData) => {
    try {
      setIsLoading(true);
      await dispatch(createProjectDB({ organizationId, projectData })).unwrap();
      setIsValidProjectName(true);
      await getUser();
      return true;
    } catch (error) {
      setIsLoading(false);
      setProjectNameError(true);
      setIsValidProjectName(false);
      return false;
    } finally {
      await dispatch(fetchProjectsData(organizationId)).unwrap();
      setIsLoading(false);
    }
  };

  const onDeleteProject = async (projectId) => {
    try {
      setIsLoading(true);
      await dispatch(deleteProjectDB({ organizationId, projectId })).unwrap();
      return true;
    } catch (error) {
      setIsLoading(false);
      return false;
    } finally {
      await dispatch(fetchProjectsData(organizationId)).unwrap();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlice();

    return () => {
      // Clears projects data state when component unmounts
      dispatch(clearProjectsData());
    };
  }, [organizationId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceProjectsData]);

  return {
    projects,
    sliceProjectsLength: sliceProjectsData.length || 0,
    filterProjects,
    isLoading,
    onAddProject,
    onDeleteProject,
    isShown,
    setIsShown,
    isValidProjectName,
    setIsValidProjectName,
    projectNameError,
    setProjectNameError,
    errorMsg,
    setErrorMsg,
  };
};

export default useProjects;
