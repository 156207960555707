/*
 * Renders the project settings view
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BreadCrumbs from '../components/molecules/tailwind/BreadCrumbs';
import CustomBasicContainerForContent from '../components/molecules/tailwind/CustomBasicContainerForContent';
import CustomContainer from '../components/molecules/tailwind/CustomContainer';
import SettingsContentLayout from '../components/molecules/tailwind/SettingsContentLayout';
import SettingsLayout from '../components/molecules/tailwind/SettingsLayout';
import SettingsSidebar from '../components/molecules/tailwind/SettingsSidebar';
import ViewLayout from '../components/molecules/tailwind/ViewLayout';
import ForbiddenAlert from '../components/organisms/tailwind/ForbiddenAlert';
import BlackWhiteList from '../components/organisms/tailwind/projectSettings/BlackWhiteList/BlackWhiteList';
import GeneralSettings from '../components/organisms/tailwind/projectSettings/GeneralSettings';
import ImageLabelsSettings from '../components/organisms/tailwind/projectSettings/ImageLabels/ImageLabelsSettings';
import UserMngmt from '../components/organisms/tailwind/projectSettings/UserMngmt';
import TextLabelSettings from '../components/organisms/tailwind/projectSettings/textLabels/TextLabelSettings';
import useImageLabels from '../hooks/useImageLabels';
import useOrganizations from '../hooks/useOrganizations';
import useProject from '../hooks/useProject';
import useProjects from '../hooks/useProjects';
import useTextLabels from '../hooks/useTextLabels';
import useUnsavedChangesHandler from '../hooks/useUnsavedChangesHandler';
import { selectUserData } from '../reducers/userSlice';
import { checkProjectRole } from '../services/authService';

const ProjectSettings = () => {
  const { projectId, organizationId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const {
    isSaving,
    allDateLocales,
    textLabels,
    getFilteredTextLabels,
    addTextLabel,
    saveTextLabels,
    changeTextLabelName,
    changeExtendedReplacement,
    setTextLabelAttribute,
    deleteTextLabel,
    hasTextLabelExtendedReplacement,
  } = useTextLabels(projectId);
  const { onDeleteProject } = useProjects(organizationId);
  const {
    projectData,
    onChangeProjectData,
    isValidProjectName,
    setIsValidProjectName,
    errorMsg,
    setErrorMsg,
    projectNameError,
    setProjectNameError,
  } = useProject(organizationId, projectId);
  const { getOrgById } = useOrganizations(organizationId);
  const {
    isSaving: isSavingImageLabels,
    imageLabels,
    addImageLabel,
    getFilteredImageLabels,
    setLabelName,
    setLabelIsEnabled,
    saveImageLabels,
    deleteImageLabel,
  } = useImageLabels(projectId);
  const user = useSelector(selectUserData);
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState(projectData?.name);
  const [projectDescription, setProjectDescription] = useState(projectData?.description);

  useEffect(() => {
    setProjectName(projectData?.name);
    setProjectDescription(projectData?.description);
  }, [projectData]);

  const urlNames = {
    organizations: getOrgById(organizationId)?.name || '',
    projects: projectData?.name || '',
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const { setHasUnsavedChanges, handleStateChange, handleNavigation, wrappedStateSetters } =
    useUnsavedChangesHandler({
      setSelectedItem,
    });

  /**
   * Function which deletes the corresponding project and routes to the
   * Project overview
   */
  const handleProjectDeletion = () => {
    onDeleteProject(projectId);
    navigate(`/organizations/${organizationId}/projects/`);
  };

  /**
   * Handles project name change by user
   * Check if name is empty or not and update project data state
   * @param {*} name project name
   */
  const onChangeProjectName = (name) => {
    if (name.length === 0) {
      setProjectNameError(true);
      setIsValidProjectName(false);
      setErrorMsg(t('projectMngmt.overview.createProjectModal.errorsMsg.projectNameMustNotEmpty'));
    } else {
      setProjectNameError(false);
      setIsValidProjectName(true);
    }
    setProjectName(name);
  };

  /**
   * Handles project description change by user
   * @param {*} description
   */
  const onChangeProjectDescription = (description) => {
    setProjectDescription(description);
  };

  /**
   * Send request and check if the project name already exists
   * @returns boolean
   */
  const changeProjectData = async () => {
    const isSuccessful = await onChangeProjectData({
      name: projectName,
      description: projectDescription,
      // TODO: temporary is always false, because it is not supported yet
      isTemporary: false,
    });
    return isSuccessful;
  };

  /**
   * Set states for error message when the input by user is not valid
   */
  const handleNotValidUserInput = () => {
    setErrorMsg(t('projectMngmt.overview.createProjectModal.errorsMsg.projectAlreadyExists'));
    setProjectNameError(true);
    setIsValidProjectName(false);
  };

  /**
   * Save project data changes (name and/or description) when leaving the input fields (onBlur)
   */
  const saveProjectData = async () => {
    if (projectData.name !== '') {
      const isSuccesful = await changeProjectData();
      if (!isSuccesful) {
        handleNotValidUserInput();
      }
    } else {
      setErrorMsg(t('projectMngmt.overview.createProjectModal.errorsMsg.projectNameMustNotEmpty'));
      setIsValidProjectName(false);
      setProjectNameError(true);
    }
  };

  const sidebarElements = [
    {
      name: t('projectSettings.sidebar.generalSettings'),
      current: selectedItem === 'generalSettings',
      item: 'generalSettings',
      content: (
        <GeneralSettings
          projectName={projectName}
          projectDescription={projectDescription}
          onDelete={handleProjectDeletion}
          onChangeProjectName={onChangeProjectName}
          onChangeProjectDescription={onChangeProjectDescription}
          saveProjectData={saveProjectData}
          errorMsg={errorMsg}
          isValidProjectName={isValidProjectName}
          projectNameError={projectNameError}
        />
      ),
    },
    {
      name: t('projectSettings.sidebar.userMngmt'),
      current: selectedItem === 'userMngmt',
      item: 'userMngmt',
      content: <UserMngmt />,
    },
    {
      name: t('projectSettings.sidebar.pseudonymization'),
      current:
        selectedItem === 'textLabelsFromModel' ||
        selectedItem === 'textLabelsOwn' ||
        selectedItem === 'blackWhiteList' ||
        selectedItem === 'imageLabelsFromModel' ||
        selectedItem === 'imageLabelsOwn',
      children: [
        {
          name: t('projectSettings.sidebar.textLabelsFromModel'),
          item: 'textLabelsFromModel',
          current: selectedItem === 'textLabelsFromModel',
          content: (
            <TextLabelSettings
              isSaving={isSaving}
              // Only show text labels which are from the model
              textLabels={getFilteredTextLabels(true)}
              allTextLabels={textLabels}
              hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
              allDateLocales={allDateLocales}
              addTextLabel={addTextLabel}
              saveTextLabels={saveTextLabels}
              changeTextLabelName={changeTextLabelName}
              changeExtendedReplacement={changeExtendedReplacement}
              setTextLabelAttribute={setTextLabelAttribute}
              deleteTextLabel={deleteTextLabel}
              setHasUnsavedChanges={setHasUnsavedChanges}
              handleTextLabelChange={handleStateChange}
            />
          ),
        },
        {
          name: t('projectSettings.sidebar.textLabelsOwn'),
          item: 'textLabelsOwn',
          current: selectedItem === 'textLabelsOwn',
          content: (
            <TextLabelSettings
              isSaving={isSaving}
              canAddTextLabels
              // Only show text labels which are not from the model
              textLabels={getFilteredTextLabels(false)}
              allTextLabels={textLabels}
              hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
              allDateLocales={allDateLocales}
              addTextLabel={addTextLabel}
              saveTextLabels={saveTextLabels}
              changeTextLabelName={changeTextLabelName}
              changeExtendedReplacement={changeExtendedReplacement}
              setTextLabelAttribute={setTextLabelAttribute}
              deleteTextLabel={deleteTextLabel}
              setHasUnsavedChanges={setHasUnsavedChanges}
              handleTextLabelChange={handleStateChange}
            />
          ),
        },
        {
          name: t('projectSettings.sidebar.blackAndWhite'),
          item: 'blackWhiteList',
          current: selectedItem === 'blackWhiteList',
          content: (
            <BlackWhiteList
              projectId={projectId}
              textLabels={textLabels}
              hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
            />
          ),
        },
        {
          name: t('projectSettings.sidebar.imageLabelsFromModel'),
          item: 'imageLabelsFromModel',
          current: selectedItem === 'imageLabelsFromModel',
          content: (
            <ImageLabelsSettings
              // Only show text labels which are from the model
              imageLabels={getFilteredImageLabels(true)}
              addImageLabel={addImageLabel}
              allImageLabels={imageLabels}
              setLabelIsEnabled={setLabelIsEnabled}
              setLabelName={setLabelName}
              isSaving={isSavingImageLabels}
              saveImageLabels={saveImageLabels}
              canAddImageLabels={false}
              deleteImageLabel={deleteImageLabel}
              setHasUnsavedChanges={setHasUnsavedChanges}
              handleImageLabelChange={handleStateChange}
            />
          ),
        },
        {
          name: t('projectSettings.sidebar.imageLabelsOwn'),
          item: 'imageLabelsOwn',
          current: selectedItem === 'imageLabelsOwn',
          content: (
            <ImageLabelsSettings
              // Only show text labels which are not from the model
              imageLabels={getFilteredImageLabels(false)}
              addImageLabel={addImageLabel}
              allImageLabels={imageLabels}
              setLabelIsEnabled={setLabelIsEnabled}
              setLabelName={setLabelName}
              isSaving={isSavingImageLabels}
              saveImageLabels={saveImageLabels}
              canAddImageLabels
              deleteImageLabel={deleteImageLabel}
              setHasUnsavedChanges={setHasUnsavedChanges}
              handleImageLabelChange={handleStateChange}
            />
          ),
        },
      ],
      content: null,
    },
  ];

  /**
   * Handles the click on a sidebar item
   * @param {*} event Click event
   * @param {*} item The item which was clicked
   */
  const handleItemClick = (event, item) => {
    wrappedStateSetters.setSelectedItem(item);
    event.preventDefault();
  };

  useEffect(() => {
    setSelectedItem(sidebarElements[0].item);
  }, [projectId]);

  /**
   * Renders the content of the sidebar views
   * @returns
   */
  const renderSidebarContent = () => {
    // Iterate over the sidebar elements and render the content of the selected item
    return sidebarElements.map((element) => {
      // If the element is the selected item, render the content
      if (element.item === selectedItem) {
        return (
          <SettingsContentLayout
            title={element.name}
            subtitle={element.name}
            content={element.content}
            buttonAction={() => {
              handleNavigation(() =>
                navigate(`/organizations/${organizationId}/projects/${projectId}`),
              );
            }}
          />
        );
      }

      // If the element has children, iterate over them and render the content of the selected item
      if (element.children !== undefined && element.children !== null) {
        return element.children.map((child) => {
          // If the child is the selected item, render the content
          if (child.item === selectedItem) {
            return (
              <SettingsContentLayout
                title={child.name}
                content={child.content}
                buttonAction={() => {
                  handleNavigation(() =>
                    navigate(`/organizations/${organizationId}/projects/${projectId}`),
                  );
                }}
              />
            );
          }
          return null;
        });
      }

      return null;
    });
  };

  return (
    <CustomBasicContainerForContent>
      {user && checkProjectRole(user, projectId, 'ADMIN') ? (
        <ViewLayout
          breadCrumbs={
            <BreadCrumbs
              urlNames={urlNames}
              urlPath={location.pathname}
              wrapper={handleNavigation}
            />
          }
          title={t('projectSettings.settings')}
          content={
            <SettingsLayout
              settingsSidebar={
                <SettingsSidebar
                  navigation={sidebarElements}
                  handleItemClick={handleItemClick}
                  onDelete={handleProjectDeletion}
                />
              }
              content={renderSidebarContent()}
            />
          }
        />
      ) : (
        <CustomContainer>
          <ForbiddenAlert organizationId={organizationId} />
        </CustomContainer>
      )}
    </CustomBasicContainerForContent>
  );
};

export default ProjectSettings;
