import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { deleteUserFromProject, getUsersByProject, putUserToProject } from '../api/project';

const initialState = {
  data: [],
  status: '',
  error: '',
};

export const fetchUsersByProject = createAsyncThunk(
  'usersOfProject/fetch',
  async ({ orgId, projectId }) => {
    const response = await getUsersByProject(orgId, projectId);
    return response.data;
  },
);

export const addUserToProject = createAsyncThunk(
  'addUser/Project',
  async ({ orgId, projectId, userId, projectRole }) => {
    const response = await putUserToProject(orgId, projectId, userId, projectRole);
    return response.data;
  },
);

export const removeUserFromProject = createAsyncThunk(
  'removeUser/Project',
  async ({ orgId, projectId, userId }) => {
    const response = await deleteUserFromProject(orgId, projectId, userId);
    return response.data;
  },
);

/**
 * The slice of users in project
 */
export const usersOfProjectSlice = createSlice({
  name: 'usersOfProject',
  initialState,
  reducers: {
    /**
     * Clears the users in project state by setting it to the initial state
     */
    clearUsersOfProject() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsersByProject.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchUsersByProject.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchUsersByProject.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectUsersOfProjectData = createSelector(
  (state) => state.usersOfProject,
  (usersOfProject) => usersOfProject.data,
);
export const selectUsersOfProjectStatus = createSelector(
  (state) => state.usersOfProject,
  (usersOfProject) => usersOfProject.status,
);
export const selectUsersOfProjectError = createSelector(
  (state) => state.usersOfProject,
  (usersOfProject) => usersOfProject.error,
);

export const { clearUsersOfProject } = usersOfProjectSlice.actions;

export default usersOfProjectSlice.reducer;
