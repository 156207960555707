import { useState } from 'react';

const useSettings = (projectId) => {
  const [isShown, setIsShown] = useState(false);
  const [isDisbled, setIsDisabled] = useState(false);
  const [activeTextLabelName, setActiveTextLabelName] = useState(null);
  const [nameInputByTextLabelName, setNameInputByTextLabelName] = useState(null);

  // handle-Funktionen hier mit dispatches

  //   const sliceSettings = useSelector(selectSettings);
  //   const fetchStatus = useSelector(selectSettingsStatus);
  //   const fetchError = useSelector(selectSettingsError);

  const initSlice = async () => {};
  const transformSettingsSlice = async () => {};

  return {
    isDisbled,
    setIsDisabled,
    isShown,
    setIsShown,
    activeTextLabelName,
    setActiveTextLabelName,
    nameInputByTextLabelName,
    setNameInputByTextLabelName,
  };
};

export default useSettings;
