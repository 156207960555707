/**
 * Slice which provides reducer and state of the auth state
 */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  login as loginRequest,
  logout as logoutRequest,
  refreshToken as refreshTokenRequest,
  verifyToken as verifyTokenRequest,
} from '../api/authentication';
// eslint-disable-next-line import/no-cycle

const initialState = {
  data: {
    authenticated: false,
    token: '',
  },
  status: 'idle',
  error: null,
};

/**
 * Tries to login the user
 */
export const login = createAsyncThunk('auth/login', async (payload) => {
  const response = await loginRequest(payload);

  return {
    authenticated: true,
    token: response.data.access,
  };
});

/**
 * Handles the token refreshing
 * @param {Function} rejectWithValue Function, which sets the status to rejected
 * @returns State data or rejection
 */
export const refreshToken = createAsyncThunk('auth/refresh', async () => {
  const refreshResponse = await refreshTokenRequest();
  return {
    authenticated: true,
    token: refreshResponse.data.access,
  };
});

/**
 * Tries to update the token
 */
export const verifyToken = createAsyncThunk('token/verify', async () => {
  await verifyTokenRequest();
});

/**
 * Tries to logout the user
 */
export const logout = createAsyncThunk('auth/logout', async () => {
  localStorage.clear();
  sessionStorage.clear();

  // clear token in data of slice state
  await logoutRequest();
  return { authenticated: false, token: '' };
});

/**
 * The slice of the auth state
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state) => {
      state.data.authenticated = true;
    },
    deauthenticate: (state) => {
      state.data.authenticated = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'login pending';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'login succeeded';
        state.data = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.data.authenticated = false;
      })
      .addCase(verifyToken.pending, (state) => {
        state.status = 'verification pending';
      })
      .addCase(verifyToken.fulfilled, (state) => {
        state.status = 'verification succeeded';
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.status = 'verification failed';
        state.error = action.payload;
      })
      .addCase(refreshToken.pending, (state) => {
        state.status = 'refreshing pending';
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.status = 'refreshing succeeded';
        state.data = action.payload;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.status = 'refreshing failed';
        state.data.authenticated = false;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = 'logout succeeded';
        state.data = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { authenticate, deauthenticate } = authSlice.actions;

export const selectToken = createSelector(
  (state) => state.auth,
  (auth) => auth.data.token,
);
export const selectIsAuthenticated = createSelector(
  (state) => state.auth,
  (auth) => auth.data.authenticated,
);

export default authSlice.reducer;
