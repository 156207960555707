import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSystemFeatures,
  fetchAvailableFeatures,
  selectAvailableFeatures,
  selectAvailableFeaturesStatus,
} from '../reducers/systemFeaturesSlice';

/**
 * Hook which provides the configuration of the application as well as the current version
 */
const useSystemFeatures = () => {
  const dispatch = useDispatch();

  const [availableFeatures, setAvailableFeatures] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const sliceAvailableFeatures = useSelector(selectAvailableFeatures);
  const fetchStatus = useSelector(selectAvailableFeaturesStatus);

  const initSlice = () => {
    dispatch(fetchAvailableFeatures()).unwrap();
  };

  const transformSliceData = () => {
    setIsLoading(true);
    if (fetchStatus === 'succeeded') {
      setAvailableFeatures(sliceAvailableFeatures);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clear system features state when unmounting
      dispatch(clearSystemFeatures());
    };
  }, []);

  useEffect(() => {
    transformSliceData();
  }, [sliceAvailableFeatures]);

  return {
    availableFeatures,
    isLoading,
  };
};

export default useSystemFeatures;
