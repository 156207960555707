import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/tailwind/Button';

/**
 * Displays cards with subscription plans
 * @returns {JSX.Element} SubscriptionPlans component
 */
const SubscriptionPlans = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center p-6">
      <div className="mb-5 overflow-hidden p-3">
        <h1 className="text-4xl font-bold text-white">{t('subscription.more')}</h1>
      </div>
      <div className="flex w-2/3 flex-col justify-center space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
        <div className="min-w-60 relative w-1/2 overflow-hidden rounded-lg bg-white shadow-lg lg:w-2/6">
          <div className="p-6">
            <h2 className="mb-4 text-2xl font-bold text-blue-2">Demo</h2>
            <p className="mb-4 text-grey-7">{t('subscription.test')}</p>
            <ul className="mb-6 list-inside list-disc text-blue-2">
              <li>{t('subscription.text')}</li>
              <li>{t('subscription.face')}</li>
              <li>{t('subscription.basicAI')}</li>
            </ul>
          </div>
          <div className="absolute bottom-0 w-full bg-grey-4 p-6 text-center">
            <Button
              buttonText={t('subscription.resume')}
              buttonAction={onClose}
              color="white"
              fullWidth
            />
          </div>
        </div>
        <div className="min-w-60 w-1/2 overflow-hidden rounded-lg bg-white shadow-lg lg:w-2/6">
          <div className="p-6">
            <h2 className="mb-4 text-2xl font-bold text-blue-2">Enterprise</h2>
            <p className="mb-4 text-grey-7">{t('subscription.productive')}</p>
            <ul className="mb-6 list-inside list-disc text-blue-2">
              <li>{t('subscription.textPicture')}</li>
              <li>{t('subscription.licencePlate')}</li>
              <li>{t('subscription.ocr')}</li>
              <li>{t('subscription.ownAI')}</li>
            </ul>
          </div>
          <div className="bg-grey-4 px-6 pt-6 text-center">
            <Button
              buttonText={t('subscription.appointment')}
              buttonAction={() =>
                window.open(
                  'https://cal.scitlab.de/team/doccape/beratung',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              color="blue"
              fullWidth
            />
            <p>
              {t('subscription.writeMail')}{' '}
              <a
                className="text-babyBlue-4"
                href="mailto:support@doccape.de?subject=Contact doccape"
              >
                Mail
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
