import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';

/**
 * Label component for displaying text labels
 * @param {string} name - Name of the label
 * @param {string} rightIcon - Icon to be displayed on the right side of the label
 * @param {string} color - Color of the label (default: grey)
 * @param {boolean} disableHover - Whether the hover effect should be disabled or not
 * @param {string} hoverColor - Color of the label on hover (default: grey)
 * @param {function} onClick - Function to be called on click
 * @param {boolean} active - Whether the label is active or not
 *
 * @example
 * <Label name="Label" rightIcon={<DotIcon />} color="grey" hoverColor="grey" />
 */
const Label = ({
  name,
  rightIcon,
  color = 'grey',
  disableHover = false,
  hoverColor = 'grey',
  activeColor = 'grey',
  onClick,
  active,
  disabled = false,
  leftIcon,
  isImageLabel = false, // TODO: This is only a temporary solution!! As soon as shorter label names are enforced, this can be removed
}) => {
  const colorVariants = {
    lightPurple: 'text-purple-1 ring-purple-1',
    purple: 'text-purple-2 ring-purple-2',
    deepPurple: 'text-purple-3 ring-purple-3',
    lightPink: 'text-pink-1 ring-pink-1',
    pink: 'text-pink-2 ring-pink-2',
    deepPink: 'text-pink-3 ring-pink-3',
    orange: 'text-orange-1 ring-orange-1',
    deepOrange: 'text-orange-2 ring-orange-2',
    green: 'text-green-2 ring-green-2',
    deepGreen: 'text-green-4 ring-green-4',
    lightGreen: 'text-green-3 ring-green-3',
    teal: 'text-teal-2 ring-teal-2',
    lightTeal: 'text-teal-1 ring-teal-1',
    blue: 'text-babyBlue-8 ring-babyBlue-8',
    lightBlue: 'text-babyBlue-4 ring-babyBlue-4',
    deepBlue: 'text-blue-3 ring-blue-3',
    lightGrey: 'text-grey-6 ring-grey-6',
    grey: 'text-grey-7 ring-grey-7',
    black: 'text-black-1 ring-black-1',
    brown: 'text-brown-1 ring-brown-1',
  };

  const hoverColorVariants = {
    lightPurple: 'hover:bg-purple-1 hover:text-white hover:ring-purple-1',
    purple: 'hover:bg-purple-2 hover:text-white hover:ring-purple-2',
    deepPurple: 'hover:bg-purple-3 hover:text-white hover:ring-purple-3',
    lightPink: 'hover:bg-pink-1 hover:text-white hover:ring-pink-1',
    pink: 'hover:bg-pink-2 hover:text-white hover:ring-pink-2',
    deepPink: 'hover:bg-pink-3 hover:text-white hover:ring-pink-3',
    orange: 'hover:bg-orange-1 hover:text-white hover:ring-orange-1',
    deepOrange: 'hover:bg-orange-2 hover:text-white hover:ring-orange-2',
    green: 'hover:bg-green-2 hover:text-white hover:ring-green-2',
    deepGreen: 'hover:bg-green-4 hover:text-white hover:ring-green-4',
    lightGreen: 'hover:bg-green-3 hover:text-white hover:ring-green-3',
    teal: 'hover:bg-teal-2 hover:text-white hover:ring-teal-2',
    lightTeal: 'hover:bg-teal-1 hover:text-white hover:ring-teal-1',
    blue: 'hover:bg-babyBlue-8 hover:text-white hover:ring-babyBlue-8',
    lightBlue: 'hover:bg-babyBlue-4 hover:text-white hover:ring-babyBlue-4',
    deepBlue: 'hover:bg-blue-3 hover:text-white hover:ring-blue-3',
    lightGrey: 'hover:bg-grey-6 hover:text-white hover:ring-grey-6',
    grey: 'hover:bg-grey-7 hover:text-white hover:ring-grey-7',
    black: 'hover:bg-black-1 hover:text-white hover:ring-black-1',
    brown: 'hover:bg-brown-1 hover:text-white hover:ring-brown-1',
  };

  const activeColorVariants = {
    lightPurple: 'text-white bg-purple-1 ring-purple-1',
    purple: 'text-white bg-purple-2 ring-purple-2',
    deepPurple: 'text-white bg-purple-3 ring-purple-3',
    lightPink: 'text-white bg-pink-1 ring-pink-1',
    pink: 'text-white bg-pink-2 ring-pink-2',
    deepPink: 'text-white bg-pink-3 ring-pink-3',
    orange: 'text-white bg-orange-1 ring-orange-1',
    deepOrange: 'text-white bg-orange-2 ring-orange-2',
    green: 'text-white bg-green-2 ring-green-2',
    deepGreen: 'text-white bg-green-4 ring-green-4',
    lightGreen: 'text-white bg-green-3 ring-green-3',
    teal: 'text-white bg-teal-2 ring-teal-2',
    lightTeal: 'text-white bg-teal-1 ring-teal-1',
    blue: 'text-white bg-babyBlue-8 ring-babyBlue-8',
    lightBlue: 'text-white bg-babyBlue-4 ring-babyBlue-4',
    deepBlue: 'text-white bg-blue-3 ring-blue-3',
    lightGrey: 'text-white bg-grey-6 ring-grey-6',
    grey: 'text-white bg-grey-7 ring-grey-7',
    black: 'text-white bg-black-1 ring-black-1',
    brown: 'text-white bg-brown-1 ring-brown-1',
  };

  const createClassNames = () => {
    // TODO: This is only a temporary solution!! As soon as shorter label names are enforced, this can be removed
    let baseClassNames = '';
    if (isImageLabel) {
      baseClassNames =
        'inline-flex flex-shrink-0 items-center justify-start rounded-md w-20 px-2 py-1 text-base font-medium ring-1 ring-inset';
    } else {
      baseClassNames =
        'inline-flex flex-shrink-0 items-center justify-start rounded-md w-16 px-2 py-1 text-base font-medium ring-1 ring-inset';
    }
    let colorClassNames = colorVariants[color];
    let hoverClassNames = `${hoverColorVariants[hoverColor]} hover:cursor-pointer`;
    if (active) {
      colorClassNames = activeColorVariants[activeColor];
    }
    if (disableHover) {
      hoverClassNames = '';
    }
    if (disabled) {
      colorClassNames = 'text-grey-3 ring-grey-3';
      hoverClassNames = 'hover:cursor-not-allowed';
    }

    return classNames(baseClassNames, colorClassNames, hoverClassNames);
  };

  return (
    <span onClick={onClick} className={createClassNames()}>
      <div className={leftIcon ? 'mr-1 w-1' : ''}>{leftIcon}</div>
      {name}
      <div className="mr-1 w-1">{rightIcon}</div>
    </span>
  );
};

export default Label;
