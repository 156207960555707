import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../atoms/tailwind/Button';
import Input from '../../../atoms/tailwind/Input';
import AppToaster from '../../../molecules/AppToaster';

/**
 * Component which allows the user to change their own data
 * within the user settings
 */
const EditProfile = ({ userFirstName, userLastName, updateUserProfile }) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState(userFirstName || '');
  const [lastName, setLastName] = useState(userLastName || '');

  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [errorMsgFirstName, setErrorMsgFirstName] = useState('');
  const [errorMsgLastName, setErrorMsgLastName] = useState('');

  const updateUserData = async () => {
    if (!firstName || firstName === '') {
      setIsValidFirstName(false);
      setErrorMsgFirstName(t('userSettings.general.error.firstNameEmpty'));
      return;
    }
    setIsValidFirstName(true);
    setErrorMsgFirstName('');

    if (!lastName || lastName === '') {
      setIsValidLastName(false);
      setErrorMsgLastName(t('userSettings.general.error.lastNameEmpty'));
      return;
    }
    setIsValidLastName(true);
    setErrorMsgLastName('');

    try {
      await updateUserProfile(firstName, lastName).then(() => {
        AppToaster({
          description: t('userSettings.general.successUpdateUserData'),
          status: 'success',
          position: 'bottom',
          duration: 1000,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="mb-5 grid grid-cols-3">
        <div className="pr-5 text-right align-top text-xl text-blue-2">
          {t('userSettings.general.firstName')}
        </div>
        <div className="col-span-1">
          <Input
            isValid={isValidFirstName}
            type="text"
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
          <div className="text-center text-red-3 lg:text-xs">{errorMsgFirstName}</div>
        </div>
      </div>

      <div className="grid grid-cols-3">
        <div className="pr-5 text-right align-top text-xl text-blue-2">
          {t('userSettings.general.lastName')}
        </div>
        <div className="col-span-1">
          <Input
            isValid={isValidLastName}
            type="text"
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
          <div className="text-center text-red-3 lg:text-xs">{errorMsgLastName}</div>
        </div>
      </div>

      <div className="grid grid-cols-3">
        <div />
        <div className="col-span-2 mt-5">
          <Button
            buttonText={t('userSettings.general.updateUserData')}
            buttonAction={updateUserData}
          />
        </div>
      </div>
    </>
  );
};

export default EditProfile;
