import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import AppToaster from '../components/molecules/AppToaster';
import {
  clearImageLabels,
  fetchImageLabels,
  selectImageLabels,
  selectImageLabelsError,
  selectImageLabelsStatus,
  updateImageLabels,
} from '../reducers/imageLabelsSlice';
import {
  createLabelColor,
  transformToSliceData,
  updateImageLabel,
} from '../services/imageLabelService';
import { createImageLabel } from '../types/imageLabel';

const useImageLabels = (projectId) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // ------------- SLICE ACCESS
  const sliceImageLabels = useSelector(selectImageLabels);

  const [isSaving, setIsSaving] = useState(false);

  const fetchStatus = useSelector(selectImageLabelsStatus);
  const fetchError = useSelector(selectImageLabelsError);

  // ------------- STATES
  const [imageLabels, setImageLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initSliceData = async () => {
    setIsLoading(true);
    await dispatch(fetchImageLabels({ projectId })).unwrap();
  };

  const transformSliceData = () => {
    if (fetchStatus === 'succeeded') {
      setIsLoading(true);
      const transformedImageLabels = sliceImageLabels.map((sliceImageLabel) =>
        createImageLabel({
          ...sliceImageLabel,
          color: createLabelColor(sliceImageLabel.id),
          isHidden: false,
        }),
      );
      setImageLabels(transformedImageLabels);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSliceData();
    return () => {
      // Clear image labels state when component unmounts
      dispatch(clearImageLabels());
    };
  }, [projectId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceImageLabels]);

  const findImageLabelIdxByName = (imageLabelName) => {
    return imageLabels.findIndex((imageLabel) => imageLabel.name === imageLabelName);
  };

  const imageAnonymizationTypes = {
    MASK: {
      translationKey: 'settings.imageAnonymization.imageAnonymizationTypeMASK',
      anonymizationParameters: {},
    },
  };

  // ------------- HOOK FUNCTIONS

  const setLabelAnonymizationType = (imageLabelName, anonymizationType) => {
    const labelIdx = findImageLabelIdxByName(imageLabelName);
    if (labelIdx !== -1) {
      const updatedLabel = {
        ...imageLabels[labelIdx],
        anonymizationType,
      };

      const newImageLabels = updateImageLabel(imageLabels, labelIdx, updatedLabel);

      setImageLabels(newImageLabels);
    }
  };

  /**
   * Updates the isEnabled property of the image label with the given name
   * @param {string} imageLabelName - The name of the image label to update
   * @param {boolean} isEnabled - The new value of the isEnabled property
   */
  const setLabelIsEnabled = (imageLabelName, isEnabled) => {
    const labelIdx = findImageLabelIdxByName(imageLabelName);

    // If label is not found, do nothing because nothing can be updated
    if (labelIdx !== -1) {
      const updatedLabel = {
        ...imageLabels[labelIdx],
        isEnabled,
      };

      const newImageLabels = updateImageLabel(imageLabels, labelIdx, updatedLabel);
      setImageLabels(newImageLabels);
    }
  };

  /**
   * Updates the name of the image label with the given name
   * @param {string} imageLabelName - The name of the image label to update
   * @param {string} newName - The new name of the image label
   */
  const setLabelName = (imageLabelName, newName) => {
    const labelIdx = findImageLabelIdxByName(imageLabelName);
    if (labelIdx !== -1) {
      if (imageLabels[labelIdx].fromModel) {
        throw new Error('Cannot rename image label from model!');
      }

      const updatedLabel = {
        ...imageLabels[labelIdx],
        name: newName,
      };

      const newImageLabels = updateImageLabel(imageLabels, labelIdx, updatedLabel);
      setImageLabels(newImageLabels);
    }
  };

  const saveImageLabels = async (newImageLabels) => {
    setIsSaving(true);
    const newSliceImageLabels = transformToSliceData(newImageLabels);
    await dispatch(updateImageLabels({ projectId, imageLabels: newSliceImageLabels })).unwrap();
    AppToaster({
      description: t('settings.imageAnonymization.updatingImageLabelsSuccessToast'),
      status: 'success',
    });
    setIsSaving(false);
  };

  /**
   * Adds a new image label to the state and immediately(!) saves it to the backend
   * @param {string} labelName - Name of the new image label
   *
   * @return boolean - true if the label was added, false otherwise
   */
  const addImageLabel = async (labelName) => {
    const newTextLabel = createImageLabel({
      name: labelName,
      isEnabled: true,

      color: createLabelColor(imageLabels.length),
    });

    const updatedImageLabels = [...imageLabels, newTextLabel];
    try {
      await saveImageLabels(updatedImageLabels);
      setImageLabels(updatedImageLabels);
      return true;
    } catch (error) {
      return false;
    }
  };

  /**
   * Deletes the image label with the given name
   * @param {string} imageLabelName - The name of the image label to delete
   */
  const deleteImageLabel = async (imageLabelName) => {
    const labelIdx = findImageLabelIdxByName(imageLabelName);
    if (labelIdx !== -1) {
      if (imageLabels[labelIdx].fromModel) {
        throw new Error('Cannot rename image label from model!');
      }

      const newImageLabels = [...imageLabels];
      newImageLabels.splice(labelIdx, 1);

      await saveImageLabels(newImageLabels);
      setImageLabels(newImageLabels);
    }
  };

  const setLabelIsHidden = (labelName, isHidden) => {
    const labelIdx = imageLabels.findIndex((label) => label.name === labelName);
    const labelAtIdx = { ...imageLabels[labelIdx] };
    const updatedLabel = { ...labelAtIdx, isHidden };
    const newImageLabels = updateImageLabel(imageLabels, labelIdx, updatedLabel);
    setImageLabels(newImageLabels);
  };

  /**
   * Returns a list with all image labels which are from the model or not depending on the parameter `fromModal`
   * @param {boolean} fromModal - If true, only image labels from the model are returned, otherwise only text labels not from the model (default: true)
   * @returns {object} - Object with text labels filtered by the parameter `fromModal`
   */
  const getFilteredImageLabels = (fromModal = true) => {
    // get all filtered attributes
    const filteredTextLabels = imageLabels.filter(
      (imageLabel) => imageLabel.fromModel === fromModal,
    );

    return filteredTextLabels;
  };

  return {
    isSaving,
    getFilteredImageLabels,
    addImageLabel,
    imageLabels,
    isLoading,
    imageAnonymizationTypes,
    setLabelAnonymizationType,
    setLabelIsHidden,
    setLabelIsEnabled,
    setLabelName,
    saveImageLabels,
    deleteImageLabel,
  };
};

export default useImageLabels;
