import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';
import DotIcon from '../../atoms/icons/DotIcon';
import Label from './Label';

/**
 * Displays a column of labels (image or text labels)
 * @param {object[]} labels - A list of labels to be displayed
 * @param {string} activeLabelName - The name of the currently active label
 * @param {function} setActiveLabelName - Sets the name of the currently active label
 * @param {function} hasLabelExtendedReplacement - A function which returns true if the label has an extended replacement (optional)
 * @param {boolean} canAddLabel - Whether a label can be added default: false
 * @returns
 */
const LabelSelectionArea = ({
  labels,
  activeLabelName,
  setActiveLabelName,
  hasLabelExtendedReplacement,
  canAddLabel,
  isImageLabel = false, // TODO: This is only a temporary solution!! As soon as shorter label names are enforced, this can be removed
}) => {
  // If no label can be edited (because the labels are from the model), the height is bigger, because no add button is shown
  const height = canAddLabel ? 'h-[30rem]' : 'h-[33rem]';

  /**
   * Boolean which is true if the labels are iterable (array) and false if they are an object
   * @type {boolean}
   */
  const areLabelsIterable = labels !== undefined && labels !== null && Array.isArray(labels);

  const renderSelectionItem = ([labelName, label]) => {
    return (
      <li key={labelName} className="my-2 self-center px-1">
        <Label
          name={labelName}
          rightIcon={
            // only if hasLabelExtendedReplacement is defined and hasLabelExtendedReplacement(label) is true, the DotIcon is shown
            hasLabelExtendedReplacement !== undefined &&
            hasLabelExtendedReplacement !== null &&
            hasLabelExtendedReplacement(label) ? (
              <DotIcon />
            ) : null
          }
          color={label.color}
          hoverColor={label.color}
          activeColor={label.color}
          onClick={() => setActiveLabelName(labelName)}
          active={activeLabelName === labelName}
          isImageLabel={isImageLabel} // TODO: This is only a temporary solution!! As soon as shorter label names are enforced, this can be removed
        />
      </li>
    );
  };

  return (
    <div className="col-span-1 flex h-fit w-[8rem] flex-col rounded-xl border border-babyBlue-1 bg-white p-2 shadow">
      <ul
        className={classNames(
          `${height} flex w-full flex-col overflow-y-auto overflow-x-hidden px-5 align-middle`,
        )}
      >
        {areLabelsIterable
          ? labels.map((label) => renderSelectionItem([label.name, label]))
          : Object.entries(labels).map(([labelName, label]) =>
              renderSelectionItem([labelName, label]),
            )}
      </ul>
    </div>
  );
};

export default LabelSelectionArea;
