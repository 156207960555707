import { useEffect, useRef, useState } from 'react';
import hashObject from '../services/versionControlService';

const useVersionControl = (annotations, images) => {
  // Hash of the data when the compilation of the document was started
  const [compileHash, setCompileHash] = useState(null);
  // Hash of the data when the document was saved
  const [saveHash, setSaveHash] = useState(null);

  const [isDocumentOutdated, setIsDocumentOutdated] = useState(false);
  const isFirstRender = useRef(true);

  /**
   * Initializes the compile and save hashes
   */
  const initHashes = async () => {
    const hash = await hashObject({ annotations, images });
    setCompileHash(hash);
    setSaveHash(hash);
  };

  /**
   * Compares the compile hash with the current hash
   */
  const compareVersions = async () => {
    const hash = await hashObject({ annotations, images });

    // Compare hash with the compileHash
    // If the hash is different, the document was changed
    // If the hash is the same, the document was not changed
    setIsDocumentOutdated(compileHash !== hash);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      if (annotations !== null && images !== null) {
        initHashes();
        isFirstRender.current = false;
      }
    } else {
      compareVersions();
    }
  }, [annotations, images, compileHash]);

  return { isDocumentOutdated, initHashes };
};

export default useVersionControl;
