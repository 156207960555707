import React from 'react';

/**
 * Custom container for centering content
 * Can be used in combination with other containers like the LoginContainer
 */
const CustomContainer = ({ children }) => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center bg-transparent px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm md:max-w-md lg:max-w-lg">{children}</div>
    </div>
  );
};

export default CustomContainer;
