import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import doccapeLogo from '../../../../assets/DocCape_Favicon.svg';
import ThumbsUp from '../../../../assets/DocCape_Hand_thumbsup_yellow.png';
import CustomCard from '../../../molecules/tailwind/CustomCard';

/**
 * Component for the card that is shown when the user tries to use an already used token
 * @param {string} title The title of the card
 * @param {string} subtitle The subtitle of the card
 */
const AlreadyUsedTokenCard = ({ title, subtitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  return (
    <CustomCard
      image={logoImage}
      title={title}
      subtitle={subtitle}
      buttonText={t('organizationMngmt.invitationUserAlreadyUsedToken.sendLinkAgainButton')}
      buttonAction={() => navigate('/login')}
      content={
        <div className="flex w-full items-center justify-center">
          <img className="w-20" src={ThumbsUp} alt="Thumbs Icon" />
        </div>
      }
    />
  );
};

export default AlreadyUsedTokenCard;
