import React from 'react';
import LabelDropdown from '../../../../../molecules/tailwind/LabelDropDown';

const BlacklistSelectionArea = ({
  textLabels,
  selectedTextElement,
  blacklist,
  whitelist,
  hasTextLabelExtendedReplacement,
  changeBlacklistLabel,
}) => {
  /**
   * Creates the labels for the blacklist dropdown
   * @returns {Array} labels - The list of labels for the blacklist dropdown
   */
  const createLabelsForDropdown = () => {
    // Creates a list of all label names that are in the whitelist of the current text element
    const whitelistLabels = [];
    whitelist.forEach((item) => {
      if (item.value === selectedTextElement) {
        whitelistLabels.push(item.labelName);
      }
    });

    const labels = [];
    Object.entries(textLabels).forEach(([labelName, textLabel]) => {
      labels.push({
        id: labelName,
        name: labelName,
        value: textLabel,
        disabled: whitelistLabels.includes(labelName),
      });
    });
    return labels;
  };

  const labelsForDropdown = createLabelsForDropdown();

  /**
   * Returns the blacklisted label of the given text element
   * @returns {String | null} selectedBlacklistedLabel - The blacklisted label of the given text element
   */
  const getBlacklistedLabelOfTextElement = (textelement) => {
    const selectedBlacklistedLabel = blacklist.filter((item) => item.value === textelement);
    return selectedBlacklistedLabel.length > 0 ? selectedBlacklistedLabel[0].labelName : null;
  };

  /**
   * Returns the dropdown item representing the selected label
   * @returns {Object | null} selectedLabel - The dropdown item representing the selected label
   */
  const getSelectedLabel = () => {
    const selectedBlacklistedLabel = getBlacklistedLabelOfTextElement(selectedTextElement);
    // Get the dropdown item representing the selected label
    const selectedLabel = labelsForDropdown.filter(
      (item) => item.name === selectedBlacklistedLabel,
    );
    return selectedLabel.length > 0 ? selectedLabel[0] : null;
  };

  const handleLabelChange = (labelName) => {
    const oldLabelName = getSelectedLabel()?.name || null;
    changeBlacklistLabel(selectedTextElement, oldLabelName, labelName);
  };

  return (
    <LabelDropdown
      selectedItem={getSelectedLabel()}
      items={labelsForDropdown}
      onChange={(label) => handleLabelChange(label.name)}
      hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
    />
  );
};

export default BlacklistSelectionArea;
