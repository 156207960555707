import React from 'react';
import Button from '../../atoms/tailwind/Button';

/**
 * Custom card component that can display different content
 * @param {String} title - Title of the card
 * @param {String} subtitle - Subtitle of the card
 * @param {JSX} image - Image of the card
 * @param {JSX} content - Content of the card
 * @param {String} buttonText - Text of the button
 * @param {Function} buttonAction - Action of the button
 * @param {Boolean} disabledButton - If the button should be disabled
 * @param {JSX} additionalButton - Additional buttons
 * @param {String} width - Width of the card [md, lg] (default: md)
 * @example
 * const image = (
 *  <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
 * );
 *
 * const form = (
 *  <CustomForm inputFields={loginInputFields} additionalContent={loginAdditionalContent} />
 * );
 *
 * <CustomCard
 * image={image}
 * title={t('login.header')}
 * buttonText={t('login.login')}
 * buttonAction={() => {navigate('/home');}}
 * content={form}
 * />
 */
const CustomCard = ({
  title,
  subtitle,
  image,
  content,
  buttonText,
  disabledButton,
  buttonAction,
  additionalButton = null,
  width = 'md',
}) => {
  const cardWidth = width === 'md' ? 'w-[32rem]' : 'w-[62rem]';
  return (
    <div className={`${cardWidth} rounded-xl bg-white p-12 shadow`}>
      {/* 
        Header section of the card
      */}
      <div className="flex w-full justify-center">{image}</div>
      <br />
      <p className="flex w-full justify-center text-center text-2xl font-bold text-blue-2">
        {title}
      </p>
      {subtitle === null ? null : <br />}
      <p className="flex w-full justify-center text-center text-2xl text-blue-2">{subtitle}</p>
      <br />

      {content}

      {content && (buttonText || buttonAction) ? (
        <>
          <br />
          <br />
        </>
      ) : null}

      {/* 
        Action section of the card
      */}
      {buttonAction || buttonText ? (
        <div className="flex flex-col gap-3">
          <Button
            buttonText={buttonText}
            color="blue-large"
            fullWidth
            buttonAction={buttonAction}
            disabled={buttonAction === null || disabledButton}
          />
          {additionalButton}
        </div>
      ) : null}
    </div>
  );
};

export default CustomCard;
