import { useState } from 'react';

import { postScores } from '../api/project';

const useScores = (projectId) => {
  const [isScoresLoading, setIsScoresLoading] = useState(false);
  const [scores, setScores] = useState([]);

  const computeScores = async (documentIds) => {
    setIsScoresLoading(true);
    await postScores(projectId, documentIds).then((res) => {
      setScores(res.data);
    });
    setIsScoresLoading(false);
  };

  return {
    isScoresLoading,
    setIsScoresLoading,
    computeScores,
    scores,
  };
};

export default useScores;
