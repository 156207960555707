import { API } from './axios';

/**
 * Retrieves the data of the currently logged in user
 */
export const getUserData = () => {
  return API.get(`users/me`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Updates user data
 * @param {*} firstname new value of the first name
 * @param {*} lastname new value of the last name
 * @returns Promise
 */
export const putUserData = (firstName, lastName) => {
  return API.put('/users/me', { firstName, lastName }, { withCredentials: true });
};
