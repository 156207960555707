import React from 'react';
import LabelEditRow from './LabelEditRow';

/**
 * Displays the edit area for image or text labels
 * @param {object[]} editingRows - A list of objects which specify what input fields should be displayed
 * @param {string} editingRows[].title - The title of the row
 * @param {boolean} editingRows[].isVisible - Whether the row should be visible
 * @param {React.ReactNode} editingRows[].children - The children to be displayed in the row
 */
const LabelEditArea = ({ editingRows }) => {
  return (
    <div className="h-[30rem] overflow-y-auto overflow-x-hidden rounded-xl bg-grey-0 p-4">
      <div className="grid grid-cols-1 gap-4">
        {editingRows.map((row) => {
          if (!row.isVisible) {
            return null;
          }
          return (
            <LabelEditRow key={row.title} title={row.title}>
              {row.children}
            </LabelEditRow>
          );
        })}
      </div>
    </div>
  );
};

export default LabelEditArea;
