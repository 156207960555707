import React from 'react';

const SearchIcon = () => {
  return (
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.656774 2.53352C1.69677 0.783516 3.73677 -0.0864835 5.71677 0.273516C7.96677 0.683516 9.57677 2.74352 9.60677 4.99352C9.59677 5.97352 9.28677 6.88352 8.77677 7.65352C10.6968 9.28352 12.6268 10.9235 14.5468 12.5535C14.8368 12.8035 15.1368 13.0535 15.4268 13.3035C15.7368 13.5635 15.6968 14.0935 15.4268 14.3635C15.1168 14.6735 14.6768 14.6235 14.3668 14.3635C12.3068 12.6135 10.2368 10.8535 8.17677 9.10352C8.03677 8.98352 7.90677 8.87352 7.76677 8.75352C7.37677 9.06352 6.93677 9.32352 6.45677 9.50352C4.59677 10.2135 2.37677 9.61352 1.11677 8.07352C-0.163227 6.51352 -0.383226 4.27352 0.666774 2.52352L0.656774 2.53352ZM1.94677 6.69352C2.63677 7.85352 4.02677 8.50352 5.36677 8.25352C6.94677 7.96352 8.07677 6.58352 8.09677 4.98352C8.07677 3.62352 7.23677 2.34352 5.93677 1.87352C4.59677 1.39352 3.08677 1.80352 2.18677 2.91352C1.32677 3.98352 1.23677 5.51352 1.93677 6.68352L1.94677 6.69352Z" />
      </svg>
    </div>
  );
};

export default SearchIcon;
