import { Listbox, Transition } from '@headlessui/react';
import { Fragment, React, useEffect, useState } from 'react';

import { classNames } from '../../../utils/tailwindUtils';

import { removeItemFromArray } from '../../../services/utils';
import DropdownIcon from '../icons/DropdownIcon';

/**
 * Custom Dropdown
 * @param {object} selectedItem currently selected object {id, name, value}
 * @param {array} items array of objects inside the dropdown [{id, name, value}, ...]
 * @param {function} onChange function which handles the change of the current selection
 */
const Dropdown = ({ selectedItem, items, onChange }) => {
  const [chosen, setChosen] = useState(selectedItem);
  const [elements, setElements] = useState(items);

  useEffect(() => {
    setChosen(selectedItem);
    setElements(items);
  }, [selectedItem, items]);

  /**
   * Function which moves the selection on top of the options so when the dropdown is open the current selection is on top
   * @param {Object} selectedElement Element that is selected within the dropdown by the user
   */
  const handleSelection = (selectedElement) => {
    const itemsWithoutSelectedItem = removeItemFromArray(
      elements,
      elements.indexOf(selectedElement),
    );
    setElements([selectedElement, ...itemsWithoutSelectedItem]);
    setChosen(selectedElement);
    onChange(selectedElement);
  };

  return (
    <Listbox value={chosen} onChange={handleSelection}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className="relative flex min-w-full cursor-default items-center justify-between rounded-xl bg-white pl-2.5 text-left text-base text-blue-2 shadow-sm ring-1 ring-inset ring-babyBlue-1">
            <div className="block truncate">{chosen?.name}</div>
            <div>
              <DropdownIcon />
            </div>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute left-0 top-0 z-50 max-h-60 min-w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-babyBlue-1 sm:text-sm">
              {elements.map((element) => (
                <Listbox.Option
                  key={element.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-babyBlue-3 text-blue-2' : 'text-blue-2',
                      'relative cursor-default select-none text-base rounded-md flex items-center',
                    )
                  }
                  value={element}
                >
                  {({ selected }) => (
                    <div
                      className={classNames(
                        selected ? 'bg-babyBlue-3 text-blue-2' : 'text-blue-2 py-2',
                        'flex justify-between items-center w-full text-base relative cursor-default select-none pl-2.5 rounded-md',
                      )}
                    >
                      <span>{element.name}</span>
                      {selected ? (
                        <span>
                          <DropdownIcon />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default Dropdown;
