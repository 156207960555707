import { API } from './axios';

/**
 * Fetch the types of personally identifiable information that the backend is looking for.
 * The result is a list of text labels
 * @returns Promise
 */
export const getTextLabels = (projectId) => {
  return API.get(`projects/${projectId}/settings/text-labels`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Updates the given textLabels in the db
 * @param {number} projectId The id of the project
 * @param {list} payload The updated textLabels
 * @returns Promise
 */
export const putTextLabels = (projectId, payload) => {
  return API.put(`/projects/${projectId}/settings/text-labels`, payload, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetches the Image Anonymization labels from Database.
 *
 * @returns
 */
export const getImageLabels = (projectId) => {
  return API.get(`projects/${projectId}/settings/image-labels`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetches the Image Anonymization labels from Database.
 *
 * @returns
 */
export const putImageLabels = (projectId, imageLabels) => {
  return API.put(`projects/${projectId}/settings/image-labels`, imageLabels, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

export const getProjectSettings = (projectId) => {
  return API.get(`projects/${projectId}/settings`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

export const getBlacklist = (projectId) => {
  return API.get(`projects/${projectId}/settings/blacklists`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

export const putBlacklist = (projectId, blacklist) => {
  return API.put(`projects/${projectId}/settings/blacklists`, blacklist, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

export const getWhitelist = (projectId) => {
  return API.get(`projects/${projectId}/settings/whitelists`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

export const putWhitelist = (projectId, whitelist) => {
  return API.put(`projects/${projectId}/settings/whitelists`, whitelist, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Post scores of choosen and finalized documents
 * @param {*} payload document ids
 * @returns Promise
 */
export const postScores = (projectId, documentIds) => {
  return API.post(`projects/${projectId}/documents/score`, documentIds, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetch all projects for a given orgId
 * @param {*} orgId organization Id
 * @returns project information for a given orgId
 */
export const getProjectsData = (orgId) => {
  return API.get(`orgs/${orgId}/projects/me`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Create a new project for a given orgId
 * @param {*} orgId organization Id
 */
export const createProject = (orgId, projectData) => {
  return API.post(`orgs/${orgId}/projects`, projectData, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Update project name and project description
 * @param {*} orgId organization Id
 */
export const putProject = (orgId, projectId, projectData) => {
  return API.put(`orgs/${orgId}/projects/${projectId}`, projectData, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Get data for a specific project
 * @param {*} orgId organization Id
 */
export const getProject = (orgId, projectId) => {
  return API.get(`orgs/${orgId}/projects/${projectId}`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Get all users of a specific project
 */
export const getUsersByProject = (orgId, projectId) => {
  return API.get(`orgs/${orgId}/projects/${projectId}/users`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Add user to a specific project
 */
export const putUserToProject = (orgId, projectId, userId, projectRole) => {
  return API.put(
    `orgs/${orgId}/projects/${projectId}/users/${userId}`,
    { projectRole },
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Removes a single user from the corresponding project
 * @param {Integer} orgId Organisation of the user
 * @param {Integer} projectId Project od the user
 * @param {Integer} userId ID of the user
 */
export const deleteUserFromProject = (orgId, projectId, userId) => {
  return API.delete(`orgs/${orgId}/projects/${projectId}/users/${userId}`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Remove user from a specific project
 * @param {number} orgId - The id of the organization
 * @param {number} projectId - The id of the project
 */
export const deleteProject = (orgId, projectId) => {
  return API.delete(`orgs/${orgId}/projects/${projectId}`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};
