import { Box, Center, IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';

const NavigatorButton = ({ icon, tooltip, isSelected, onClick, isDisabled, navigatorWidth }) => {
  return (
    <Tooltip label={tooltip} placement="right" hasArrow>
      <Box>
        <IconButton
          sx={{
            width: navigatorWidth,
            rounded: 0,
            borderLeft: isSelected ? '4px' : '0px',
            color: 'gray.500',
            bg: 'transparent',
            _active: { bg: 'gray.300', color: 'gray.600' },
            _hover: { bg: 'transparent', color: 'gray.600' },
          }}
          size="lg"
          onClick={onClick}
          isDisabled={isDisabled ?? false}
          isActive={isSelected}
          icon={<Center>{icon}</Center>}
          variant="unstyled"
        />
      </Box>
    </Tooltip>
  );
};

export default NavigatorButton;
