const translations = {
  general: {
    copy: 'Copy',
    copied: 'Copied',
  },
  about: {
    and: 'and',
    description1: ' is one of the projects supported by the ',
    description2: '.',
  },
  footer: {
    support: 'Support',
    licence: 'Licence',
    termsOfUse: 'Terms of Use',
    termsAndConditions: 'Terms and Conditions',
    privacyNotice: 'Privacy Notice',
    website: 'Website',
    apiDocs: 'API Documentation',
    version: {
      link: 'Version',
      doccape: 'doccape Version',
      preprocessor: 'Preprocessor Version',
      textAi: 'Text AI Version',
      imageAi: 'Image AI Version',
      frontend: 'Frontend Version',
    },
  },
  subscription: {
    navButton: 'Book enterprise',
    more: 'You need more?',
    test: 'Try doccape',
    text: 'Text anonymization',
    face: 'Face detection',
    basicAI: 'Basic AI model',
    resume: 'Resume testing',
    productive: 'Use doccape in production',
    textPicture: 'Recognize text in images',
    licencePlate: 'Licence plate detection',
    ocr: 'OCR recognition of scanned documents',
    ownAI: 'Own AI model with defined labels',
    appointment: 'Book appointment',
    writeMail: 'Or write us a',
  },
  resetPassword: {
    headline: 'Reset password',
    email: 'Email Address',
    emailNotValid: 'Please enter a valid email address',
    headerText: 'Please enter your email to reset your password',
    buttonText: 'Request Email',
    resetSuccess: 'You will shortly receive an email to reset your password',
    resetSuccessSubtext: 'Please check your spam folder as well',
    backToLoginButton: 'Back to Login',
    setNewPasswordTitle: 'New Attempt!',
    setNewPassword: 'Set a new password',
    confirmNewPassword: 'Repeat new password',
    passwordsDoNotMatch: 'The entered passwords do not match',
    tokenInvalid: 'The token is invalid please request a new email to reset the password',
    passwordTooShort: 'The entered password is too short. It should contain at least 8 characters.',
    serverError:
      'Your request could not be processed. Please contact an administrator and try again later.',
    send: 'Send',
    cancel: 'Cancel',
  },
  annotation: {
    textLabelsLabel: 'Available textLabels:',
    browse: 'Browse your computer',
    drop: 'Drop a %{formats} document here.',
    metric: {
      f1: 'F1-Score',
      f2: 'F2-Score',
      falseNegatives: 'False Negatives',
      falsePositives: 'False Positives',
      precision: 'Precision',
      recall: 'Recall',
      truePositives: 'True Positives',
    },
    na: 'n/a',
    or: 'or',
    scoresHeader: 'Metriken',
    scoresDescription:
      'The following metrics evaluate the automatic detection of personal data on the basis of your manual corrections.',
    scoresNote: 'Note that the metrics can only be as accurate as your corrections.',
    scoresDuplicateSwitchText: 'Consider duplicates',
    noScoresText: 'No metrics available',
    changeTextLabel: 'Change type:',
    delete: 'Delete annotation:',
    create: 'Create annotation:',
    options: 'Options:',
    deleteHere: 'Only here',
    deleteHereDescription: 'Delete this mark',
    deleteAllText: 'All with same text',
    deleteAllTextDescription: 'Also deletes all marks with the same text',
    deleteAllTextLabelAndText: 'All with same type & text',
    deleteAllTextLabelAndTextDescription: 'Also deletes all marks with the same type and text',
    addHere: 'Only here',
    addHereDescription: 'Sets the mark here',
    addAllText: 'Mark all unmarked',
    addAllTextDescription: 'Automatically marks all unmarked occurrences',
    applyToAllText: 'Apply to all',
    applyToAllTextDescription: 'Apply this type to all marks with the same text',
    changeHere: 'Only here',
    changeHereDescription: 'Changes the type of the selected mark',
    changeAllText: 'All with same text',
    changeAllTextDescription: 'Type change will also affect other marks with the same text',
    changeAllTextLabelAndText: 'All with same type & text',
    changeAllTextLabelAndTextDescription:
      'Type change will also affect other marks with the same type and text',
    applyPseuodnymToAllWithSameTextLabelAndText: 'Apply to all',
    applyPseuodnymToAllWithSameTextLabelAndTextDescription:
      'Apply the pseudonym to all marks with the same type and text',
  },
  anonymization: {
    anonymization: 'Anonymization',
    advanced_settings: 'Mechanism per category',
    do_not_anonymize: 'Do not anonymize',
    default: 'Mechanism',
    default_tooltip:
      'Choose the default mechanism to use for anonymizing personal data. You can hover over each mechanism to get more information.',
    use_default: 'Use default',
    generalization: {
      name: 'Generalization',
      replacement: 'Replace with',
      tooltip: 'Replace with a more general phrase',
    },
    laplace_noise: {
      epsilon: 'Epsilon',
      epsilon_hint: 'Epsilon has to be larger than 0',
      epsilon_tooltip:
        'Epsilon controls the level of noise added. A smaller epsilon increases privacy.',
      name: 'Laplace Noise',
    },
    options: 'Options',
    pseudonymization: {
      name: 'Pseudonymization',
      format_string: 'Replace with',
      format_string_hint: 'use {} once as placeholder',
      counter_value: 'Initial value',
      counter_value_hint: 'enter a number larger than 0',
      tooltip: 'Replace with a pseudonym',
      pii: 'PII',
      pseudonym: 'Pseudonym',
      wholeDocument: 'Whole document',
      onlyPage: 'Only this page',
      exportButton: 'Export',
      export: 'Export table as CSV',
      replacement: 'Replacement',
    },
    randomized_response: {
      name: 'Randomized Response',
      no_config: 'This mechanism is configured to use differential privacy',
    },
    stateful: 'Stateful',
    suppression: {
      as_original: 'as original',
      name: 'Suppression',
      custom_length: 'Length',
      custom_length_hint: 'leave empty or enter a number larger than 0',
      redact: 'Redact',
      suppression_char: 'Replace with',
      tooltip: 'Replace each character with a different one',
    },
  },
  app: {
    fetching_recognizers_failed_toast: 'Loading the list of available recognizers failed.',
    fetching_textLabels_failed_toast:
      'Loading the textLabel categories for manual annotation failed.',
    fetching_documents_failed_toast: 'Loading the documents failed.',
    fetching_image_anonymization_labels_failed_toast:
      'Loading the image anonymization labels failed',
    fetching_image_anonymization_settings_failed_toast:
      'Loading the image anonymization settings failed',
    network_error_toast: 'The server is not available.',
    authorization_error_toast: 'Authorisation failed.',
    rendering_error: 'Unexpected Error',
    rendering_error_action: 'Please try reloading the page.',
    save_success_toast: 'Successfully saved',
    search: 'Search',
    unsavedChangesHandler: {
      navigationWarning: 'You have unsaved changes. Are you sure you want to leave this page?',
      stateChangeWarning: 'You have unsaved changes. Are you sure you want to leave?',
    },
  },
  disclaimer: {
    text: 'Do not use this software to anonymize documents. Do not further use the documents downloaded from this software.',
    title: 'This is a prototype',
  },
  documentSelection: {
    heading: 'Document Overview',
    settings: 'Settings',
    uploadButton: 'Upload',
    dropzone: {
      wrongDocumentFormat: 'is not in a supported format. Please upload PDFs only',
    },
    loadStatus: {
      low: {
        text: 'Low Load',
        tooltip: 'The system is currently under low load.',
      },
      medium: {
        text: 'Medium Load',
        tooltip: 'The system is currently under medium load.',
      },
      high: {
        text: 'High Load',
        tooltip: 'The system is currently under high load. Please be patient.',
      },
    },
    upload: 'Upload files',
    uploadLoading: 'Upload in progress',
    uploadSuccess: 'Upload successful',
    uploadFailed: 'Upload failed',
    exportButton: 'Export Overview',
    export: 'Export documents as CSV',
    userMngmtButton: 'User Management',
    userMngmtTooltip: 'Overview of all users',
    inviteUserButton: 'Invite User',
    inviteUserTooltip: 'Invite a new user to the project',
    refreshButton: 'Refresh',
    refresh: 'Refresh list',
    reset: 'Restore document to original state',
    resetFailed: 'Restore document to original state failed',
    resetPopoverHeader: 'Confirmation',
    resetPopoverContent:
      'Are you sure you want to reset the document to its original state? This is irreversible and will remove all adjustments.',
    delete: 'Delete document',
    deleteFailed: 'Deletion failed',
    deleteSelectedButton: 'Delete',
    deleteSelectedTooltip: 'Delete selection',
    cannotDeleteSelectedTooltip: 'Selection contains unpreprocessed documents',
    deletePopoverHeader: 'Confirmation',
    deletePopoverContent: 'Are you sure you want to delete the document?',
    priorizePreprocessing: 'Priorize preprocessing',
    disablePreprocessing: 'Disable preprocessing',
    enablePreprocessing: 'Enable preprocessing',
    startReview: 'Start review',
    noData: 'No documents uploaded',
    downloadButton: 'Download',
    downloadPopoverHeader: 'Confirmation',
    downloadPopoverContent:
      'The document has not been fully edited yet. Do you still want to download the document?',
    download: 'Download anonymized version',
    downloadFailed: 'Download failed',
    unfinalize: 'Reopen for review',
    unfinalizeFailedToast: 'Could not reopen document',
    archived: 'Archive document',
    unarchived: 'Unarchive document',
    headerButtons: {
      upload: 'Add Document',
      analyze: 'Metrics / Analyze',
      export: 'Export Overview',
      download: 'Download',
      delete: 'Delete',
    },
    filter: {
      fromDateLabel: 'edited from',
      toDateLabel: 'edited to',
    },
    comboBoxAutoComplete: {
      placeholder: 'Case Number',
      notFound: 'Result not found',
    },
    status: {
      ALL: 'Alle Dokumente',
      UNPROCESSED: 'Open',
      FINALIZED: 'Review finished',
      PROCESSING: 'In Progress',
      COMPLETED: 'Completed',
      ERROR: 'Error',
      PREVIEW_ERROR: 'Preview Error',
      ARCHIVED: 'Archived',
    },
    header: {
      name: 'Name',
      size: 'Size',
      lastModified: 'Last Modified',
      status: 'Status',
      documentTagValue: 'Group',
    },
    downloadSelectedButton: 'Download',
    downloadSelectedTooltip: 'Download selection',
    cannotDownloadSelectedTooltip: 'Selection contains unpreprocessed documents',
    showModelQualitySelectedTooltip: 'Show model quality',
    cannotShowModelQualitySelectedTooltip: 'Selection contains not finalized documents',
    showModelQualityloadSelectedButton: 'Model quality',
    scores: {
      image: {
        header: 'Image',
      },
      text: {
        header: 'Text',
      },
    },
    documentSummary: {
      loading: {
        titleFirstPart: 'Document',
        titleSecondPart: 'will be summarised...',
        subtitle: '',
        buttonText: 'Cancel',
      },
      loadingFinished: {
        title: 'Document',
        subtitle: 'Summary',
        buttonText: 'Close',
      },
    },
  },
  help: {
    intro: 'The following steps briefly outline the anonymization process:',
    item1: 'Upload the document that you want to anonymize.',
    item2: 'Extend and correct the automatically detected personal data in the left document view.',
    item3: 'Configure the anonymization according to your needs.',
    item4: 'Preview the document on the right document view and download your anonymized document.',
    tip: 'Tip: Many elements will show you further information if you hover the mouse pointer over them.',
  },
  main: {
    anonymize_file_failed_toast: 'Creating the anonymized file failed',
    compile_anonymize_file_failed_toast: 'Compiling the anonymized file failed',
    anonymizing_piis_failed_toast: 'Anonymizing the personal data failed',
    fetching_scores_failed_toast: 'Loading of the data for model quality failed',
    create_text_datasets_failed_toast: 'Creation of text datasets failed',
    create_image_datasets_failed_toast: 'Creation of image datasets failed',
    find_piis_failed_toast: 'Processing of the document failed',
    finalizeFailedToast: 'Could not finalize the file',
    finalizeSuccessToast: 'Finalizing completed',
    saveFailedToast: 'Could not save the file',
    documentIsLockedMessage: 'Someone else edited this document. Please reload the page!',
    reload: 'Reload document',
    toastMessages: {
      documentCompilationSuccess: 'Document compilation completed',
      documentCompilationFailed: 'Document compilation failed',
      documentSaveSuccess: 'Document saved',
    },
    documentFinalizedAlert: 'This document is finalized and cannot be edited anymore.',
    documentFinalizedAlertTooltip: 'Close document',
  },
  mainMenu: {
    saveTooltip: 'Save',
    createDatasetTooltip: 'Create dataset',
    compileTooltip: 'Refresh',
    finalizeTooltip: 'Finish review',
    closeTooltip: 'Close',
    saveAndCloseButton: 'Save and close',
    closeButton: 'Close',
    closePopoverHeader: 'Back to document selection',
    closePopoverBody: 'Do you want to save your changes before closing?',
    scoresTooltip: 'Metrics',
    finalizePopoverHeader: 'Finish Review',
    finalizePopoverBody: 'Would you like to save and finish the review?',
    finalizePopoverButton: 'Ok',
    displayPseudonyms: 'Show Pseudonyms',
    hidePseudonyms: 'Show Piis',
    autofillTooltip: 'Set pseudonyms automatically',
    documentModifiedTooltip: 'Last Modified: ',
    undoNotPossible: 'Undo not possible',
    redoNotPossible: 'Redo not possible',
    undo: 'Undo',
    redo: 'Redo',
    undoRedoActions: {
      undoNotPossible: 'Undo not possible',
      redoNotPossible: 'Redo not possible',
      addAnnotation: 'Add annotation',
      removeAllAnnotationsWithTextLabel: 'Remove all annotations of type',
      removeAllAnnotationsWithSameTextLabelAndText:
        'Remove all annotations with same text and type',
      removeAllAnnotationsWithSameText: 'Remove all annotations with same text',
      annotateAllUnmarked: 'Annotate all unmarked',
      setCrIdAndPseudonymOfAnnotation: 'Set association of annotation',
      setCrIdAndPseudonymOfAnnotations: 'Set association of annotations',
      changeTextLabelNameOfAllAnnotationsWithSameText:
        'Change type of all annotations with same text',
      changeCrIdOfAllAnnotationsWithSameTextLabelAndText:
        'Change association of all annotations with same text and type',
      changeTextLabelNameOfAnnotation: 'Change type of annotation',
      changeCrIdOfAnnotation: 'Change association of annotation',
      findAndRemoveAnnotation: 'Delete annotation',
      deleteBboxInImage: 'Delete bounding box',
      addBbox: 'Add bounding box',
      updateBbox: 'Update bounding box',
      changeLabelOfBbox: 'Change the label of bounding box',
    },
  },
  nav: {
    about: 'About doccape',
    help: 'Help',
    logout: 'Logout',
    settings: 'Settings',
  },
  breadcrumbs: {
    organizations: 'Organizations',
    project: 'Project',
    projects: 'Projects',
    documents: 'Documents',
    settings: 'Settings',
    users: 'User Management',
  },
  preview: {
    warning: 'The document is being compiled. Please wait.',
    error: 'An error occurred while compiling the document. The displayed content may be outdated.',
    outdated:
      'You made changes to the document. This preview may be outdated. Please compile if you want to see your changes.',
  },
  sidebar: {
    views: {
      pseudonymization: {
        tooltip: 'Pseudonymization',
      },
      textLabelSelection: {
        tooltip: 'TextLabels',
      },
      imageAnonymization: {
        tooltip: 'Image Anonymization',
        tooltipWithoutImgs:
          'No images detected or disabled image anonymization settings during creation',
        tooltipSettingsDisabled: 'Image anonymization settings disabled',
        page: 'Page',
      },
    },
    clickables: {
      settings: {
        tooltip: 'Settings',
      },
    },
  },
  textLabelSelection: {
    options: 'Options',
    deleteAllMarksPrefix: 'Delete all Marks for label (',
    deleteAllMarksSuffix: ')',
  },
  imageAnnotation: {
    noImagesInDocument: 'This document does not contain images.',
    disabledLabelTooltip: 'Activate this label in settings to view the bounding boxes',
    bboxs: 'Boxes',
    labels: 'Labels',
    cancelDrawingTooltip: 'Cancel drawing (ESC)',
    hideLabel: 'Hide label',
    addBbox: 'Add bounding box',
    mainMenu: {
      saveTooltip: 'Save',
      deleteTooltip: 'Delete box',
      editTooltip: 'Edit box',
      cancelEditingTooltip: 'End editing',
      zoomInTooltip: 'Zoom in',
      zoomOutTooltip: 'Zoom out',
      centerTooltip: 'Center',
      moveTooltip: 'Move image (CTRL/CMD)',
      cancelMoveTooltip: 'Cancel image moving (ESC)',
    },
  },
  userSettings: {
    settings: 'Profile Settings',
    sidebar: {
      apiManager: 'API Manager',
      general: 'Personal Data',
      security: 'Security',
    },
    apiManager: {
      title: 'API Manager',
      description: 'You can create and share an API key here',
      buttonName: 'Create API Key',
      generateApiKey: 'Generate API Key',
      copiedToClipboard: 'Copied to clipboard',
      apiKey: 'API Key',
      documentation: 'You can find the documentation for the API here',
      copyNotice:
        'Your generated API key is only visible once - please copy and store it immediately.',
      generationNotice: 'Generating a new API key will invalidate the old one.',
    },
    general: {
      firstName: 'First Name',
      lastName: 'Last Name',
      updateUserData: 'Update data',
      successUpdateUserData: 'Changes saved successfully',
      error: {
        firstNameEmpty: 'Der Vorname darf nicht leer sein',
        lastNameEmpty: 'Der Nachname darf nicht leer sein',
      },
    },
    security: {
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
      changePassword: 'Change Password',
      confirmPasswordMissmatch: 'The given passwords are not the same',
      passwordLengthError:
        'The password you entered is too short. Please choose a password with at least 8 characters.',
      successPasswordChange: 'The password was changed successfully',
    },
  },
  settings: {
    recognizers: {
      description: 'Enable recognizers to search personal data for a category.',
      names: {
        number_recognizer: 'Recognize numbers',
        de_country_recognizer: 'Recognize country names',
        de_date_recognizer: 'Recognize dates',
        email_recognizer: 'Recognize e-mail addresses',
        phone_number_recognizer: 'Recognize phone numbers',
        money_recognizer: 'Recognize amounts of money',
        statistical_recognizer:
          'Use statistical models to recognize persons, locations, organizations and miscellaneous entities',
      },
      title: 'Categories',
    },
    title: 'Settings',
    save: 'Save',
    cancel: 'Cancel',
    reset: 'Reset',
    textLabels: {
      title: 'TextLabels',
      selectionTitle: 'Text Labels',
      editing: 'Editing',
      description: 'Description',
      pseudonymization: 'Pseudonymization',
      hide: 'Hide',
      delete: 'Delete Text Label',
      isRequired: 'Required',
      alreadyUsed: 'Already used',
      add: 'Add',
      name: 'Abbreviation',
      maxNameChars: 'Max 6 Characters',
      newTextLabel: 'New',
      replaceWith: 'Replace with',
      replacementOptions: {
        pseudonym: 'Pseudonym',
        suppression: 'Suppression',
        rule: 'Rule',
      },
      ruleSettings: 'Rule',
      ruleOptions: {
        noRule: 'No Rule',
        partialReplacement: 'Partial Replacement',
        dateReplacement: 'Date Replacement',
      },
      preview: 'Preview',
      previewPlaceholder: 'Enter text',
      pseudonymizationExtended: 'Extension',
      partialReplacementTitle: 'Replace only a part',
      partialReplacementStart: 'start from position',
      partialReplacementStop: 'end at position',
      partialReplacementStartDescription:
        'The replacement starts here, also including this position (Negative values count from the back, a 0 also means from the beginning)',
      partialReplacementStopDescription:
        'The replacement stops here, also including this position. (Negative values count from the back, a 0 also means until the end)',
      partialReplacementError: 'Start must be in front or the same as end',
      datePartialReplacementTitle: 'Replace parts of Dates',
      replaceDay: 'Day',
      replaceDayDescription: 'Replaces the day',
      replaceMonth: 'Month',
      replaceMonthDescription: 'Replaces the month',
      replaceYear: 'Year',
      replaceYearDescription: 'Replaces the year',
      useNumericPartialReplacement: 'Numbers only',
      useNumericPartialReplacementDescription:
        'Ignores all non numerical characters during partial replacement, e.g. in the string D4F45G only the number 445 is concerned ',
      showWeekdayTitle: 'Show Weekday',
      dateLocale: 'Language',
      dateLocaleTooltip: 'Defines the date format. Auto will detect the format automatically.',
      noExtendedReplacement: 'None',
      updatingTextLabelsSuccessToast: 'Changes successfully applied',
      updatingTextLabelsFailedToast: 'Saving textLabels failed',
    },
    listing: {
      title: 'Black- & Whitelisting',
      filter: {
        search: 'Search',
      },
      textelements: {
        title: 'Text Elements',
        subtitle: 'Select a text element',
        placeholder: 'New text element',
      },
      whitelist: {
        title: 'Is displayed',
        subtitle: 'when classified as',
      },
      blacklist: {
        title: 'Is pseudonymized',
        subtitle: 'and replaced by',
      },
      whitelistHelpText:
        'List of text elements which should not be anonymized. By selecting a label only text elements that are classified with that label are not anonymized. Select "All labels" if the text elements should not be anonymized regardless of the classified label.',
      blacklistHelpText:
        'List of text elements that should always be classified as the selected label.',
      addItem: 'Add',
      blacklistItemPlaceholder: 'Please choose a label',
      whitelistItemPlaceholder: 'All labels',
      removeItemTooltip: 'Remove item',
      addWhitelistItemTooltip: 'Add item to Whitelist',
      addBlacklistItemTooltip: 'Add item to Blacklist',
      importTooltip:
        'The csv file has the format `value;label` (separators are semicolons). The name of the headers must be `value` and `label`.',
      removeAllItems: 'Remove all',
      removeAllItemsPopoverHeader: 'Remove all items',
      removeAllItemsPopoverBody: 'Are you sure you want to remove all items?',
      importButton: 'Import CSV',
      error: {
        addWhitelistItem: 'Adding whitelist item failed',
        addBlacklistItem: 'Adding blacklist item failed',
        updateWhitelistItem: 'Updating whitelist item failed',
        updateBlacklistItem: 'Updating blacklist item failed',
        deleteWhitelistItem: 'Deleting whitelist item failed',
        deleteBlacklistItem: 'Deleting blacklist item failed',
        csvFormat: 'Csv file has the wrong format',
      },
    },
    imageAnonymization: {
      title: 'Image Anonymization',
      selectionTitle: 'Image Labels',
      editing: 'Editing',
      name: 'Name',
      maxNameChars: 'Max 11 Characters',
      enable: 'Activate',
      newImageLabel: 'New',
      updatingImageLabelsSuccessToast: 'Changes successfully applied',
      updatingImageLabelsFailedToast: 'Saving image labels failed',
      isRequired: 'Required',
      alreadyUsed: 'Already used',
      delete: 'Delete Image Label',
      textAnonymization: 'Text Anonymization',
      faceDetection: 'Face Detection',
      anonymizationType: 'Anonymization Type',
      imageAnonymizationTypeMASK: 'Black',
      updatingImageAnonymizationSettingsFailedToast:
        'Saving the image anonymization settings failed',
      updatingImageAnonymizationLabelSettingsFailedToast:
        'Saving the image anonymaiztion label settings failed',
    },
    about: {
      title: 'About',
      system_header: 'System',
      fetching_version_failed_toast: 'Das Laden der Versionsnummern ist fehlgeschlagen',
    },
  },
  textLabels: {
    date: 'Dates',
    email: 'E-mail Addresses',
    loc: 'Locations',
    org: 'Organizations',
    per: 'Persons',
    tel: 'Phone Numbers',
    bic: 'Bank Identifier Codes',
    blz: 'Bank codes',
    fax: 'Fax Numbers',
    fin: 'Vehicle Identification Numbers',
    gan: 'Survey Numbers',
    iban: 'International Bank Account Numbers',
    inet: 'Web Addresses',
    kfz: 'Vehicle Registration Numbers',
    knr: 'Customer Numbers',
    oth: 'Miscellaneous',
    plz: 'Post Codes',
    str: 'Street Names',
  },
  login: {
    header: 'Log in to your account',
    email: 'Email',
    username: 'Username',
    password: 'Password',
    show: 'show',
    hide: 'hide',
    forgotpw: 'forgot password?',
    rememberMe: 'Remember me',
    login: 'Login',
    registration: 'Sign Up',
    footer: {
      copyright: '2024 scitlab UG, All rights reserved.',
    },
    alert: 'wrong email or password',
  },
  projectMngmt: {
    overview: {
      title: 'Project Overview',
      addProject: 'Create Project',
      noProjects: {
        heading: 'There are no Projects yet',
        content: 'Please Contact an Andmistrator',
        contentAdmin: 'Create a Project to get Started',
      },
      createProjectModal: {
        title: 'Create Project',
        subtitle: 'Create a new project',
        projectName: 'Name',
        projectDescription: 'Description',
        submit: 'Submit',
        errorsMsg: {
          projectAlreadyExists: 'Project name already exists',
          projectNameMustNotEmpty: 'Project name must not be empty',
        },
      },
      projectCard: {
        heading: 'Project',
        documents: 'Documents',
        projectId: 'Project ID',
        lastModified: 'Last Doc. Update',
        openProjectButton: 'Open Project',
      },
    },
    userMngmt: {
      heading: 'User Overview',
      subHeading: 'A list of all users from the project',
      addUserDialog: {
        heading: 'Add User to Project',
        subtitle: 'Please enter the email address of the person you want to add to the project.',
        button: 'Add User',
        role: 'Role',
        success: 'User successfully invited',
        errorMsg: {
          inviteNotPossible: 'User could not be invited',
        },
      },
      changeUserRole: {
        success: 'Role changed successfully',
      },
      deleteUser: {
        success: 'User was removed from the project',
        errorMsg: 'User could not be removed from the project',
      },
      tableHead: {
        name: 'Name',
        email: 'Email',
        status: 'Status',
        role: 'Role',
      },
      status: {
        verified: 'Verified',
        notVerified: 'Not Verified',
      },
    },
  },
  projectSettings: {
    settings: 'Project Settings',
    redirectButton: 'Back',
    sidebar: {
      generalSettings: 'General Settings',
      userMngmt: 'User Management',
      pseudonymization: 'Pseudonymization',
      textLabels: 'Text Labels',
      textLabelsFromModel: 'Automatic Text Labels',
      textLabelsOwn: 'Own Text Labels',
      blackAndWhite: 'Black- & Whitelisting',
      rules: 'Rules',
      imageLabels: 'Image Labels',
      imageLabelsFromModel: 'Automatic Image Labels',
      imageLabelsOwn: 'Own Image Labels',
      deleteProject: 'Delete project',
      confirmationDeleteProject: 'Do you really want to delete this project?',
    },
  },
  organizationMngmt: {
    tokenVerification: {
      loading: 'Just a moment...',
    },
    invitationUser: {
      email: 'Email adress',
      inviteUser: 'Invite User',
      subtitle:
        'Please enter the email address of the person you want to invite to the organization.',
      success: 'User successfully invited',
      errorMsg: {
        duplicateUser: 'Mail adress already exists',
        invalidEmail: 'Email adress is invalid',
      },
    },
    registrationUser: {
      heading: 'Welcome',
      subHeading: 'Create Your Account',
      userData: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        password: 'Set Password',
        repeatPassword: 'Repeat Password',
        invalidInputs: {
          invalidFirstName: 'Please enter a valid first name',
          invalidLastName: 'Please enter a valid last name',
          invalidEmail: 'Please enter a valid email address',
          passwordsNoMatch: 'The two passwords do not match.',
          passwordTooShort: 'The password must have at least 8 characters.',
        },
        infoText: `By registering, you agree to the <linkPN>Privacy Notice</linkPN> of doccape.`,
      },
      successRegistration: 'Successfully registered',
      errorRegistration: 'The registration could not be completed.',
      createAccountButton: 'Create Account',
    },
    registrationUserSuccess: {
      heading: 'Congratulations!',
      subHeading: 'You have been successfully registered',
      infoText: `You will receive an email with a confirmation link. Please click on the link to verify your email address.`,
      toLoginButton: 'To Login',
    },
    verifyUserSuccess: {
      heading: 'Welcome to doccape!',
      subHeading: 'Your account has been successfully verified',
      toLoginButton: 'To Login',
    },
    invitationUserNotValidToken: {
      heading: 'This does not work!',
      subHeading: 'Invalid invitation link.',
      infoText: `Registration cannot be completed. Please contact the administrator.`,
    },
    invitationUserExpiredToken: {
      heading: 'Oops! You are too late!',
      sendingLinkSuccesfulHeading: 'Invitation link sent',
      subHeading: 'This invitation link has expired',
      sendLinkAgainButton: 'Send link again',
      sendLinkInfo:
        'We have sent you an email with a new invitation link. Please check your mailbox.',
      sendingLinkFailed: 'Sending link failed.',
    },
    invitationUserAlreadyUsedToken: {
      heading: 'You are already registered!',
      subHeading: 'This invitation link has already been used',
      sendLinkAgainButton: 'To Login',
    },
    invitationUserNonExistingOrOutdatedToken: {
      heading: 'This does not work!',
      subHeading: 'Token does not exist.',
      infoText: `Either an incorrect token was used or the token is no longer valid. It could be that a newer token exists. Please check your mails for this.`,
    },
    invitationUnknonError: {
      heading: 'Something went wrong.',
      subHeading: 'Please contact the administrator.',
    },
    userMngmt: {
      heading: 'User Management',
      deleteUser: {
        success: 'User has been deleted',
        errorMsg: 'User could not be deleted',
      },
      changeUserRole: {
        success: 'Role changed successfully',
        errorMsg: 'Role could not be changed',
      },
      resendEmailInvitation: {
        buttonName: 'Resend Link',
        success: 'Link successfully sent',
      },
    },
  },
  verifyEmail: {
    emailVerificationNotValidToken: {
      heading: 'This does not work!',
      subHeading: 'Invalid verification link.',
      infoText: `Verification cannot be completed. Please contact support@doccape.de.`,
    },
    emailVerificationExpiredToken: {
      heading: 'Oops! You are too late!',
      sendingLinkSuccesfulHeading: 'Verification link sent',
      subHeading: 'This verification link has expired',
      sendLinkAgainButton: 'Send link again',
      sendLinkInfo:
        'We have sent you an email with a new verification link. Please check your mailbox.',
      sendingLinkFailed: 'Sending link failed.',
    },
    emailVerificationAlreadyUsedToken: {
      heading: 'You are already verified!',
      subHeading: 'This verification link has already been used',
      sendLinkAgainButton: 'To Login',
    },
    emailVerificationNonExistingTokenOrOutdated: {
      heading: 'This does not work!',
      subHeading: 'Token does not exist.',
      infoText: `Either an incorrect token was used or the token is no longer valid. It could be that a newer token exists. Please check your mails for this.`,
    },
    verifyEmailSuccess: {
      heading: 'Welcome to doccape!',
      subHeading: 'Your account has been successfully verified',
      toLoginButton: 'To Login',
    },
  },
  forbiddenAlert: {
    title: 'Error',
    subtitle: 'This page does not exist or you do not have the required permissions',
    button: 'Take me back to the Homepage',
  },
  notFoundAlert: {
    title: 'Error',
    subtitle: 'This page does not exist',
    button: 'Take me back',
  },
  tablePaginationFooter: {
    locationInfo: '{{from}} to {{to}} of {{count}}',
    prev: 'Prev',
    next: 'Next',
  },
  errors: {
    default: { message: 'An error has occured' },
    'api-key': {
      post: {
        '': {
          400: 'An error occurred while creating the API key',
          403: 'An error occurred while creating the API key',
          404: 'An error occurred while creating the API key',
          409: 'An error occurred while creating the API key',
          422: 'An error occurred while creating the API key',
          429: 'An error occurred while creating the API key',
          500: 'An error occurred while creating the API key',
        },
      },
    },
    auth: {
      put: {
        'users/resend-invitation-email/#': {
          400: 'An error occurred when resending the invitation email',
          403: 'An error occurred when resending the invitation email',
          404: 'An error occurred when resending the invitation email',
          409: 'An error occurred when resending the invitation email',
          422: 'An error occurred when resending the invitation email',
          429: 'An error occurred when resending the invitation email',
          500: 'An error occurred when resending the invitation email',
        },
        '/auth/resend-email-verification/#': {
          400: 'An error occurred while renewing the token',
          403: 'An error occurred while renewing the token',
          404: 'An error occurred while renewing the token',
          409: 'An error occurred while renewing the token',
          422: 'An error occurred while renewing the token',
          429: 'An error occurred while renewing the token',
          500: 'An error occurred while renewing the token',
        },
      },
    },
    documents: {
      put: {
        'images/pii-regions/#': {
          400: 'An error occurred while saving the bounding boxes',
          403: 'An error occurred while saving the bounding boxes',
          404: 'An error occurred while saving the bounding boxes',
          409: 'An error occurred while saving the bounding boxes',
          422: 'An error occurred while saving the bounding boxes',
          429: 'An error occurred while saving the bounding boxes',
          500: 'An error occurred while saving the bounding boxes',
        },
      },
      post: {
        'compile/#': {
          400: 'An error occurred while updating the pdf document',
          403: 'An error occurred while updating the pdf document',
          404: 'An error occurred while updating the pdf document',
          409: 'An error occurred while updating the pdf document',
          422: 'An error occurred while updating the pdf document',
          429: 'An error occurred while updating the pdf document',
          500: 'An error occurred while updating the pdf document',
        },
        'create-text-datasets/#': {
          400: 'An error occurred while creating the text dataset',
          403: 'An error occurred while creating the text dataset',
          404: 'An error occurred while creating the text dataset',
          409: 'An error occurred while creating the text dataset',
          422: 'An error occurred while creating the text dataset',
          429: 'An error occurred while creating the text dataset',
          500: 'An error occurred while creating the text dataset',
        },
        'create-image-datasets/#': {
          400: 'An error occurred while creating the image dataset',
          403: 'An error occurred while creating the image dataset',
          404: 'An error occurred while creating the image dataset',
          409: 'An error occurred while creating the image dataset',
          422: 'An error occurred while creating the image dataset',
          429: 'An error occurred while creating the image dataset',
          500: 'An error occurred while creating the image dataset',
        },
      },
    },
    projects: {
      get: {
        '#/settings/#/text-labels': {
          400: 'An error occurred while loading the text labels',
          403: 'An error occurred while loading the text labels',
          404: 'An error occurred while loading the text labels',
          409: 'An error occurred while loading the text labels',
          422: 'An error occurred while loading the text labels',
          429: 'An error occurred while loading the text labels',
          500: 'An error occurred while loading the text labels',
        },
        '#/settings/#/image-labels': {
          400: 'An error occurred while loading the image labels',
          403: 'An error occurred while loading the image labels',
          404: 'An error occurred while loading the image labels',
          409: 'An error occurred while loading the image labels',
          422: 'An error occurred while loading the image labels',
          429: 'An error occurred while loading the image labels',
          500: 'An error occurred while loading the image labels',
        },
        '#/settings': {
          400: 'An error occurred while loading the settings',
          403: 'An error occurred while loading the settings',
          404: 'An error occurred while loading the settings',
          409: 'An error occurred while loading the settings',
          422: 'An error occurred while loading the settings',
          429: 'An error occurred while loading the settings',
          500: 'An error occurred while loading the settings',
        },
        '#/settings/blacklists': {
          400: 'An error occurred while loading the blacklist',
          403: 'An error occurred while loading the blacklist',
          404: 'An error occurred while loading the blacklist',
          409: 'An error occurred while loading the blacklist',
          422: 'An error occurred while loading the blacklist',
          429: 'An error occurred while loading the blacklist',
          500: 'An error occurred while loading the blacklist',
        },
        '#/settings/whitelists': {
          400: 'An error occurred while loading the whitelist',
          403: 'An error occurred while loading the whitelist',
          404: 'An error occurred while loading the whitelist',
          409: 'An error occurred while loading the whitelist',
          422: 'An error occurred while loading the whitelist',
          429: 'An error occurred while loading the whitelist',
          500: 'An error occurred while loading the whitelist',
        },
        '#/documents/#/anonymized/download': {
          400: 'An error occurred while downloading the anonymized document',
          403: 'An error occurred while downloading the anonymized document',
          404: 'An error occurred while downloading the anonymized document',
          409: 'An error occurred while downloading the anonymized document',
          422: 'An error occurred while downloading the anonymized document',
          429: 'An error occurred while downloading the anonymized document',
          500: 'An error occurred while downloading the anonymized document',
        },
        '#/documents': {
          400: 'An error occurred while loading the documents',
          403: 'An error occurred while loading the documents',
          404: 'An error occurred while loading the documents',
          409: 'An error occurred while loading the documents',
          422: 'An error occurred while loading the documents',
          429: 'An error occurred while loading the documents',
          500: 'An error occurred while loading the documents',
        },
        '#/documents/#/piis': {
          400: 'An error occurred while loading the data of the document',
          403: 'An error occurred while loading the data of the document',
          404: 'An error occurred while loading the data of the document',
          409: 'An error occurred while loading the data of the document',
          422: 'An error occurred while loading the data of the document',
          429: 'An error occurred while loading the data of the document',
          500: 'An error occurred while loading the data of the document',
        },
        '#/documents/#/original/base64': {
          400: 'An error occurred while loading the non-anonymized document',
          403: 'An error occurred while loading the non-anonymized document',
          404: 'An error occurred while loading the non-anonymized document',
          409: 'An error occurred while loading the non-anonymized document',
          422: 'An error occurred while loading the non-anonymized document',
          429: 'An error occurred while loading the non-anonymized document',
          500: 'An error occurred while loading the non-anonymized document',
        },
        '#/documents/#/anonymized/base64': {
          400: 'An error occurred while loading the anonymized document',
          403: 'An error occurred while loading the anonymized document',
          404: 'An error occurred while loading the anonymized document',
          409: 'An error occurred while loading the anonymized document',
          422: 'An error occurred while loading the anonymized document',
          429: 'An error occurred while loading the anonymized document',
          500: 'An error occurred while loading the anonymized document',
        },
        '#/documents/#/images/#/#/image_file': {
          400: 'An error occurred while loading the images',
          403: 'An error occurred while loading the images',
          404: 'An error occurred while loading the images',
          409: 'An error occurred while loading the images',
          422: 'An error occurred while loading the images',
          429: 'An error occurred while loading the images',
          500: 'An error occurred while loading the images',
        },
      },
      put: {
        '#/documents/#/piis': {
          400: 'Error saving the document',
          403: 'Error saving the document',
          404: 'Error saving the document',
          409: 'Error saving the document',
          422: 'Error saving the document',
          429: 'Error saving the document',
          500: 'Error saving the document',
        },
        '#/settings/text-labels': {
          400: 'An error occurred while saving the text labels',
          403: 'An error occurred while saving the text labels',
          404: 'An error occurred while saving the text labels',
          409: 'An error occurred while saving the text labels',
          422: 'An error occurred while saving the text labels',
          429: 'An error occurred while saving the text labels',
          500: 'An error occurred while saving the text labels',
        },
        '#/settings/#/image-labels': {
          400: 'An error occurred while saving the image labels',
          403: 'An error occurred while saving the image labels',
          404: 'An error occurred while saving the image labels',
          409: 'An error occurred while saving the image labels',
          422: 'An error occurred while saving the image labels',
          429: 'An error occurred while saving the image labels',
          500: 'An error occurred while saving the image labels',
        },
        '#/settings/blacklists': {
          400: 'An error occurred while saving the blacklist',
          403: 'An error occurred while saving the blacklist',
          404: 'An error occurred while saving the blacklist',
          409: 'An error occurred while saving the blacklist',
          422: 'An error occurred while saving the blacklist',
          429: 'An error occurred while saving the blacklist',
          500: 'An error occurred while saving the blacklist',
        },
        '#/settings/whitelists': {
          400: 'An error occurred while saving the whitelist',
          403: 'An error occurred while saving the whitelist',
          404: 'An error occurred while saving the whitelist',
          409: 'An error occurred while saving the whitelist',
          422: 'An error occurred while saving the whitelist',
          429: 'An error occurred while saving the whitelist',
          500: 'An error occurred while saving the whitelist',
        },
        '#/documents/#/reset': {
          400: 'An error occurred when resetting the document to its original state',
          403: 'An error occurred when resetting the document to its original state',
          404: 'An error occurred when resetting the document to its original state',
          409: 'An error occurred when resetting the document to its original state',
          422: 'An error occurred when resetting the document to its original state',
          429: 'An error occurred when resetting the document to its original state',
          500: 'An error occurred when resetting the document to its original state',
        },
        '#/documents/#/finalize': {
          400: 'An error occurred while completing the document',
          403: 'An error occurred while completing the document',
          404: 'An error occurred while completing the document',
          409: 'An error occurred while completing the document',
          422: 'An error occurred while completing the document',
          429: 'An error occurred while completing the document',
          500: 'An error occurred while completing the document',
        },
        '#/documents/#/unfinalize': {
          400: 'An error occurred when trying to reopen the completed document',
          403: 'You do not have the permissions to reopen this document',
          404: 'An error occurred when trying to reopen the completed document',
          409: 'An error occurred when trying to reopen the completed document',
          422: 'An error occurred when trying to reopen the completed document',
          429: 'An error occurred when trying to reopen the completed document',
          500: 'An error occurred when trying to reopen the completed document',
        },
      },
      post: {
        '#/documents/scores': {
          400: 'An error occurred while loading the model quality',
          403: 'An error occurred while loading the model quality',
          404: 'An error occurred while loading the model quality',
          409: 'An error occurred while loading the model quality',
          422: 'An error occurred while loading the model quality',
          429: 'An error occurred while loading the model quality',
          500: 'An error occurred while loading the model quality',
        },
        '#/documents/anonymized/download': {
          400: 'An error occurred while downloading multiple anonymized documents',
          403: 'An error occurred while downloading multiple anonymized documents',
          404: 'An error occurred while downloading multiple anonymized documents',
          409: 'An error occurred while downloading multiple anonymized documents',
          422: 'An error occurred while downloading multiple anonymized documents',
          429: 'An error occurred while downloading multiple anonymized documents',
          500: 'An error occurred while downloading multiple anonymized documents',
        },
        '#/documents': {
          400: 'An error occurred while uploading the documents',
          403: 'An error occurred while uploading the documents',
          404: 'An error occurred while uploading the documents',
          409: 'An error occurred while uploading the documents',
          422: 'An error occurred while uploading the documents',
          429: 'An error occurred while uploading the documents',
          500: 'An error occurred while uploading the documents',
        },
        '#/documents/#/priorize-preprocessing': {
          400: 'An error occurred while prioritizing preprocessing',
          403: 'An error occurred while prioritizing preprocessing',
          404: 'An error occurred while prioritizing preprocessing',
          409: 'An error occurred while prioritizing preprocessing',
          422: 'An error occurred while prioritizing preprocessing',
          429: 'An error occurred while prioritizing preprocessing',
          500: 'An error occurred while prioritizing preprocessing',
        },
        '#/documents/#/enable-preprocessing': {
          400: 'An error occurred when activating preprocessing',
          403: 'An error occurred when activating preprocessing',
          404: 'An error occurred when activating preprocessing',
          409: 'An error occurred when activating preprocessing',
          422: 'An error occurred when activating preprocessing',
          429: 'An error occurred when activating preprocessing',
          500: 'An error occurred when activating preprocessing',
        },
        '#/documents/#/disable-preprocessing': {
          400: 'An error occurred when deactivating preprocessing',
          403: 'An error occurred when deactivating preprocessing',
          404: 'An error occurred when deactivating preprocessing',
          409: 'An error occurred when deactivating preprocessing',
          422: 'An error occurred when deactivating preprocessing',
          429: 'An error occurred when deactivating preprocessing',
          500: 'An error occurred when deactivating preprocessing',
        },
        '#/documents/#/piis': {
          400: 'An error occurred while saving the personally identifiable information',
          403: 'An error occurred while saving the personally identifiable information',
          404: 'An error occurred while saving the personally identifiable information',
          409: 'An error occurred while saving the personally identifiable information',
          422: 'An error occurred while saving the personally identifiable information',
          429: 'An error occurred while saving the personally identifiable information',
          500: 'An error occurred while saving the personally identifiable information',
        },
      },
      delete: {
        '#/documents/#': {
          400: 'An error occurred while deleting the document',
          403: 'An error occurred while deleting the document',
          404: 'An error occurred while deleting the document',
          409: 'An error occurred while deleting the document',
          422: 'An error occurred while deleting the document',
          429: 'An error occurred while deleting the document',
          500: 'An error occurred while deleting the document',
        },
        '#/documents': {
          400: 'An error occurred while deleting the documents',
          403: 'An error occurred while deleting the documents',
          404: 'An error occurred while deleting the documents',
          409: 'An error occurred while deleting the documents',
          422: 'An error occurred while deleting the documents',
          429: 'An error occurred while deleting the documents',
          500: 'An error occurred while deleting the documents',
        },
      },
    },
    orgs: {
      get: {
        '#/projects/me': {
          400: 'An error occurred while loading the projects',
          403: 'An error occurred while loading the projects',
          404: 'An error occurred while loading the projects',
          409: 'An error occurred while loading the projects',
          422: 'An error occurred while loading the projects',
          429: 'An error occurred while loading the projects',
          500: 'An error occurred while loading the projects',
        },
        '/me': {
          400: 'An error occurred while loading the organizations',
          403: 'An error occurred while loading the organizations',
          404: 'An error occurred while loading the organizations',
          409: 'An error occurred while loading the organizations',
          422: 'An error occurred while loading the organizations',
          429: 'An error occurred while loading the organizations',
          500: 'An error occurred while loading the organizations',
        },
      },
      put: {
        '#/users/#': {
          400: 'An error occured while updating the user role',
          403: 'An error occured while updating the user role',
          404: 'An error occured while updating the user role',
          409: 'An error occured while updating the user role',
          422: 'An error occured while updating the user role',
          429: 'An error occured while updating the user role',
          500: 'An error occured while updating the user role',
        },
        '#/projects/#/users/#': {
          400: 'An error occured while adding the user to the project / updating the user role',
          403: 'An error occured while adding the user to the project / updating the user role',
          404: 'An error occured while adding the user to the project / updating the user role',
          409: 'An error occured while adding the user to the project / updating the user role',
          422: 'An error occured while adding the user to the project / updating the user role',
          429: 'An error occured while adding the user to the project / updating the user role',
          500: 'An error occured while adding the user to the project / updating the user role',
        },
      },
      post: {
        '#/projects': {
          400: 'An error occurred while creating the project',
          403: 'An error occurred while creating the project',
          404: 'An error occurred while creating the project',
          409: 'An error occurred while creating the project',
          422: 'An error occurred while creating the project',
          429: 'An error occurred while creating the project',
          500: 'An error occurred while creating the project',
        },
      },
      delete: {
        '#/projects/#': {
          400: 'An error occurred while deleting the project',
          403: 'You do not have the permission to delete this project',
          404: 'An error occurred while deleting the project',
          409: 'An error occurred while deleting the project',
          422: 'An error occurred while deleting the project',
          429: 'An error occurred while deleting the project',
          500: 'An error occurred while deleting the project',
        },
        '#/projects/#/users/#': {
          400: 'An error occurred when removing the user from the project',
          403: 'An error occurred when removing the user from the project',
          404: 'An error occurred when removing the user from the project',
          409: 'An error occurred when removing the user from the project',
          422: 'An error occurred when removing the user from the project',
          429: 'An error occurred when removing the user from the project',
          500: 'An error occurred when removing the user from the project',
        },
        '#/users/#': {
          400: 'An error occurred when deleting the user',
          403: 'You are not authorized to delete the user',
          404: 'An error occurred when deleting the user',
          409: 'An error occurred when deleting the user',
          422: 'An error occurred when deleting the user',
          429: 'An error occurred when deleting the user',
          500: 'An error occurred when deleting the user',
        },
      },
    },
    system: {
      get: {
        system: {
          '/system/version': {
            400: 'An error occurred while loading the version number',
            403: 'An error occurred while loading the version number',
            404: 'An error occurred while loading the version number',
            409: 'An error occurred while loading the version number',
            422: 'An error occurred while loading the version number',
            429: 'An error occurred while loading the version number',
            500: 'An error occurred while loading the version number',
          },
        },
      },
    },
    users: {
      put: {
        'change-password': {
          400: 'An error occurred while changing the password',
          403: 'An error occurred while changing the password',
          404: 'An error occurred while changing the password',
          409: 'An error occurred while changing the password',
          422: 'An error occurred while changing the password',
          429: 'An error occurred while changing the password',
          500: 'An error occurred while changing the password',
        },
      },
    },
    problemCodes: {
      INCORRECT_OLD_PASSWORD: 'The old password is incorrect',
      NEW_PASSWORD_SAME_AS_OLD_PASSWORD:
        'The new password is identical to the previous password. Please choose a different password.',
      LAST_ADMIN_DEMOTION: 'There must be at least one administrator',
      OPTIMISTIC_LOCK: 'The data has been changed in the meantime',
      TOKEN_LIMIT_EXCEEDED:
        'The maximum number of tokens has been reached. Please contact support@doccape.de.',
      RATE_LIMIT_EXCEEDED: 'The maximum number of requests has been reached',
      INVALID_PDF_FILE: 'The file format is not supported',
      DOCUMENT_TOO_BIG: 'The file size exceeds the limit',
    },
  },
};

export default translations;
