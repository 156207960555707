import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getDocumentsTagsWithValuesByProject } from '../api/document';

const initialState = {
  data: [],
  statusDocumentsTagsWithValues: '',
  error: '',
};

/**
 * Fetches all document tags and values of a project
 */
export const fetchDocumentsTagsWithValuesDB = createAsyncThunk(
  'documents/fetchDocumentsTagWithValues',
  async (projectId) => {
    const response = await getDocumentsTagsWithValuesByProject(projectId);
    return response.data;
  },
);

/**
 * The slice of the document tags with values of a project
 */
export const documentsTagsWithValuesSlice = createSlice({
  name: 'documentsTagsWithValues',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentsTagsWithValuesDB.pending, (state) => {
        state.statusDocumentsTagsWithValues = 'pending';
      })
      .addCase(fetchDocumentsTagsWithValuesDB.fulfilled, (state, action) => {
        state.statusDocumentsTagsWithValues = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchDocumentsTagsWithValuesDB.rejected, (state, action) => {
        state.statusDocumentsTagsWithValues = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectDocumentsTagsWithValues = createSelector(
  (state) => state.documentsTagsWithValues,
  (documentsTagsWithValues) => documentsTagsWithValues.data,
);
export const selectDocumentsTagsWithValuesStatus = createSelector(
  (state) => state.documentsTagsWithValues,
  (documentsTagsWithValues) => documentsTagsWithValues.statusDocumentsTagsWithValues,
);
export const selectDocumentsTagsWithValuesError = createSelector(
  (state) => state.documentsTagsWithValues,
  (documentsTagsWithValues) => documentsTagsWithValues.error,
);

export default documentsTagsWithValuesSlice.reducer;
