import { API } from './axios';

/**
 * Logs the user in
 * @param {Object} payload The payload containing the email and the password of the user
 * @param {string} payload.email
 * @param {string} payload.password
 * @returns Promise
 */
export const login = (payload) => {
  return API.post('auth/login', payload, {
    withCredentials: true,
  });
};

/**
 * Verifies the current access token
 * @returns Promise
 */
export const verifyToken = () => {
  return API.get('/token/verify', {
    withCredentials: true,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

/**
 * Verifies the invitation token of an invited user
 * @param {string} token The invitation token
 * @returns Promise
 */
export const verifyInvitationToken = (token) => {
  return API.get(`/auth/users/validate-invitation-token/${token}`, {
    withCredentials: true,
  });
};

/**
 * Issues a new invitation token for an invited user and sends it to the user's email
 * @param {string} token The old invitation token
 */
export const resendEmailInvitation = (token) => {
  return API.put(`/auth/users/resend-invitation-email/${token}`, {
    withCredentials: true,
  });
};

/**
 * Issues a new invitation token for an invited user by an admin and sends it to the user's email
 * @param {number} orgId id of the organization the user was invited to
 * @param {number} userId Id of the user to be invited
 */
export const resendEmailInvitationByAdmin = (orgId, userId) => {
  return API.put(`/orgs/${orgId}/users/resend-invitation-email-by-admin/${userId}`, {
    withCredentials: true,
  });
};

/**
 * Refreshes the access token
 * @returns Promise
 */
export const refreshToken = () => {
  return API.post('/auth/refresh', { withCredentials: true });
};

/**
 * Logs the user out
 * @returns Promise
 */
export const logout = () => {
  return API.post('/auth/logout', { withCredentials: true });
};

/**
 * Requests an email with a link to reset the password of an user
 * @param {string} email of the user who forgot his password
 * @returns Promise
 */
export const postRequestResetPasswort = (email) => {
  return API.post('/auth/users/send-password-reset-mail', { email });
};

/**
 * Sets a new password after requesting a password reset token
 * @param {string} token password reset token sent via mail
 * @param {string} newPassword new password for the corresponding user
 * @returns Promise
 */
export const postResetPassword = (token, newPassword) => {
  return API.post('/auth/users/reset-password', { token, newPassword });
};

/**
 * Registers a new user
 * @param {string} email The email of the user
 * @param {string} password The password of the user
 * @param {string} firstName The first name of the user
 * @param {string} lastName  The last name of the user
 * @param {boolean} hasTermsAccepted Whether the user has accepted the terms of service
 * @param {string} orgName The name of the organization the user is registering for [OPTIONAL]
 * @returns
 */
export const postRegisterUser = (
  email,
  password,
  firstName,
  lastName,
  hasTermsAccepted,
  utmParameters,
  orgName = undefined,
) => {
  return API.post('/auth/register', {
    email,
    password,
    firstName,
    lastName,
    orgName,
    hasTermsAccepted,
    ...utmParameters,
  });
};

/**
 * Verifies the user after registration
 * @param {string} verifyEmailToken The token the user received after registering
 * @returns Promise
 */
export const verifyUserAfterRegistration = (verifyEmailToken) => {
  return API.put(`/auth/users/verify-email/${verifyEmailToken}`);
};

/**
 * Resends the email verification to the user
 * @param {string} oldToken The old email verification token
 * @returns Promise
 */
export const resendEmailVerification = (oldToken) => {
  return API.put(`/auth/resend-email-verification/${oldToken}`);
};

/*
 * Generates a new API key for the user
 * @returns Promise
 */
export const generateApiKey = () => {
  return API.post('/api-key', {}, { withCredentials: true });
};

/**
 * Changes the password of a user
 * @param {string} oldPassword current password of the corresponding user
 * @param {string} newPassword password which the user wants to use in the future
 * @returns Promise
 */
export const changePassword = (oldPassword, newPassword) => {
  return API.put('/users/change-password', { oldPassword, newPassword }, { withCredentials: true });
};
