import { Disclosure } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import doccapeLogo from '../../../assets/DocCape_Favicon.svg';
import { useLogout } from '../../../hooks/useLogout';
import useOrganizationLogo from '../../../hooks/useOrganizationLogo';
import useSystemFeatures from '../../../hooks/useSystemFeatures';
import { selectUserData } from '../../../reducers/userSlice';
import { checkOrgRole } from '../../../services/authService';
import CloseIconWhite from '../../atoms/icons/CloseIconWhite';
import MenuButton from '../../atoms/tailwind/MenuButton';
import Separator from '../../atoms/tailwind/Separator';
import BurgerMenu from './BurgerMenu';
import BurgerMenuContent from './BurgerMenuContent';
import NavbarContainer from './NavbarContainer';
import NavigationNavbar from './NavigationNavbar';

const Navbar = ({ children, setShowSubscriptionPlans }) => {
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUserData);
  const { performLogout } = useLogout();
  
  // Available system features (used for conditional rendering)
  const { availableFeatures } = useSystemFeatures();

  const { organizationLogo } = useOrganizationLogo(organizationId);

  const premiumButtonItem = {
    name: t('subscription.navButton'),
    onClick: () => {
      setShowSubscriptionPlans(true);
    },
    color: 'orange',
  };

  /**
   * Returns the available navigation items based on the user's role in the organization and available features
   * @returns {Array} List of navigation items
   */
  const getNavigation = () => {
    const navigation = [];
    if (availableFeatures && availableFeatures.demoEnabled) {
      navigation.push(premiumButtonItem);
    }
    if (checkOrgRole(user, organizationId, 'ADMIN')) {
      navigation.push({
        name: t('documentSelection.userMngmtButton'),
        onClick: () => {
          navigate(`/organizations/${organizationId}/users`);
        },
        current: true,
      });
    }
    return navigation;
  }

  const navigation = getNavigation();

  const userNavigation = [
    {
      name: t('nav.settings'),
      onClick: () => {
        // Opens the API key dialog
        navigate(`/organizations/${organizationId}/users/${user.id}/settings`);
      },
    },
    {
      name: t('nav.logout'),
      onClick: () => {
        performLogout();
        navigate('/');
      },
    },
  ];

  /**
   * Renders the user menu button used in the MenuButton component as button prop
   */
  const renderUserMenuButton = () => {
    return (
      <div className="flex max-w-xs items-center space-x-2 rounded-full pr-1 text-xl text-white hover:cursor-pointer focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2">
        {/* 
          TODO: Images are not yet supported and will be added later
          <img className="h-8 w-8 rounded-full bg-white" src={user.image} alt="" />
        */}
        <div>{`${user.firstName} ${user.lastName}`}</div>
      </div>
    );
  };

  return (
    <NavbarContainer>
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex w-[16%] items-center justify-between">
                  <button
                    type="button"
                    onClick={() => navigate(`/organizations/${organizationId}/projects`)}
                  >
                    <img
                      className="pointer-events-auto sm:h-8 sm:w-8 md:h-10 md:w-10 lg:h-12 lg:w-12"
                      src={doccapeLogo}
                      alt="DocCape"
                    />
                  </button>
                  {organizationLogo.base64Logo ? (
                    <>
                      <CloseIconWhite />
                      <img
                        className="h-[24px]"
                        src={`data:image/*;base64,${organizationLogo.base64Logo}`}
                        alt="Customer Company"
                      />{' '}
                    </>
                  ) : null}
                </div>
                {/* Navigation buttons in navbar */}
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <NavigationNavbar navigation={navigation} />
                    {/* Dialog when adding a user to organization */}
                    <Separator />
                    <MenuButton menuItems={userNavigation} button={renderUserMenuButton()} />
                  </div>
                </div>
                <BurgerMenu open={open} />
              </div>
            </div>
            {/* Dropdown content of burger menu in mobile view */}
            <BurgerMenuContent
              navigation={navigation}
              user={user}
              userNavigation={userNavigation}
            />
          </>
        )}
      </Disclosure>
      {children}
    </NavbarContainer>
  );
};

export default Navbar;
