import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';
import Box from '../../atoms/Box';
import AnnotationForm from './AnnotationForm';

const TextAnnotationController = ({
  textLabels,
  selectedTextLabelName,
  annotations,
  paragraphs,
  createRenderableTextSegments,
  annotateAllUnmarked,
  findAndRemoveAnnotation,
  changeTextLabelNameOfAllAnnotationsWithSameText,
  setCrIdAndPseudonymOfAnnotation,
  removeAllAnnotationsWithSameTextLabelAndText,
  removeAllAnnotationsWithSameText,
  addAnnotation,
  changeTextLabelNameOfAnnotation,
  changeCrIdOfAnnotation,
  changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
  selectedParagraphIndex,
  toggleSelectedAnnotation,
  selectAnnotation,
  deselectAnnotation,
  isAnnotationSelected,
  isDisplayingPseudonyms,
}) => {
  return (
    <Box
      className="annotation-card"
      bg="white"
      rounded={5}
      pt={2}
      pl={2}
      pr={2}
      pb={2}
      height="100%"
    >
      {false ? (
        <>
          <br />
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        </>
      ) : (
        paragraphs.length > 0 &&
        paragraphs[0].tokens.length > 0 && (
          <AnnotationForm
            textLabels={textLabels}
            selectedTextLabelName={selectedTextLabelName}
            annotations={annotations}
            paragraphs={paragraphs}
            createRenderableTextSegments={createRenderableTextSegments}
            annotateAllUnmarked={annotateAllUnmarked}
            findAndRemoveAnnotation={findAndRemoveAnnotation}
            changeTextLabelNameOfAllAnnotationsWithSameText={
              changeTextLabelNameOfAllAnnotationsWithSameText
            }
            setCrIdAndPseudonymOfAnnotation={
              (paragraphIdx, annotationIdx, crId) =>
                setCrIdAndPseudonymOfAnnotation(paragraphIdx, annotationIdx, crId, textLabels) // TODO: Rename to WithTextLabels
            }
            removeAllAnnotationsWithSameTextLabelAndText={
              removeAllAnnotationsWithSameTextLabelAndText
            }
            removeAllAnnotationsWithSameText={removeAllAnnotationsWithSameText}
            addAnnotation={addAnnotation}
            selectedParagraphIndex={selectedParagraphIndex}
            toggleSelectedAnnotation={toggleSelectedAnnotation}
            isAnnotationSelected={isAnnotationSelected}
            changeTextLabelNameOfAnnotation={changeTextLabelNameOfAnnotation}
            changeCrIdOfAnnotation={(annotation, crId) =>
              changeCrIdOfAnnotation(annotation, crId, textLabels)
            }
            changeCrIdOfAllAnnotationsWithSameTextLabelAndText={
              changeCrIdOfAllAnnotationsWithSameTextLabelAndText
            }
            selectAnnotation={selectAnnotation}
            deselectAnnotation={deselectAnnotation}
            isDisplayingPseudonyms={isDisplayingPseudonyms}
          />
        )
      )}
    </Box>
  );
};

export default TextAnnotationController;
