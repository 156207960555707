import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ComboBoxAutoComplete from '../../../atoms/tailwind/ComboBoxAutoComplete';
import Dropdown from '../../../atoms/tailwind/Dropdown';
import Searchbar from '../../../atoms/tailwind/Searchbar';

/**
 * Table filter header component used in the documents table
 */
const DocumentTableFilterHeader = ({
  filterDocuments,
  searchValue,
  setSearchValue,
  // setFromDateValue, // TODO: Uncomment when date picker is implemented
  // setToDateValue, // TODO: Uncomment when date picker is implemented
  documentStatusValue,
  setDocumentStatusValue,
  documentTagsWithValues,
  setDocumentTagValue,
  placeholderDocumentTag,
}) => {
  const { t } = useTranslation();

  // Last search value is used to prevent the filter from being called multiple times if the search value has not changed
  const [lastSearchValue, setLastSearchValue] = useState('');

  const documentStatusList = [
    { id: 'all', name: t('documentSelection.status.ALL'), value: '*' },
    { id: 'unprocessed', name: t('documentSelection.status.UNPROCESSED'), value: 'UNPROCESSED' },
    { id: 'processing', name: t('documentSelection.status.PROCESSING'), value: 'PROCESSING' },
    { id: 'completed', name: t('documentSelection.status.COMPLETED'), value: 'COMPLETED' },
    { id: 'finalized', name: t('documentSelection.status.FINALIZED'), value: 'FINALIZED' },
    { id: 'archived', name: t('documentSelection.status.ARCHIVED'), value: 'ARCHIVED' },
    { id: 'error', name: t('documentSelection.status.ERROR'), value: 'ERROR' },
  ];

  /**
   * The selected item from the document status dropdown
   * Default value is the first item in the list (ALL)
   */
  const selectedItem =
    documentStatusList.find((item) => item.value === documentStatusValue) || documentStatusList[0];

  /**
   * Handles the search filter for the documents table
   */
  const handleSearchFilter = () => {
    // Only filter if the search value has changed
    if (searchValue === lastSearchValue) {
      return;
    }
    filterDocuments();
    setLastSearchValue(searchValue);
  };

  /**
   * Handles the status filter for the documents table
   * @param {object} item The selected item from the dropdown
   *
   */
  const handleStatusFilter = (item) => {
    // If the user selects the default value (show all status), set the value to null
    if (item === documentStatusList[0]) {
      setDocumentStatusValue('');
      filterDocuments({ status: '' });
      return;
    }
    setDocumentStatusValue(item.value);
    filterDocuments({ status: item.value });
  };

  /**
   * Handles the document tag filter for the documents table
   * @param {object} item The selected item from the document tag combobox
   *
   */
  const handleDocumentTagFilter = (item) => {
    if (item.length > 0 && item[0].value === null) {
      setDocumentTagValue(null);
      filterDocuments({ tagKeyValues: null });
      return;
    }
    setDocumentTagValue(item);
    filterDocuments({ tagKeyValues: item });
  };

  /**
   * Handles the filter for the documents table
   * @param {Date} date The selected date
   */
  // const handleToDateFilter = (date) => {
  //   setToDateValue(date.toISOString());
  //   filterDocuments({ toDate: date.toISOString() }); // TODO: Uncomment when date picker is implemented
  // };

  /**
   * Handles the filter for the documents table
   * @param {Date} date The selected date
   */
  // const handleFromDateFilter = (date) => {
  //   setFromDateValue(date.toISOString());
  //   filterDocuments({ fromDate: date.toISOString() }); // TODO: Uncomment when date picker is implemented
  // };

  // Enter key listener for search input
  useEffect(() => {
    const handleEnter = (event) => {
      if (event.key === 'Enter') {
        handleSearchFilter();
      }
    };
    // Warning: This document object is a reference to the document object in the DOM (https://developer.mozilla.org/docs/Web/API/Window/document)
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [searchValue]);

  /**
   * Filters for the documents table header
   */
  const filters = [
    {
      component: (
        <Dropdown
          selectedItem={selectedItem}
          items={documentStatusList}
          onChange={(item) => handleStatusFilter(item)}
        />
      ),
    },
    documentTagsWithValues.length !== 0
      ? {
          component: (
            <ComboBoxAutoComplete
              items={documentTagsWithValues}
              onChange={(item) => handleDocumentTagFilter(item)}
              placeholder={placeholderDocumentTag}
            />
          ),
        }
      : null,
    // { // TODO: Uncomment when date picker is implemented
    //   component: (
    //     <div className="flex">
    //       <p className="w-[7rem] self-center">{t('documentSelection.filter.fromDateLabel')}</p>
    //       <DatePicker defaultValue={new Date()} onChange={handleFromDateFilter} />
    //     </div>
    //   ),
    // },
    // {
    //   component: (
    //     <div className="flex">
    //       <p className="w-[7rem] self-center">{t('documentSelection.filter.toDateLabel')}</p>
    //       <DatePicker defaultValue={new Date()} onChange={handleToDateFilter} />
    //     </div>
    //   ),
    // },
    {
      component: (
        <Searchbar
          type="text"
          name="DocumentSearch"
          id="docSearch"
          onChange={(event) => setSearchValue(event.target.value)}
          onBlur={() => handleSearchFilter()}
          placeholder={t('app.search')}
        />
      ),
    },
  ];

  return (
    <div className="grid max-w-full grid-cols-4 gap-2">
      {filters.map((filter) => {
        if (!filter) {
          return null;
        }
        return <div className="w-52">{filter.component}</div>;
      })}
    </div>
  );
};

export default DocumentTableFilterHeader;
