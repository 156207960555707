/**
 * Transforms a list of objects into an array of arrays with header and data
 * which can be used for an csv export.
 *
 * @param {Object[]} objectList The given list of objects should be transformed
 * @return {headers: Array[], data: Array[Array[]]} The transformed headers and data
 */
export const transformToCSVData = (objectList) => {
  const csvData = { headers: [], data: [] };
  if (objectList && objectList.length === 0) return csvData;

  // set headers
  csvData.headers = Object.keys(objectList[0]);

  // set data
  objectList.forEach((item) => {
    csvData.data.push(Object.values(item));
  });

  return csvData;
};

/**
 * Transforms csv headers and csv values to an array of objects
 * @param {string[]} csvHeaders List of headers
 * @param {string[]} csvValues List of values
 */
export const transformFromCSVData = (csvHeaders, csvValues) => {
  const array = csvValues.map((values) => {
    const obj = csvHeaders.reduce((object, header, index) => {
      // eslint-disable-next-line no-param-reassign
      object[header] = values[index].trim();
      return object;
    }, {});
    return obj;
  });
  return array;
};

/**
 * Parses a csv string into an object with headers and rows.
 * @param {string} data The csv string to parse
 * @returns {headers: Array[], rows: Array[Array[]]} The parsed csv data
 */
const parseCsvString = (data) => {
  let headers = [];

  // Csv files can end with \n or with \r\n, therefore a check is necessary
  if (data.includes('\r')) {
    // Takes the first row, removes whitespaces and only uses the first two elements (because of split there is a third element with "")
    headers = data.slice(0, data.indexOf('\r')).replace(/ /g, '').split(';');
  } else {
    // Takes the first row, removes whitespaces and only uses the first two elements (because of split there is a third element with "")
    headers = data.slice(0, data.indexOf('\n')).replace(/ /g, '').split(';');
  }

  const strRows = data
    .trim()
    .slice(data.indexOf('\n') + 1)
    .split('\n');

  // split each row into an array of values
  const rows = strRows.map((strRow) => {
    const row = strRow.split(';').map((value) => value.trim());
    if (row.length !== headers.length) {
      throw new Error('CSV file has wrong format');
    }
    return row;
  });

  return { headers, rows };
};

/**
 * Loads a csv file and parses it into an object with headers and rows.
 * Then the callback function is called with the parsed csv data.
 *
 * @param {*} file The name of the file to load
 * @param {*} callback The callback function to call with the parsed csv data
 */
export const loadCSV = (file, callback) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const csvData = parseCsvString(event.target.result);
    callback(csvData);
  };
  reader.readAsText(file);
};
