import React from 'react';

const DotIcon = () => {
  return (
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 7"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.452148" width="6" height="6" rx="3" />
    </svg>
  );
};

export default DotIcon;
