import { Box, Button, Center, Flex, HStack, Select, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { getColorCodeByName } from '../../../utils/uiUtils';
import OptionTable from '../../molecules/OptionTable';

const PseudonymizationView = ({
  textLabels,
  annotations,
  selectedParagraphIndex,
  changeCrIdOfAnnotation,
}) => {
  const { t } = useTranslation();

  // State for indicating if the textLabel text in the tables are over the whole document or only the page
  const [tableType, setTableType] = useState('onlyPage');
  const [tableRows, setTableRows] = useState([]);

  /**
   * Decreases the crId of the given annotation
   * @param {Object} annotation The given annotation to decrease the crId
   */
  const decreaseCrId = (annotation) => {
    let newCrId = 1;

    // If crId of the annotation is not null decrease crId if not leave newCrId by 1
    if (annotation.crId !== null) {
      newCrId = annotation.crId > 1 ? annotation.crId - 1 : 1;
    }

    changeCrIdOfAnnotation(annotation, newCrId);
  };

  /**
   * Increases the crId of the given annotation
   * @param {Object} annotation The given annotation to increase the crId
   */
  const increaseCrId = (annotation) => {
    let newCrId = 1;

    // If crId of the annotation is not null increase crId if not leave newCrId by 1
    if (annotation.crId !== null) {
      newCrId = annotation.crId + 1;
    }

    changeCrIdOfAnnotation(annotation, newCrId);
  };

  /*
  We update the rows each time when the annotations change.
  This allows to show newly marked annotations in the pseudonomyize section,
  and equally remove deleted ones.
   */
  const createRows = () => {
    // Checks if annotations do not exist and then sets tableRows to empty array
    if (
      typeof annotations === 'undefined' ||
      annotations === null ||
      annotations.length <= selectedParagraphIndex
    ) {
      setTableRows([]);
      return;
    }

    const rowsTmp = [];

    // This array holds all annotations that should be displayed in the table
    let anotationsToDisplay = [];
    if (tableType === 'wholeDocument') {
      // Get annotations for the whole document in one list
      const annotationsOfWholeDocument = [];
      annotations.forEach((paragraph) => {
        paragraph.forEach((annotation) => {
          annotationsOfWholeDocument.push(annotation);
        });
      });
      anotationsToDisplay = annotationsOfWholeDocument;
    } else {
      anotationsToDisplay = annotations[selectedParagraphIndex];
    }

    for (let annotationIdx = 0; annotationIdx < anotationsToDisplay.length; annotationIdx += 1) {
      const annotation = anotationsToDisplay[annotationIdx];
      const displayedCrId = annotation.crId !== null ? annotation.crId : '?';
      const textLabelText = `${annotation.textLabelName}-${displayedCrId}`;
      rowsTmp.push([
        <Text fontSize="16px">{annotation.text}</Text>,
        <div key={textLabelText}>
          <HStack>
            <Button bg="gray.300" size="xs" onClick={() => decreaseCrId(annotation)}>
              <Text fontSize="16px">-</Text>
            </Button>
            <Box p={1}>
              <Flex
                width="65px"
                height="24px"
                borderRadius={5}
                color="white"
                bg={getColorCodeByName(textLabels[annotation.textLabelName]?.color)}
                fontWeight="medium"
                fontSize="16px"
                style={{
                  textTransform: 'uppercase',
                }}
              >
                <Center height="100%" width="100%">
                  <Text fontSize="16px">{textLabelText}</Text>
                </Center>
              </Flex>
            </Box>
            <Button bg="gray.300" size="xs" onClick={() => increaseCrId(annotation)}>
              <Text fontSize="16px">+</Text>
            </Button>
          </HStack>
        </div>,
      ]);
    }
    setTableRows(rowsTmp);
  };

  /**
   * Handles the change of the table type select value
   * @param {Object} event
   */
  const handleTableTypeChange = (event) => {
    setTableType(event.target.value);
  };

  // Creates rows if related data changes
  useEffect(() => {
    createRows();
  }, [annotations, textLabels, selectedParagraphIndex, tableType]);

  /**
   * Collects all occurring annotations in the text
   * @param {*} typeOfTable
   * @returns An array of all occurring annotations in the text, depending on typeOfTable
   */
  const createAnnotation = (typeOfTable) => {
    const annots = [];
    if (typeOfTable === 'onlyPage') {
      annotations[selectedParagraphIndex].map((annotation) => annots.push(annotation.pseudonym));
      return annots;
    }

    annotations.map((page) => page.map((annotation) => annots.push(annotation.pseudonym)));
    return annots;
  };

  /**
   * Collects all headers for the table export
   * @returns An array of column headers
   */
  const createCSVExportPseudonymHeaders = () => {
    return [
      t('anonymization.pseudonymization.pii'),
      t('anonymization.pseudonymization.pseudonym'),
      t('anonymization.pseudonymization.replacement'),
    ];
  };

  /**
   * Creates whole dataset which are exported when clicking the export button
   * @param {*} pseudonymTableRows
   * @returns Dataset for export in row oriented format with the structure [PII, pseudonym, replacement]
   */
  const createCSVExportPseudonymData = (pseudonymTableRows) => {
    const selectedAnnotations = createAnnotation(tableType);
    const exportPiiPseudonymData = [];

    pseudonymTableRows.map((row, idx) =>
      exportPiiPseudonymData.push([row[0].props.children, row[1].key, selectedAnnotations[idx]]),
    );

    const data = exportPiiPseudonymData;
    return data;
  };

  return tableRows.length !== 0 ? (
    <VStack height="100%" width="100%" pl="2" overflow="auto">
      <HStack width="99%" pb="2">
        <Select size="sm" value={tableType} onChange={handleTableTypeChange}>
          <option value="onlyPage">{t('anonymization.pseudonymization.onlyPage')}</option>
          <option value="wholeDocument">{t('anonymization.pseudonymization.wholeDocument')}</option>
        </Select>

        <CSVLink
          headers={createCSVExportPseudonymHeaders()}
          data={createCSVExportPseudonymData(tableRows)}
          filename="pseudonyms.csv"
          separator=";"
        >
          <Tooltip label={t('anonymization.pseudonymization.export')}>
            <Button size="sm">
              <Text fontSize="sm">{t('anonymization.pseudonymization.exportButton')}</Text>{' '}
            </Button>
          </Tooltip>
        </CSVLink>
      </HStack>
      <Box height="100%" width="100%" pl="2" overflow="auto">
        <OptionTable
          header={[
            <Text>{t('anonymization.pseudonymization.pii')}</Text>,
            <Text>{t('anonymization.pseudonymization.pseudonym')}</Text>,
          ]}
          body={tableRows}
          name="pseudonymization-table"
        />
      </Box>
    </VStack>
  ) : null;
};

export default PseudonymizationView;
