import React from 'react';

/**
 * Displays a simple checkbox input
 *
 * @param {string} id - The id of the checkbox
 * @param {boolean} checked - Whether the checkbox is checked
 * @param {function} onChange - The function to call when the checkbox is changed
 */
const Checkbox = ({ id, checked, onChange }) => {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="h-4 w-4 rounded-md text-blue-2 hover:cursor-pointer focus:ring-blue-1"
      />
    </div>
  );
};

export default Checkbox;
