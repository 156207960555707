import classNames from 'classnames';
import React from 'react';

/**
 * Standalone tooltip component that can be used to display additional information on hover
 * @param {string} position Position of the tooltip (top, bottom, left, right)#
 * @param {string} text Text to be displayed in the tooltip
 */
const Tooltip = ({ position = 'top', text, children }) => {
  const tooltipClasses = classNames('absolute z-10 p-2 text-sm text-white bg-darkGrey-1 rounded', {
    'bottom-full left-1/2 transform -translate-x-1/2 mb-2': position === 'top',
    'top-full left-1/2 transform -translate-x-1/2 mt-2': position === 'bottom',
    'right-full top-1/2 transform -translate-y-1/2 mr-2': position === 'left',
    'left-full top-1/2 transform -translate-y-1/2 ml-2': position === 'right',
  });

  return (
    <div className="group relative flex items-center">
      {children}
      <div className={classNames('invisible group-hover:visible w-96 text-center', tooltipClasses)}>
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
