import { useDispatch } from 'react-redux';
import { clearUserData, fetchUserData, updateUserData } from '../reducers/userSlice';

/**
 * Provides a function to store user data in the redux store
 */
const useUserData = () => {
  const dispatch = useDispatch();

  // Warning: If a useEffect is added to this hook, review how to change the usage of this hook in the useProjects hook
  // -> We don't want to create explicit dependencies between hooks

  /**
   * Retrieves the user data
   * (does not return the data, to get the data use the redux useSelector hook)
   */
  const getUser = async () => {
    try {
      await dispatch(fetchUserData()).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.info("Couldn't retrieve user data: ", error);
    }
  };

  /**
   * Updates the user data from the redux store
   */
  const updateUserProfile = async (firstName, lastName) => {
    await dispatch(updateUserData({ firstName, lastName })).unwrap();
  };

  /**
   * Clears the user data from the redux store
   */
  const clearUser = () => {
    dispatch(clearUserData());
  };

  return { getUser, clearUser, updateUserProfile };
};

export default useUserData;
