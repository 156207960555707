import { FormControl, FormLabel, Switch, TabPanel } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScoresTable from './ScoresTable';

/**
 * Creates a tab panel for the scores
 * @param {object} scores The scores object
 * @param {string} type The type of the scores
 * @param {boolean} withDuplicatesSwitch Whether to show the switch for duplicates
 */
const ScoresTabContent = ({ scores, type, withDuplicatesSwitch }) => {
  const { t } = useTranslation();
  const [useDuplicates, setUseDuplicates] = useState(false);

  /**
   * Creates a string representing the attribute of the scores object that contains the scores without duplicates
   * @returns {string} The attribute of the scores object that contains the scores without duplicates
   */
  const createScoreWithoutDuplicates = () => {
    return `${type}WithoutDuplicates`;
  };

  /**
   * Renders the scores table
   * @returns {JSX.Element} The scores table or a text if the scores are null
   */
  const renderScoresTable = () => {
    if (scores === null) {
      return t('annotation.noScoresText');
    }
    if (
      withDuplicatesSwitch &&
      typeof scores[createScoreWithoutDuplicates()] !== 'undefined' &&
      scores[createScoreWithoutDuplicates()] !== null
    ) {
      return (
        <ScoresTable
          scores={useDuplicates ? scores[type] : scores[createScoreWithoutDuplicates()]}
        />
      );
    }
    return <ScoresTable scores={scores[type]} />;
  };

  return (
    <TabPanel>
      {withDuplicatesSwitch && (
        <FormControl display="flex" alignItems="center" pb={3}>
          <FormLabel htmlFor="using-scores-duplicates" mb="0">
            {t('annotation.scoresDuplicateSwitchText')}
          </FormLabel>
          <Switch
            onChange={() => {
              setUseDuplicates(!useDuplicates);
            }}
          />
        </FormControl>
      )}
      {renderScoresTable()}
    </TabPanel>
  );
};

export default ScoresTabContent;
