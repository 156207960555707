import { createStandaloneToast } from '@chakra-ui/react';

/**
 * Displays a toast message
 * @param {*} props
 * @param {string} props.description - The message to display
 * @param {string} props.status - The status of the message
 */
const AppToaster = ({ description, status, position = 'top-right', duration }) => {
  const { toast } = createStandaloneToast();
  const toastId = toast({
    description,
    status,
    position,
    duration,
  });

  return {
    toastId,
    close: () => toast.close(toastId),
    update: (newDescription, newStatus, newPosition, newDuration) =>
      toast.update(toastId, { description: newDescription, status: newStatus, position: newPosition, duration: newDuration }),
  };
};

export default AppToaster;
