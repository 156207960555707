import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../../atoms/tailwind/Checkbox';
import Dropdown from '../../../../atoms/tailwind/Dropdown';
import InfoTooltipIcon from '../../../../atoms/tailwind/InfoTooltipIcon';

const DateReplacement = ({
  textLabel,
  allDateLocales,
  toggleReplaceDay,
  toggleReplaceMonth,
  toggleReplaceYear,
  toggleShowWeekday,
  changeDateLocale,
}) => {
  const { t } = useTranslation();

  /**
   * Transforms the given date locales into an array of options useable by the Dropdown component
   */
  const createAllDateLocaleOptions = () => {
    const dateOptions = [];
    let optionId = 0;
    allDateLocales.forEach((locale) => {
      dateOptions.push({
        id: optionId++,
        value: locale,
        name: locale,
      });
    });
    return dateOptions;
  };

  return (
    <>
      <div className="grid w-24 grid-cols-2 gap-1">
        <Checkbox id="day" checked={textLabel.replaceDay} onChange={toggleReplaceDay} />
        <InfoTooltipIcon tooltipText={t('settings.textLabels.replaceDayDescription')}>
          <div className="text-base text-blue-2">{t('settings.textLabels.replaceDay')}</div>
        </InfoTooltipIcon>

        <Checkbox id="month" checked={textLabel.replaceMonth} onChange={toggleReplaceMonth} />
        <InfoTooltipIcon tooltipText={t('settings.textLabels.replaceMonthDescription')}>
          <div className="text-base text-blue-2">{t('settings.textLabels.replaceMonth')}</div>
        </InfoTooltipIcon>

        <Checkbox id="year" checked={textLabel.replaceYear} onChange={toggleReplaceYear} />
        <InfoTooltipIcon tooltipText={t('settings.textLabels.replaceYearDescription')}>
          <div className="text-base text-blue-2">{t('settings.textLabels.replaceYear')}</div>
        </InfoTooltipIcon>
      </div>
      <div>
        <div className="grid w-24 grid-cols-2 gap-1 whitespace-nowrap">
          <InfoTooltipIcon tooltipText={t('settings.textLabels.dateLocaleTooltip')}>
            <div className="flex h-full items-center justify-center text-blue-2">
              {t('settings.textLabels.dateLocale')}:
            </div>
          </InfoTooltipIcon>
          <div className="ml-8 w-24">
            <Dropdown
              selectedItem={createAllDateLocaleOptions().find(
                (item) => item.value === textLabel.dateLocale,
              )}
              items={createAllDateLocaleOptions()}
              onChange={(item) => changeDateLocale(item.value)}
            />
          </div>
        </div>
        <div className="grid w-24 grid-cols-2 gap-1 whitespace-nowrap">
          <Checkbox id="showWeekday" checked={textLabel.showWeekday} onChange={toggleShowWeekday} />

          <div className="text-base text-blue-2">{t('settings.textLabels.showWeekdayTitle')}</div>
        </div>
      </div>
    </>
  );
};

export default DateReplacement;
