import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { resendEmailInvitation, verifyInvitationToken } from '../api/authentication';
import { postRegisterUser } from '../api/organization';
import AppToaster from '../components/molecules/AppToaster';

const useInvitationUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidRepeatPassword, setIsValidRepeatPassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [isVerifyInvitationTokenNotValid, setIsVerifyInvitationTokenNotValid] = useState(false);
  const [isVerifyInvitationTokenExpired, setIsVerifyInvitationTokenExpired] = useState(false);
  const [isVerifyInvitationTokenAlreadyUsed, setIsVerifyInvitationTokenAlreadyUsed] =
    useState(false);
  const [
    doesVerifyInvitationTokenNotExistOrIsOutdated,
    setDoesVerifyInvitationTokenNotExistOrIsOutdated,
  ] = useState(false);

  const [isUnknownError, setIsUnknownError] = useState(false);

  const onInviteUser = async ({ verifyEmailToken, passwordConfirmation }) => {
    await postRegisterUser(verifyEmailToken, firstName, lastName, password, passwordConfirmation);
  };

  /**
   * Handles error messages when verifying a token or registering a user
   * @param {string} statusCode The status code of the response
   */
  const handleTokenError = (statusCode) => {
    switch (statusCode) {
      case 422:
        setIsVerifyInvitationTokenNotValid(true);
        break;
      case 409:
        setIsVerifyInvitationTokenAlreadyUsed(true);
        break;
      case 410:
        setIsVerifyInvitationTokenExpired(true);
        break;
      case 404:
        setDoesVerifyInvitationTokenNotExistOrIsOutdated(true);
        break;
      case 500:
        setIsUnknownError(true);
        break;
      default:
        break;
    }
  };

  /**
   * Verifies given token in url
   */
  const verifyToken = async (verifyEmailToken) => {
    try {
      const response = await verifyInvitationToken(verifyEmailToken);
      if (response.status === 200) {
        navigate(`/invitation/registration/${verifyEmailToken}`);
      }
    } catch (err) {
      handleTokenError(err.response.status);
    }
  };

  /**
   * Refreshes the token by sending a new one to the user's email
   * @param {string} verifyEmailToken The old token
   */
  const refreshInvitationToken = async (verifyEmailToken) => {
    try {
      await resendEmailInvitation(verifyEmailToken);
      return true;
    } catch (err) {
      return false;
    }
  };

  const registerUser = async (verifyEmailToken) => {
    if (password.length < 8) {
      setIsValidPassword(false);
      setErrorMsg(t('organizationMngmt.registrationUser.userData.invalidInputs.passwordTooShort'));
      return;
    }
    setIsValidPassword(true);
    setErrorMsg('');

    if (password !== repeatPassword) {
      setIsValidRepeatPassword(false);
      setErrorMsg(t('organizationMngmt.registrationUser.userData.invalidInputs.passwordsNoMatch'));
      return;
    }
    setIsValidRepeatPassword(true);
    setErrorMsg('');

    try {
      await onInviteUser({
        verifyEmailToken,
        passwordConfirmation: repeatPassword,
      });
      AppToaster({
        description: t('organizationMngmt.registrationUser.successRegistration'),
        status: 'success',
      });
      setIsRegistrationSuccessful(true);
    } catch (error) {
      handleTokenError(error.response.status);
    }
  };

  return {
    isValidRepeatPassword,
    isValidPassword,
    errorMsg,
    password,
    repeatPassword,
    firstName,
    lastName,
    isRegistrationSuccessful,
    isVerifyInvitationTokenNotValid,
    isVerifyInvitationTokenExpired,
    isVerifyInvitationTokenAlreadyUsed,
    doesVerifyInvitationTokenNotExistOrIsOutdated,
    isUnknownError,
    refreshInvitationToken,
    setPassword,
    setRepeatPassword,
    setFirstName,
    setLastName,
    registerUser,
    verifyToken,
    acceptedTerms,
    setAcceptedTerms,
  };
};

export default useInvitationUser;
