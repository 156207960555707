import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getOrganizations } from '../api/organization';

const initialState = {
  data: [],
  status: '',
  error: '',
};

export const fetchOrganizations = createAsyncThunk('fetch/organizations', async () => {
  const response = await getOrganizations();
  return response.data;
});

/**
 * The slice of the organization
 */
export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    /**
     * Clears the organizations state by setting it to the initial state
     */
    clearOrganizations() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectOrganizationsData = createSelector(
  (state) => state.organizations,
  (organizations) => organizations.data,
);
export const selectOrganizationsStatus = createSelector(
  (state) => state.organizations,
  (organizations) => organizations.status,
);
export const selectOrganizationsError = createSelector(
  (state) => state.organizations,
  (organizations) => organizations.error,
);

export const { clearOrganizations } = organizationsSlice.actions;

export default organizationsSlice.reducer;
