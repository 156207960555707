import React from 'react';
import { useTranslation } from 'react-i18next';
import doccapeLogo from '../../../../assets/DocCape_Favicon.svg';
import ThumbsUp from '../../../../assets/DocCape_Hand_thumbsup_yellow.png';
import { classNames } from '../../../../utils/tailwindUtils';
import CustomCard from '../../../molecules/tailwind/CustomCard';

/**
 * Component for the card that is shown when the user tries to use an expired token
 * @param {string} title The title of the card
 * @param {string} sendingLinkHeading The heading of the card when the link was sent successfully
 * @param {string} sendLinkInfo The info text of the card when the link was sent successfully
 * @param {function} handleRefreshToken The function to refresh the token
 * @param {boolean} isLinkSentSuccesful Whether the link was sent successfully
 */
const ExpiredTokenCard = ({
  title,
  sendingLinkHeading,
  sendLinkInfo,
  handleRefreshToken,
  isLinkSentSuccesful = false,
}) => {
  const { t } = useTranslation();

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  const thumbsIconStyle = isLinkSentSuccesful ? 'rotate-0' : 'rotate-180';

  return (
    <CustomCard
      image={logoImage}
      title={isLinkSentSuccesful ? sendingLinkHeading : title}
      subtitle={
        isLinkSentSuccesful ? null : t('organizationMngmt.invitationUserExpiredToken.subHeading')
      }
      buttonText={t('organizationMngmt.invitationUserExpiredToken.sendLinkAgainButton')}
      buttonAction={
        // If the link was sent successfully, the button should not be clickable
        isLinkSentSuccesful
          ? null
          : () => {
              handleRefreshToken();
            }
      }
      content={
        <>
          <div className="flex w-full items-center justify-center">
            <img
              className={classNames(`w-20 ${thumbsIconStyle}`)}
              src={ThumbsUp}
              alt="Thumbs Icon"
            />
          </div>
          {isLinkSentSuccesful ? (
            <p className="mt-3 text-center text-sm text-grey-1">{sendLinkInfo}</p>
          ) : null}
        </>
      }
    />
  );
};

export default ExpiredTokenCard;
