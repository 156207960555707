/**
 * Scans the annotations from the annotationIdx and paragraphIdx backwards and finds the
 * closest predecessor with same textLabelName and text.
 * If there is no predecessor, the crId is null.
 * While scanning also the max crId of the same textLabelName is tracked and returned.
 *
 * @param {*} annotations The annotations
 * @param {*} annotationIdx The index of the annotation for which the predecessor should be found
 * @param {*} paragraphIdx The index of the paragraph in which the annotation is located
 * @returns
 */
const findClosestPredecessorCrId = (annotations, annotationIdx, paragraphIdx) => {
  const annotation = annotations[paragraphIdx][annotationIdx];
  let maxCrId = 0;
  for (let i = paragraphIdx; i >= 0; i -= 1) {
    const paragraphAnnotations = annotations[i];
    const startAnnotationIdx =
      i === paragraphIdx ? annotationIdx - 1 : paragraphAnnotations.length - 1;
    for (let j = startAnnotationIdx; j >= 0; j -= 1) {
      const predecessor = paragraphAnnotations[j];
      if (
        predecessor.textLabelName === annotation.textLabelName &&
        predecessor.text === annotation.text
      ) {
        return { crId: predecessor.crId, maxCrId };
      }
      if (predecessor.textLabelName === annotation.textLabelName) {
        maxCrId = Math.max(maxCrId, predecessor.crId);
      }
    }
  }
  return { crId: null, maxCrId };
};

/**
 * Scans the annotations from the annotationIdx and paragraphIdx forwards and finds the
 * closest successor with same textLabelName and text.
 * If there is no predecessor, the crId is null.
 * While scanning also the max crId of the same textLabelName is tracked and returned.
 *
 * @param {*} annotations The annotations
 * @param {*} annotationIdx The index of the annotation for which the successor should be found
 * @param {*} paragraphIdx The index of the paragraph in which the annotation is located
 * @returns
 */
const findClosestSuccessorCrId = (annotations, annotationIdx, paragraphIdx) => {
  const annotation = annotations[paragraphIdx][annotationIdx];
  let maxCrId = 0;
  for (let i = paragraphIdx; i < annotations.length; i += 1) {
    const paragraphAnnotations = annotations[i];
    const startAnnotationIdx = i === paragraphIdx ? annotationIdx + 1 : 0;
    for (let j = startAnnotationIdx; j < paragraphAnnotations.length; j += 1) {
      const successor = paragraphAnnotations[j];
      if (
        successor.textLabelName === annotation.textLabelName &&
        successor.text === annotation.text
      ) {
        return { crId: successor.crId, maxCrId };
      }
      if (successor.textLabelName === annotation.textLabelName) {
        maxCrId = Math.max(maxCrId, successor.crId);
      }
    }
  }
  return { crId: null, maxCrId };
};

/**
 * Creates a new crId for a new annotation.
 * The new crId is the crId of the closest predecessor or successor, if no predecessor exists
 * If there is no predecessor or successor, the new crId is the max crId + 1 of that textLabel
 *
 * @param {*} annotations
 * @param {*} paragraphIdx
 * @param {*} newAnnotationIdx
 * @returns
 */
export const createCrIdForNewAnnotation = (annotations, paragraphIdx, newAnnotationIdx) => {
  const { crId: predCrId, maxCrId: predMaxCrId } = findClosestPredecessorCrId(
    annotations,
    newAnnotationIdx,
    paragraphIdx,
  );
  if (predCrId !== null) {
    return predCrId;
  }
  const { crId: sucCrId, maxCrId: sucMaxCrId } = findClosestSuccessorCrId(
    annotations,
    newAnnotationIdx,
    paragraphIdx,
  );
  if (sucCrId !== null) {
    return sucCrId;
  }
  return Math.max(predMaxCrId, sucMaxCrId) + 1;
};
