import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postRegisterUser } from '../api/authentication';
import { validateEmail } from '../utils/emailUtils';

const useRegistration = (queryParams) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [firstName, setFirstName] = useState(queryParams.firstName || '');
  const [lastName, setLastName] = useState(queryParams.lastName || '');
  const [email, setEmail] = useState(queryParams.email || '');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidRepeatPassword, setIsValidRepeatPassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [utmParameters, setUtmParameters] = useState(queryParams.utmParameters);

  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);

  const registerUser = async () => {
    if (!firstName || firstName === '') {
      setIsValidFirstName(false);
      setErrorMsg(t('organizationMngmt.registrationUser.userData.invalidInputs.invalidFirstName'));
      return;
    }
    setIsValidFirstName(true);
    setErrorMsg('');

    if (!lastName || lastName === '') {
      setIsValidLastName(false);
      setErrorMsg(t('organizationMngmt.registrationUser.userData.invalidInputs.invalidLastName'));
      return;
    }
    setIsValidLastName(true);
    setErrorMsg('');

    if (!email || !validateEmail(email)) {
      setIsValidEmail(false);
      setErrorMsg(t('organizationMngmt.registrationUser.userData.invalidInputs.invalidEmail'));
      return;
    }
    setIsValidEmail(true);
    setErrorMsg('');

    if (password.length < 8) {
      setIsValidPassword(false);
      setErrorMsg(t('organizationMngmt.registrationUser.userData.invalidInputs.passwordTooShort'));
      return;
    }
    setIsValidPassword(true);
    setErrorMsg('');

    if (password !== repeatPassword) {
      setIsValidRepeatPassword(false);
      setErrorMsg(t('organizationMngmt.registrationUser.userData.invalidInputs.passwordsNoMatch'));
      return;
    }
    setIsValidRepeatPassword(true);
    setErrorMsg('');
    try {
      await postRegisterUser(email, password, firstName, lastName, true, utmParameters);
      setIsRegistrationSuccessful(true);
    } catch (error) {
      setIsRegistrationSuccessful(false);
    }
  };

  return {
    isValidFirstName,
    isValidLastName,
    isValidEmail,
    isValidRepeatPassword,
    isValidPassword,
    errorMsg,
    acceptedTerms,
    password,
    email,
    repeatPassword,
    firstName,
    lastName,
    isRegistrationSuccessful,
    setAcceptedTerms,
    setEmail,
    setPassword,
    setRepeatPassword,
    setFirstName,
    setLastName,
    registerUser,
  };
};

export default useRegistration;
