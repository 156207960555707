import { Button, Input, Spinner, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CiImport } from 'react-icons/ci';

/**
 * Renders the csv import button for black- and whitelists
 */
const ListingImportButton = ({ importListItems, isImporting }) => {
  const { t } = useTranslation();

  /**
   * Handles the upload by importing the data from the chosen csv file
   * @param {Object} event
   */
  const handleOnSubmit = (file) => {
    importListItems(file);
  };

  return (
    <Button
      size="sm"
      disabled={isImporting}
      leftIcon={isImporting ? <Spinner size="sm" /> : <CiImport />}
    >
      <Input
        type="file"
        accept=".csv"
        position="absolute"
        top="0"
        left="0"
        opacity="0"
        aria-hidden
        onChange={(event) => handleOnSubmit(event.target.files[0])}
        pointerEvents={isImporting ? 'none' : 'all'}
      />
      <Text>{t('settings.listing.importButton')}</Text>
    </Button>
  );
};

export default ListingImportButton;
