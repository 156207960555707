import { Disclosure } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../utils/tailwindUtils';
import UserMenuMobile from './UserMenuMobile';

const BurgerMenuContent = ({ navigation, user, userNavigation }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Navigation buttons mobile */}
      <Disclosure.Panel className="md:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          <button
            type="button"
            className="block rounded-md px-3 py-2 text-base font-medium text-white"
          >
            {t('organizationMngmt.invitationUser.inviteUser')}
          </button>
          {navigation.map((item) => (
            <Disclosure.Button
              key={item.name}
              as="a"
              href={item.href}
              className={classNames(
                item.current
                  ? 'bg-blue-900 text-white'
                  : 'text-white hover:bg-blue-700 hover:bg-opacity-75',
                'block rounded-md px-3 py-2 text-base font-medium',
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              {item.name}
            </Disclosure.Button>
          ))}
        </div>
        <UserMenuMobile user={user} userNavigation={userNavigation} />
      </Disclosure.Panel>
    </>
  );
};

export default BurgerMenuContent;
