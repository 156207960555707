import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  Spacer,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiHide, BiPlus, BiShowAlt } from 'react-icons/bi';
import { MdEditOff } from 'react-icons/md';
import { transformToRGBA } from '../../../utils/uiUtils';
import BboxCard from './BboxCard';

/**
 * Displays accordions for the different labelNames
 */
const BboxCardsArea = ({
  renderableBboxs,
  renderableDetections,
  labels,
  activeBbIdx,
  handleCreateBbox,
  isLabelInImage,
  isLabelActive,
  isCreatingBbox,
  isEditingBbox,
  cancelDrawing,
  isMovingImageButtonMode,
  setLabelIsHidden,
  isBboxActive,
  setBboxActive,
  onChangeLabelName,
  getNumberOfBboxesOfLabel,
}) => {
  const { t } = useTranslation();

  /**
   * This workaround is required due to the usage of chakra components. Those componenents do not
   * 'understand' the color codes of tailwind and need hex codes to function properly
   * can be deleted after upgrading the editor to tailwind components
   */
  const getColorCodeByName = (colorName) => {
    switch (colorName) {
      case 'lightPurple':
        return '#BCABFF';
      case 'purple':
        return '#7551FF';
      case 'deepPurple':
        return '#D251FF';
      case 'deepPink':
        return '#FF5487';
      case 'pink':
        return '#FFA0C5';
      case 'lightPink':
        return '#FFB9B2';
      case 'deepOrange':
        return '#FB7B05';
      case 'orange':
        return '#FBBC05';
      case 'green':
        return '#01B574';
      case 'deepGreen':
        return '#3EA101';
      case 'lightGreen':
        return '#92D25F';
      case 'teal':
        return '#01AAB5';
      case 'lightTeal':
        return '#70E6D9';
      case 'blue':
        return '#86ABE3';
      case 'lightBlue':
        return '#77A9F4';
      case 'deepBlue':
        return '#003EB9';
      case 'lightGrey':
        return '#7D95B3';
      case 'grey':
        return '#626188';
      case 'black':
        return '#0A1A39';
      case 'brown':
        return '#823408';

      // returns black as default so we can ensure that everything is readable
      default:
        return '#0A1A39';
    }
  };

  /**
   * Returns styles for the label accordion
   * @param {Object} label The label of the accordion
   * @returns A style object
   */
  const getAccordionStyles = (label) => {
    const styles = { alignItems: 'center' };
    if (label.isEnabled) {
      if (!isLabelInImage(label)) {
        styles.color = 'gray.400';
      }
    } else {
      styles.color = 'gray';
      styles.backgroundColor = 'gray.200';
      styles.cursor = 'not-allowed';
    }
    if (isLabelActive(label)) {
      styles.backgroundColor = transformToRGBA(label.color, 0.7);
      styles.borderWidth = '1px';
    }
    return styles;
  };

  /**
   * Renders the Accordion Button
   * @param {*} label
   * @returns Accordion button
   */
  const renderAccordionButton = (label) => {
    return label.isEnabled && isLabelInImage(label) && !isCreatingBbox ? (
      <AccordionButton ml={-2} w={10}>
        <AccordionIcon
          sx={{
            position: 'relative',
            left: '-70%',
          }}
        />
      </AccordionButton>
    ) : null;
  };

  /**
   * Renders the add bounding box button
   * @param {*} label
   * @returns Add bounding box button
   */
  const renderAddBboxButton = (label) => {
    return (activeBbIdx !== null && label.isEnabled && !isEditingBbox) ||
      (label.isEnabled && !isCreatingBbox) ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.addBbox')}>
        <Button
          flex="1"
          ml={-6}
          mr={!isLabelInImage(label) ? -3 : 0}
          variant="ghost"
          leftIcon={<BiPlus />}
          onClick={() => handleCreateBbox(label)}
        />
      </Tooltip>
    ) : null;
  };

  /**
   * Renders cancel button if a bounding box is drawn
   * @param {*} label
   * @returns Cancel drawing button
   */
  const renderCancelDrawingButton = (label) => {
    return isLabelActive(label) ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.cancelDrawingTooltip')}>
        <Button
          flex="1"
          mr={-3}
          variant="ghost"
          leftIcon={<MdEditOff />}
          onClick={() => cancelDrawing()}
        />
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the hide label button
   * @param {*} label
   * @returns Hide label button
   */
  const renderHideLabelButton = (label) => {
    return label.isEnabled && isLabelInImage(label) && !isCreatingBbox ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.hideLabel')}>
        <Button
          flex="1"
          variant="ghost"
          leftIcon={label.isHidden ? <BiHide /> : <BiShowAlt />}
          onClick={() => {
            setLabelIsHidden(label.name, !label.isHidden);
          }}
        />
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the bbox cards of the given label
   * @param {*} label
   */
  const renderBboxCardsOfLabel = (label) => {
    return label.isEnabled && isLabelInImage(label) ? (
      <AccordionPanel pb={2}>
        {renderableBboxs.map((bbox, key) => {
          if (bbox.label !== label.name) return null;
          return (
            <Box key={`${key + 1}-${label.name}`}>
              <BboxCard
                boxName={`${key + 1}-${label.name}`}
                labelName={label.name}
                labels={labels}
                bbox={bbox}
                color={label.color}
                isClickable={!isMovingImageButtonMode && !isEditingBbox && !isCreatingBbox}
                isBboxActive={isBboxActive}
                setBboxActive={setBboxActive}
                onChangeLabelName={onChangeLabelName}
              />
            </Box>
          );
        })}
      </AccordionPanel>
    ) : null;
  };

  /**
   * Renders the buttons for a given label
   * @param {Object} label The given label
   * @returns Buttons
   */
  const renderLabelButtons = (label) => {
    // Don't render any buttons if the image is moved
    if (isMovingImageButtonMode) {
      return null;
    }

    /**
     * Renders certain buttons (cancel drawing, hide label, add bbox, accordion) depending on conditions
     */
    return (
      <Flex
        sx={{
          '& > button': {
            _hover: { bg: 'transparent', color: 'gray.600' },
            cursor: !label.isEnabled ? 'not-allowed' : 'pointer',
          },
        }}
      >
        {renderCancelDrawingButton(label)}
        {renderHideLabelButton(label)}
        {renderAddBboxButton(label)}
        {renderAccordionButton(label)}
      </Flex>
    );
  };

  return labels && renderableDetections ? (
    <Accordion allowToggle>
      {labels.map((label) => (
        <Tooltip
          label={!label.isEnabled ? t('imageAnnotation.disabledLabelTooltip') : null}
          key={label.name}
        >
          <AccordionItem key={label.name} isDisabled={!label.isEnabled}>
            <h2>
              <Flex h={12} pl={2} sx={getAccordionStyles(label)}>
                <Circle
                  size="20px"
                  bg={
                    getNumberOfBboxesOfLabel(label, renderableDetections) && label.isEnabled
                      ? getColorCodeByName(label.color)
                      : 'gray'
                  }
                  opacity={1}
                />
                <Badge ml="1" h={5}>
                  {label.name} ({getNumberOfBboxesOfLabel(label, renderableDetections)})
                </Badge>
                <Spacer />
                <Flex
                  sx={{
                    '& > button': {
                      _hover: { bg: 'transparent', color: 'gray.600' },
                      cursor: !label.isEnabled ? 'not-allowed' : 'pointer',
                    },
                  }}
                >
                  {renderLabelButtons(label)}
                </Flex>
              </Flex>
              <Divider />
            </h2>
            {renderBboxCardsOfLabel(label)}
          </AccordionItem>
        </Tooltip>
      ))}
    </Accordion>
  ) : null;
};

export default BboxCardsArea;
