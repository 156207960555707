import React from 'react';
import { useTranslation } from 'react-i18next';
import doccapeLogo from '../../../../assets/DocCape_Favicon.svg';
import ThumbsUp from '../../../../assets/DocCape_Hand_thumbsup_yellow.png';
import CustomCard from '../../../molecules/tailwind/CustomCard';

/**
 * Component for the card that is shown when the user tries to a token and an unknown error occurs
 */
const UnknownErrorCard = () => {
  const { t } = useTranslation();

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  return (
    <CustomCard
      image={logoImage}
      title={t('organizationMngmt.invitationUnknonError.heading')}
      subtitle={t('organizationMngmt.invitationUnknonError.subHeading')}
      buttonAction={null}
      content={
        <div className="flex w-full items-center justify-center">
          <img className="w-20 rotate-180" src={ThumbsUp} alt="Thumbs Icon" />
        </div>
      }
    />
  );
};

export default UnknownErrorCard;
