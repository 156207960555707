import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPseudonymFromText } from '../../../../../services/pseudonymizationService';
import { renameObjectKey } from '../../../../../services/utils';
import AddIconSmall from '../../../../atoms/icons/AddIconSmall';
import Label from '../../../../molecules/tailwind/Label';
import LabelSelectionArea from '../../../../molecules/tailwind/LabelSelectionArea';
import TextLabelEditArea from './TextLabelEditArea';

const TextLabelSettings = ({
  isSaving,
  canAddTextLabels,
  allDateLocales,
  addTextLabel,
  saveTextLabels,
  changeTextLabelName,
  changeExtendedReplacement,
  setTextLabelAttribute,
  deleteTextLabel,
  textLabels,
  allTextLabels, // is needed to check if name and pseudonym are unique to ALL text labels
  hasTextLabelExtendedReplacement,
  setHasUnsavedChanges,
  handleTextLabelChange,
}) => {
  const { t } = useTranslation();

  /**
   * Holds all names of the textLabels
   */
  const [nameInputByTextLabelName, setNameInputByTextLabelName] = useState({});
  const [activeTextLabelName, setActiveTextLabelName] = useState(Object.keys(textLabels)[0]);
  const [preview, setPreview] = useState('');
  const [pseudonymizedPreview, setPseudonymizedPreview] = useState('');
  const nameValidityByTextLabelName = useRef(null);

  /**
   * Handles the input of a preview value by creating a pseudonym for it
   */
  useEffect(() => {
    if (textLabels[activeTextLabelName] && preview !== '') {
      const pseudonymizedValue = createPseudonymFromText(preview, textLabels[activeTextLabelName]);
      setPseudonymizedPreview(pseudonymizedValue);
    }
    if (preview === '') {
      setPseudonymizedPreview('');
    }
  }, [preview, textLabels, activeTextLabelName]);

  /**
   * Initializes the name validity and input objects.
   */
  useEffect(() => {
    const initnameValidityByTextLabelName = {};
    const initnameInputByTextLabelName = {};
    // Uses allTextLabels for validation
    Object.keys(allTextLabels).forEach((labelName) => {
      initnameValidityByTextLabelName[labelName] = {
        valid: true,
        formMessage: null,
      };
      initnameInputByTextLabelName[labelName] = labelName;
    });

    setNameInputByTextLabelName(initnameInputByTextLabelName);
    nameValidityByTextLabelName.current = initnameValidityByTextLabelName;
  }, []);

  /**
   * Validates the name input and sets a flag and a error message.
   * The flag prevents saving the text label.
   *
   * @param {string} labelName
   * @param {string} name
   */
  const setIsNameValid = (labelName, name) => {
    if (name === '') {
      nameValidityByTextLabelName.current[labelName] = {
        valid: false,
        formMessage: <div className="py-1 text-red-3">{t('settings.textLabels.isRequired')}</div>,
      };
    } else if (
      // Uses allTextLabels for validation
      Object.keys(allTextLabels).find(
        (otherTagName) => otherTagName !== labelName && otherTagName === name,
      )
    ) {
      nameValidityByTextLabelName.current[labelName] = {
        valid: false,
        formMessage: <div className="py-1 text-red-3">{t('settings.textLabels.alreadyUsed')}</div>,
      };
    } else {
      nameValidityByTextLabelName.current[labelName] = {
        valid: true,
        formMessage: null,
      };
    }
  };

  /**
   * Handles changes in the name field triggered by the keyboard presses.
   * Limits the length of the name field to 6 chars.
   * Additionally it initiates the validation of the input.
   *
   * @param {string} labelName
   * @param {string} newName The keyboard input
   */
  const handleChangeName = (labelName, newName) => {
    const editedName = newName.slice(0, 6);
    setIsNameValid(labelName, editedName);
    setNameInputByTextLabelName({
      ...nameInputByTextLabelName,
      [labelName]: editedName,
    });
  };

  /**
   * Default Replacement input validation.
   * Prevents the user from saving the changes to the database
   * and displays an error whenever:
   *     - input is empty
   *     - defaultReplacement is already used
   */
  const defaultReplacementValidityByTagName = useRef(null);
  useEffect(() => {
    const initDefaultReplacementValidityByTagName = {};
    // Uses allTextLabels for validation
    Object.keys(allTextLabels).forEach((labelName) => {
      initDefaultReplacementValidityByTagName[labelName] = {
        valid: true,
        formMessage: null,
      };
    });
    defaultReplacementValidityByTagName.current = initDefaultReplacementValidityByTagName;
  }, []);

  /**
   * Updates the labelName in the state variables
   * This is necessary because the labelName is used as a key in the state variables.
   *
   * @param {string} oldTagName
   * @param {string} newTagName
   */
  const updateLabelNameInStateVariables = (oldTagName, newTagName) => {
    // update the labelName in this component
    const refs = [nameValidityByTextLabelName, defaultReplacementValidityByTagName];
    const tmp = refs;
    refs.forEach((ref, index) => {
      tmp[index].current = renameObjectKey(ref.current, oldTagName, newTagName);
    });

    setNameInputByTextLabelName(renameObjectKey(nameInputByTextLabelName, oldTagName, newTagName));
  };

  /**
   * Saves a valid new name of a text label to the text labels state.
   *
   * @param {string} labelName
   */
  const handleSaveName = (labelName) => {
    const newName = nameInputByTextLabelName[labelName];
    if (labelName !== newName && nameValidityByTextLabelName.current[labelName].valid) {
      // update state variables in this component
      updateLabelNameInStateVariables(labelName, newName);
      changeTextLabelName(labelName, newName);
      setActiveTextLabelName(newName);
    }
  };

  /**
   * Validates the default pseudonym and sets a flag and a error message.
   * The flag prevents closing the settings and storing the value to the database.
   *
   * @param {string} labelName
   * @param {string} defaultReplacement
   */
  const setIsDefaultReplacementValid = (labelName, defaultReplacement) => {
    if (defaultReplacement === '') {
      defaultReplacementValidityByTagName.current[labelName] = {
        valid: false,
        formMessage: <div className="py-1 text-red-3">{t('settings.textLabels.isRequired')}</div>,
      };
    } else if (
      // Uses allTextLabels for validation
      Object.entries(allTextLabels).find(
        ([otherTagName, otherTag]) =>
          otherTagName !== labelName && otherTag.defaultReplacement === defaultReplacement,
      )
    ) {
      defaultReplacementValidityByTagName.current[labelName] = {
        valid: false,
        formMessage: <div className="py-1 text-red-3">{t('settings.textLabels.alreadyUsed')}</div>,
      };
    } else {
      defaultReplacementValidityByTagName.current[labelName] = {
        valid: true,
        formMessage: null,
      };
    }
  };

  /**
   * Changes a text label's default pseudonym and sets its validation.
   *
   * @param {string} labelName
   * @param {string} defaultReplacement
   */
  const handleChangeDefaultReplacement = (labelName, defaultReplacement) => {
    setIsDefaultReplacementValid(labelName, defaultReplacement);
    setTextLabelAttribute(labelName, 'defaultReplacement', defaultReplacement);
  };

  const handleDelete = (labelName) => {
    // set active Tag to left neighbor if possible, else to right neighbor, else to none
    const activeTagIndex = Object.keys(textLabels).findIndex((tagName) => tagName === labelName);
    const isNotFirstLabel = activeTagIndex !== 0;
    const isNotLastLabel = activeTagIndex !== Object.keys(textLabels).length - 1;
    if (isNotFirstLabel) {
      setActiveTextLabelName(Object.keys(textLabels)[activeTagIndex - 1]);
    } else if (isNotLastLabel) {
      setActiveTextLabelName(Object.keys(textLabels)[activeTagIndex + 1]);
    } else {
      setActiveTextLabelName(null);
    }

    // remove labelName from nameInput and nameValidity objects
    delete nameValidityByTextLabelName.current[labelName];
    delete defaultReplacementValidityByTagName.current[labelName];
    const newnameInputByTextLabelName = { ...nameInputByTextLabelName };
    delete newnameInputByTextLabelName[labelName];
    setNameInputByTextLabelName(newnameInputByTextLabelName);

    deleteTextLabel(labelName);
  };

  /**
   * Creates a new unique text label name.
   * @returns the new text label name
   */
  const generateUniqueTextLabelName = () => {
    if (
      !Object.keys(textLabels).find(
        (labelName) => labelName === t('settings.textLabels.newTextLabel'),
      )
    ) {
      return t('settings.textLabels.newTextLabel');
    }
    // append a unique number to NewTag
    for (let i = 1; i < Object.keys(textLabels).length + 1; i += 1) {
      if (
        !Object.keys(textLabels).find(
          (labelName) => labelName === `${t('settings.textLabels.newTextLabel')}-${i}`,
        )
      ) {
        return `${t('settings.textLabels.newTextLabel')}-${i}`;
      }
    }
    return null;
  };

  /**
   * Handles adding a new text label
   */
  const handleAddTextLabel = () => {
    const labelName = generateUniqueTextLabelName();
    nameValidityByTextLabelName.current[labelName] = {
      valid: true,
      formMessage: null,
    };
    defaultReplacementValidityByTagName.current[labelName] = {
      valid: true,
      formMessage: null,
    };

    setNameInputByTextLabelName({ ...nameInputByTextLabelName, [labelName]: labelName });
    addTextLabel(labelName);

    // Updates the active text label name only if there are no unsaved changes
    // (handleTextLabelChange calls the set state function only if there are no unsaved changes)
    handleTextLabelChange(setActiveTextLabelName, labelName);
  };

  const textlabelColumn = (
    <>
      <li className="col-span-1 flex w-fit flex-col justify-center">
        <p className="w-fit text-xl text-blue-2">{t('settings.textLabels.selectionTitle')}</p>
        <div className="mt-4 h-[34rem] w-fit">
          <LabelSelectionArea
            labels={textLabels}
            activeLabelName={activeTextLabelName}
            setActiveLabelName={(value) => handleTextLabelChange(setActiveTextLabelName, value)}
            hasLabelExtendedReplacement={hasTextLabelExtendedReplacement}
            canAddLabel={canAddTextLabels}
          />
          {canAddTextLabels ? (
            <div className="mt-2 flex h-[2rem] justify-center">
              <Label
                leftIcon={<AddIconSmall />}
                active
                color="grey"
                hoverColor="grey"
                onClick={handleAddTextLabel}
              />
            </div>
          ) : null}
        </div>
      </li>
      <li className="col-span-2 flex w-full flex-col justify-start">
        <p className="w-full text-xl text-blue-2">{t('settings.textLabels.editing')}</p>
        <div className=" mt-4">
          <TextLabelEditArea
            isSaving={isSaving}
            textLabel={textLabels[activeTextLabelName]}
            nameValidityByTextLabelName={nameValidityByTextLabelName}
            nameInputByTextLabelName={nameInputByTextLabelName}
            defaultReplacementValidityByTagName={defaultReplacementValidityByTagName}
            changeName={(value) => handleChangeName(activeTextLabelName, value)}
            onBlurChangeName={() => handleSaveName(activeTextLabelName)}
            onDelete={() => handleDelete(activeTextLabelName)}
            onSave={() => saveTextLabels(allTextLabels)}
            changeDefaultReplacement={(value) =>
              handleChangeDefaultReplacement(activeTextLabelName, value)
            }
            changeDescription={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'description', value)
            }
            changePartialReplacementStart={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'partialReplacementStart', value)
            }
            changePartialReplacementStop={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'partialReplacementStop', value)
            }
            changeUseNumericPartialReplacement={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'useNumericPartialReplacement', value)
            }
            changeReplaceDay={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'replaceDay', value)
            }
            changeReplaceMonth={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'replaceMonth', value)
            }
            changeReplaceYear={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'replaceYear', value)
            }
            changeShowWeekday={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'showWeekday', value)
            }
            changeDateLocale={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'dateLocale', value)
            }
            changeExtendedReplacement={(value) =>
              changeExtendedReplacement(activeTextLabelName, value)
            }
            changeUseSuppression={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'useSuppression', value)
            }
            changeSuppressionLength={(value) =>
              setTextLabelAttribute(activeTextLabelName, 'suppressionLength', value)
            }
            allDateLocales={allDateLocales}
            preview={preview}
            setPreview={setPreview}
            pseudonymizedPreview={pseudonymizedPreview}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </div>
      </li>
    </>
  );

  // TODO: Sidebar anpassen (siehe Design!!!)
  return (
    <div className="h-full w-full">
      <ul className="mt-3 grid grid-cols-1 gap-4 py-2 md:grid-cols-3 lg:gap-1">
        {textlabelColumn}
      </ul>
    </div>
  );
};

export default TextLabelSettings;
