import { Select } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Renders the selector for an the label item in the whitelist
 *
 * @param {array} labels The labels to select from (excluding the wildcard option)
 * @param {string} label The current label
 * @param {function} handleItemLabelChange The function to handle the change of the label
 * @returns
 */
const WhitelistItemLabelSelector = ({ label, labels, handleItemLabelChange }) => {
  const { t } = useTranslation();

  return (
    <Select value={label} onChange={handleItemLabelChange}>
      <option value="*">{t('settings.listing.whitelistItemPlaceholder')}</option>
      {Object.keys(labels).map((labelName) => {
        return (
          <option value={labelName} key={labelName}>
            {labelName}
          </option>
        );
      })}
    </Select>
  );
};

export default WhitelistItemLabelSelector;
