export const createTextLabel = ({
  name,
  defaultReplacement = '',
  description = '',
  color = 'red',
  fromModel = false,
  usePartialReplacement = false,
  partialReplacementStart = 1,
  partialReplacementStop = 0,
  useNumericPartialReplacement = false,
  useDatePartialReplacement = false,
  replaceDay = false,
  replaceMonth = false,
  replaceYear = false,
  dateLocale = 'AUTO',
  showWeekday = false,
  useSuppression = false,
  suppressionLength = 4,
}) => {
  return {
    name,
    defaultReplacement,
    description,
    color,
    fromModel,
    usePartialReplacement,
    partialReplacementStart,
    partialReplacementStop,
    useNumericPartialReplacement,
    useDatePartialReplacement,
    replaceDay,
    replaceMonth,
    replaceYear,
    dateLocale,
    showWeekday,
    useSuppression,
    suppressionLength,
  };
};

export const hasTextLabelExtendedReplacement = (textLabel) => {
  return textLabel.usePartialReplacement || textLabel.useDatePartialReplacement;
};
