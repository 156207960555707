import * as EmailValidator from 'email-validator';

/**
 *  Validates the email
 * @param {string} email The email to validate
 * @returns {boolean} True if the email is valid, false otherwise
 */
export const validateEmail = (email) => {
  return EmailValidator.validate(email);
};
