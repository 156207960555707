import { Box, Center, Flex, Grid, GridItem, HStack, theme, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { BiRedo, BiUndo } from 'react-icons/bi';
import { MdDeleteOutline, MdEdit, MdEditOff } from 'react-icons/md';
import { SlClose, SlCursorMove, SlFrame } from 'react-icons/sl';
import SmallIconButton from '../../../molecules/SmallIconButton';

const mainMenuHeight = `(2rem + 1*${theme.space[2]})`; // Button height + 2*2*padding

const ImageAnnotationTopLeftMenu = ({
  onDeleteBbox,
  handleZoomIn,
  handleZoomOut,
  centerView,
  setIsMovingImageButtonMode,
  isMovingImageButtonMode,
  isCreatingBbox,
  cancelDrawing,
  setActiveBbox,
  isEditingBbox,
  cancelEditingBbox,
  beginEditingBbox,
  activeBboxId,
  imageAnnotationUndo,
  imageAnnotationRedo,
  imageAnnotationUndoActionName,
  imageAnnotationRedoActionName,
}) => {
  const { t } = useTranslation();

  /**
   * Renders the button for zooming in in the image
   */
  const renderZoomInButton = () => {
    return (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.zoomInTooltip')}>
        <Box>
          <SmallIconButton onClick={handleZoomIn} icon={<AiOutlinePlus size={16} />} />
        </Box>
      </Tooltip>
    );
  };

  /**
   * Renders button for zooming out in the image
   */
  const renderZoomOutButton = () => {
    return (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.zoomOutTooltip')}>
        <Box>
          <SmallIconButton onClick={handleZoomOut} icon={<AiOutlineMinus size={16} />} />
        </Box>
      </Tooltip>
    );
  };

  /**
   * Renders the button for centering the image
   */
  const renderCenterViewButton = () => {
    return (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.centerTooltip')}>
        <Box>
          <SmallIconButton
            onClick={() => {
              centerView();
            }}
            icon={<SlFrame size={16} />}
          />
        </Box>
      </Tooltip>
    );
  };

  /**
   * Renders the button for moving the image
   */
  const renderMovingImageButton = () => {
    return !isMovingImageButtonMode ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.moveTooltip')}>
        <Box>
          <SmallIconButton
            onClick={() => {
              setIsMovingImageButtonMode(true);
              setActiveBbox(null);
            }}
            icon={<SlCursorMove size={16} />}
          />
        </Box>
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the button for cancel drawing in the image
   */
  const renderCancelDrawingButton = () => {
    return isCreatingBbox ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.cancelDrawingTooltip')}>
        <Box>
          <SmallIconButton
            onClick={() => {
              cancelDrawing();
            }}
            icon={<MdEditOff size={19} />}
          />
        </Box>
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the button for cancel moving the image
   */
  const renderCancelMovingButton = () => {
    return isMovingImageButtonMode ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.cancelMoveTooltip')}>
        <Box>
          <SmallIconButton
            onClick={() => {
              setIsMovingImageButtonMode(false);
            }}
            icon={<SlClose size={16} />}
          />
        </Box>
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the button for cancel editing a bounding box in the image
   */
  const renderCancelEditingBboxButton = () => {
    return activeBboxId !== null && isEditingBbox ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.cancelEditingTooltip')}>
        <Box>
          <SmallIconButton
            onClick={() => {
              cancelEditingBbox();
            }}
            icon={<MdEditOff size={19} />}
          />
        </Box>
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the button for deleting a bounding box in the image
   */
  const renderDeletingBboxButton = () => {
    return activeBboxId !== null ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.deleteTooltip')}>
        <Box>
          <SmallIconButton onClick={onDeleteBbox} icon={<MdDeleteOutline size={19} />} />
        </Box>
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the button for editing a bounding box in the image
   */
  const renderEditingBboxButton = () => {
    return activeBboxId !== null && !isEditingBbox ? (
      <Tooltip placement="bottom" label={t('imageAnnotation.mainMenu.editTooltip')}>
        <Box>
          <SmallIconButton
            onClick={() => {
              beginEditingBbox();
            }}
            icon={<MdEdit size={19} />}
          />
        </Box>
      </Tooltip>
    ) : null;
  };

  /**
   * Renders the buttons for moving and zooming the image
   */
  const renderImageZoomMoveButtons = () => {
    if (activeBboxId !== null || isCreatingBbox || isEditingBbox) {
      return null;
    }
    return (
      <GridItem>
        <Center>
          <HStack>
            <Flex>{renderZoomInButton()}</Flex>
            <Flex>{renderZoomOutButton()}</Flex>
            <Flex>
              <GridItem>
                <Center>
                  <HStack>
                    {renderCenterViewButton()}
                    {renderMovingImageButton()}
                  </HStack>
                </Center>
              </GridItem>
            </Flex>
          </HStack>
        </Center>
      </GridItem>
    );
  };

  /**
   * Renders the button for undoing an action
   */
  const renderUndoButton = () => {
    return (
      <Tooltip
        label={
          imageAnnotationUndoActionName
            ? `${t('mainMenu.undo')}: ${t(
                `mainMenu.undoRedoActions.${imageAnnotationUndoActionName}`,
              )}`
            : t('mainMenu.undoRedoActions.undoNotPossible')
        }
      >
        <Box>
          <SmallIconButton
            disabled={!imageAnnotationUndoActionName}
            onClick={imageAnnotationUndo}
            icon={<BiUndo size={20} />}
          />
        </Box>
      </Tooltip>
    );
  };

  /**
   * Renders the button for redoing an action
   */
  const renderRedoButton = () => {
    return (
      <Tooltip
        label={
          imageAnnotationRedoActionName
            ? `${t('mainMenu.redo')}: ${t(
                `mainMenu.undoRedoActions.${imageAnnotationRedoActionName}`,
              )}`
            : t('mainMenu.undoRedoActions.redoNotPossible')
        }
      >
        <Box>
          <SmallIconButton
            disabled={!imageAnnotationRedoActionName}
            onClick={imageAnnotationRedo}
            icon={<BiRedo size={20} />}
          />
        </Box>
      </Tooltip>
    );
  };

  /**
   * Renders the buttons for undoing and redoing actions
   */
  const renderHistoryButtons = () => {
    return (
      <GridItem>
        <Center>
          <HStack>
            <Flex>{renderUndoButton()}</Flex>
            <Flex>{renderRedoButton()}</Flex>
          </HStack>
        </Center>
      </GridItem>
    );
  };

  return (
    <Box height={`calc(${mainMenuHeight})`} bg="gray.300">
      <Flex flexDirection="row" padding="2">
        <Grid templateColumns="repeat(3, 1fr)" templateRows="repeat(1, 1fr)" width="100%">
          <GridItem>
            <Flex>
              <HStack>
                {renderHistoryButtons()}
                {renderCancelDrawingButton()}
                {renderCancelMovingButton()}
                {renderDeletingBboxButton()}
                {renderCancelEditingBboxButton()}
                {renderEditingBboxButton()}
              </HStack>
            </Flex>
          </GridItem>
          {renderImageZoomMoveButtons()}
        </Grid>
      </Flex>
    </Box>
  );
};

export default ImageAnnotationTopLeftMenu;
