import {
  Button,
  Center,
  Divider,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AnnotationMarkRightClickMenu = ({
  textLabels,
  annotation,
  // setCrIdAndPseudonym,
  annotateAllUnmarked,
  changeTextLabelNameOfAllAnnotationsWithSameText,
  closeAddAnnotationsPopover,
  changeTextLabelName,
  changeCrId,

  changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
}) => {
  const { t } = useTranslation();
  const [pseudonymInputFieldValue, setPseudonymInputFieldValue] = useState('');

  /**
   * Initializes the input fields of the pseudonymization input whenever the
   * annotation changes.
   */
  useEffect(() => {
    if (typeof annotation !== 'undefined') {
      setPseudonymInputFieldValue(annotation.crId ? annotation.crId : '');
    }
  }, [annotation]);

  return (
    <Center>
      <VStack>
        <HStack>
          <Select
            size="sm"
            width="85px"
            value={annotation.textLabelName}
            onChange={(event) => {
              const newTextLabel = event.target.value;
              changeTextLabelName(annotation, textLabels[newTextLabel]);
            }}
          >
            {Object.keys(textLabels).map((textLabelName) => (
              <option key={`TextLabelSelectionOption-${textLabelName}`} value={textLabelName}>
                {textLabelName}
              </option>
            ))}
          </Select>
          <Tooltip hasArrow label={t('annotation.applyToAllTextDescription')}>
            <Button
              width="75%"
              size="sm"
              onClick={() => {
                const newTextLabel = textLabels[annotation.textLabelName];
                changeTextLabelNameOfAllAnnotationsWithSameText(annotation, newTextLabel);
                closeAddAnnotationsPopover();
              }}
            >
              {t('annotation.applyToAllText')}
            </Button>
          </Tooltip>
        </HStack>
        <Divider />
        <HStack>
          <NumberInput
            min="1"
            size="sm"
            width="85px"
            value={pseudonymInputFieldValue}
            onChange={(value) => {
              setPseudonymInputFieldValue(value);
            }}
            onBlur={() => {
              const newCrId = parseInt(pseudonymInputFieldValue, 10);
              if (newCrId) {
                changeCrId(newCrId);
              }
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Tooltip
            hasArrow
            label={t('annotation.applyPseuodnymToAllWithSameTextLabelAndTextDescription')}
          >
            <Button
              width="75%"
              size="sm"
              disabled={annotation.crId === null}
              onClick={() => {
                changeCrIdOfAllAnnotationsWithSameTextLabelAndText(annotation);
                closeAddAnnotationsPopover();
              }}
            >
              {t('annotation.applyPseuodnymToAllWithSameTextLabelAndText')}
            </Button>
          </Tooltip>
        </HStack>
        <Divider />
        <Tooltip hasArrow label={t('annotation.addAllTextDescription')}>
          <Button
            width="100%"
            size="sm"
            colorScheme="blue"
            onClick={() => {
              annotateAllUnmarked(annotation);
              closeAddAnnotationsPopover();
            }}
          >
            {t('annotation.addAllText')}
          </Button>
        </Tooltip>
      </VStack>
    </Center>
  );
};

export default AnnotationMarkRightClickMenu;
