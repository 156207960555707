import React, { useRef } from 'react';
import { classNames } from '../../../utils/tailwindUtils';

/**
 * Displays a button for uploading files containing text and icon with one of the defined color styles
 * @param {string} buttonText - The text inside the button default null
 * @param {JSX.Element} icon - The icon to display default null
 * @param {function} buttonAction - The action to perform when the button is clicked
 * @param {string} color - The color of the button (babyBlue, blue, blueNav, green, red, violet, white)
 * @param {boolean} isLoading - Whether the loading spinner should be displayed
 * @param {JSX.Element} loadingButton - The button to display when the loading spinner is active
 */
const UploadButton = ({
  buttonText = null,
  icon = null,
  buttonAction,
  color = 'blue',
  isLoading = null,
  loadingButton = null,
  ...rest
}) => {
  if (isLoading !== null && loadingButton === null) {
    console.error('Property loadingButton is required if isLoading is set!');
    return null;
  }

  // This input ref is necessary to reset the input value after a file has been uploaded
  const inputRef = useRef(null);

  const colorVariants = {
    babyBlue:
      'bg-babyBlue-1 text-white hover:bg-white border-babyBlue-1 hover:border-babyBlue-1 hover:text-babyBlue-1 focus-visible:outline-babyBlue-1',
    blue: 'bg-blue-3 text-white hover:bg-white border-blue-3 hover:border-blue-3 hover:text-blue-3 focus-visible:outline-blue-3',
    blueNav:
      'bg-blue-2 text-white  hover:bg-white border-white hover:border-blue-2 hover:text-blue- focus-visible:outline-blue-2',
    green:
      'bg-green-1 text-white hover:bg-white border-green-1 hover:border-green-1 hover:text-green-1 focus-visible:outline-green-1',
    red: 'bg-red-2 text-white hover:bg-white border-red-2 hover:border-red-2 hover:text-red-2 focus-visible:outline-red-2',
    violet:
      'bg-violet-1 text-white hover:bg-white border-violet-1 hover:border-violet-1 hover:text-violet-1 focus-visible:outline-violet-1',
    white:
      'bg-white text-blue-3 hover:bg-blue-3 border-babyBlue-1 hover:border-blue-3 hover:text-white focus-visible:outline-white',
  };

  return (
    /* 
    I can't tell why the "max-h-9" parameter is necessary but without it the upload button is the only button
    which has a height of 40px instead of 36px like every other button
    */
    <label
      className={classNames(
        `${colorVariants[color]} flex items-center max-h-9 pr-2.5 shadow-sm border rounded-xl leading-2 hover:border-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:cursor-pointer`,
      )}
      {...rest}
    >
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        // Reset the input value after a file has been uploaded (otherwise the same file can't be uploaded again)
        onClick={() => {
          inputRef.current.value = null;
        }}
        onChange={buttonAction}
        multiple
      />
      <div className="flex items-center justify-around text-sm font-medium">
        <div>{isLoading ? loadingButton : icon}</div>
        <div>{buttonText}</div>
      </div>
    </label>
  );
};

export default UploadButton;
