import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import IconButtonWithTooltip from './IconButtonWithTooltip';

/**
 * Displays an alert message
 * @param {*} props
 * @param {string} props.description - The message to display
 * @param {string} props.actionTooltip - The tooltip to display when hovering over the button
 * @param {function} props.onAction - The function to call when the button is clicked
 * @param {React.Component} props.actionIcon - The icon to display on the button
 * @param {string} props.type - The type of the alert
 */
const EditorAlert = ({ description, actionTooltip, onAction, actionIcon, type }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height="100%"
      background={type === 'warning' ? 'yellow.200' : 'red.300'}
      rounded={5}
      border="1px solid black"
    >
      <Box flex="1" display="flex" justifyContent="center" alignItems="center">
        <Text>{description}</Text>
      </Box>
      {onAction === undefined ? null : (
        <Box>
          <Box mr={2}>
            <IconButtonWithTooltip
              size="md"
              tooltipLabel={actionTooltip}
              icon={actionIcon}
              onClick={onAction}
              iconColor="black"
              colorScheme={type === 'warning' ? 'yellow.200' : 'red.300'}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditorAlert;
