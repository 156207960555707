import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  deleteUserFromOrganization,
  getUsersByOrganization,
  postUserToOrganization,
  putChangeRoleUser,
} from '../api/organization';

const initialState = {
  data: [],
  status: '',
  error: '',
};

export const fetchUsersByOrganization = createAsyncThunk(
  'usersOfOrganization/fetch',
  async (orgId) => {
    const response = await getUsersByOrganization(orgId);
    return response.data;
  },
);

/**
 * Uses an async thunk to handle async request
 * @param {string} type - the type of the async thunk for reducer
 * @param {Function} fn - the api function to be called
 * @returns {AsyncThunk<unknown, any, {}>} - the async thunk
 */
const useAsynThunk = (type, fn) => {
  // TODO: Do this for all async thunks!!! (change definition of api functions and check if args are correctly passed)
  return createAsyncThunk(type, async (args, { rejectWithValue }) => {
    try {
      const response = await fn(args);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw rejectWithValue(err.response.data);
    }
  });
};

export const addUserToOrganization = useAsynThunk('addUser/Organization', postUserToOrganization);

export const removeUserFromOrganization = createAsyncThunk(
  'deleteUser/Organization',
  async ({ orgId, userId }) => {
    const response = await deleteUserFromOrganization({ orgId, userId });
    return response.data;
  },
);

export const updateUserRole = createAsyncThunk(
  'updateUserRole/Organization',
  async ({ orgId, userId, organizationRole }) => {
    const response = await putChangeRoleUser({ orgId, userId, organizationRole });
    return response.data;
  },
);

/**
 * The slice of the organization
 */
export const usersOfOrganizationSlice = createSlice({
  name: 'usersOfOrganization',
  initialState,
  reducers: {
    /**
     * Clears the usersOfOrganization state by setting it to the initial state
     */
    clearUsersOfOrganization() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsersByOrganization.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchUsersByOrganization.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchUsersByOrganization.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectUsersOfOrganizationData = createSelector(
  (state) => state.usersOfOrganization,
  (usersOfOrganization) => usersOfOrganization.data,
);
export const selectUsersOfOrganizationStatus = createSelector(
  (state) => state.usersOfOrganization,
  (usersOfOrganization) => usersOfOrganization.status,
);
export const selectUsersOfOrganizationError = createSelector(
  (state) => state.usersOfOrganization,
  (usersOfOrganization) => usersOfOrganization.error,
);

export const { clearUsersOfOrganization } = usersOfOrganizationSlice.actions;

export default usersOfOrganizationSlice.reducer;
