import React from 'react';
import { useTranslation } from 'react-i18next';
import ReturnIcon from '../../atoms/icons/ReturnIcon';
import IconButton from '../../atoms/tailwind/IconButton';
import SeparatorHorizontal from '../../atoms/tailwind/SeparatorHorizontal';

/**
 * Custom container for structuring the layout inside of the content of the settings view
 */
const SettingsContentLayout = ({ title, content, buttonAction }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full space-y-2">
      {/* Title (presumably the name of the selected setting) */}
      <div className="flex flex-row items-center justify-between">
        <p className="mb-3 font-futura-fat text-3xl text-blue-2">{title}</p>
        <IconButton
          buttonText={t('projectSettings.redirectButton')}
          icon={<ReturnIcon />}
          buttonAction={buttonAction}
        />
      </div>
      <div className="mb-3 mt-5">
        <SeparatorHorizontal />
      </div>

      {/* Content depends on views */}
      <div>{content}</div>
    </div>
  );
};

export default SettingsContentLayout;
