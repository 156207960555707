import React from 'react';
import { Tr, Td } from '@chakra-ui/react';

/*
 * Generates a Table Row with styling as displayed in the Options section.
 *
 * Params:
 *  entries: Array with row's column contents.
 */
const OptionTableRow = ({ entries, name }) => {
  return (
    <Tr>
      {entries.map((entry, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Td pt="2px" pb="2px" pr="1px" pl="1px" key={`${name}-col-${entry}-${index}`}>
            {entry}
          </Td>
        );
      })}
    </Tr>
  );
};

export default OptionTableRow;
