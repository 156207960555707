import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';
import ArrowDownIcon from '../../atoms/icons/ArrowDownIcon';
import ArrowUpIcon from '../../atoms/icons/ArrowUpIcon';

/**
 * Displays the heading of a table column with an arrow icon to indicate sorting.
 * @param {string} text - The text to display in the heading
 * @param {string} arrowType - The type of arrow to display (asc, desc, '')
 * @param {function} onClick - The function to call when the heading is clicked
 * @param {string} id - The id of the heading
 * @param {boolean} disableSorting - Whether to disable sorting on the heading
 *
 * @throws Will throw an error if arrowType is not one of the following: asc, desc, ''
 */
const SortableTableHeading = ({ text, arrowType = '', onClick, id, disableSorting }) => {
  const arrowTypes = ['asc', 'desc', ''];

  if (!arrowTypes.includes(arrowType)) {
    throw new Error(`Invalid arrow type: ${arrowType}`);
  }

  const arrowStyle = arrowType === '' ? 'invisible' : 'visible';

  /**
   * Renders different arrow icons based on arrowType prop
   */
  const renderArrow = () => {
    switch (arrowType) {
      case 'asc':
        return (
          <div className="text-blue-2">
            <ArrowUpIcon aria-hidden="true" />
          </div>
        );
      case 'desc':
        return (
          <div className="text-blue-2">
            <ArrowDownIcon aria-hidden="true" />
          </div>
        );
      default:
        return <ArrowDownIcon aria-hidden="true" />;
    }
  };

  return (
    <span
      className={`group inline-flex ${disableSorting ? '' : 'hover:cursor-pointer'}`}
      id={id}
      onClick={onClick}
    >
      <div className="flex h-10 flex-col justify-center">{text}</div>
      <span
        className={classNames(
          `${arrowStyle} ml-2 flex-none rounded text-grey-2 group-hover:visible group-focus:visible flex items-center justify-center`,
        )}
      >
        {disableSorting ? null : renderArrow()}
      </span>
    </span>
  );
};

export default SortableTableHeading;
