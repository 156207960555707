import { Box, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import TextLabel from './TextLabel';

const TextLabelSelection = ({
  selectedTextLabelName,
  textLabels,
  textLabelHiddenColor,
  removeAllAnnotationsWithTextLabel,
  hasTextLabelExtendedReplacement,
  selectTextLabelIfValid,
}) => {
  return (
    <Box width={300} bg="white" p={2} rounded="5">
      <SimpleGrid columns={3} spacingY={1} spacingX={2}>
        {Object.entries(textLabels).map(([textLabelName, textLabel]) => {
          return (
            textLabel && (
              <TextLabel
                hiddenColor={textLabelHiddenColor}
                textLabelName={textLabelName}
                textLabel={textLabel}
                key={textLabelName}
                hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
                selectTextLabelIfValid={selectTextLabelIfValid}
                removeAllAnnotationsWithTextLabel={() =>
                  removeAllAnnotationsWithTextLabel(textLabelName)
                }
                isSelected={selectedTextLabelName === textLabelName}
              />
            )
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default TextLabelSelection;
