import { Center, HStack, IconButton, Input, Spacer, Spinner, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiX } from 'react-icons/bi';
import BlacklistItemLabelSelector from './BlacklistItemLabelSelector';
import WhitelistItemLabelSelector from './WhitelistItemLabelSelector';

/**
 * Renders a single black-/whitelist item consisting of a input field, a select field and a remove button
 * @param {Object} props The props of the whitelist item
 * @param {string} props.value
 * @param {string} props.label
 * @param {Function} props.onSave
 * @param {Function} props.onRemove
 * @param {string} type The type of the list item (either `blacklist` or `whitelist`)
 */
const ListItem = ({ value, labelName, onLabelUpdate, onValueUpdate, onRemove, labels, type }) => {
  const { t } = useTranslation();
  const [itemValue, setItemValue] = useState(value);
  const [itemLabel, setItemLabel] = useState(labelName);
  const [isRemoving, setIsRemoving] = useState(false);

  /**
   * Handles the change of the whitelist item value
   * @param {Object} event
   */
  const handleItemValueChange = (event) => {
    setItemValue(event.target.value);
  };

  const handleItemValueBlur = () => {
    if (itemValue !== value) {
      onValueUpdate(itemValue, itemLabel);
    }
  };

  /**
   * Handles the change of the whitelist item label
   * @param {Object} event
   */
  const handleItemLabelChange = (event) => {
    if (event.target.value !== itemLabel) {
      setItemLabel(event.target.value);
      onLabelUpdate(itemValue, event.target.value);
    }
  };

  /**
   * Handles the removal of the whiteslist item
   */
  const handleRemove = async () => {
    setIsRemoving(true);
    await onRemove();
    setIsRemoving(false);
  };

  return (
    <HStack mb={2}>
      <Center width="100%">
        <Input
          value={itemValue}
          onChange={handleItemValueChange}
          onBlur={handleItemValueBlur}
          mr={4}
        />
        {(() => {
          switch (type) {
            case 'whitelist':
              return (
                <WhitelistItemLabelSelector
                  label={itemLabel}
                  labels={labels}
                  handleItemLabelChange={handleItemLabelChange}
                />
              );
            case 'blacklist':
              return (
                <BlacklistItemLabelSelector
                  label={itemLabel}
                  labels={labels}
                  handleItemLabelChange={handleItemLabelChange}
                />
              );
            default:
              throw new Error(`Invalid list item type ${type}`);
          }
        })()}
      </Center>
      <Spacer />
      <Tooltip hasArrow label={t('settings.listing.removeItemTooltip')}>
        <IconButton
          icon={isRemoving ? <Spinner /> : <BiX />}
          onClick={handleRemove}
          disabled={isRemoving}
        />
      </Tooltip>
    </HStack>
  );
};

export default ListItem;
