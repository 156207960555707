import React from 'react';
import CloseIcon from '../../atoms/icons/CloseIcon';

/**
 * Overlay component that renders a darkened background with a close button and children
 * @param {JSX} children - Content of the overlay
 * @param {Boolean} show - Whether the overlay is shown
 * @param {Function} onClose - Function to close the overlay
 * @returns {JSX.Element} Overlay component
 */
const Overlay = ({ children, show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black-1 bg-opacity-95 flex justify-center items-center z-50">
      <div className="relative w-full h-full">
        <button type="button" onClick={onClose} className="absolute top-0 right-0 m-4 text-white">
          <CloseIcon />
        </button>
        <div className="flex flex-col justify-center h-full">{children}</div>
      </div>
    </div>
  );
};

export default Overlay;
