import React from 'react';

const AddIconSmall = () => {
  return (
    <svg fill="currentColor" width="12" height="12" viewBox="0 0 12 12 ">
      <path d="M7.57333 7.57333V9.81333C7.57333 10.6 7.54667 10.9067 7.37333 11.24C7.13333 11.6933 6.65333 12 6 12C5.34667 12 4.86667 11.6933 4.62667 11.24C4.45333 10.9067 4.42667 10.6 4.42667 9.81333V7.57333H2.18667C1.4 7.57333 1.09333 7.54667 0.76 7.37333C0.306667 7.13333 0 6.65333 0 6C0 5.34667 0.306667 4.86667 0.76 4.62667C1.09333 4.45333 1.4 4.42667 2.18667 4.42667H4.42667V2.18667C4.42667 1.4 4.45333 1.09333 4.62667 0.76C4.86667 0.306667 5.34667 0 6 0C6.65333 0 7.13333 0.306667 7.37333 0.76C7.54667 1.09333 7.57333 1.4 7.57333 2.18667V4.42667H9.81333C10.6 4.42667 10.9067 4.45333 11.24 4.62667C11.6933 4.86667 12 5.34667 12 6C12 6.65333 11.6933 7.13333 11.24 7.37333C10.9067 7.54667 10.6 7.57333 9.81333 7.57333H7.57333Z" />
    </svg>
  );
};

export default AddIconSmall;
