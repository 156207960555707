import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getWhitelist, putWhitelist } from '../api/project';

const initialState = {
  data: [],
  status: '',
  error: '',
};

/**
 * Fetches the whitelist
 */
export const fetchWhitelist = createAsyncThunk('whitelist/fetch', async ({ projectId }) => {
  const response = await getWhitelist(projectId);
  return response.data;
});

export const updateWhitelist = createAsyncThunk(
  'whitelist/update',
  async ({ projectId, whitelist }) => {
    const response = await putWhitelist(projectId, whitelist);
    return response.data;
  },
);

/**
 * The slice of the whitelist state
 */
export const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    setWhitelist(state, action) {
      return action.payload;
    },
    /**
     * Clears the whitelist state by setting it to the initial state
     */
    clearWhitelist() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWhitelist.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchWhitelist.fulfilled, (state, action) => {
        // Save fetched whitelist in store
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchWhitelist.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectWhitelist = createSelector(
  (state) => state.whitelist,
  (whitelist) => whitelist.data,
);
export const selectWhitelistStatus = createSelector(
  (state) => state.whitelist,
  (whitelist) => whitelist.status,
);
export const selectWhitelistError = createSelector(
  (state) => state.whitelist,
  (whitelist) => whitelist.error,
);

export const { clearWhitelist } = whitelistSlice.actions;

export default whitelistSlice.reducer;
