import { EventSourcePolyfill } from 'event-source-polyfill';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getBaseURL } from '../api/axios';
import { selectToken } from '../reducers/authSlice';

/**
 * Use effect to subscribe to project events sent by the server
 * @param {number} projectId The id of the project to subscribe to
 * @param {Object} onEvents An object that specifies what should happen on which event
 */
const useProjectEvents = (projectId, onEvents) => {
  const token = useSelector(selectToken);

  const eventHandler = useCallback(
    (event) => {
      try {
        const parsedData = JSON.parse(event.data);

        if (typeof parsedData === 'undefined' || parsedData === null) {
          throw new Error('parsedData is undefined or null!');
        }
        if (typeof onEvents === 'undefined' || onEvents === null) {
          throw new Error('The onEvents object is undefined or null!');
        }
        if (typeof onEvents[parsedData.eventType] === 'undefined') {
          console.warn(`No event handler for event type ${parsedData.eventType} found!`);
          return;
        }

        // For debugging purposes
        console.info('Event received: ', parsedData.eventType, ', with data: ', parsedData);
        onEvents[parsedData.eventType](parsedData);
      } catch (error) {
        if (error instanceof SyntaxError) {
          throw new Error('The event data is not valid json!');
        }
      }
    },
    [onEvents],
  );

  /**
   * Subscribes to project events sent by the server
   * @returns The EventSource object
   */
  const subscribe = () => {
    const eventSource = new EventSourcePolyfill(
      // To use Event Source in development you have to `export REACT_APP_API_BASE_URL=http://10.30.100.220:9000/api`
      `${getBaseURL()}/projects/${projectId}/events`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    eventSource.onmessage = eventHandler;

    // TODO: Handle errors
    // eventSource.onerror = () => {
    //   eventSource.close();
    // };

    return eventSource;
  };

  useEffect(() => {
    const events = subscribe();
    return () => {
      // This closes only the EventSource connection in the browser, not the connection on the server!
      events.close();
    };
  }, [
    onEvents, // Does not always work!
  ]);
};

export default useProjectEvents;
