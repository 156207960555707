import React from 'react';
import { useTranslation } from 'react-i18next';
import Searchbar from '../../atoms/tailwind/Searchbar';

const CustomButtonGroup = ({ searchbar = true, children, searchAction }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between space-x-1">
      {/* Buttongroup */}
      <div className="flex w-full justify-between">{children}</div>
      {
        /* Searchbar */
        searchbar ? (
          <Searchbar
            id="search-field"
            placeholder={`${t('app.search')} ...`}
            type="text"
            name="search"
            onChange={(event) => searchAction(event.target.value)}
          />
        ) : null
      }
    </div>
  );
};

export default CustomButtonGroup;
