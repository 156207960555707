import i18next from 'i18next';
import { API } from '../api/axios';
import AppToaster from '../components/molecules/AppToaster';
import { handleUnauthorizedResponse, setAuthenticationHeader } from './authService';

/**
 * Retrieves a cookie form the browser
 * @param {string} name The name of the cookie
 * @returns the value of the cookie
 */
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, '');
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const setXSRFTokenHeader = (config) => {
  const csrftoken = getCookie('csrftoken');
  if (csrftoken) {
    config.headers['X-CSRFToken'] = csrftoken;
  }
};

/**
 * Creates a translation key for the error message
 * This translation key is used to translate the error message
 *
 * @param {object} error The error object
 * @returns the translation key with the form 'errors.resource.method.urlWithoutNumbers.statusCode
 */
const createErrorTranlsationKey = (error) => {
  const { responseURL: url } = error.request;
  const { status } = error.response;
  const { method } = error.config;
  const { problemCode } = error.response.data || {};

  // If a problem code is available, use it to translate the error message
  if (problemCode) {
    return `errors.problemCodes.${problemCode}`;
  }

  const pathElements = url.split('api/')[1].split('/');
  const resource = pathElements[0];

  const urlWithoutNumbers =
    pathElements.length > 1 ? url.split(`${resource}/`)[1].replace(/\d+/g, '#') : resource;

  return `errors.${resource}.${method}.${urlWithoutNumbers}.${status}`;
};

export const setupInterceptors = (store) => {
  API.interceptors.request.use(
    (config) => {
      setAuthenticationHeader(config, store);
      setXSRFTokenHeader(config);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  API.interceptors.response.use(
    (res) => res,
    async (err) => {
      switch (err.response.status) {
        case 400:
          if (err.response.data.problemCode === 'INVALID_PATH_PARAM') {
            window.location.href = '#/unauthorized';
            return Promise.reject(err);
          }
          break;
        case 404:
          // If the logo of the org of the user is not found, no error message is shown
          if (err.response.data.problemCode === 'LOGO_NOT_FOUND') {
            return Promise.reject(err);
          }
          break;
        case 401:
          return handleUnauthorizedResponse(err, store, API);
        case 403:
          window.location.href = '#/unauthorized';
          return Promise.reject(err);
        default:
          break;
      }
      switch (err.response.message) {
        case 'Network Error':
          AppToaster({
            description: i18next.t('app.network_error_toast'),
            status: 'error',
          });
          break;
        default:
          break;
      }

      const translationKey = createErrorTranlsationKey(err);
      const errorMessage = i18next.t(translationKey);

      // If the translation key equals the translation, the translation does not exist
      if (translationKey !== errorMessage) {
        AppToaster({
          description: errorMessage,
          status: 'error',
        });
      } else {
        AppToaster({
          description: i18next.t('errors.default.message'),
          status: 'error',
        });
      }

      return Promise.reject(err);
    },
  );
};

/*
 *
 * Retrieves a csrf token, which is needed for POST requests
 */
export const getCSRFToken = async () => {
  // Deletes "old" cookie
  if (getCookie('csrftoken') !== null) {
    document.cookie = 'csrftoken=; Max-Age=-99999999;';
  }
  // await API.get('/docs', { withCredentials: true });
};
