import { SHA256 } from 'crypto-js';

/**
 * Hashes the given object and returns the hash.
 * @param {Object} obj The object to hash
 * @returns Promise
 */
const hashObject = async (obj) => {
  const jsonString = JSON.stringify(obj);
  return SHA256(jsonString).toString();
};

export default hashObject;
