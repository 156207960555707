import React from 'react';
import { Circle, Group, Line } from 'react-konva';
import getColorCodeByNameWithOpacity from '../../../../utils/workarounds';

const CreateBbox = ({
  bboxBorderColor,
  bboxVertexColor,
  drawnKeyPoints,
  flattenedPoints,
  bboxColor,
  isFinished,
}) => {
  const vertexRadiusT = 3; // The vertex radius of the key point circles

  /**
   * Handles what happens if the mouse enters the shape
   * @param {Object} event
   */
  const handleGroupMouseOver = (event) => {
    if (!isFinished) return;
    // eslint-disable-next-line no-param-reassign
    event.target.getStage().container().style.cursor = 'move';
  };

  /**
   * Handles what happens if the mouse leaves the shape
   * @param {Object} event
   */
  const handleGroupMouseOut = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.getStage().container().style.cursor = 'default';
  };

  return (
    typeof flattenedPoints !== 'undefined' && (
      <Group
        name="polygon"
        draggable={isFinished}
        onMouseOver={handleGroupMouseOver}
        onMouseOut={handleGroupMouseOut}
      >
        <Line
          points={flattenedPoints.concat([flattenedPoints[0], flattenedPoints[1]])}
          stroke={getColorCodeByNameWithOpacity(bboxBorderColor, 1.0)}
          strokeWidth={2}
          closed={isFinished}
          fill={getColorCodeByNameWithOpacity(bboxColor, 0.7)}
        />
        {drawnKeyPoints.map((point, index) => {
          const x = point[0] - vertexRadiusT / 2;
          const y = point[1] - vertexRadiusT / 2;
          const startPointAttr =
            index === 0
              ? {
                  hitStrokeWidth: 12,
                }
              : null;
          return (
            <Circle
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              x={x}
              y={y}
              radius={vertexRadiusT}
              fill={getColorCodeByNameWithOpacity(bboxVertexColor, 1.0)}
              stroke="#696969"
              strokeWidth={2}
              {...startPointAttr}
            />
          );
        })}
      </Group>
    )
  );
};

export default CreateBbox;
