import i18next from 'i18next';

/**
 * Generates the name for the given url segment
 * @param {string} segment - The url segment
 * @returns {string} The name of the url segment
 */
const generateName = (segment) => {
  switch (segment) {
    case 'users': {
      return i18next.t('breadcrumbs.users');
    }
    case 'settings': {
      return i18next.t('breadcrumbs.settings');
    }
    case 'projects': {
      return i18next.t('breadcrumbs.projects');
    }
    case 'documents': {
      return i18next.t('breadcrumbs.documents');
    }
    default: {
      // Default case: Capitalize first letter of string and return it
      return segment.charAt(0).toUpperCase() + segment.slice(1);
    }
  }
};

/**
 * Generates the breadcrumbs for the given current path
 *
 * @param {Object} names Object for the names of the "entity elements" of the breadcrumbs (e.g. "DocCape" for "organizations/1" or "Lennart" for "users/1"):
 * - key: name of the entity element (e.g. "organizations"), value: name of the entity element (e.g. "DocCape")
 * @param {String} path String of the current path (e.g. "organizations/1/projects/1/documents/1")
 * @returns A list of objects with the name and path of the breadcrumb elements
 */
const generateBreadcrumbs = (names, path) => {
  const segments = path.split('/').filter(Boolean);
  const breadcrumbItems = [];

  for (let i = 0; i < segments.length; i += 1) {
    if (!Number.isNaN(parseInt(segments[i], 10))) {
      if (segments[i - 1]) {
        /* TODO: Special case for organizations
         * (only for now - until application supports multiple organizations for one user)
         */
        if (segments[i - 1] === 'organizations') {
          breadcrumbItems.push({
            name: names[segments[i - 1]],
            path: `/${segments.slice(0, i + 1).join('/')}/projects`,
          });
        } else {
          breadcrumbItems.push({
            name: names[segments[i - 1]],
            path: `/${segments.slice(0, i + 1).join('/')}`,
          });
        }
      }
      if (segments[i + 1]) {
        breadcrumbItems.push({
          name: generateName(segments[i + 1]),
          path: `/${segments.slice(0, i + 2).join('/')}`,
        });
      }
    }
  }

  return breadcrumbItems;
};

export default generateBreadcrumbs;
