/**
 * Transforms a `stagePos` (pos wrt to the stage coordinate system) into the image coordinate system
 * @param {number[]} stagePos The given position ([x, y])
 * @param {number[]} translationVector A vector ([x, y]) which translated the image
 * @param {number} scaleFactor A factor which scaled the image
 * @returns The position in the image frame
 */
export const toImageFrame = (stagePos, translationVector, scaleFactor) => {
  return [
    (stagePos[0] - translationVector[0]) / scaleFactor,
    (stagePos[1] - translationVector[1]) / scaleFactor,
  ];
};

/**
 * Transforms a `pos` in a translated and scaled coordinate system (wrt that of the stage)
 * back to the coordinate system of the stage
 * @param {number[]} pos The given position ([x, y])
 * @param {number[]} translationVector A vector ([x, y]) which translates the given `pos`
 * @param {number} scaleFactor A factor for which the given `pos` is scaled
 * @returns The drawn position
 */
export const toStageFrame = (pos, translationVector, scaleFactor) => {
  return [translationVector[0] + pos[0] * scaleFactor, translationVector[1] + pos[1] * scaleFactor];
};

/**
 * Computes the translation vector and the scale factor for centering the image
 * @param {number} inputStageWidth The width of the stage
 * @param {number} inputStageHeight The height of the stage
 * @param {number} inputImageWidth The width of the image
 * @param {number} inputImageHeighgt The height of the image
 * @returns A tuple containing the translation vector and the scale factor
 */
export const computeCenteringScaleFactor = (
  inputStageWidth,
  inputStageHeight,
  inputImageWidth,
  inputImageHeighgt,
) => {
  const ratioWidth = parseFloat(inputStageWidth) / parseFloat(inputImageWidth);
  const ratioHeight = parseFloat(inputStageHeight) / parseFloat(inputImageHeighgt);
  if (ratioWidth < 1 || ratioHeight < 1) {
    return Math.min(ratioWidth, ratioHeight);
  }
  return 1;
};

/**
 * Computes the translation vector for centering the image
 * @param {number} inputStageWidth The width of the stage
 * @param {number} inputStageHeight The height of the stage
 * @param {number} inputImageWidth The width of the image
 * @param {number} inputImageHeighgt The height of the image
 * @param {number} imageScaleFactor The scale factor for the image
 * @returns A tuple containing the translation vector
 */
export const computeCenteringTranslationVector = (
  inputStageWidth,
  inputStageHeight,
  inputImageWidth,
  inputImageHeighgt,
  imageScaleFactor,
) => {
  const diffWidth = inputStageWidth - imageScaleFactor * inputImageWidth;
  const diffHeight = inputStageHeight - imageScaleFactor * inputImageHeighgt;
  if (diffWidth < 0 || diffHeight < 0) {
    return [1.0, 1.0];
  }
  return [diffWidth / 2.0, diffHeight / 2.0];
};
