import { Disclosure } from '@headlessui/react';
import React from 'react';

const UserMenuMobile = ({ user, userNavigation }) => {
  if (!userNavigation) {
    console.error('Property userNavigation is required for component UserMenuMobile!');
    return null;
  }
  // TODO: Add styling after mobile design is completed
  return (
    <>
      {/* Profile image and text mobile */}
      <div className="border-t pb-3 pt-4">
        <div className="flex items-center px-5">
          <div className="shrink-0">
            {/* 
            TODO: Images are not yet supported and will be added later
            <img className="h-10 w-10 rounded-full" src={user.image} alt="" />
          */}
          </div>
          <div className="ml-3">
            <div className="text-base font-medium text-white">{`${user.firstName} ${user.lastName}`}</div>
            {/*
              TODO: Currently only the name should be displayed
              <div className="text-sm font-medium">{user.email}</div>
            */}
          </div>
        </div>

        {/* Profile dropdown mobile */}
        <div className="mt-3 space-y-1 px-2">
          {userNavigation.map((item) => (
            <Disclosure.Button
              key={item.name}
              as="a"
              onClick={item.onClick}
              className="block rounded-md px-3 py-2 text-base font-medium text-white"
            >
              {item.name}
            </Disclosure.Button>
          ))}
        </div>
      </div>
    </>
  );
};

export default UserMenuMobile;
