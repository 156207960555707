import React, { useEffect, useMemo, useState } from 'react';
import Box from '../../atoms/Box';
import TokenAnnotator from './TokenAnnotator';

const AnnotationForm = ({
  textLabels,
  selectedTextLabelName,
  annotations,
  paragraphs,
  createRenderableTextSegments,
  annotateAllUnmarked,
  findAndRemoveAnnotation,
  changeTextLabelNameOfAllAnnotationsWithSameText,
  setCrIdAndPseudonymOfAnnotation,
  removeAllAnnotationsWithSameTextLabelAndText,
  removeAllAnnotationsWithSameText,
  addAnnotation,
  selectedParagraphIndex,
  toggleSelectedAnnotation,
  isAnnotationSelected,
  changeTextLabelNameOfAnnotation,
  changeCrIdOfAnnotation,
  changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
  isDisplayingPseudonyms,
}) => {
  const initIsPopoverAddAnnotationsOpen = useMemo(() => {
    const ret = {};
    if (annotations.length > 0) {
      annotations[selectedParagraphIndex].forEach((annotation) => {
        ret[annotation.id] = false;
      });
    }
    return ret;
  }, [annotations, selectedParagraphIndex]);

  const [isPopoverAddAnnotationsOpen, setIsPopoverAddAnnotationsOpen] = useState(
    initIsPopoverAddAnnotationsOpen,
  );

  const openPopover = (annotationId) => {
    setIsPopoverAddAnnotationsOpen((prevState) => ({
      ...prevState,
      [annotationId]: true,
    }));
  };

  const closePopover = (annotationId) => {
    setIsPopoverAddAnnotationsOpen((prevState) => ({
      ...prevState,
      [annotationId]: false,
    }));
  };

  useEffect(() => {
    const update = {};
    if (annotations.length > 0) {
      annotations[selectedParagraphIndex].forEach((annotation) => {
        if (annotation.id in isPopoverAddAnnotationsOpen) {
          update[annotation.id] = isPopoverAddAnnotationsOpen[annotation.id];
        } else {
          update[annotation.id] = false;
        }
      });
    }
    setIsPopoverAddAnnotationsOpen(update);
  }, [annotations, selectedParagraphIndex]);

  const onAnnotationAdd = (start, end) => {
    if (selectedTextLabelName !== null) {
      return addAnnotation(
        selectedParagraphIndex,
        start,
        end,
        selectedTextLabelName,
        textLabels[selectedTextLabelName],
      );
    }
    throw new Error('Cannot create annotation, no text label selected');
  };

  const textSegments = useMemo(
    () => createRenderableTextSegments(selectedParagraphIndex, textLabels),
    [selectedParagraphIndex, textLabels, annotations],
  );

  return (
    <Box height="100%">
      <TokenAnnotator
        textLabels={textLabels}
        textSegments={textSegments}
        isPopoverOpen={isPopoverAddAnnotationsOpen}
        setIsPopoverOpen={setIsPopoverAddAnnotationsOpen}
        closePopover={closePopover}
        onAnnotationAdd={onAnnotationAdd}
        findAndRemoveAnnotation={findAndRemoveAnnotation}
        annotateAllUnmarked={annotateAllUnmarked}
        toggleSelectedAnnotation={toggleSelectedAnnotation}
        changeTextLabelNameOfAllAnnotationsWithSameText={
          changeTextLabelNameOfAllAnnotationsWithSameText
        }
        openPopover={openPopover}
        setCrIdAndPseudonymOfAnnotation={setCrIdAndPseudonymOfAnnotation}
        paragraph={paragraphs[selectedParagraphIndex]}
        removeAllAnnotationsWithSameTextLabelAndText={removeAllAnnotationsWithSameTextLabelAndText}
        removeAllAnnotationsWithSameText={removeAllAnnotationsWithSameText}
        selectedParagraphIndex={selectedParagraphIndex}
        isAnnotationSelected={isAnnotationSelected}
        changeTextLabelNameOfAnnotation={changeTextLabelNameOfAnnotation}
        changeCrIdOfAnnotation={changeCrIdOfAnnotation}
        changeCrIdOfAllAnnotationsWithSameTextLabelAndText={
          changeCrIdOfAllAnnotationsWithSameTextLabelAndText
        }
        isDisplayingPseudonyms={isDisplayingPseudonyms}
      />
    </Box>
  );
};

export default AnnotationForm;
