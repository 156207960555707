import { Alert, AlertIcon } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changePassword } from '../../../../api/authentication';
import Button from '../../../atoms/tailwind/Button';
import Input from '../../../atoms/tailwind/Input';
import AppToaster from '../../../molecules/AppToaster';

/**
 * Component which allows the user to change the current password
 * within the user settings
 */
const ChangePassword = () => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const minimumPasswordLength = 8;

  const updatePassword = () => {
    if (newPassword === confirmNewPassword) {
      if (newPassword.length < minimumPasswordLength) {
        setErrorMessage(t('userSettings.security.passwordLengthError'));
      } else {
        setErrorMessage('');
        changePassword(oldPassword, newPassword).then(() => {
          AppToaster({
            description: t('userSettings.security.successPasswordChange'),
            status: 'success',
            position: 'bottom',
            duration: 1000,
          });
          setNewPassword('');
          setOldPassword('');
          setConfirmNewPassword('');
        });
      }
    } else {
      setErrorMessage(t('userSettings.security.confirmPasswordMissmatch'));
    }
  };

  return (
    <>
      <div className="mb-5 grid grid-cols-3">
        <div className="pr-5 text-right align-top text-xl text-blue-2">
          {t('userSettings.security.oldPassword')}
        </div>
        <div className="col-span-1">
          <Input
            type="password"
            value={oldPassword}
            onChange={(event) => {
              setOldPassword(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="mb-5 grid grid-cols-3">
        <div className="pr-5 text-right align-top text-xl text-blue-2">
          {t('userSettings.security.newPassword')}
        </div>
        <div className="col-span-1">
          <Input
            type="password"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div className="pr-5 text-right align-top text-xl text-blue-2">
          {t('userSettings.security.confirmNewPassword')}
        </div>
        <div className="col-span-1">
          <Input
            type="password"
            value={confirmNewPassword}
            onChange={(event) => {
              setConfirmNewPassword(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div />
        {errorMessage ? (
          // TODO: Implement tailwind alert after design is finalized
          <div className="mt-5">
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          </div>
        ) : null}
      </div>
      <div className="grid grid-cols-3">
        <div />
        <div className="col-span-2 mt-5">
          <Button
            buttonText={t('userSettings.security.changePassword')}
            buttonAction={updatePassword}
          />
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
