import React from 'react';

const AddIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m20.66,18.66v2.36c0,.83-.03,1.15-.21,1.5-.25.48-.76.8-1.45.8s-1.19-.32-1.45-.8c-.18-.35-.21-.67-.21-1.5v-2.36h-2.36c-.83,0-1.15-.03-1.5-.21-.48-.25-.8-.76-.8-1.45s.32-1.19.8-1.45c.35-.18.67-.21,1.5-.21h2.36v-2.36c0-.83.03-1.15.21-1.5.25-.48.76-.8,1.45-.8s1.19.32,1.45.8c.18.35.21.67.21,1.5v2.36h2.36c.83,0,1.15.03,1.5.21.48.25.8.76.8,1.45s-.32,1.19-.8,1.45c-.35.18-.67.21-1.5.21h-2.36Z" />
    </svg>
  );
};

export default AddIcon;
