import React from 'react';
import { classNames } from '../../../../../../utils/tailwindUtils';

const TextElementSelectionArea = ({
  selectedTextElement,
  setSelectedTextElement,
  textElements,
}) => {
  const handleSelection = (element) => {
    if (element === selectedTextElement) {
      setSelectedTextElement(null);
    } else {
      setSelectedTextElement(element);
    }
  };

  return (
    <div className="col-span-1 flex h-fit w-full flex-col rounded-xl border border-babyBlue-1 bg-white p-2 shadow">
      <ul className="h-80 overflow-auto">
        {textElements.map((element) => {
          return (
            <li
              key={element}
              className={classNames(
                element === selectedTextElement
                  ? 'text-blue-2 select-none bg-babyBlue-3 hover:cursor-pointer'
                  : 'text-blue-2 select-none bg-white hover:bg-babyBlue-3 hover:cursor-pointer',
              )}
              onClick={() => handleSelection(element)}
            >
              {element}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TextElementSelectionArea;
