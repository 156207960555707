import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';

const SettingsSidebar = ({ navigation, handleItemClick }) => {
  const currentItemColor = 'bg-babyBlue-3';
  return (
    <nav className="flex flex-1 flex-col">
      <ul className="flex flex-1 flex-col items-center gap-y-7 ">
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41 25.6988V15.3012H37.4482C37.1136 14.2229 36.6503 13.196 36.1356 12.2204L38.6836 9.67877L31.3227 2.33626L28.8004 4.85222C27.8223 4.33876 26.7928 3.87664 25.7119 3.54289V0H15.2881V3.54289C14.2072 3.87664 13.1777 4.33876 12.1996 4.85222L9.67734 2.33626L2.31638 9.67877L4.86441 12.2204C4.34965 13.196 3.88638 14.2229 3.55179 15.3012H0V25.6988H3.55179C3.88638 26.7771 4.34965 27.804 4.86441 28.7796L2.31638 31.3212L9.67734 38.6637L12.1996 36.1478C13.1777 36.6612 14.2072 37.1234 15.2881 37.4571V41H25.7119V37.4571C26.7928 37.1234 27.8223 36.6612 28.8004 36.1478L31.3227 38.6637L38.6836 31.3212L36.1356 28.7796C36.6503 27.804 37.1136 26.7771 37.4482 25.6988H41ZM10.295 20.4872C10.295 14.8904 14.8506 10.3463 20.4614 10.3463C26.0722 10.3463 30.6277 14.8904 30.6277 20.4872C30.6277 26.0839 26.0722 30.6281 20.4614 30.6281C14.8506 30.6281 10.295 26.0839 10.295 20.4872Z"
            fill="#00289D"
          />
        </svg>
        <li>
          {/* TODO: Arrangement of sidebar items horizontally in tablet and smartphone view? */}
          <ul className="-mx-2 space-y-2 lg:space-y-7">
            {navigation.map((navigationItem) => (
              <li key={navigationItem.name} className="cursor-pointer">
                {!navigationItem.children ? (
                  <a
                    onClick={(event) => handleItemClick(event, navigationItem.item)}
                    className={classNames(
                      `${
                        navigationItem.current ? currentItemColor : ''
                      } block rounded-sm py-1 pl-10 pr-6 text-base leading-6 text-blue-2 hover:bg-grey-4`,
                    )}
                  >
                    {navigationItem.name}
                  </a>
                ) : (
                  <Disclosure as="div" defaultOpen>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            `${
                              navigationItem.current ? currentItemColor : ''
                            } flex items-center w-full text-left text-base rounded-sm p-1 gap-x-4 leading-6 text-blue-2 hover:bg-grey-4`,
                          )}
                        >
                          <ChevronRightIcon
                            className={classNames(
                              open ? 'rotate-90 text-blue-2' : 'text-blue-2',
                              'h-5 w-5 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                          {navigationItem.name}
                        </Disclosure.Button>
                        {open && (
                          <Disclosure.Panel as="ul" className="mt-5 space-y-5 px-7 md:space-y-7">
                            {navigationItem.children.map((subItem) => (
                              <li key={subItem.name}>
                                <Disclosure.Button
                                  as="a"
                                  onClick={(event) => {
                                    handleItemClick(event, subItem.item);
                                  }}
                                  className={classNames(
                                    `${
                                      subItem.current ? currentItemColor : ''
                                    } block rounded-sm py-1 pr-2 pl-8 text-base leading-6 text-blue-2 hover:bg-grey-4`,
                                  )}
                                >
                                  {subItem.name}
                                </Disclosure.Button>
                              </li>
                            ))}
                          </Disclosure.Panel>
                        )}
                      </>
                    )}
                  </Disclosure>
                )}
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default SettingsSidebar;
