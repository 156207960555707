import React from 'react';

/**
 * Container for the navbar
 */
const NavbarContainer = ({ children }) => {
  return <div className="sticky top-0 z-50 w-full bg-blue-2">{children}</div>;
};

export default NavbarContainer;
