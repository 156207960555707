import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TextLabelDatePartialReplacement = ({
  textLabel,
  toggleReplaceDay,
  toggleReplaceMonth,
  toggleReplaceYear,
  toggleShowWeekday,
  changeDateLocale,
  allDateLocales,
}) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <FormControl as={SimpleGrid} columns={{ base: 1, lg: 6 }}>
        <FormLabel htmlFor="day" fontWeight="normal">
          <Tooltip label={t('settings.textLabels.replaceDayDescription')}>
            <Text
              sx={{
                '&:hover': {
                  cursor: 'help',
                },
              }}
            >
              {t('settings.textLabels.replaceDay')}
            </Text>
          </Tooltip>
        </FormLabel>
        <Switch id="day" isChecked={textLabel.replaceDay} onChange={toggleReplaceDay} />

        <FormLabel htmlFor="month" fontWeight="normal">
          <Tooltip label={t('settings.textLabels.replaceMonthDescription')}>
            <Text
              sx={{
                '&:hover': {
                  cursor: 'help',
                },
              }}
            >
              {t('settings.textLabels.replaceMonth')}
            </Text>
          </Tooltip>
        </FormLabel>
        <Switch id="month" isChecked={textLabel.replaceMonth} onChange={toggleReplaceMonth} />

        <FormLabel htmlFor="year" fontWeight="normal">
          <Tooltip label={t('settings.textLabels.replaceYearDescription')}>
            <Text
              sx={{
                '&:hover': {
                  cursor: 'help',
                },
              }}
            >
              {t('settings.textLabels.replaceYear')}
            </Text>
          </Tooltip>
        </FormLabel>
        <Switch id="year" isChecked={textLabel.replaceYear} onChange={toggleReplaceYear} />
      </FormControl>
      <HStack>
        <Tooltip label={t('settings.textLabels.dateLocaleTooltip')}>
          <Text>{t('settings.textLabels.dateLocale')}:</Text>
        </Tooltip>
        <Select
          size="sm"
          width="70px"
          value={textLabel.dateLocale}
          onChange={(event) => {
            changeDateLocale(event.target.value);
          }}
        >
          {allDateLocales.map((locale) => (
            <option key={`Option-${locale}`} value={locale}>
              {locale}
            </option>
          ))}
        </Select>
      </HStack>
      <Checkbox isChecked={textLabel.showWeekday} onChange={toggleShowWeekday}>
        <Text>{t('settings.textLabels.showWeekdayTitle')}</Text>
      </Checkbox>
    </Stack>
  );
};

export default TextLabelDatePartialReplacement;
