import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';
import PasswordInput from '../../atoms/tailwind/PasswordInput';
import Select from '../../atoms/tailwind/Select';

/**
 * Custom form component which can be used in combination with the CustomCard component
 * @param {Array} inputFields - Array of objects with the following properties:
 * - id: id of the input field
 * - name: name of the input field
 * - type: type of the input field
 * - value: value of the input field
 * - onChange: onChange function of the input field
 * - onBlur: onBlur function of the input field
 * - valid?: validate function of the input field
 * - errorMsg?: error message of the input field
 * - options?: options of the input field (only for type select)
 * - required: whether the input field is required
 * @param {JSX} additionalContent - Additional content to be displayed below the input fields
 */
const CustomForm = ({ inputFields, additionalContent }) => {
  if (!inputFields) {
    console.error('Property inputFields is required for component CustomForm!');
    return null;
  }

  /**
   * Renders the input field depending on the type
   * @param {Object} inputField - Object with the following properties:
   * - id: id of the input field
   * - name: name of the input field
   * - type: type of the input field
   * - value: value of the input field
   * - onChange: onChange function of the input field
   * - valid?: validate function of the input field
   * - errorMsg?: error message of the input field
   * - options?: options of the input field (only for type select)
   * - required: whether the input field is required
   * @returns {JSX} - JSX element of the input field
   */
  const renderInputField = (inputField) => {
    if (inputField.type === 'textarea') {
      return (
        <textarea
          id={inputField.id}
          name={inputField.id}
          required={inputField.required}
          className="block w-full resize-none rounded-xl border-0 p-1.5 text-blue-2 shadow-sm ring-1 ring-inset ring-babyBlue-1 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:text-sm sm:leading-6"
          value={inputField.value}
          onChange={inputField.onChange}
          rows={3}
          onBlur={inputField.onBlur}
        />
      );
    }
    if (inputField.type === 'select') {
      return (
        <Select
          id={inputField.id}
          name={inputField.name}
          required={inputField.required}
          onChange={inputField.onChange}
          onBlur={inputField.onBlur}
        >
          {inputField.options}
        </Select>
      );
    }
    if (inputField.type === 'password') {
      return <PasswordInput valid={inputField.valid} onChange={inputField.onChange} />;
    }
    return (
      <>
        <input
          id={inputField.id}
          name={inputField.id}
          type={inputField.type}
          required={inputField.required}
          className={classNames(
            // if valid is undefined style the input field as valid (= normal)
            (inputField.valid !== undefined && inputField.valid) || inputField.valid === undefined
              ? 'block w-full rounded-xl border-0 py-1.5 text-blue-2 text-base shadow-sm ring-1 ring-inset ring-babyBlue-1 placeholder:text-gray-3 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:leading-6'
              : 'border-red-3 block w-full rounded-xl border-2 px-3.5 py-2 text-blue-2 text-base focus:border-red-3 focus:ring-red-3 focus:ring-1 sm:leading-6',
          )}
          value={inputField.value}
          onChange={inputField.onChange}
          onBlur={inputField.onBlur}
        />
        {(inputField.valid !== undefined && inputField.valid) ||
        inputField.valid === undefined ? null : (
          <div className="mt-1 text-red-3 lg:text-xs">{inputField.errorMsg}</div>
        )}
      </>
    );
  };

  return (
    <div className="space-y-4">
      {inputFields.map((inputField) => (
        <div key={inputField.name}>
          <label
            htmlFor={inputField.id}
            className="block text-base font-medium leading-6 text-blue-2"
          >
            {inputField.name}
          </label>
          <div className="mt-2">{renderInputField(inputField)}</div>
        </div>
      ))}
      {additionalContent}
    </div>
  );
};

export default CustomForm;
