import { useEffect, useState } from 'react';

const useLayout = () => {
  const [navBarHeight, setNavBarHeight] = useState('50px'); // TODO: get from theme
  const [contentHeight, setContentHeight] = useState(`calc(100vh - ${navBarHeight})`);
  const [mainMenuHeight, setMainMenuHeight] = useState(30);
  const [scrollBarWidth, setScrollBarWidth] = useState('9px');
  const [navigatorWidth, setNavigatorWidth] = useState('60px');
  const [imageAnnotationControlWidth, setImageAnnotationControlWidth] = useState('70%');

  useEffect(() => {}, []);

  return {
    contentHeight,
    mainMenuHeight,
    scrollBarWidth,
    navigatorWidth,
    imageAnnotationControlWidth,
  };
};

export default useLayout;
