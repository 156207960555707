import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Navigate, useLocation } from 'react-router-dom';
import useUserData from '../../hooks/useUserData';
import { refreshToken, selectIsAuthenticated, selectToken } from '../../reducers/authSlice';
import LoadingPage from './LoadingPage';

/**
 * Custom route component, which renders the children according to the success of authentication
 * It displays a loading page while the authentication is being checked
 * Use this component inside of the actual route
 *
 * @param {string} redirection The path the user should be redirected to, if authentication fails or succeeds (depending on authenticated)
 * @returns {React.ReactNode} Children or navigate component
 * @example
 * <Route exact path="/"
 *   element={
 *   <ProtectedRoute redirection="/login">
 *     <HomePage />
 *   </ProtectedRoute>
 *   }
 *   />
 */
const ProtectedRoute = React.memo(({ children, redirection }) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);
  const [isCheckingAuth, setIsCheckingAuth] = React.useState(false);
  const { getUser, clearUser } = useUserData();

  // The token should only be refreshed on the first render, if this check is not present,
  // accessing the login page will result in a endless refresh loop
  useEffect(() => {
    /**
     * Checks if the user is (still) authenticated
     */
    async function checkAuth() {
      try {
        setIsCheckingAuth(true);
        if (token === '') {
          await dispatch(refreshToken()).unwrap();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.info("Couldn't refresh token");
      } finally {
        setIsCheckingAuth(false);
      }
    }

    checkAuth();
  }, []);

  /**
   * Retrieves the user data from the backend and saves it in the store
   * if the user is authenticated
   */
  useEffect(() => {
    if (auth) {
      getUser();
    }

    return () => {
      clearUser();
    };
  }, [auth]);

  // If the check is still running, show a loading page
  if (isCheckingAuth) {
    return <LoadingPage />;
  }

  // Save location in localStorage
  localStorage.setItem('location', useLocation().pathname);

  // If authenticated, return an outlet that will render child elements
  // If not, return element that will navigate to the given redirection
  return auth ? children : <Navigate to={redirection} />;
});

export default ProtectedRoute;
