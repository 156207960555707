import React, { useEffect, useMemo } from 'react';
import { Image, Layer, Stage } from 'react-konva';
import useImage from 'use-image';
import ActiveBbox from './bbox/ActiveBbox';
import CreateBbox from './bbox/CreateBbox';
import EditBbox from './bbox/EditBbox';
import InactiveBbox from './bbox/InactiveBbox';
import areObjectsEqual from './utils';

const KonvaAnnotator = ({
  imageBytes,
  renderableBboxs,
  activeBbox,
  setActiveBbox,
  isEditingBbox,
  isCreatingBbox,
  setIsCreatingBbox,
  setActiveLabel,
  stageHeight,
  stageWidth,
  isMovingImageButtonMode,
  toStageFrame,
  translationVector,
  scaleFactor,
  initAnnotatorStage,
  activeLabel,
  updateBbox,
  addBbox,
  drawnKeyPoints,
  transformedKeyPoints,
  flattenedPoints,
  handlePointDragMove,
  handleMouseMove,
  pointDragBoundFunc,
  setPressedMouseButton,
  handleZoomIntoMousePointer,
  computeDraggedKeyPoints,
  addKeyPoint,
  isPolyComplete,
}) => {
  const [image, status] = useImage(`data:image/*;base64,${imageBytes}`);

  useEffect(() => {
    if (status === 'loaded') {
      initAnnotatorStage(image.width, image.height);
    }
  }, [image, status]);

  /**
   * The image scaled and translated
   */
  const ImageComponent = useMemo(() => {
    // eslint-disable-next-line react/no-unstable-nested-components
    if (status === 'loaded') {
      return (
        <Image
          x={translationVector[0]}
          y={translationVector[1]}
          scaleX={scaleFactor}
          scaleY={scaleFactor}
          image={image}
        />
      );
    }
    return null;
  }, [image, status, scaleFactor, translationVector]);

  /**
   * Handles the adding of a new key point
   * @param {Object} event
   */
  const handleAddKeyPoint = (event) => {
    // If the polygon is completed don't add another key point
    if (isPolyComplete) return;

    const newTransformedPoints = addKeyPoint(event);

    // If the new key point which should be added to the drawn key points state is the fourth finish the bbox
    if (newTransformedPoints.length === 4) {
      // Sets the bbox to the new transformed key points
      const bbox = newTransformedPoints;
      addBbox(bbox, activeLabel);

      setActiveLabel(null);
      setIsCreatingBbox(false);
    }
  };

  /**
   * Handles the click on the mouse button
   * @param {Object} event
   */
  const handleMouseDown = (event) => {
    setPressedMouseButton(event.evt.button);
    if (isCreatingBbox) {
      handleAddKeyPoint(event);
    }
  };

  /**
   * Handles the release of the mouse button
   */
  const handleMouseUp = () => {
    setPressedMouseButton(null);
    // If the bbox is edited, save the bbox after the mouse key is up
    if (isEditingBbox && activeBbox !== null) {
      updateBbox(transformedKeyPoints, activeBbox.id.labelIdx, activeBbox.id.bboxIdx);
    }
  };

  const handleMouseLeave = () => {
    setPressedMouseButton(null);
  };

  /**
   * Handles the mouse wheel
   * @param {Object} event
   */
  const handleWheel = (event) => {
    if (activeBbox === null && !isCreatingBbox && !isEditingBbox) {
      handleZoomIntoMousePointer(event);
    }
  };

  /**
   * Handles the dragging of the shape
   * @param {Object} event
   */
  const handleGroupDragEnd = (event) => {
    if (event.target.name() === 'polygon' && activeBbox !== null) {
      const draggedKeyPoints = computeDraggedKeyPoints(event);
      updateBbox(draggedKeyPoints, activeBbox.id.labelIdx, activeBbox.id.bboxIdx);
    }
  };

  /**
   * Renders the edit bounding box
   */
  const renderEditBbox = (bboxLabelId, bbox) => {
    return (
      <EditBbox
        key={`EditBB-${bboxLabelId}`}
        bboxColor={bbox.color}
        bboxBorderColor={bbox.color}
        handleGroupDragEnd={handleGroupDragEnd}
        drawnKeyPoints={drawnKeyPoints}
        flattenedPoints={flattenedPoints}
        bboxVertexColor={bbox.color}
        handlePointDragMove={handlePointDragMove}
        pointDragBoundFunc={pointDragBoundFunc}
      />
    );
  };

  /**
   * Renders the active bounding box
   */
  const renderActiveBbox = (bboxLabelId, bbox) => {
    return (
      <ActiveBbox
        key={`ActiveBB-${bboxLabelId}`}
        bboxCoordinates={bbox.bbox}
        bboxLabelId={bboxLabelId}
        bboxColor={bbox.color}
        bboxBorderColor={bbox.color}
        translationVector={translationVector}
        scaleFactor={scaleFactor}
        toStageFrame={toStageFrame}
        isMovingImageButtonMode={isMovingImageButtonMode}
        setBboxInactive={() => setActiveBbox(null)}
      />
    );
  };

  /**
   * Renders the inactive bounding box
   */
  const renderInactiveBbox = (bboxId, bbox) => {
    return (
      <InactiveBbox
        key={`InactiveBB-${bboxId.labelIdx}-${bboxId.bboxIdx}}`}
        translationVector={translationVector}
        scaleFactor={scaleFactor}
        bboxCoordinates={bbox.bbox}
        bboxColor={bbox.color}
        bboxBorderColor={bbox.color}
        isMovingImageButtonMode={isMovingImageButtonMode}
        isEditingBbox={isEditingBbox}
        isCreatingBbox={isCreatingBbox}
        setBboxActive={() => setActiveBbox(bbox)}
        toStageFrame={toStageFrame}
      />
    );
  };

  const renderCreateBbox = () => {
    return (
      isCreatingBbox && (
        <CreateBbox
          key="CreateBB"
          bboxBorderColor={activeLabel.color}
          bboxVertexColor={activeLabel.color}
          drawnKeyPoints={drawnKeyPoints}
          flattenedPoints={flattenedPoints}
          bboxColor={activeLabel.color}
          isFinished={isPolyComplete}
        />
      )
    );
  };

  /**
   * Renders the bounding box (inactive, active or edit)
   */
  const renderBbox = (bboxId, bbox) => {
    // If no bbox is active render the inactive bounding box
    if (activeBbox === null) {
      return renderInactiveBbox(bboxId, bbox);
    }
    if (areObjectsEqual(bboxId, activeBbox.id) && isEditingBbox) {
      return renderEditBbox(bboxId, bbox);
    }
    if (areObjectsEqual(bboxId, activeBbox.id) && !isEditingBbox) {
      return renderActiveBbox(bboxId, bbox);
    }
    if (!areObjectsEqual(bboxId, activeBbox.id)) {
      return renderInactiveBbox(bboxId, bbox);
    }
    return null;
  };

  return (
    <Stage width={stageWidth} height={stageHeight}>
      <Layer
        onWheel={handleWheel}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {ImageComponent}
        {renderableBboxs.map((renderableBbox) => {
          return renderBbox(renderableBbox.id, renderableBbox);
        })}
        {renderCreateBbox()}
      </Layer>
    </Stage>
  );
};

export default KonvaAnnotator;
