import React from 'react';

const ExportIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="M24.1103 17.2602C23.7303 17.2602 23.4203 17.5802 23.4203 17.9502V22.3902H12.8103V13.1602H19.1903C20.1603 13.1602 20.1603 11.6602 19.1903 11.6602H12.0803C11.7003 11.6602 11.4803 11.8902 11.4003 12.1702C11.3503 12.2702 11.3203 12.3802 11.3203 12.5102V22.9802C11.3203 23.2302 11.4203 23.4102 11.5703 23.5302C11.6803 23.6702 11.8503 23.7702 12.0803 23.7702H24.0903C24.4503 23.7702 24.6503 23.5502 24.7203 23.2802C24.7703 23.1902 24.8003 23.0902 24.8003 22.9802V17.9502C24.8003 17.5802 24.4803 17.2602 24.1103 17.2602Z" />
      <path d="M27.8904 9.27988C27.8904 8.92988 27.5904 8.62988 27.2404 8.62988C25.8304 8.62988 24.4304 8.62988 23.0204 8.62988C22.8204 8.62988 22.6204 8.62988 22.4204 8.62988C21.5804 8.62988 21.5804 9.92988 22.4204 9.92988C23.4504 9.92988 24.4904 9.92988 25.5204 9.92988C23.8504 11.5999 22.1804 13.2699 20.5004 14.9499C20.2604 15.1899 20.0204 15.4299 19.7804 15.6699C19.1004 16.3499 20.1604 17.4099 20.8404 16.7299C22.5304 15.0399 24.2204 13.3499 25.9104 11.6599C26.1304 11.4399 26.3504 11.2199 26.5704 10.9999V14.0999C26.5704 14.9399 27.8704 14.9399 27.8704 14.0999V9.27988H27.8904Z" />
    </svg>
  );
};

export default ExportIcon;
