import React from 'react';

const SummaryIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m27.55,7.39c-1.69,1.69-3.39,3.38-5.08,5.08-.22.22-.44.44-.66.66v-3.1c0-.84-1.3-.84-1.3,0,0,1.41,0,2.81,0,4.22,0,.2,0,.4,0,.6,0,.35.3.65.65.65,1.41,0,2.81,0,4.22,0,.2,0,.4,0,.6,0,.84,0,.84-1.3,0-1.3h-3.1c1.67-1.67,3.35-3.35,5.02-5.02.24-.24.48-.48.72-.72.68-.68-.38-1.74-1.06-1.06Z" />
      <path d="m16.84,18.51c-1.41,0-2.81,0-4.22,0-.2,0-.4,0-.6,0-.84,0-.84,1.3,0,1.3h3.1c-1.67,1.67-3.35,3.35-5.02,5.02-.24.24-.48.48-.72.72-.68.68.38,1.74,1.06,1.06,1.69-1.69,3.39-3.38,5.08-5.08.22-.22.44-.44.66-.66v3.1c0,.84,1.3.84,1.3,0v-4.82c0-.35-.3-.65-.65-.65Z" />
    </svg>
  );
};

export default SummaryIcon;
