import { ScrollMode, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import React from 'react';
// import './PdfPreview.css';

const pdfWorker = './pdfWorker.js';

/**
 * Displays a single page of the pdf with the viewer component
 */
const PdfPreview = ({
  base64Pdf,
  parentSize,
  zoomInstance,
  scrollModeInstance,
  pageNavigationInstance,
  selectedParagraphIndex,
}) => {
  const marginForShadow = 10;

  return (
    <div
      style={{
        height: parentSize.height,
        width: parentSize.width - 2 * marginForShadow,
        key: `page_${selectedParagraphIndex + 1}`,
      }}
    >
      <Worker workerUrl={pdfWorker} />
      {parentSize !== undefined ? (
        <Viewer
          initialPage={selectedParagraphIndex}
          scrollMode={ScrollMode.Page}
          fileUrl={`data:application/pdf;base64,${base64Pdf}`} // TODO: path to URL for pdf
          defaultScale={SpecialZoomLevel.PageFit}
          plugins={[zoomInstance, scrollModeInstance, pageNavigationInstance]}
        />
      ) : null}
    </div>
  );
};

export default PdfPreview;
