import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/tailwind/Button';

const NavigationNavbar = ({ navigation }) => {
  const { t } = useTranslation();

  if (!navigation) {
    console.error('Property userNavigation is required for component NavigationNavbar!');
    return null;
  }

  /**
   * Renders button based on the properties of the given `item`
   * @param {*} item
   * @returns {JSX.Element} Button component
   */
  const renderButton = (item) => {
    return (
      <Button
        key={item.name}
        buttonText={item.name}
        buttonAction={item.onClick}
        color={item.color || 'blueNav'}
      />
    );
  };

  return (
    <>
      {/* Navigation buttons desktop */}
      <div className="hidden md:block">
        <div className="flex items-baseline space-x-4">
          {navigation.map((item) => renderButton(item))}
        </div>
      </div>
    </>
  );
};

export default NavigationNavbar;
