/**
 * Slice where all configuration data is stored as well as the current version
 */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getBackendVersion } from '../api/system';

const initialState = {
  backendVersion: {
    doccapeVersion: '',
    doccapeBuildTime: '',
    preprocessorVersion: '',
    textAiVersion: '',
    imageAiVersion: '',
  },

  status: '',
  error: '',
};

export const fetchBackendVersion = createAsyncThunk('fetch/backendVersion', async () => {
  const response = await getBackendVersion();
  return response.data;
});

export const systemSlice = createSlice({
  name: 'systemVersion',
  initialState,
  reducers: {
    /**
     * Clears the systemVersion state by setting it to the initial state
     */
    clearSystemVersion() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBackendVersion.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchBackendVersion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBackendVersion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const selectBackendVersion = createSelector(
  (state) => state.systemVersion,
  (systemVersion) => systemVersion.data,
);
export const selectBackendVersionStatus = createSelector(
  (state) => state.systemVersion,
  (systemVersion) => systemVersion.status,
);

export const { clearSystemVersion } = systemSlice.actions;

export default systemSlice.reducer;
