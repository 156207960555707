import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../atoms/tailwind/Button';
import Checkbox from '../../../../atoms/tailwind/Checkbox';
import Input from '../../../../atoms/tailwind/Input';
import LabelEditArea from '../../../../molecules/tailwind/LabelEditArea';

const ImageLabelsEditArea = ({
  isSaving,
  imageLabel,
  onSave,
  setLabelIsEnabled,
  nameValidityByImageLabelName,
  nameInputByImageLabelName,
  changeName,
  onBlurChangeName,
  onDelete,
  setHasUnsavedChanges,
}) => {
  const { t } = useTranslation();

  if (!imageLabel || !nameValidityByImageLabelName.current) {
    return (
      <div className="h-[34rem] rounded-xl bg-grey-0 p-8">
        <LabelEditArea editingRows={[]} />
      </div>
    );
  }
  const isNameValid = nameValidityByImageLabelName.current[imageLabel.name];
  const nameInvalidFormMessage = nameValidityByImageLabelName.current[imageLabel.name]?.formMessage;

  const editingRows = [
    {
      title: t('settings.imageAnonymization.name'),
      children: (
        <>
          <Input
            type="text"
            value={nameInputByImageLabelName[imageLabel.name]}
            placeholder={t('settings.imageAnonymization.maxNameChars')}
            onChange={(event) => {
              setHasUnsavedChanges(true);
              changeName(event.target.value);
            }}
            disabled={imageLabel.fromModel}
            isValid={isNameValid.valid}
            onBlur={onBlurChangeName}
          />
          {nameInvalidFormMessage}
        </>
      ),
      isVisible: true,
    },
    // {
    //   title: t('settings.textLabels.description'), // TODO: Change translation
    //   children: (
    //     <TextArea
    //       value={textLabel.description}
    //       onChange={(event) => changeDescription(event.target.value)}
    //       // className="block w-full resize-none rounded-xl border-0 p-1.5 text-base text-blue-2 shadow-sm ring-1 ring-inset ring-babyBlue-1 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:leading-6"
    //     />
    //   ),
    //   isVisible: true,
    // },
    // TODO: Enable
    {
      title: t('settings.imageAnonymization.enable'),
      children: (
        <div className="flex h-full w-full items-center">
          <Checkbox
            onChange={(event) => {
              setHasUnsavedChanges(true);
              setLabelIsEnabled(imageLabel.name, event.target.checked);
            }}
            checked={imageLabel.isEnabled}
          />
        </div>
      ),
      isVisible: true,
    },
    {
      title: null,
      children: (
        <div className="grid grid-cols-2 gap-2">
          <Button
            buttonText={t('settings.imageAnonymization.delete')}
            buttonAction={onDelete}
            disabled={imageLabel.fromModel || isSaving}
            color="red"
          />
          <Button
            buttonText={t('settings.save')}
            buttonAction={() => {
              setHasUnsavedChanges(false);
              onSave();
            }}
            color="blue"
            disabled={isSaving || !isNameValid.valid}
            isLoading={isSaving}
          />
        </div>
      ),
      isVisible: true,
    },
  ];

  return (
    <div className="h-[34rem] rounded-xl bg-grey-0 p-8">
      <LabelEditArea editingRows={editingRows} />
    </div>
  );
};

export default ImageLabelsEditArea;
