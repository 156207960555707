import { Center, HStack, IconButton, Input, Select, Spacer, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BiPlus } from 'react-icons/bi';

/**
 * Renders the add listing item button
 * @param {Object} props The props of the AddListingItemButton
 * @param {string} props.addButtonTooltip
 * @param {Funcion} props.onAdd
 * @param {string} props.placeholder
 * @param {boolean} props.checkLabels
 * @param {Object} props.labels
 */
const AddListingItemButton = ({
  addButtonTooltip,
  onAdd,
  placeholder,
  checkLabels,
  labels,
  checkIfExist,
}) => {
  const [itemValue, setItemValue] = useState('');
  const [itemLabel, setItemLabel] = useState('');

  /**
   * Handles the change of the blacklist item value
   * @param {Object} event
   */
  const handleItemValueChange = (event) => {
    setItemValue(event.target.value);
  };

  /**
   * Handles the change of the blacklist item label
   * @param {Object} event
   */
  const handleItemLabelChange = (event) => {
    setItemLabel(event.target.value);
  };

  /**
   * Executes the onAdd function and resets states
   */
  const handleAddButtonClick = () => {
    onAdd(itemValue, itemLabel);
    setItemValue('');
    setItemLabel('');
  };

  /**
   * Checks if the states `itemTag` and `itemValue` are valid
   */
  const isInputInvalid = () => {
    if (checkLabels) {
      return itemLabel === '' || itemValue === '' || checkIfExist(itemValue);
    }
    return itemValue === '' || checkIfExist(itemValue, itemLabel);
  };

  return (
    <HStack mb={2}>
      <Center width="100%">
        <Input value={itemValue} onChange={handleItemValueChange} mr={4} />
        <Select value={itemLabel} onChange={handleItemLabelChange} placeholder={placeholder}>
          {Object.keys(labels).map((labelName) => {
            return (
              <option value={labelName} key={labelName}>
                {labelName}
              </option>
            );
          })}
        </Select>
      </Center>
      <Spacer />
      <Tooltip hasArrow label={addButtonTooltip}>
        <IconButton
          icon={<BiPlus />}
          onClick={handleAddButtonClick}
          color="white"
          backgroundColor="green"
          sx={{ '&:hover': { color: 'green', backgroundColor: 'white' } }}
          isDisabled={isInputInvalid()}
        />
      </Tooltip>
    </HStack>
  );
};

export default AddListingItemButton;
