/**
 * Renders Inactive Bounding Box
 */
import * as React from 'react';
import { Shape } from 'react-konva';
import getColorCodeByNameWithOpacity from '../../../../utils/workarounds';

const KonvaInactiveBbox = ({
  bboxCoordinates,
  bboxColor,
  bboxBorderColor,
  scaleFactor,
  translationVector,
  isMovingImageButtonMode,
  isEditingBbox,
  isCreatingBbox,
  setBboxActive,
  toStageFrame,
}) => {
  /**
   * Sets the current bbox active
   */
  const handleBboxClick = () => {
    if (isMovingImageButtonMode || isEditingBbox || isCreatingBbox) return;
    setBboxActive();
  };

  return bboxCoordinates ? (
    <Shape
      onClick={handleBboxClick}
      /*
       * The scene function triggers during render and draws the shape with the drawn key points
       * (these points need to be transformed to the drawn frame because the bboxCoordinates are the origianl positions)
       */
      sceneFunc={(context, shape) => {
        context.beginPath();

        bboxCoordinates.forEach((coord, idx) => {
          const stagePoint = toStageFrame(coord, translationVector, scaleFactor);
          if (idx === 0) {
            context.moveTo(stagePoint[0], stagePoint[1]);
          } else {
            context.lineTo(stagePoint[0], stagePoint[1]);
          }
        });

        context.closePath();
        context.fillStrokeShape(shape);
      }}
      stroke={getColorCodeByNameWithOpacity(bboxBorderColor, 1.0)}
      strokeWidth={1}
      fill={getColorCodeByNameWithOpacity(bboxColor, 0.7)}
    />
  ) : null;
};

export default KonvaInactiveBbox;
