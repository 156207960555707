import { refreshToken } from '../reducers/authSlice';

export const setAuthenticationHeader = (config, store) => {
  const { getState } = store;
  const state = getState();
  const { token } = state.auth.data;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
};

export const handleUnauthorizedResponse = async (error, store, axiosInstance) => {
  const { dispatch } = store;
  const originalConfig = error.config;

  if (
    originalConfig.url !== '/auth/login' && // no need to refresh token after calling login
    originalConfig.url !== '/auth/refresh' && // no need to refresh token when refreshing token
    error.response &&
    !originalConfig._retry // only retry once for any request
  ) {
    // Access Token was expired
    originalConfig._retry = true;

    try {
      // Call refresh token (token is sent via cookie)
      await dispatch(refreshToken()).unwrap();

      return axiosInstance(originalConfig);
    } catch (refreshTokenError) {
      return Promise.reject(refreshTokenError);
    }
  }

  return Promise.reject(error);
};

/**
 * Checks if a given user has a given role in a given organization
 * @param {Object} user - The user object
 * @param {string | number} orgId - The organization id
 * @param {String} role - The role to check
 * @returns {boolean} - True if the user has the role in the organization, false otherwise
 */
export const checkOrgRole = (user, orgId, role = 'ADMIN') => {
  let orgIdInt = orgId;
  try {
    orgIdInt = parseInt(orgIdInt, 10);
  } catch (e) {
    return false;
  }

  if (!user.orgRoles) {
    return false;
  }

  return (
    user.orgRoles.filter((orgRole) => orgRole.orgId === orgIdInt && orgRole.role === role).length >
    0
  );
};

/**
 * Checks if a given user has a given role in a given project
 * @param {Object} user - The user object
 * @param {string | number} projectId - The project id
 * @param {String} role - The role to check
 * @returns {boolean} - True if the user has the role in the project, false otherwise
 */
export const checkProjectRole = (user, projectId, role = 'ADMIN') => {
  let projectIdInt = projectId;
  try {
    projectIdInt = parseInt(projectIdInt, 10);
  } catch (e) {
    return false;
  }

  if (!user.projectRoles) {
    return false;
  }

  return (
    user.projectRoles.filter(
      (projectRole) => projectRole.projectId === projectIdInt && projectRole.role === role,
    ).length > 0
  );
};
