import { Box, Center } from '@chakra-ui/react';
import React, { useState } from 'react';

const SmallIconButton = (props) => {
  const { onClick, disabled, isPreviewLoading, icon } = props;
  const [hover, setHover] = useState();
  const gray200 = '#E2E8F0';
  const gray600 = '#4A5568';

  const normalStyle = {
    color: gray600,
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const hoverStyle = {
    color: gray600,
    background: gray200,
    height: '24px',
    width: '24px',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  const coloredIcon = hover
    ? React.cloneElement(icon, {
        color: hoverStyle.color,
      })
    : React.cloneElement(icon, { color: normalStyle.color });

  return (
    <Box sx={disabled ? { cursor: 'not-allowed' } : null}>
      <Box
        sx={
          disabled
            ? {
                opacity: '0.3',
                pointerEvents: 'none',
              }
            : null
        }
      >
        <div style={hover ? hoverStyle : normalStyle}>
          <button
            onMouseOver={handleMouseIn}
            onFocus={handleMouseIn}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onClick={onClick}
            disabled={isPreviewLoading}
            type="button"
          >
            <Center>{coloredIcon}</Center>
          </button>
        </div>
      </Box>
    </Box>
  );
};

export default SmallIconButton;
