/**
 * Slice which provides reducer and state of the logo of a organization
 */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getOrganizationLogo } from '../api/organization';

const initialState = {
  logo: {},
  status: 'idle',
  error: null,
};

/**
 * Retrieves organization logo
 */
export const fetchOrganizationLogo = createAsyncThunk(
  'organization/logo',
  async (organizationId) => {
    const response = await getOrganizationLogo(organizationId);
    return response.data;
  },
);

export const organizationLogoSlice = createSlice({
  name: 'organizationLogo',
  initialState,
  reducers: {
    /**
     * Clears the  state by setting it to the initial state
     */
    clearLogoData() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganizationLogo.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchOrganizationLogo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.logo = action.payload;
      })
      .addCase(fetchOrganizationLogo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectOrganizationLogo = createSelector(
  (state) => state.organizationLogo,
  (organizationLogo) => organizationLogo.logo,
);
export const selectOrganizationLogoStatus = createSelector(
  (state) => state.organizationLogo,
  (organizationLogo) => organizationLogo.status,
);
export const selectOrganizationLogoError = createSelector(
  (state) => state.organizationLogo,
  (organizationLogo) => organizationLogo.error,
);

export const { clearLogoData } = organizationLogoSlice.actions;

export default organizationLogoSlice.reducer;
