import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { resendEmailVerification, verifyUserAfterRegistration } from '../api/authentication';

/**
 * Handles the verification of a user after registration
 */
const useRegistrationUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isVerifyEmailTokenNotValid, setIsVerifyEmailTokenNotValid] = useState(false);
  const [isVerifyEmailTokenExpired, setIsVerifyEmailTokenExpired] = useState(false);
  const [isVerifyEmailTokenAlreadyUsed, setIsVerifyEmailTokenAlreadyUsed] = useState(false);
  const [doesoesVerifyEmailTokenNotExistOrIsOutdated, setDoesVerifyEmailTokenNotExistOrIsOutdated] =
    useState(false);
  const [isUnknownError, setIsUnknownError] = useState(false);

  /**
   * Handles error messages when verifying a token or registering a user
   * @param {string} statusCode The status code of the response
   */
  const handleTokenError = (statusCode) => {
    switch (statusCode) {
      case 422:
        setIsVerifyEmailTokenNotValid(true);
        break;
      case 409:
        setIsVerifyEmailTokenAlreadyUsed(true);
        break;
      case 410:
        setIsVerifyEmailTokenExpired(true);
        break;
      case 404:
        setDoesVerifyEmailTokenNotExistOrIsOutdated(true);
        break;
      case 500:
        setIsUnknownError(true);
        break;
      default:
        break;
    }
  };

  /**
   * Verifies given token in url
   */
  const verifyToken = async (emailToken) => {
    try {
      const response = await verifyUserAfterRegistration(emailToken);
      if (response.status === 200) {
        navigate('/verify-email-success');
      }
    } catch (err) {
      handleTokenError(err.response.status);
    }
  };

  /**
   * Refreshes the verify token by sending a new one to the user's email
   * @param {string} verifyEmailToken The old token
   */
  const refreshVerifyEmailToken = async (verifyEmailToken) => {
    try {
      await resendEmailVerification(verifyEmailToken);
      return true;
    } catch (err) {
      return false;
    }
  };

  return {
    isVerifyEmailTokenNotValid,
    isVerifyEmailTokenExpired,
    isVerifyEmailTokenAlreadyUsed,
    doesoesVerifyEmailTokenNotExistOrIsOutdated,
    isUnknownError,
    verifyToken,
    refreshVerifyEmailToken,
  };
};

export default useRegistrationUser;
