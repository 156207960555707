import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchBlacklist,
  selectBlacklist,
  selectBlacklistError,
  selectBlacklistStatus,
  updateBlacklist,
} from '../reducers/blacklistSlice';
import { transformToSliceData } from '../services/listingService';
import { removeItemFromArray, setItemAtIndex } from '../services/utils';
import { loadCSV, transformFromCSVData } from '../utils/csvUtils';

/**
 * @deprecated - Should be deleted after old document selection is removed
 */
const useBlacklist = (projectId) => {
  const dispatch = useDispatch();

  const [blacklist, setBlacklist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCSVImporting, setIsCSVImporting] = useState(false);

  const sliceBlacklist = useSelector(selectBlacklist);
  const fetchStatus = useSelector(selectBlacklistStatus);
  const fetchError = useSelector(selectBlacklistError);

  // TOOD: Naming
  const initSlice = () => {
    setIsLoading(true);
    dispatch(fetchBlacklist({ projectId }));
  };

  const transformSliceData = () => {
    if (fetchStatus === 'succeeded') {
      setIsLoading(true);
      setBlacklist(sliceBlacklist);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlice();
  }, [projectId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceBlacklist]);

  const saveBlacklist = () => {
    const updatedSliceBlacklist = transformToSliceData(blacklist, projectId);
    dispatch(updateBlacklist({ projectId, blacklist: updatedSliceBlacklist }));
  };

  const addBlacklistItem = (item) => {
    const updatedBlacklist = [...blacklist, item];
    setBlacklist(updatedBlacklist);
  };

  const removeBlacklistItem = (idx) => {
    const updatedBlacklist = removeItemFromArray(blacklist, idx);
    setBlacklist(updatedBlacklist);
  };

  const updateBlacklistItem = (item, idx) => {
    const updatedBlacklist = setItemAtIndex(blacklist, idx, item);
    setBlacklist(updatedBlacklist);
  };

  const removeAllBlacklistItems = () => {
    setBlacklist([]);
  };

  const importBlacklistItems = (file) => {
    loadCSV(file, (csvData) => {
      try {
        setIsCSVImporting(true);
        const { headers, rows } = csvData;
        if (headers[0] !== 'value' || headers[1] !== 'label') {
          throw new Error('CSV file has wrong format');
        }
        const csvObjects = transformFromCSVData(headers, rows);
        // use whitelist items with empty tag name as wildcard
        const updatedWhitelist = csvObjects.map((item) => ({
          labelName: item.label,
          value: item.value,
        }));
        setBlacklist(updatedWhitelist);
      } catch (error) {
        // TODO
        throw new BlacklistImportError('CSV file has wrong format');
      } finally {
        setIsCSVImporting(false);
      }
    });
  };

  return {
    isLoading,
    blacklist,
    isCSVImporting,
    saveBlacklist,
    addBlacklistItem,
    removeBlacklistItem,
    updateBlacklistItem,
    removeAllBlacklistItems,
    importBlacklistItems,
  };
};

export default useBlacklist;
