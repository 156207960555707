import React from 'react';
import useLayout from '../../hooks/useLayout';
import Box from '../atoms/Box';

const VScrollable = (props) => {
  const { scrollBarWidth } = useLayout();

  return (
    <Box
      overflowY="auto"
      sx={{
        padding: '4px',
        '&::-webkit-scrollbar': {
          backgroundColor: 'white',
          border: '0px',
          borderColor: 'gray.100',
          width: scrollBarWidth,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          border: '0px solid transparent',
          backgroundClip: 'content-box',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          border: '0px solid transparent',
          backgroundClip: 'content-box',
          borderRadius: '10px',
        },
      }}
      {...props}
    />
  );
};

export default VScrollable;
