import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Switch,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageLabelSettings from './ImageLabelSettings';

const ImageAnonymization = ({
  imageLabels,
  imageAnonymizationTypes,
  setImageAnonymizationType,
  setImageLabelIsEnabled,
  isImageAnonymizationEnabled,
  setIsImageAnonymizationEnabled,
}) => {
  const { t } = useTranslation();

  // saves the index when the user deactivate the last anonymization type
  const [idxLastDisabledAnonymizationType, setIdxLastDisabledAnonymizationType] = useState(0);

  /* Label specific settings */
  const labelsSettings = useMemo(
    () =>
      imageLabels
        .map((label) => ({
          title: label.name,
          setIsEnabled: (isEnabled) => {
            setImageLabelIsEnabled(label.name, isEnabled);
          },
          isEnabled: label.isEnabled,
          component: (
            <ImageLabelSettings
              label={label}
              isDisabled={!isImageAnonymizationEnabled || !label.isEnabled}
              imageAnonymizationTypes={imageAnonymizationTypes}
              setImageAnonymizationType={setImageAnonymizationType}
            />
          ),
        }))
        .sort((labelA, labelB) => {
          if (labelA.title < labelB.title) {
            return -1;
          }
          if (labelA.title > labelB.title) {
            return 1;
          }
          return 0;
        }),
    [imageLabels, isImageAnonymizationEnabled],
  );

  /**
   * Handles enabling and disabling of the individual parts of the image anonymization settings
   * @param {*} value
   * @param {*} idx
   */
  const handleOnChangeImageAnonymizationTypesIsEnabled = (value, idx) => {
    if (!value) {
      // settings disabled
      const hasOtherEnabled = Object.values(labelsSettings).some(
        (element, elementIdx) => elementIdx !== idx && element.isEnabled,
      );
      if (hasOtherEnabled) {
        labelsSettings[idx].setIsEnabled(false);
      } else {
        // save last, disable type & global settings
        setIdxLastDisabledAnonymizationType(idx);
        labelsSettings[idx].setIsEnabled(false);
        setIsImageAnonymizationEnabled(false);
      }
    } else {
      // settings enabled
      labelsSettings[idx].setIsEnabled(true);
    }
  };

  /**
   * Handles enabling and disabling of the image anonymization settings
   * @param {*} value
   */
  const handleOnChangeGlobalIsEnabled = (value) => {
    setIsImageAnonymizationEnabled(value);
    if (value) {
      // check if no type setting is enabled
      const hasOtherEnabled = labelsSettings.some((type) => type.isEnabled);
      // if none exists, enable last one
      if (!hasOtherEnabled) {
        labelsSettings[idxLastDisabledAnonymizationType].setIsEnabled(true);
      }
    }
  };

  return (
    <>
      <br />
      <Box>
        <Grid>
          <HStack>
            <GridItem>{t('settings.imageAnonymization.enable')}:</GridItem>
            <GridItem>
              <Switch
                isChecked={isImageAnonymizationEnabled}
                onChange={(event) => {
                  handleOnChangeGlobalIsEnabled(event.target.checked);
                }}
              />
            </GridItem>
          </HStack>
          <br />
          <GridItem>
            <Accordion allowMultiple>
              {labelsSettings.map((label, labelIdx) => (
                <AccordionItem pb={1}>
                  <>
                    <h2>
                      <AccordionButton>
                        <Flex width="full">
                          <HStack>
                            <Box width="150px">{label.title}</Box>
                            <Spacer />
                            <Box>
                              <Switch
                                size="sm"
                                isChecked={label.isEnabled}
                                onChange={(event) => {
                                  handleOnChangeImageAnonymizationTypesIsEnabled(
                                    event.target.checked,
                                    labelIdx,
                                  );
                                }}
                                isDisabled={!isImageAnonymizationEnabled}
                              />
                            </Box>
                          </HStack>
                          <Spacer />
                          <AccordionIcon />
                        </Flex>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel fontSize="sm" pb={2}>
                      {label.component}
                    </AccordionPanel>
                  </>
                </AccordionItem>
              ))}
            </Accordion>
          </GridItem>
        </Grid>
      </Box>
      <br />
    </>
  );
};

export default ImageAnonymization;
