import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getImageLabels, putImageLabels } from '../api/project';

const initialState = {
  data: [],
  status: '',
  error: '',
};

/**
 * Fetches the imageLabels
 */
export const fetchImageLabels = createAsyncThunk('imageLabels/fetch', async ({ projectId }) => {
  const response = await getImageLabels(projectId);
  return response.data;
});

export const updateImageLabels = createAsyncThunk(
  'imageLabels/update',
  async ({ projectId, imageLabels }) => {
    const response = await putImageLabels(projectId, imageLabels);
    return response.data;
  },
);

/**
 * The slice of the imageLabels state
 */
export const imageLabelsSlice = createSlice({
  name: 'imageLabels',
  initialState,
  reducers: {
    setImageLabels(state, action) {
      return action.payload;
    },
    /**
     * Clears the imageLabels state by setting it to the initial state
     */
    clearImageLabels() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchImageLabels.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchImageLabels.fulfilled, (state, action) => {
        // Save fetched imageLabels in store
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchImageLabels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectImageLabels = createSelector(
  (state) => state.imageLabels,
  (imageLabels) => imageLabels.data,
);
export const selectImageLabelsStatus = createSelector(
  (state) => state.imageLabels,
  (imageLabels) => imageLabels.status,
);
export const selectImageLabelsError = createSelector(
  (state) => state.imageLabels,
  (imageLabels) => imageLabels.error,
);

export const { setImageLabels, clearImageLabels } = imageLabelsSlice.actions;

export default imageLabelsSlice.reducer;
