import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

const Button = (props) => {
  const { width, size, colorScheme, onClick, children } = props;

  return (
    <ChakraButton width={width} size={size} colorScheme={colorScheme} onClick={onClick}>
      {children}
    </ChakraButton>
  );
};

export default Button;
