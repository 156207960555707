import React, { useEffect } from 'react';
import useBlackWhitelist from '../../../../../hooks/useBlackWhitelist';
import BlackWhiteListContent from './BlackWhiteListContent';
import BlackWhiteListFilter from './BlackWhiteListFilter';

const BlackWhiteList = ({ projectId, textLabels, hasTextLabelExtendedReplacement }) => {
  const {
    textElements,
    blacklist,
    whitelist,
    changeBlacklistLabel,
    changeWhitelistLabel,
    isLabelNameSelected,
    addNewTextElement,
    removeTextElement,
    getFilteredTextElements,
  } = useBlackWhitelist(projectId);

  // Displayed text elements are the text elements that are currently displayed in the list due to filtering
  const [displayedTextElements, setDisplayedTextElements] = React.useState(textElements);
  useEffect(() => {
    setDisplayedTextElements(textElements);
  }, [textElements]);
  const [selectedTextElement, setSelectedTextElement] = React.useState(null);

  return (
    <div className="h-full w-full">
      <BlackWhiteListFilter
        setSelectedTextElement={setSelectedTextElement}
        getFilteredTextElements={getFilteredTextElements}
        setDisplayedTextElements={setDisplayedTextElements}
      />
      <BlackWhiteListContent
        textElements={displayedTextElements}
        selectedTextElement={selectedTextElement}
        setSelectedTextElement={setSelectedTextElement}
        addNewTextElement={addNewTextElement}
        removeTextElement={removeTextElement}
        textLabels={textLabels}
        blacklist={blacklist}
        whitelist={whitelist}
        changeBlacklistLabel={changeBlacklistLabel}
        changeWhitelistLabel={changeWhitelistLabel}
        hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
        isLabelNameSelected={isLabelNameSelected}
      />
    </div>
  );
};

export default BlackWhiteList;
