import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useLayout from '../../../hooks/useLayout';
import ImageAnnotationControl from './ImageAnnotationControl';
import ImageAnnotationRightMenu from './ImageAnnotationRightMenu';
import ImageAnnotationTopLeftMenu from './imageAnnotationTopMenu/ImageAnnotationTopLeftMenu';
import ImageAnnotationTopRightMenu from './imageAnnotationTopMenu/ImageAnnotationTopRightMenu';

const ImageAnnotationMain = ({
  onSaveDocument,
  onCloseDocument,
  isPreviewLoading,
  createRenderableBboxs,
  activeImageId,
  getImageById,
  createRenderableDetections,
  isCreatingBbox,
  isBboxActive,
  setIsCreatingBbox,
  isEditingBbox,
  isMovingImageButtonMode,
  activeLabel,
  setActiveLabel,
  activeBbox,
  setActiveBbox,
  cancelDrawing,
  beginEditingBbox,
  cancelEditingBbox,
  clearActiveBbox,
  setIsMovingImageButtonMode,
  updateBbox,
  addBbox,
  changeLabelOfBbox,
  setBboxActive,
  onDeleteBbox,
  centeringScaleFactor,
  scaleFactor,
  translationVector,
  stageWidth,
  stageHeight,
  isLabelActive,
  setStageWidth,
  setStageHeight,
  centerView,
  zoomAtStagePos,
  toStageFrame,
  toImageFrame,
  initAnnotatorStage,
  handleZoomIn,
  handleZoomOut,
  drawnKeyPoints,
  transformedKeyPoints,
  flattenedPoints,
  handlePointDragMove,
  handleMouseMove,
  pointDragBoundFunc,
  setPressedMouseButton,
  clearBboxData,
  handleZoomIntoMousePointer,
  computeDraggedKeyPoints,
  addKeyPoint,
  isPolyComplete,
  getNumberOfBboxesOfLabel,
  handleCreateBbox,
  isSaving,
  finalizeDocument,
  isFinalizing,
  imageAnnotationUndoActionName,
  imageAnnotationRedoActionName,
  imageAnnotationUndo,
  imageAnnotationRedo,
  imageLabels,
  setImageLabelIsHidden,
}) => {
  const { imageAnnotationControlWidth } = useLayout();

  const activeImage = getImageById(activeImageId);

  const [renderableBboxs, setRenderableBboxs] = useState([]);
  useEffect(() => {
    if (
      activeImage !== null &&
      typeof activeImage !== 'undefined' &&
      imageLabels !== null &&
      typeof imageLabels !== 'undefined'
    ) {
      const bboxs = createRenderableBboxs(activeImage, imageLabels);
      setRenderableBboxs(bboxs);
    }
  }, [activeImage, imageLabels]);

  const renderableDetections = createRenderableDetections(activeImage);

  useEffect(() => {
    clearActiveBbox();
    clearBboxData();
  }, [activeImageId]);

  /**
   * Checks if a bounding box of the given label is in the image
   * @param {Object} label The label of the bounding boxes
   * @returns True if there are bounding boxes with the given label in the image
   */
  const isLabelInImage = (label) => {
    return renderableDetections[label.name] && renderableDetections[label.name].bboxs.length > 0;
  };

  /**
   * Changes the label name
   *
   * @param {number} bboxIdx The index of bounding box
   * @param {number} labelIdx The index of the old label
   * @param {string} oldLabelName The name of the old label
   * @param {string} newLabelName The name of the new label
   */
  const onChangeLabelName = (bboxIdx, labelIdx, oldLabelName, newLabelName) => {
    if (oldLabelName !== newLabelName) {
      changeLabelOfBbox(bboxIdx, labelIdx, newLabelName);
      setActiveBbox(null);

      // Becase it is possible to change a label in the editing mode,
      // the editing mode needs to be canceled to avoid unwanted behaviour
      cancelEditingBbox();
    }
  };

  /**
   * Handles key down event
   * @param {object} event Key event
   */
  const keyDownHandler = (event) => {
    event.preventDefault();
    if (event.key === 'Escape') {
      if (isCreatingBbox) {
        cancelDrawing();
      }
      if (isMovingImageButtonMode) {
        setIsMovingImageButtonMode(false);
      }
    }
    if (event.key === 'Delete') {
      if (activeBbox !== null) {
        onDeleteBbox();
      }
    }
  };

  // useEffect for handling keydown events
  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    // Clean-up event listener
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isCreatingBbox, isMovingImageButtonMode, activeBbox]);

  return (
    activeImageId !== null &&
    typeof activeImageId !== 'undefined' && (
      <Flex w="100%" height="100%" flexDirection="column">
        <Flex w="100%" height="95%" flexDirection="row">
          <Box w={imageAnnotationControlWidth} height="100%">
            <ImageAnnotationTopLeftMenu
              onDeleteBbox={onDeleteBbox}
              activeBboxId={activeBbox ? activeBbox.id : null}
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              centeringScaleFactor={centeringScaleFactor}
              scaleFactor={scaleFactor}
              centerView={centerView}
              zoomAtStagePos={zoomAtStagePos}
              isMovingImageButtonMode={isMovingImageButtonMode}
              setIsMovingImageButtonMode={setIsMovingImageButtonMode}
              isEditingBbox={isEditingBbox}
              cancelEditingBbox={cancelEditingBbox}
              beginEditingBbox={beginEditingBbox}
              setActiveBbox={setActiveBbox}
              isCreatingBbox={isCreatingBbox}
              cancelDrawing={cancelDrawing}
              handleZoomIn={handleZoomIn}
              handleZoomOut={handleZoomOut}
              imageAnnotationUndo={imageAnnotationUndo}
              imageAnnotationRedo={imageAnnotationRedo}
              imageAnnotationUndoActionName={imageAnnotationUndoActionName}
              imageAnnotationRedoActionName={imageAnnotationRedoActionName}
            />
            <ImageAnnotationControl
              stageHeight={stageHeight}
              renderableBboxs={renderableBboxs}
              stageWidth={stageWidth}
              setStageHeight={setStageHeight}
              setStageWidth={setStageWidth}
              activeImage={activeImage}
              setActiveLabel={setActiveLabel}
              isCreatingBbox={isCreatingBbox}
              setIsCreatingBbox={setIsCreatingBbox}
              isEditingBbox={isEditingBbox}
              isMovingImageButtonMode={isMovingImageButtonMode}
              activeLabel={activeLabel}
              activeBbox={activeBbox}
              setActiveBbox={setActiveBbox}
              scaleFactor={scaleFactor}
              toStageFrame={toStageFrame}
              toImageFrame={toImageFrame}
              translationVector={translationVector}
              initAnnotatorStage={initAnnotatorStage}
              updateBbox={updateBbox}
              addBbox={addBbox}
              drawnKeyPoints={drawnKeyPoints}
              transformedKeyPoints={transformedKeyPoints}
              flattenedPoints={flattenedPoints}
              handlePointDragMove={handlePointDragMove}
              handleMouseMove={handleMouseMove}
              pointDragBoundFunc={pointDragBoundFunc}
              setPressedMouseButton={setPressedMouseButton}
              handleZoomIntoMousePointer={handleZoomIntoMousePointer}
              computeDraggedKeyPoints={computeDraggedKeyPoints}
              addKeyPoint={addKeyPoint}
              isPolyComplete={isPolyComplete}
            />
          </Box>
          <Box w={`calc(100% - ${imageAnnotationControlWidth})`}>
            <ImageAnnotationTopRightMenu
              isSaving={isSaving}
              finalizeDocument={finalizeDocument}
              isFinalizing={isFinalizing}
              onSaveDocument={onSaveDocument}
              onCloseDocument={onCloseDocument}
              isPreviewLoading={isPreviewLoading}
            />
            <ImageAnnotationRightMenu
              activeImage={activeImage}
              renderableBboxs={renderableBboxs}
              renderableDetections={renderableDetections}
              labels={imageLabels}
              handleCreateBbox={handleCreateBbox}
              activeBbIdx={activeBbox ? activeBbox.id : null}
              isLabelInImage={isLabelInImage}
              isLabelActive={isLabelActive}
              isCreatingBbox={isCreatingBbox}
              isEditingBbox={isEditingBbox}
              cancelDrawing={cancelDrawing}
              isMovingImageButtonMode={isMovingImageButtonMode}
              setLabelIsHidden={setImageLabelIsHidden}
              isBboxActive={isBboxActive}
              setBboxActive={setBboxActive}
              onChangeLabelName={onChangeLabelName}
              getNumberOfBboxesOfLabel={getNumberOfBboxesOfLabel}
            />
          </Box>
        </Flex>
      </Flex>
    )
  );
};

export default ImageAnnotationMain;
