import React from 'react';

const DownloadIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="33" viewBox="0 0 38 33">
      <path d="M18.0871 19.5375C18.2271 19.6775 18.3671 19.8175 18.5071 19.9575C18.7571 20.2075 19.1771 20.2075 19.4271 19.9575C20.4171 18.9675 21.4171 17.9675 22.4071 16.9775C22.5471 16.8375 22.6871 16.6975 22.8271 16.5575C23.4171 15.9675 22.4971 15.0475 21.9071 15.6375L19.7071 17.8375V9.7275C19.7071 8.7575 18.2071 8.7575 18.2071 9.7275V17.8375C17.6171 17.2475 17.0271 16.6575 16.4371 16.0675C16.2971 15.9275 16.1571 15.7875 16.0171 15.6475C15.4271 15.0575 14.5071 15.9775 15.0971 16.5675C16.0871 17.5575 17.0871 18.5575 18.0771 19.5475L18.0871 19.5375Z" />
      <path d="M24.1975 22.145H13.7275C12.7575 22.145 12.7575 23.645 13.7275 23.645H24.1975C25.1675 23.645 25.1675 22.145 24.1975 22.145Z" />
    </svg>
  );
};

export default DownloadIcon;
