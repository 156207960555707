import { Center, Flex, VStack } from '@chakra-ui/react';
import React from 'react';
import useLayout from '../../../hooks/useLayout';
import NavigatorButton from '../../molecules/NavigatorButton';

const Navigator = ({
  views,
  isSidebarOpen,
  setIsSidebarOpen,
  activeSidebarContentArea,
  changeActiveSidebarView,
}) => {
  const { navigatorWidth } = useLayout();

  return (
    <Flex flexDir="column" bg="gray.400" p={0} width={navigatorWidth} height="100%">
      <VStack spacing={0}>
        {Object.keys(views).map((viewName) => (
          <Center key={`ViewNavigatorButton-${viewName}`}>
            <NavigatorButton
              icon={views[viewName].icon}
              tooltip={views[viewName].tooltip}
              isSelected={viewName === activeSidebarContentArea}
              isDisabled={views[viewName].isDisabled}
              onClick={() => {
                if (viewName !== activeSidebarContentArea || !isSidebarOpen) {
                  changeActiveSidebarView(views[viewName].activeAnnotationView, viewName);
                  setIsSidebarOpen(true);
                } else {
                  setIsSidebarOpen(false);
                }
              }}
              navigatorWidth={navigatorWidth}
            />
          </Center>
        ))}
      </VStack>
    </Flex>
  );
};

export default Navigator;
