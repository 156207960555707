import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ScoresTable = ({ scores }) => {
  const { t } = useTranslation();

  const tableValues = new Map();
  const metrics = new Set();
  const textLabelIDs = [];

  const extractTableValues = (metricsObject, textLabel) => {
    textLabelIDs.push(textLabel);
    Object.entries(metricsObject).forEach((metricsEntry) => {
      const [metricName, metricValue] = metricsEntry;
      tableValues.set(
        `${textLabel}-${metricName}`,
        ['f1', 'f2', 'precision', 'recall'].includes(metricName)
          ? metricValue.toFixed(2)
          : metricValue,
      );
      metrics.add(metricName);
    });
  };

  extractTableValues(scores.total, 'total');

  Object.entries(scores.byLabel).forEach((scoresEntry) => {
    const [textLabel, metricsObject] = scoresEntry;
    if (metricsObject === null) return;
    extractTableValues(metricsObject, textLabel);
  });

  const tableHeader = (
    <Tr>
      {[<Td key="0" />].concat(
        [...metrics].map((item) => <Th key={item}>{t(`annotation.metric.${item}`)}</Th>),
      )}
    </Tr>
  );

  const tableBody = textLabelIDs.map((textLabelName) => (
    <Tr key={textLabelName}>
      <Th>{textLabelName.toUpperCase()}</Th>
      {[...metrics].map((metricName) => (
        <Td key={metricName}>
          {tableValues.has(`${textLabelName}-${metricName}`)
            ? tableValues.get(`${textLabelName}-${metricName}`)
            : t('annotation.na')}
        </Td>
      ))}
    </Tr>
  ));

  return (
    <Table variant="striped" size="sm">
      <Thead>{tableHeader}</Thead>
      <Tbody>{tableBody}</Tbody>
    </Table>
  );
};

export default ScoresTable;
