// TODO: Chakra-imports (need to be removed)
import {
  FormErrorMessage,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../../atoms/tailwind/Checkbox';

import InfoTooltipIcon from '../../../../atoms/tailwind/InfoTooltipIcon';

const PartialReplacement = ({
  textLabel,
  changePartialReplacementStart,
  changePartialReplacementStop,
  toggleUseNumericPartialReplacement,
}) => {
  const { t } = useTranslation();
  const [isPartialReplacementError, setIsPartialReplacementError] = useState(false);

  /**
   * Checks if the passed range is valid.
   * Range is valid when either start or stop is 0 and none of the following is present:
   *   1. signum of start and stop is equal and start is greater than stop, e.g. (5, 2) or (-1, -2)
   *   2. positive values for stop while start is negative, e.g. (-2, 1)
   * @param {*} start
   * @param {*} stop
   * @returns
   */
  const isRangeValid = (start, stop) => {
    return (
      (!(start > stop && Math.sign(start) === Math.sign(stop)) &&
        !(start < stop && Math.sign(start) === -1 && Math.sign(stop) > 0)) ||
      start === 0 ||
      stop === 0
    );
  };

  const [partialReplacementStart, setPartialReplacementStart] = useState(
    textLabel.partialReplacementStart,
  );
  const [partialReplacementStop, setPartialReplacementStop] = useState(
    textLabel.partialReplacementStop,
  );

  /**
   * Sets a valid value in the store when leaving the focus of the input field.
   */
  const onBlurPartialReplacementStart = () => {
    if (partialReplacementStart !== '' && !isPartialReplacementError) {
      changePartialReplacementStart(parseInt(partialReplacementStart, 10));
    } else if (partialReplacementStart === '' && !isPartialReplacementError) {
      setPartialReplacementStart(1);
      changePartialReplacementStart(1);
    }
  };

  /**
   * Sets a valid value in the store when leaving the focus of the input field.
   */
  const onBlurPartialReplacementStop = () => {
    if (partialReplacementStop !== '' && !isPartialReplacementError) {
      changePartialReplacementStop(parseInt(partialReplacementStop, 10));
    } else if (partialReplacementStop === '' && !isPartialReplacementError) {
      setPartialReplacementStop(0);
      changePartialReplacementStop(0);
    }
  };

  /**
   * Sets the value in the state when changing the input field
   * and sets the error flag in case of an invalid value.
   * @param {*} value
   */
  const onChangePartialReplacementStart = (value) => {
    setPartialReplacementStart(value);
    if (value !== '' && !isRangeValid(parseInt(value, 10), parseInt(partialReplacementStop, 10))) {
      setIsPartialReplacementError(true);
    } else {
      setIsPartialReplacementError(false);
    }
  };

  /**
   * Sets the value in the state when changing the input field
   * and sets the error flag in case of an invalid value.
   * @param {*} value
   */
  const onChangePartialReplacementStop = (value) => {
    setPartialReplacementStop(value);
    if (value !== '' && !isRangeValid(parseInt(partialReplacementStart, 10), parseInt(value, 10))) {
      setIsPartialReplacementError(true);
    } else {
      setIsPartialReplacementError(false);
    }
  };

  return (
    <div className="w-[300]">
      <div className="grid grid-cols-2 gap-1">
        {/* Start of the partial replacement */}
        <InfoTooltipIcon tooltipText={t('settings.textLabels.partialReplacementStartDescription')}>
          <div className="whitespace-nowrap text-base text-blue-2">
            {t('settings.textLabels.partialReplacementStart')}:
          </div>
        </InfoTooltipIcon>
        {/* // TODO: Change to tailwind */}
        <NumberInput
          id="from"
          size="sm"
          maxW="100px"
          maxH="32px"
          value={partialReplacementStart}
          onChange={onChangePartialReplacementStart}
          onBlur={onBlurPartialReplacementStart}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        {/* End of the partial replacement */}
        <InfoTooltipIcon tooltipText={t('settings.textLabels.partialReplacementStopDescription')}>
          <div className="text-base text-blue-2">
            {t('settings.textLabels.partialReplacementStop')}:
          </div>
        </InfoTooltipIcon>
        {/* // TODO: Change to tailwind */}
        <NumberInput
          size="sm"
          id="to"
          maxW="100px"
          maxH="32px"
          isRequired={false}
          value={partialReplacementStop}
          onChange={onChangePartialReplacementStop}
          onBlur={onBlurPartialReplacementStop}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        {isPartialReplacementError ? (
          <FormErrorMessage>{t('settings.textLabels.partialReplacementError')}</FormErrorMessage>
        ) : null}

        {/* Only numbers checkbox */}
        <InfoTooltipIcon
          tooltipText={t('settings.textLabels.useNumericPartialReplacementDescription')}
        >
          <div className="text-base text-blue-2">
            {t('settings.textLabels.useNumericPartialReplacement')}:
          </div>
        </InfoTooltipIcon>
        <Checkbox
          checked={textLabel.useNumericPartialReplacement}
          onChange={toggleUseNumericPartialReplacement}
        />
      </div>
    </div>
  );
};

export default PartialReplacement;
