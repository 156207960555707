import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VScrollable from '../../molecules/VScrollable';
import About from './tabs/About';
import ImageLabelsSettings from './tabs/imageLabels/ImageLabelsSettings';
import ListingSettings from './tabs/listing/ListingSettings';
import TextLabelsSettings from './tabs/textLabels/TextLabelsSettings';

const SettingsMain = ({
  isShown,
  setIsShown,
  // text labels
  textLabels,
  addTextLabel,
  hasTextLabelExtendedReplacement,
  textLabelHiddenColor,
  saveTextLabels,
  changeTextLabelName,
  changeExtendedReplacement,
  setTextLabelAttribute,
  deleteTextLabel,
  allDateLocales,
  // image labels
  imageLabels,
  imageAnonymizationTypes,
  setImageAnonymizationType,
  setImageLabelIsEnabled,
  isImageAnonymizationEnabled,
  setIsImageAnonymizationEnabled,
  saveImageLabels,
  // whitelist
  whitelist,
  isWhitelistCSVImporting,
  saveWhitelist,
  addWhitelistItem,
  removeWhitelistItem,
  updateWhitelistItem,
  removeAllWhitelistItems,
  importWhitelistItems,
  // blacklist
  blacklist,
  isBlacklistCSVImporting,
  saveBlacklist,
  addBlacklistItem,
  removeBlacklistItem,
  updateBlacklistItem,
  removeAllBlacklistItems,
  importBlacklistItems,
}) => {
  const { t } = useTranslation();

  /* Tabs close states */
  const [canTextLabelsClose, setCanTextLabelsClose] = useState(true);

  /* Tabs */
  const tabs = [
    /* Text Labels */
    {
      title: t('settings.textLabels.title'),
      canClose: canTextLabelsClose,
      setCanClose: setCanTextLabelsClose,
      onClose: () => {
        saveTextLabels();
      },
      component: (
        <VScrollable>
          <TextLabelsSettings
            textLabels={textLabels}
            addTextLabel={addTextLabel}
            setCanClose={setCanTextLabelsClose}
            hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
            textLabelHiddenColor={textLabelHiddenColor}
            saveTextLabels={saveTextLabels}
            changeTextLabelName={changeTextLabelName}
            changeExtendedReplacement={changeExtendedReplacement}
            setTextLabelAttribute={setTextLabelAttribute}
            deleteTextLabel={deleteTextLabel}
            allDateLocales={allDateLocales}
          />
        </VScrollable>
      ),
    },
    /* Black- & Whitelisting */
    {
      title: t('settings.listing.title'),
      canClose: true,
      setCanClose: () => {},
      onClose: () => {
        saveBlacklist();
        saveWhitelist();
      },
      component: (
        <VScrollable>
          <ListingSettings
            whitelist={whitelist}
            isWhitelistCSVImporting={isWhitelistCSVImporting}
            addWhitelistItem={addWhitelistItem}
            removeWhitelistItem={removeWhitelistItem}
            updateWhitelistItem={updateWhitelistItem}
            removeAllWhitelistItems={removeAllWhitelistItems}
            importWhitelistItems={importWhitelistItems}
            blacklist={blacklist}
            isBlacklistCSVImporting={isBlacklistCSVImporting}
            addBlacklistItem={addBlacklistItem}
            removeBlacklistItem={removeBlacklistItem}
            updateBlacklistItem={updateBlacklistItem}
            removeAllBlacklistItems={removeAllBlacklistItems}
            importBlacklistItems={importBlacklistItems}
            textLabels={textLabels}
          />
        </VScrollable>
      ),
    },
    /* Image Anonymization */
    {
      title: t('settings.imageAnonymization.title'),
      canClose: true,
      setCanClose: () => {},
      onClose: () => {
        saveImageLabels();
      },
      component: (
        <ImageLabelsSettings
          imageLabels={imageLabels}
          imageAnonymizationTypes={imageAnonymizationTypes}
          setImageAnonymizationType={setImageAnonymizationType}
          setImageLabelIsEnabled={setImageLabelIsEnabled}
          isImageAnonymizationEnabled={isImageAnonymizationEnabled}
          setIsImageAnonymizationEnabled={setIsImageAnonymizationEnabled}
        />
      ),
    },
    /* About */
    {
      title: t('settings.about.title'),
      canClose: true,
      setCanClose: () => {},
      onClose: () => {},
      component: <About />,
    },
  ];

  const onSettingsClose = async () => {
    tabs.forEach((tab) => tab.onClose());
  };

  const canClose = !tabs.find((tab) => !tab.canClose);

  return (
    <Modal
      isOpen={isShown}
      onClose={canClose ? onSettingsClose : null}
      size="4xl"
      scrollBehavior="inside"
      onOverlayClick={() => {
        if (canClose) {
          setIsShown(false);
        }
      }}
      onEsc={() => {
        if (canClose) {
          setIsShown(false);
        }
      }}
    >
      <ModalOverlay />
      <ModalContent>
        {/* TODO: <ModalHeader>{t('settings.title')}</ModalHeader> */}
        <ModalHeader>Header Modal</ModalHeader>
        <ModalCloseButton isDisabled={!canClose} onClick={() => setIsShown(false)} />
        <ModalBody>
          <Tabs variant="enclosed">
            <TabList>
              {tabs.map((tab) => (
                <Tab key={tab.title} fontWeight="medium">
                  {tab.title}
                </Tab>
              ))}
            </TabList>
            <TabPanels h="60vh">
              {tabs.map((tab) => (
                <TabPanel key={`Component-${tab.title}`}>{tab.component}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SettingsMain;
