import { API } from './axios';

/**
 * Get all organizations from the logged in user
 */
export const getOrganizations = () => {
  return API.get('/orgs/me', {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Get the logo of the corresponding organization
 */
export const getOrganizationLogo = (orgId) => {
  return API.get(`orgs/${orgId}/logo`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Get all users of a specific organizations
 */
export const getUsersByOrganization = (orgId) => {
  return API.get(`orgs/${orgId}/users`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

export const deleteUserFromOrganization = ({ orgId, userId }) => {
  return API.delete(`orgs/${orgId}/users/${userId}`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Add a user to an specific organizations
 */
export const postUserToOrganization = ({ orgId, email }) => {
  return API.post(`orgs/${orgId}/users`, email, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Register user to organization
 */
// TODO: Fix methond naming?
export const postRegisterUser = (
  verifyEmailToken,
  firstName,
  lastName,
  password,
  passwordConfirmation,
) => {
  return API.put(
    `auth/users/verify-invited-email/${verifyEmailToken}`,
    { firstName, lastName, password, passwordConfirmation },
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Changes the role of an user inside an organization
 * @param {number} orgId ID of the organization in which the user is part of
 * @param {number} userId ID of the user which role should be updated
 * @param {string} organizationRole value of the new role
 */
export const putChangeRoleUser = ({ orgId, userId, organizationRole }) => {
  return API.put(
    `orgs/${orgId}/users/${userId}`,
    { organizationRole },
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};
