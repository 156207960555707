import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import DotIcon from '../../atoms/icons/DotIcon';
import DropdownIcon from '../../atoms/icons/DropdownIcon';
import Label from './Label';

/**
 * Custom Dropdown for Labels. It displays a list of labels and allows the user to select one of them.
 * @param {object} selectedItem currently selected label (can be null)
 * @param {array} labels array of objects inside the dropdown
 * @param {function} onChange function which handles the change of the current selection
 * @param {function} hasTextLabelExtendedReplacement function which checks if the label has an extended replacement
 */
const LabelDropdown = ({
  selectedItem = null,
  items,
  onChange,
  hasTextLabelExtendedReplacement,
}) => {
  const [chosen, setChosen] = useState(selectedItem);

  useEffect(() => {
    setChosen(selectedItem);
  }, [selectedItem]);

  /**
   * Handles the selection of an element by setting the chosen element and calling the onChange function
   * @param {Object} selectedElement Element that is selected within the dropdown by the user
   */
  const handleSelection = (selectedElement) => {
    setChosen(selectedElement);
    onChange(selectedElement);
  };

  return (
    <div className="col-span-1 flex h-fit w-full flex-col rounded-xl border border-babyBlue-1 bg-white p-1 shadow">
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="min-w-full">
            <button
              type="button"
              className="relative flex min-w-full cursor-pointer items-center justify-between bg-white px-1.5 text-left text-blue-2 sm:text-sm sm:leading-6"
            >
              {chosen ? (
                <Label
                  name={chosen.name}
                  rightIcon={hasTextLabelExtendedReplacement(chosen) ? <DotIcon /> : null}
                  color={chosen.value.color}
                  disableHover
                  activeColor={chosen.value.color}
                  hoverColor={chosen.value.color} // Hover color is used for active color
                  active
                />
              ) : (
                /*
                 * Empty p tag to ensure that the dropdown icon is always on the right side
                 * (even if there is no label selected)
                 */
                <p />
              )}
              <div>
                <DropdownIcon />
              </div>
            </button>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 min-w-full origin-top-right rounded-md bg-white p-1 shadow-lg ring-1 focus:outline-none">
            <div className="col-span-1 flex h-fit w-full flex-col">
              <ul className="grid grid-cols-3 gap-2">
                {items.map((item) => (
                  <Menu.Item key={item.id}>
                    <Label
                      name={item.name}
                      onClick={() => handleSelection(item)}
                      rightIcon={hasTextLabelExtendedReplacement(item) ? <DotIcon /> : null}
                      color="grey"
                      hoverColor={item.value.color}
                      active={item.name === chosen?.name}
                      activeColor={item.value.color}
                      disabled={item.disabled}
                    />
                  </Menu.Item>
                ))}
              </ul>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default LabelDropdown;
