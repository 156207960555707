import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import SmallIconButton from './SmallIconButton';

/**
 * Component for displaying a popover with a close and an action button
 * It is e.g. used to display the popover for closing a document
 */
const ActionPopover = (props) => {
  const {
    triggerButtonIcon,
    isLoading,
    headerContent,
    bodyContent,
    closeButtonContent,
    onClose,
    tooltip,
    actionButtonContent,
    onAction,
  } = props;

  const renderDisplayCloseButton = () => {
    return !isLoading ? (
      <PopoverTrigger>
        <div>
          <Tooltip label={tooltip}>
            <Box>
              <SmallIconButton icon={triggerButtonIcon} />
            </Box>
          </Tooltip>
        </div>
      </PopoverTrigger>
    ) : (
      <Box>
        <SmallIconButton disabled icon={triggerButtonIcon} />
      </Box>
    );
  };

  return (
    <Popover>
      {renderDisplayCloseButton()}
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontSize="18px">{headerContent}</PopoverHeader>
        <PopoverBody>{bodyContent}</PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button colorScheme="red" onClick={onClose}>
              {closeButtonContent}
            </Button>
            <Button colorScheme="green" onClick={onAction}>
              {actionButtonContent}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default ActionPopover;
