import mime from 'mime';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import AppToaster from '../../molecules/AppToaster';

/**
 * Style of dropzone border
 */
const baseStyle = 'rounded-lg';
const focusedStyle = 'outline-dashed outline-2 outline-blue-1';
const acceptStyle = 'outline-dashed outline-2 outline-blue-3';
const rejectStyle = 'outline-dashed outline-2 outline-red-1';

/**
 * Wraps children component inside a customizable box than can handle drag and drop events.
 * @param {*} onDropAccepted Method called when valid files are dropped
 * @param {*} acceptedExtensions A list of accepted file extensions
 * @param {*} noKeyboard If true, disables SPACE/ENTER to open the native file selection dialog.
 * @param {*} noClick If true, disables click to open the native file selection dialog
 * @param {*} children The children rendered inside the wrapping box
 * @param {*} props Props to customize the box
 * @returns
 */
const Dropzone = ({
  onDropAccepted,
  acceptedExtensions,
  multiple = true,
  noKeyboard = true,
  noClick = true,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  /**
   * Creates the accept object for the react-dropzone
   * from a list of accepted file extensions
   * @param {Array[String]} extensions
   * @returns
   */
  const createAcceptForDropzone = (extensions) => {
    const accept = {};
    extensions.forEach((extension) => {
      accept[mime.getType(extension)] = [extension];
    });
    return accept;
  };

  /**
   *
   * @param {Array} rejectionsArray contains the errors and the file names for the corresponding error
   */
  const handleRejectionError = (rejectionsArray) => {
    rejectionsArray.forEach((errorItem) => {
      if (errorItem.errors[0].code === 'file-invalid-type')
        AppToaster({
          description: `${errorItem.file.name} ${t(
            'documentSelection.dropzone.wrongDocumentFormat',
          )}`,
          status: 'error',
        });
    });
  };

  /**
   * Create the dropzone
   */
  const {
    getRootProps: getDropzoneProps,
    getInputProps: getDropzoneInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: createAcceptForDropzone(acceptedExtensions),
    onDropRejected: (rejectionsArray) => handleRejectionError(rejectionsArray),
    noClick,
    noKeyboard,
    multiple,
    onDropAccepted,
  });

  const styleClasses = useMemo(() => {
    let classes = baseStyle;
    if (isFocused) classes += ` ${focusedStyle}`;
    if (isDragAccept) classes += ` ${acceptStyle}`;
    if (isDragReject) classes += ` ${rejectStyle}`;
    return classes;
  }, [isFocused, isDragAccept, isDragReject]);

  return (
    <div {...props} {...getDropzoneProps({ className: styleClasses })}>
      <input {...getDropzoneInputProps()} />
      {children}
    </div>
  );
};

export default Dropzone;
