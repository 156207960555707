import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWhitelist,
  selectWhitelist,
  selectWhitelistError,
  selectWhitelistStatus,
  updateWhitelist,
} from '../reducers/whitelistSlice';
import { transformToSliceData } from '../services/listingService';
import { removeItemFromArray, setItemAtIndex } from '../services/utils';
import { loadCSV, transformFromCSVData } from '../utils/csvUtils';

/**
 * @deprecated - Should be deleted after old document selection is removed
 */
const useWhitelist = (projectId) => {
  const dispatch = useDispatch();

  const [whitelist, setWhitelist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCSVImporting, setIsCSVImporting] = useState(false);

  const sliceWhitelist = useSelector(selectWhitelist);
  const fetchStatus = useSelector(selectWhitelistStatus);
  const fetchError = useSelector(selectWhitelistError);

  // TOOD: Naming
  const initSlice = () => {
    setIsLoading(true);
    dispatch(fetchWhitelist({ projectId }));
  };

  const transformSliceData = () => {
    if (fetchStatus === 'succeeded') {
      setIsLoading(true);
      setWhitelist(sliceWhitelist);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlice();
  }, [projectId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceWhitelist]);

  const saveWhitelist = () => {
    const updatedSliceWhitelist = transformToSliceData(whitelist, projectId);
    dispatch(updateWhitelist({ projectId, whitelist: updatedSliceWhitelist }));
  };

  const addWhitelistItem = (item) => {
    const updatedWhitelist = [...whitelist, item];
    setWhitelist(updatedWhitelist);
  };

  const removeWhitelistItem = (idx) => {
    const updatedWhitelist = removeItemFromArray(whitelist, idx);
    setWhitelist(updatedWhitelist);
  };

  const updateWhitelistItem = (item, idx) => {
    const updatedWhitelist = setItemAtIndex(whitelist, idx, item);
    setWhitelist(updatedWhitelist);
  };

  const removeAllWhitelistItems = () => {
    setWhitelist([]);
  };

  const importWhitelistItems = (file) => {
    loadCSV(file, (csvData) => {
      try {
        setIsCSVImporting(true);
        const { headers, rows } = csvData;
        if (headers[0] !== 'value' || headers[1] !== 'label') {
          throw new Error('CSV file has wrong format');
        }
        const csvObjects = transformFromCSVData(headers, rows);
        // use whitelist items with empty tag name as wildcard
        const updatedWhitelist = csvObjects.map((item) => ({
          labelName: item.label === '' ? '*' : item.label,
          value: item.value,
        }));
        setWhitelist(updatedWhitelist);
      } catch (error) {
        // TODO
        throw new WhitelistImportError('CSV file has wrong format');
      } finally {
        setIsCSVImporting(false);
      }
    });
  };

  return {
    isLoading,
    whitelist,
    isCSVImporting,
    saveWhitelist,
    addWhitelistItem,
    removeWhitelistItem,
    updateWhitelistItem,
    removeAllWhitelistItems,
    importWhitelistItems,
  };
};

export default useWhitelist;
