/**
 * Renders Edit Bounding Box
 */
import * as React from 'react';
import { useState } from 'react';
import { Circle, Group, Line } from 'react-konva';
import getColorCodeByNameWithOpacity from '../../../../utils/workarounds';

const EditBbox = ({
  bboxColor,
  bboxBorderColor,
  handleGroupDragEnd,
  drawnKeyPoints,
  flattenedPoints,
  bboxVertexColor,
  handlePointDragMove,
  pointDragBoundFunc,
}) => {
  const [stage, setStage] = useState();
  const [minMaxX, setMinMaxX] = useState([0, 0]); // min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); // min and max in y axis

  const vertexRadiusT = 4;

  const minMax = (pointsT) => {
    return pointsT.reduce((acc, val) => {
      // eslint-disable-next-line no-param-reassign
      acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
      // eslint-disable-next-line no-param-reassign
      acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
      return acc;
    }, []);
  };

  /**
   * Handles cursor change if the cursor enters the shape
   * @param {Object} event
   */
  const handleGroupMouseOver = (event) => {
    const container = event.target.getStage().container();
    container.style.cursor = 'move';
    setStage(event.target.getStage());
  };

  /**
   * Handles what happens if the mouse leaves the shape
   * @param {Object} event
   */
  const handleGroupMouseOut = (event) => {
    const container = event.target.getStage().container();
    container.style.cursor = 'default';
    setStage(event.target.getStage());
  };

  /**
   * Handles cursor change if the cursor enters the circle
   * @param {Object} event
   */
  const handleCircleMouseOver = (event) => {
    const container = event.target.getStage().container();
    container.style.cursor = 'crosshair';
    setStage(event.target.getStage());
  };

  /**
   * Handles cursor change if the cursor leaves the circle
   * @param {Object} event
   */
  const handleCircleMouseOut = (event) => {
    const container = event.target.getStage().container();
    container.style.cursor = 'default';
    setStage(event.target.getStage());
  };

  /**
   * Handles the start of the shape dragging
   */
  const handleGroupDragStart = () => {
    const arrX = drawnKeyPoints.map((p) => p[0]);
    const arrY = drawnKeyPoints.map((p) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };

  /**
   * Bounds the area in which the shape can be dragged
   * @param {Object} pos The position of the shape
   * @param {number} pos.x
   * @param {number} pos.y
   * @returns The bodunded position
   */
  const groupDragBound = (pos) => {
    let { x, y } = pos;
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  };

  return drawnKeyPoints ? (
    <Group
      name="polygon"
      draggable
      onDragStart={handleGroupDragStart}
      onDragEnd={handleGroupDragEnd}
      dragBoundFunc={groupDragBound}
      onMouseOver={handleGroupMouseOver}
      onMouseOut={handleGroupMouseOut}
    >
      <Line
        points={flattenedPoints.concat([flattenedPoints[0], flattenedPoints[1]])}
        stroke={getColorCodeByNameWithOpacity(bboxBorderColor, 1.0)}
        strokeWidth={3}
        closed
        fill={getColorCodeByNameWithOpacity(bboxColor, 0.7)}
      />
      {drawnKeyPoints.map((point, index) => {
        const x = point[0] - vertexRadiusT / 2;
        const y = point[1] - vertexRadiusT / 2;

        const startPointAttr =
          index === 0
            ? {
                hitStrokeWidth: 12,
              }
            : null;
        return (
          <Circle
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            x={x}
            y={y}
            radius={vertexRadiusT}
            fill={getColorCodeByNameWithOpacity(bboxVertexColor, 1.0)}
            stroke="#696969"
            strokeWidth={3}
            draggable
            onMouseEnter={handleCircleMouseOver}
            onMouseLeave={handleCircleMouseOut}
            onDragMove={handlePointDragMove}
            dragBoundFunc={(pos) =>
              pointDragBoundFunc(stage.width(), stage.height(), vertexRadiusT, pos)
            }
            {...startPointAttr}
          />
        );
      })}
    </Group>
  ) : null;
};

export default EditBbox;
