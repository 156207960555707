import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useMainMenu = (organizationId, projectId) => {
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadPopOverOpen, setIsDownloadPopOverOpen] = useState(false);
  const [isFinalizingPopOverOpen, setIsFinalizingPopOverOpen] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  useEffect(() => {}, []);

  const onCloseDocument = () => {
    navigate(`/organizations/${organizationId}/projects/${projectId}`);
  };

  return {
    isSaving,
    setIsSaving,
    isFinalizing,
    setIsFinalizing,
    isDownloading,
    setIsDownloading,
    isDownloadPopOverOpen,
    setIsDownloadPopOverOpen,
    isFinalizingPopOverOpen,
    setIsFinalizingPopOverOpen,
    onCloseDocument,
    isPreviewLoading,
    setIsPreviewLoading,
  };
};

export default useMainMenu;
