import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteOutline } from 'react-icons/md';

/**
 * Renders the delete all button for black- and whitelists
 */
const ListingRemoveAllButton = ({ removeAllListItems }) => {
  const { t } = useTranslation();
  const [isRemoving, setIsRemoving] = React.useState(false);

  const onRemoveAll = async () => {
    setIsRemoving(true);
    await removeAllListItems();
    setIsRemoving(false);
  };

  return (
    <Popover closeOnBlur>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              size="sm"
              leftIcon={isRemoving ? <Spinner size="sm" /> : <MdDeleteOutline />}
              colorScheme="red"
            >
              <Text>{t('settings.listing.removeAllItems')}</Text>{' '}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="medium">
              {t('settings.listing.removeAllItemsPopoverHeader')}
            </PopoverHeader>
            <PopoverBody>{t('settings.listing.removeAllItemsPopoverBody')}</PopoverBody>
            <PopoverFooter d="flex" justifyContent="flex-end">
              <Button
                size="sm"
                onClick={() => {
                  onRemoveAll();
                  onClose();
                }}
                colorScheme="red"
              >
                Ok
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ListingRemoveAllButton;
