import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSystemPublicFeatures,
  fetchPublicAvailableFeatures,
  selectPublicAvailableFeatures,
  selectPublicAvailableFeaturesStatus,
} from '../reducers/systemPublicFeaturesSlice';

/**
 * Hook which provides the public configuration of the application as well as the current version
 */
const useSystemPublicFeatures = () => {
  const dispatch = useDispatch();

  const [availablePublicFeatures, setAvailablePublicFeatures] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const slicePublicAvailableFeatures = useSelector(selectPublicAvailableFeatures);
  const fetchStatus = useSelector(selectPublicAvailableFeaturesStatus);

  const initSlice = () => {
    dispatch(fetchPublicAvailableFeatures()).unwrap();
  };

  const transformSliceData = () => {
    setIsLoading(true);
    if (fetchStatus === 'succeeded') {
      setAvailablePublicFeatures(slicePublicAvailableFeatures);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clear system features state when unmounting
      dispatch(clearSystemPublicFeatures());
    };
  }, []);

  useEffect(() => {
    transformSliceData();
  }, [slicePublicAvailableFeatures]);

  return {
    availablePublicFeatures,
    isLoading,
  };
};

export default useSystemPublicFeatures;
