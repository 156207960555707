import {
  Center,
  FormErrorMessage,
  SimpleGrid,
  Tag,
  TagLabel,
  TagRightIcon,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai';
import { GoPrimitiveDot } from 'react-icons/go';
import { renameObjectKey } from '../../../../../services/utils';
import TextLabelSettings from './TextLabelSettings';

/**
 * The Text Label settings.
 * Input validation prevents the user from closing the settings and synchronizing the changes in the store with the database.
 * The change of a valid text label name is synchronized with the database
 * as soon as the user leaves the input field (onBlur() event)
 * Removing all marks with the same text label also directly updates the annotations in the store.
 * Deleting and adding text labels immediatly trigger synchronization with the database.
 *
 */
const TextLabelsSettings = ({
  setCanClose,
  textLabels,
  addTextLabel,
  hasTextLabelExtendedReplacement,
  textLabelHiddenColor,
  changeTextLabelName,
  changeExtendedReplacement,
  setTextLabelAttribute,
  deleteTextLabel,
  allDateLocales,
}) => {
  const { t } = useTranslation();

  const [nameInputByTextLabelName, setNameInputByTextLabelName] = useState({});
  const [activeTextLabelName, setActiveTextLabelName] = useState(Object.keys(textLabels)[0]);
  const nameValidityByTextLabelName = useRef(null);

  /*
  Name input handling and validation:
  Prevents the user from saving and displays an error whenever:
      - input is empty
      - name is already used
  */
  useEffect(() => {
    const initnameValidityByTextLabelName = {};
    const initnameInputByTextLabelName = {};
    Object.keys(textLabels).forEach((labelName) => {
      initnameValidityByTextLabelName[labelName] = {
        valid: true,
        formMessage: null,
      };
      initnameInputByTextLabelName[labelName] = labelName;
    });
    setNameInputByTextLabelName(initnameInputByTextLabelName);
    nameValidityByTextLabelName.current = initnameValidityByTextLabelName;
  }, [textLabels]);

  /**
   * Validates the name input and sets a flag and a error message.
   * The flag prevents closing the settings and storing the value to the database.
   *
   * @param {string} labelName
   * @param {string} name
   */
  const setIsNameValid = (labelName, name) => {
    if (name === '') {
      nameValidityByTextLabelName.current[labelName] = {
        valid: false,
        formMessage: <FormErrorMessage>{t('settings.textLabels.isRequired')}</FormErrorMessage>,
      };
    } else if (
      Object.keys(textLabels).find(
        (otherTagName) => otherTagName !== labelName && otherTagName === name,
      )
    ) {
      nameValidityByTextLabelName.current[labelName] = {
        valid: false,
        formMessage: <FormErrorMessage>{t('settings.textLabels.alreadyUsed')}</FormErrorMessage>,
      };
    } else {
      nameValidityByTextLabelName.current[labelName] = {
        valid: true,
        formMessage: null,
      };
    }
  };

  /**
   * Handles changes in the name field triggered by the keyboard presses.
   * Limits the length of the name field to 6 chars.
   * Additionally it initiates the validation of the input.
   *
   * @param {string} labelName
   * @param {string} newName The keyboard input
   */
  const handleChangeName = (labelName, newName) => {
    const editedName = newName.slice(0, 6);
    setIsNameValid(labelName, editedName);
    setNameInputByTextLabelName({
      ...nameInputByTextLabelName,
      [labelName]: editedName,
    });
  };

  /*
  Default Replacement input validation.
  Prevents the user from saving the changes to the database
  and displays an error whenever:
      - input is empty
      - defaultReplacement is already used
  */
  const defaultReplacementValidityByTagName = useRef(null);
  useEffect(() => {
    const initDefaultReplacementValidityByTagName = {};
    Object.keys(textLabels).forEach((labelName) => {
      initDefaultReplacementValidityByTagName[labelName] = {
        valid: true,
        formMessage: null,
      };
    });
    defaultReplacementValidityByTagName.current = initDefaultReplacementValidityByTagName;
  }, []);

  /**
   * Updates the labelName in the state variables
   *
   * @param {string} oldTagName
   * @param {string} newTagName
   */
  const updateLabelNameInStateVariables = (oldTagName, newTagName) => {
    // update the labelName in this component
    const refs = [nameValidityByTextLabelName, defaultReplacementValidityByTagName];
    const tmp = refs;
    refs.forEach((ref, index) => {
      tmp[index].current = renameObjectKey(ref.current, oldTagName, newTagName);
    });

    setNameInputByTextLabelName(renameObjectKey(nameInputByTextLabelName, oldTagName, newTagName));
  };

  /**
   * Saves a valid new name of a text label to the store and databse.
   *
   * @param {string} labelName
   */
  const handleSaveName = (labelName) => {
    const newName = nameInputByTextLabelName[labelName];
    if (labelName !== newName && nameValidityByTextLabelName.current[labelName].valid) {
      // update state variables in this component
      updateLabelNameInStateVariables(labelName, newName);
      changeTextLabelName(labelName, newName);
      setActiveTextLabelName(newName);
    }
  };
  /* */

  /**
   * Validates the default pseudonym and sets a flag and a error message.
   * The flag prevents closing the settings and storing the value to the database.
   *
   * @param {string} labelName
   * @param {string} defaultReplacement
   */
  const setIsDefaultReplacementValid = (labelName, defaultReplacement) => {
    if (defaultReplacement === '') {
      defaultReplacementValidityByTagName.current[labelName] = {
        valid: false,
        formMessage: <FormErrorMessage>{t('settings.textLabels.isRequired')}</FormErrorMessage>,
      };
    } else if (
      Object.entries(textLabels).find(
        ([otherTagName, otherTag]) =>
          otherTagName !== labelName && otherTag.defaultReplacement === defaultReplacement,
      )
    ) {
      defaultReplacementValidityByTagName.current[labelName] = {
        valid: false,
        formMessage: <FormErrorMessage>{t('settings.textLabels.alreadyUsed')}</FormErrorMessage>,
      };
    } else {
      defaultReplacementValidityByTagName.current[labelName] = {
        valid: true,
        formMessage: null,
      };
    }
  };

  /**
   * Changes a text label's default pseudonym and sets its validation.
   *
   * @param {string} labelName
   * @param {string} defaultReplacement
   */
  const handleChangeDefaultReplacement = (labelName, defaultReplacement) => {
    setIsDefaultReplacementValid(labelName, defaultReplacement);
    setTextLabelAttribute(labelName, 'defaultReplacement', defaultReplacement);
  };

  const handleDelete = (labelName) => {
    // set active Tag to left neighbor if possible, else to right neighbor, else to none
    const activeTagIndex = Object.keys(textLabels).findIndex((tagName) => tagName === labelName);
    const isNotFirstLabel = activeTagIndex !== 0;
    const isNotLastLabel = activeTagIndex !== Object.keys(textLabels).length - 1;
    if (isNotFirstLabel) {
      setActiveTextLabelName(Object.keys(textLabels)[activeTagIndex - 1]);
    } else if (isNotLastLabel) {
      setActiveTextLabelName(Object.keys(textLabels)[activeTagIndex + 1]);
    } else {
      setActiveTextLabelName(null);
    }

    // remove labelName from nameInput and nameValidity objects
    delete nameValidityByTextLabelName.current[labelName];
    delete defaultReplacementValidityByTagName.current[labelName];
    const newnameInputByTextLabelName = { ...nameInputByTextLabelName };
    delete newnameInputByTextLabelName[labelName];
    setNameInputByTextLabelName(newnameInputByTextLabelName);

    deleteTextLabel(labelName);
  };

  /**
   * Creates a new unique text label name.
   * @returns the new text label name
   */
  const generateUniqueTextLabelName = () => {
    if (
      !Object.keys(textLabels).find(
        (labelName) => labelName === t('settings.textLabels.newTextLabel'),
      )
    ) {
      return t('settings.textLabels.newTextLabel');
    }
    // append a unique number to NewTag
    for (let i = 1; i < Object.keys(textLabels).length + 1; i += 1) {
      if (
        !Object.keys(textLabels).find(
          (labelName) => labelName === `${t('settings.textLabels.newTextLabel')}-${i}`,
        )
      ) {
        return `${t('settings.textLabels.newTextLabel')}-${i}`;
      }
    }
    return null;
  };

  /**
   * Handles adding a new text label
   */
  const handleAddTextLabel = () => {
    const labelName = generateUniqueTextLabelName(textLabels);
    nameValidityByTextLabelName.current[labelName] = {
      valid: true,
      formMessage: null,
    };
    defaultReplacementValidityByTagName.current[labelName] = {
      valid: true,
      formMessage: null,
    };

    setNameInputByTextLabelName({ ...nameInputByTextLabelName, [labelName]: labelName });
    addTextLabel(labelName);
    setActiveTextLabelName(labelName);
  };

  /*
    We use an useEffect hook here to disable the setting's canSave button.
    Doing it directly in the handler e.g. handling name change would result
    in a warning and the button would not update.
  */
  useEffect(() => {
    setCanClose(
      !Object.values(nameValidityByTextLabelName.current).find((value) => !value.valid) &&
        !Object.values(defaultReplacementValidityByTagName.current).find((value) => !value.valid),
    );
  }, [
    textLabels,
    setCanClose,
    nameValidityByTextLabelName,
    defaultReplacementValidityByTagName,
    nameInputByTextLabelName,
  ]);

  return (
    <>
      <SimpleGrid columns={9} spacing={1}>
        {Object.entries(textLabels).map(([labelName, textLabel]) => {
          if (textLabel.isHidden) {
            return (
              <Tooltip hasArrow label={textLabel.description} key={`TagSettings-Tag-${labelName}`}>
                <Tag
                  bg={activeTextLabelName === labelName ? textLabelHiddenColor : null}
                  color={activeTextLabelName === labelName ? 'white' : textLabelHiddenColor}
                  onClick={() => setActiveTextLabelName(labelName)}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  {labelName}
                  {hasTextLabelExtendedReplacement(textLabel) ? (
                    <TagRightIcon as={GoPrimitiveDot} size="2px" />
                  ) : null}
                </Tag>
              </Tooltip>
            );
          }
          return (
            <Tooltip hasArrow label={textLabel.description} key={`TagSettings-Tag-${labelName}`}>
              <Tag
                bg={activeTextLabelName === labelName ? textLabel.color : 'white'}
                color={activeTextLabelName === labelName ? 'white' : textLabel.color}
                borderWidth={activeTextLabelName === labelName ? 0 : 1}
                borderColor={activeTextLabelName === labelName ? null : textLabel.color}
                onClick={() => setActiveTextLabelName(labelName)}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {labelName}
                {hasTextLabelExtendedReplacement(textLabel) ? (
                  <TagRightIcon as={GoPrimitiveDot} size="2px" />
                ) : null}
              </Tag>
            </Tooltip>
          );
        })}
        <Tag
          onClick={handleAddTextLabel}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <TagLabel>
            <Center>
              <AiOutlinePlus />
            </Center>
          </TagLabel>
        </Tag>
      </SimpleGrid>

      {activeTextLabelName && (
        <TextLabelSettings
          textLabel={textLabels[activeTextLabelName]}
          nameValidityByTextLabelName={nameValidityByTextLabelName}
          nameInputByTextLabelName={nameInputByTextLabelName}
          defaultReplacementValidityByTagName={defaultReplacementValidityByTagName}
          setCanClose={setCanClose}
          changeName={(value) => handleChangeName(activeTextLabelName, value)}
          onBlurChangeName={() => handleSaveName(activeTextLabelName)}
          onDelete={() => handleDelete(activeTextLabelName)}
          changeDefaultReplacement={(value) =>
            handleChangeDefaultReplacement(activeTextLabelName, value)
          }
          changeDescription={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'description', value)
          }
          changeIsHidden={(value) => setTextLabelAttribute(activeTextLabelName, 'isHidden', value)}
          changePartialReplacementStart={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'partialReplacementStart', value)
          }
          changePartialReplacementStop={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'partialReplacementStop', value)
          }
          changeUseNumericPartialReplacement={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'useNumericPartialReplacement', value)
          }
          changeReplaceDay={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'replaceDay', value)
          }
          changeReplaceMonth={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'replaceMonth', value)
          }
          changeReplaceYear={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'replaceYear', value)
          }
          changeShowWeekday={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'showWeekday', value)
          }
          changeDateLocale={(value) =>
            setTextLabelAttribute(activeTextLabelName, 'dateLocale', value)
          }
          changeExtendedReplacement={(value) =>
            changeExtendedReplacement(activeTextLabelName, value)
          }
          allDateLocales={allDateLocales}
        />
      )}
    </>
  );
};

export default TextLabelsSettings;
