import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDocuments,
  disablePreprocessingDB,
  downloadAnonymizedDocument,
  downloadAnonymizedDocuments,
  enablePreprocessingDB,
  fetchDocumentsDB,
  priorizePreprocessingDB,
  removeDocument,
  removeDocuments,
  resetDocumentDB,
  selectDocumentFirstPage,
  selectDocuments,
  selectDocumentsError,
  selectDocumentsOffset,
  selectDocumentsPage,
  selectDocumentsStatus,
  selectDocumentsTotalElements,
  setArchived,
  setFinalized,
  setUnarchived,
  setUnfinalized,
  uploadDocumentDB,
} from '../reducers/documentsSlice';
import {
  acceptedExtensions,
  changeProcessingStatusOfDocument,
  generateSortArray,
} from '../services/documentService';
import { processingStatus } from '../types/processingStatus';

const useDocuments = (projectId) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const sliceDocuments = useSelector(selectDocuments);
  const totalElements = useSelector(selectDocumentsTotalElements);
  const currentPage = useSelector(selectDocumentsPage);
  const documentsOffset = useSelector(selectDocumentsOffset);
  const isFirstPage = useSelector(selectDocumentFirstPage);
  const fetchStatus = useSelector(selectDocumentsStatus);
  const fetchError = useSelector(selectDocumentsError);

  /* Filter States */
  const [searchValue, setSearchValue] = useState('');
  const [fromDateValue, setFromDateValue] = useState('');
  const [toDateValue, setToDateValue] = useState('');
  const [documentStatusValue, setDocumentStatusValue] = useState(null);
  const [documentTagValue, setDocumentTagValue] = useState(null);

  /* Sort States */
  // Sort states can have the following values: 'asc', 'desc', ''
  const [sortName, setSortName] = useState('');
  const [sortLastModified, setSortLastModified] = useState('desc'); // default sort direction
  const [sortStatus, setSortStatus] = useState('');
  const [sortDocumentTagValue, setSortDocumentTagValue] = useState('');

  const defaultPageSize = 10;

  const initSlice = async () => {
    setIsLoading(true);
    // Fetch the documents with default params
    await dispatch(
      fetchDocumentsDB({
        projectId,
        name: '',
        status: '',
        fromDate: '',
        toDate: '',
        page: 0,
        size: defaultPageSize,
        sort: ['lastModified,desc'], // default sort direction
        tagKeyValues: [],
      }),
    ).unwrap();
  };

  const transformSliceData = () => {
    if (fetchStatus === 'pending') {
      setIsLoading(true);
    }
    if (fetchStatus === 'succeeded') {
      setDocuments(sliceDocuments);
      setIsLoading(false);
    }
    if (fetchStatus === 'failed') {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clears documents state when component unmounts
      dispatch(clearDocuments());
    };
  }, []);

  useEffect(() => {
    transformSliceData();
  }, [sliceDocuments]);

  /**
   * Fetches the documents with given parameters
   * @param {number} parameters.page The page number
   * @param {string} parameters.status The status of the documents
   * @param {string} parameters.fromDate The date from which the documents were edited
   * @param {string} parameters.toDate The date to which the documents were edited
   * @param {Object} parameters.sort The sort object with the following properties:
   * - `sortNameDir` The sort direction of the name
   * - `sortLastModifiedDir` The sort direction of the last modified date
   * - `sortStatusDir` The sort direction of the status
   * @param {Object} parameters.tagKeyValues The tag key values to filter the documents
   */
  const fetchDocumentsPaged = async ({ page, status, fromDate, toDate, sort, tagKeyValues }) => {
    setIsLoading(true);

    // Build the sort array with the given sort parameters
    const { sortNameDir, sortLastModifiedDir, sortStatusDir, sortDocumentTagValueDir } = sort || {};

    // If one of the sort states are undefined, because the user changed another sort direction, the state should not be changed
    const sortArray = generateSortArray([
      { name: 'original', direction: sortNameDir === undefined ? sortName : sortNameDir },
      {
        name: 'lastModified',
        direction: sortLastModifiedDir === undefined ? sortLastModified : sortLastModifiedDir,
      },
      {
        name: 'documentTags',
        direction:
          sortDocumentTagValueDir === undefined ? sortDocumentTagValue : sortDocumentTagValueDir,
      },
      {
        name: 'processingStatus',
        direction: sortStatusDir === undefined ? sortStatus : sortStatusDir,
      },
    ]);

    /*
     * Create temporary tagKeyValues array to handle if another filter is set and the tagKeyValues remain the same
     * as well as if the tagKeyValues for filtering are empty
     */
    let tagKeyValuesTmp = tagKeyValues;
    if (tagKeyValues === undefined) {
      tagKeyValuesTmp = documentTagValue;
    } else if (tagKeyValues === null || tagKeyValues.length === 0) {
      tagKeyValuesTmp = undefined;
    }

    const options = {
      projectId,
      name: searchValue,
      // If the status is undefined, because the user changed another filter, the status should not be changed
      // If the status is set to "all status" (value: ALL) then the status value is an empty string
      status: status === undefined ? documentStatusValue : status,
      fromDate: fromDate || fromDateValue,
      toDate: toDate || toDateValue,
      page,
      size: defaultPageSize,
      sort: sortArray,
      tagKeyValues: tagKeyValuesTmp,
    };
    await dispatch(fetchDocumentsDB(options)).unwrap();
    setIsLoading(false);
  };

  /**
   * Changes the page by fetching the documents with given page number
   * @param {number} page The page new number
   */
  const changePage = async (page) => {
    fetchDocumentsPaged({
      page,
    });
  };

  /**
   * Filters the documents by fetching the documents with given parameters
   * @param {Object} filter - The filter object with the following properties:
   * - `status` The status of the documents
   * - `fromDate` The date from which the documents were edited
   * - `toDate` The date to which the documents were edited
   */
  const filterDocuments = (filter) => {
    const { status, fromDate, toDate, tagKeyValues } = filter || {};
    fetchDocumentsPaged({ status, fromDate, toDate, tagKeyValues });
  };

  // TODO: outsourcing UI logic in component, other logic in service
  // TODO: try catch and AppToaster + function call in this function. token not here!
  const onDownloadDocument = async (documentId) => {
    await downloadAnonymizedDocument(projectId, documentId);
  };

  const onDownloadDocuments = async (documentIds) => {
    await downloadAnonymizedDocuments(projectId, documentIds);
  };

  /**
   * Handles the deletion of the selected documents with given ids
   * @param {List[number]} documentIds The ids of the documents that should be deleted
   * @param {number} page The page number
   */
  const onDeleteDocuments = async (documentIds, page) => {
    if (documentIds.length === 1) {
      await dispatch(removeDocument({ projectId, documentId: documentIds[0] })).unwrap();
    } else {
      await dispatch(removeDocuments({ projectId, documentIds })).unwrap();
    }
    await fetchDocumentsPaged({ page });
  };

  /**
   * Handles the upload of an array of files (dropzone or click the upload button)
   * @param {List[File]} files array of files to be uploaded
   * @param {number} page current page the user is on while performing the action
   */
  const onUploadFiles = async (files, page) => {
    try {
      await Promise.all(
        // convert the list of files to an array so it is iterable

        Array.from(files).map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);

          // Upload formData to the db
          await dispatch(uploadDocumentDB({ projectId, formData })).unwrap();
        }),
      );
    } catch (err) {
      throw new Error('Error while uploading files');
    } finally {
      await fetchDocumentsPaged({ page });
    }
  };

  /**
   * Handles the reset of a document with given id
   * @param {number} documentId
   * @param {number} page current page the user is on while performing the action
   */
  const onResetDocument = async (documentId, page) => {
    await dispatch(resetDocumentDB({ projectId, documentId })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Sets the state of a document to archived
   * @param {number} projectId
   * @param {number} documentId
   * @param {number} page current page the user is on while performing the action
   */
  const onArchiveDocument = async (documentId, page) => {
    await dispatch(setArchived({ projectId, documentId })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Sets the state of a document to unarchived
   * @param {number} projectId
   * @param {number} documentId
   * @param {number} page current page the user is on while performing the action
   */
  const onUnarchiveDocument = async (documentId, page) => {
    await dispatch(setUnarchived({ projectId, documentId })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Unfinalize a document for editing mode
   * @param {number} documentId
   * @param {number} page current page the user is on while performing the action
   */
  const onUnfinalizeDocument = async (documentId, page) => {
    await dispatch(setUnfinalized({ projectId, documentId })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Priorizes the preprocessing of a document with given id
   * @param {number} documentId
   * @param {number} page current page the user is on while performing the action
   */
  const onPriorizePreprocessing = async (documentId, page) => {
    await dispatch(priorizePreprocessingDB({ projectId, documentId })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Handles the click on the disable button
   * @param {number} documentId The id of the document that should be disabled
   * @param {number} page current page the user is on while performing the action
   */
  const onDisableDocument = async (documentId, page) => {
    await dispatch(disablePreprocessingDB({ projectId, documentId })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Handles the click on the enable button
   * @param {number} documentId The id of the document that should be enabled
   * @param {number} page current page the user is on while performing the action
   */
  const onEnableDocument = async (documentId, page) => {
    await dispatch(enablePreprocessingDB({ projectId, documentId })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Sets a document to the finalized state
   * @param {number} documentId
   * @param {number} page current page the user is on while performing the action
   */
  const onFinalizeDocument = async (documentId, page) => {
    await dispatch(setFinalized({ projectId, documentId, finalizedStatus: true })).unwrap();
    await fetchDocumentsPaged({ page });
  };

  /**
   * Updates the processing status of a document
   * @param {number} documentId Id of the document
   * @param {string} status The new processing status of the document
   */
  const updateDocumentProcessingStatus = (documentId, status) => {
    const newDocuments = changeProcessingStatusOfDocument(documents, documentId, status);
    setDocuments(newDocuments);
  };

  /**
   * Checks wether a document is processed by the preprocessor
   * @param {object} document
   * @returns {boolean} true when document is preprocessed
   */
  const isDocumentPreprocessed = (document) => {
    return (
      document.processingStatus === processingStatus.COMPLETED ||
      document.processingStatus === processingStatus.FINALIZED
    );
  };

  /**
   * Checks wether a document is finalized
   * @param {object} document
   * @returns {boolean} true when the document is finalized
   */
  const isDocumentFinalized = (document) => {
    return document.processingStatus === processingStatus.FINALIZED;
  };

  return {
    isLoading,
    documents,
    acceptedExtensions,
    totalElements,
    currentPage,
    documentsOffset,
    isFirstPage,
    searchValue,
    setSearchValue,
    fromDateValue,
    setFromDateValue,
    toDateValue,
    setToDateValue,
    documentStatusValue,
    setDocumentStatusValue,
    sortName,
    setSortName,
    sortLastModified,
    setSortLastModified,
    sortStatus,
    setSortStatus,
    changePage,
    initDocuments: initSlice, // TODO: Remove after legacy code (DocumentSelection.jsx) is removed
    fetchDocumentsPaged,
    filterDocuments,
    onDownloadDocument,
    onDownloadDocuments,
    onDeleteDocuments,
    onUploadFiles,
    onResetDocument,
    onUnfinalizeDocument,
    onPriorizePreprocessing,
    onDisableDocument,
    onEnableDocument,
    onFinalizeDocument,
    isDocumentPreprocessed,
    isDocumentFinalized,
    updateDocumentProcessingStatus,
    documentTagValue,
    setDocumentTagValue,
    sortDocumentTagValue,
    setSortDocumentTagValue,
    onArchiveDocument,
    onUnarchiveDocument,
  };
};

export default useDocuments;
