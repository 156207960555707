import sortBy from 'lodash.sortby';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDocumentDataStatus, selectPageData } from '../reducers/documentDataSlice';
import { createCrIdForNewAnnotation } from '../services/coreferenceService';
import { getUpdatedHistory } from '../services/historyService';
import { createPseudonymFromAnnotation } from '../services/pseudonymizationService';
import {
  addParagraphAnnotations,
  changeCrIdAndPseudonymOfMatchingAnnotations,
  changeCrIdOfMatchingAnnotations,
  changeTextLabelNameOfMatchingAnnotations,
  createAnnotationFromMark,
  deleteAllMatchingAnnotations,
  findAndAnnotateAllUnmarked,
  removeParagraphAnnotations,
} from '../services/textDataService';
import { transformTextLabelsObjectToArray } from '../services/textLabelService';
import { deepCopy, setItem2DArray } from '../services/utils';
import { createAnnotation } from '../types/annotation';
import { createToken } from '../types/token';

const useTextData = (documentId) => {
  // ------------- SLICE ACCESS
  const pageData = useSelector(selectPageData);
  const fetchStatus = useSelector(selectDocumentDataStatus);

  // ------------- STATES

  const [paragraphs, setParagraphs] = useState([]);
  const [annotations, setAnnotations] = useState(null);
  const [predictedSentenceStarts, setPredictedSentenceStarts] = useState([]);
  const [sentenceStartIdsToRemove, setSentenceStartIdsToRemove] = useState([]);
  const [hasCrIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oldDocumentId, setOldDocumentId] = useState(null);

  const history = useRef([]); // holds the history of the annotations and sentence starts (for undo/redo)
  const [currentHistoryIdx, setCurrentHistoryIdx] = useState(0);
  const undoActionName = history.current[currentHistoryIdx]?.prevAction;
  const redoActionName = history.current[currentHistoryIdx + 1]?.prevAction;

  /**
   * Resets the history to the given initial state
   * @param {*} initSentenceStartIdsToRemove The initial sentence start Ids to remove
   * @param {*} initAnnotations The initial annotations
   */
  const resetHistory = (initSentenceStartIdsToRemove, initAnnotations) => {
    // reset history
    history.current = [
      {
        sentenceStartIdsToRemove: initSentenceStartIdsToRemove,
        annotations: initAnnotations,
        prevAction: null,
      },
    ];
    setCurrentHistoryIdx(0);
    setOldDocumentId(documentId);
  };

  /**
   * Transforms the sliceData TextData to different states (paragraphs and annotations) usable in components
   */
  const transformSliceData = () => {
    const initParagraphs = [];
    const initAnnotations = [];
    const initSentenceStarts = [];
    const initSentenceStartIdsToRemove = [];

    setIsLoading(true);
    let paragraphStart = 0;

    // set paragraphs, annotations and sentence starts
    pageData.forEach((page) => {
      const { textData } = page;
      const paragraphId = page.pageNum;
      if (!textData || !textData.detections) {
        return;
      }
      const tokens = textData.detections.tokens.map(
        (token) =>
          createToken(token.startChar, token.endChar, token.text, token.hasWs, token.brCount), // Currently in token.js
      );
      initParagraphs.push({
        htmlProps: {},
        tokens,
        id: paragraphId,
        start: paragraphStart,
      });
      const paragraphAnnotations = textData.detections.ents.map((ent, entIndex) => {
        const pseudonym = textData.detections.pseudonyms[entIndex];
        const crId = textData.detections.crIds[entIndex];
        const locationInOriginalDocument =
          textData.detections.locationsInOriginalDocument?.[entIndex];
        const locationInAnonymizedDocument =
          textData.detections.locationInAnonymizedDocument?.[entIndex];

        return createAnnotation({
          start: ent.startTok,
          end: ent.endTok,
          startChar: tokens[ent.startTok].startChar,
          endChar: tokens[ent.endTok].endChar,
          textLabelName: ent.tag,
          text: ent.text,
          paragraphId,
          pseudonym,
          score: ent.score,
          documentStart: paragraphStart + ent.startTok,
          documentEnd: paragraphStart + ent.endTok,
          crId,
          locationInOriginalDocument,
          locationInAnonymizedDocument,
        });
      });
      paragraphStart += tokens.length;
      initAnnotations.push(paragraphAnnotations);
      initSentenceStarts.push([...textData.detections.sentenceStarts]);
      initSentenceStartIdsToRemove.push([]);
    });

    setIsLoading(false);

    return {
      initParagraphs,
      initAnnotations,
      initSentenceStarts,
      initSentenceStartIdsToRemove,
    };
  };

  useEffect(() => {
    if (fetchStatus === 'succeeded') {
      // transform data
      const { initParagraphs, initAnnotations, initSentenceStarts, initSentenceStartIdsToRemove } =
        transformSliceData();
      setParagraphs(initParagraphs);
      setAnnotations(initAnnotations);
      setPredictedSentenceStarts(initSentenceStarts);
      setSentenceStartIdsToRemove(initSentenceStartIdsToRemove);

      if (documentId !== oldDocumentId && initParagraphs.length > 0) {
        resetHistory(initSentenceStartIdsToRemove, initAnnotations);
      }
    }
  }, [pageData, documentId]);

  /**
   * Updates the history and the current state
   * @param {*} action The action that was performed
   * @param {*} newAnnotations The new annotations
   * @param {*} newSentenceStartIdsToRemove The new sentence start Ids to remove
   */
  const updateAnnotationsHistory = (
    action,
    newAnnotations,
    newSentenceStartIdsToRemove = sentenceStartIdsToRemove,
  ) => {
    const { newHistory, newCurrentHistoryIdx } = getUpdatedHistory(
      history.current,
      {
        sentenceStartIdsToRemove: newSentenceStartIdsToRemove,
        annotations: newAnnotations,
      },
      currentHistoryIdx,
      action,
    );
    history.current = newHistory;
    setCurrentHistoryIdx(newCurrentHistoryIdx);
    setAnnotations(newAnnotations);
    setSentenceStartIdsToRemove(newSentenceStartIdsToRemove);
  };

  /**
   * Undo the last action by setting the state to the previous state saved in the history
   */
  const undo = () => {
    if (currentHistoryIdx > 0) {
      const newCurrentHistoryIdx = currentHistoryIdx - 1;
      const {
        annotations: prevAnnotations,
        sentenceStartIdsToRemove: prevSentenceStartIdsToRemove,
      } = history.current[newCurrentHistoryIdx];
      setAnnotations(prevAnnotations);
      setSentenceStartIdsToRemove(prevSentenceStartIdsToRemove);
      setCurrentHistoryIdx(newCurrentHistoryIdx);
    }
  };

  /**
   * Redo the last action by setting the state to the next state saved in the history
   */
  const redo = () => {
    if (currentHistoryIdx < history.current.length - 1) {
      const newCurrentHistoryIdx = currentHistoryIdx + 1;
      const {
        annotations: nextAnnotations,
        sentenceStartIdsToRemove: nextSentenceStartIdsToRemove,
      } = history.current[newCurrentHistoryIdx];
      setAnnotations(nextAnnotations);
      setSentenceStartIdsToRemove(nextSentenceStartIdsToRemove);
      setCurrentHistoryIdx(newCurrentHistoryIdx);
    }
  };

  // annotation functions
  const addAnnotation = (paragraphIdx, start, end, textLabelName, textLabel) => {
    const newAnnotation = createAnnotationFromMark(
      paragraphs[paragraphIdx],
      start,
      end,
      textLabelName,
    );
    const { newAnnotations, newSentenceStartIdsToRemove, annotationIdx } = addParagraphAnnotations(
      paragraphIdx,
      annotations,
      predictedSentenceStarts,
      sentenceStartIdsToRemove,
      [newAnnotation],
    );

    newAnnotation.crId = createCrIdForNewAnnotation(newAnnotations, paragraphIdx, annotationIdx);
    newAnnotation.pseudonym = createPseudonymFromAnnotation(newAnnotation, textLabel);
    newAnnotations[paragraphIdx][annotationIdx] = newAnnotation;

    updateAnnotationsHistory('addAnnotation', newAnnotations, newSentenceStartIdsToRemove);
    return newAnnotation;
  };

  const removeAllAnnotationsWithTextLabel = (textLabelName) => {
    const { newAnnotations } = deleteAllMatchingAnnotations(
      annotations,
      predictedSentenceStarts,
      sentenceStartIdsToRemove,
      (annotation) => annotation.textLabelName === textLabelName,
    );
    updateAnnotationsHistory('removeAllAnnotationsWithTextLabel', newAnnotations);
  };

  const removeAllAnnotationsWithSameTextLabelAndText = (annotation) => {
    const { newAnnotations } = deleteAllMatchingAnnotations(
      annotations,
      predictedSentenceStarts,
      sentenceStartIdsToRemove,
      (otherAnnotation) =>
        otherAnnotation.text === annotation.text &&
        otherAnnotation.textLabelName === annotation.textLabelName,
    );
    updateAnnotationsHistory('removeAllAnnotationsWithSameTextLabelAndText', newAnnotations);
  };

  const removeAllAnnotationsWithSameText = (annotation) => {
    const { newAnnotations } = deleteAllMatchingAnnotations(
      annotations,
      predictedSentenceStarts,
      sentenceStartIdsToRemove,
      (otherAnnotation) => otherAnnotation.text === annotation.text,
    );

    updateAnnotationsHistory('removeAllAnnotationsWithSameText', newAnnotations);
  };

  const annotateAllUnmarked = (annotation) => {
    const addedAnnotations = findAndAnnotateAllUnmarked(
      annotations,
      paragraphs,
      annotation.text,
      annotation.textLabelName,
      annotation.pseudonym,
      annotation.score,
      annotation.crId,
    );

    // Deep copies the states because we manipulate the states inside of addParagraphAnnotations()
    let newAnnotations = deepCopy(annotations);
    let newSentenceStartIdsToRemove = deepCopy(sentenceStartIdsToRemove);
    addedAnnotations.forEach((addedParagraphAnnotations, paragraphIndex) => {
      const added = addParagraphAnnotations(
        paragraphIndex,
        newAnnotations,
        predictedSentenceStarts,
        newSentenceStartIdsToRemove,
        addedParagraphAnnotations,
      );
      newAnnotations = added.newAnnotations;
      newSentenceStartIdsToRemove = added.newSentenceStartIdsToRemove;
    });
    updateAnnotationsHistory('annotateAllUnmarked', newAnnotations, newSentenceStartIdsToRemove);
  };

  /**
   * Sets the coreference id and the pseudonym of the annotation with the given `annotationIdx`
   *
   * @param {*} paragraphIdx The paragraphIdx of the annotation
   * @param {*} annotationIdx The idx of the annoation inside the paragraph
   * @param {*} crId The coreference Id
   * @returns
   */
  const setCrIdAndPseudonymOfAnnotation = (paragraphIdx, annotationIdx, crId, textLabels) => {
    const annotation = annotations[paragraphIdx][annotationIdx];
    let newAnnotation = { ...annotation, crId };
    const textLabel = textLabels[annotation.textLabelName];
    const pseudonym = createPseudonymFromAnnotation(newAnnotation, textLabel);

    newAnnotation = { ...newAnnotation, pseudonym };
    const newAnnotations = setItem2DArray(annotations, paragraphIdx, annotationIdx, newAnnotation);
    updateAnnotationsHistory('setCrIdAndPseudonymOfAnnotation', newAnnotations);
  };

  const changeTextLabelNameOfAllAnnotationsWithSameText = (annotation, textLabel) => {
    const newAnnotations = changeTextLabelNameOfMatchingAnnotations(
      annotations,
      (otherAnnotation) => otherAnnotation.text === annotation.text,
      textLabel,
    );
    updateAnnotationsHistory('changeTextLabelNameOfAllAnnotationsWithSameText', newAnnotations);
  };

  const changeCrIdOfAllAnnotationsWithSameTextLabelAndText = (annotation) => {
    const newAnnotations = changeCrIdOfMatchingAnnotations(
      annotations,
      (otherAnnotation) =>
        otherAnnotation.text === annotation.text &&
        otherAnnotation.textLabelName === annotation.textLabelName,
      annotation.crId,
      annotation.pseudonym,
    );

    updateAnnotationsHistory('changeCrIdOfAllAnnotationsWithSameTextLabelAndText', newAnnotations);
  };

  const changeTextLabelNameOfAnnotation = (annotation, textLabel) => {
    const newAnnotations = changeTextLabelNameOfMatchingAnnotations(
      annotations,
      (otherAnnotation) => annotation.id === otherAnnotation.id,
      textLabel,
    );

    updateAnnotationsHistory('changeTextLabelNameOfAnnotation', newAnnotations);
  };

  /**
   * Changes the coreference id of the given annotation
   * @param {*} annotation The annotation to change the coreference id of
   * @param {*} crId The new coreference id
   * @param {*} textLabels The text labels, needed s.t. the pseudonym can be created (each label holds the settings for its pseudonymization)
   */
  const changeCrIdOfAnnotation = (annotation, crId, textLabels) => {
    const newAnnotation = { ...annotation, crId };
    const annotationTextLabel = transformTextLabelsObjectToArray(textLabels).find(
      (otherTextLabel) => otherTextLabel.name === annotation.textLabelName,
    );

    const pseudonym = createPseudonymFromAnnotation(newAnnotation, annotationTextLabel);

    const newAnnotations = annotations.map((paragraphAnnotations) => {
      return paragraphAnnotations.map((otherAnnotation) => {
        if (otherAnnotation.id === newAnnotation.id) {
          return { ...newAnnotation, pseudonym };
        }
        return otherAnnotation;
      });
    });

    updateAnnotationsHistory('changeCrIdOfAnnotation', newAnnotations);
  };

  const findAndRemoveAnnotation = (paragraphIndex, start, end) => {
    const paragraphAnnotations = annotations[paragraphIndex];

    // Find and remove the matching split.
    const index = paragraphAnnotations.findIndex((s) => s.start === start && s.end === end);

    if (index >= 0) {
      const { newAnnotations, newSentenceStartIdsToRemove } = removeParagraphAnnotations(
        paragraphIndex,
        annotations,
        predictedSentenceStarts,
        sentenceStartIdsToRemove,
        [index],
      );

      updateAnnotationsHistory(
        'findAndRemoveAnnotation',
        newAnnotations,
        newSentenceStartIdsToRemove,
      );
    }
  };

  const changeTextLabelOfAllAnnotationsWithSameTextLabel = (oldTextLabelName, newTextLabelName) => {
    const newAnnotations = changeTextLabelNameOfMatchingAnnotations(
      annotations,
      (otherAnnotation) => otherAnnotation.textLabelName === oldTextLabelName,
      newTextLabelName,
    );
    // this action is not undoable (used in settings when renaming a text label)
    setAnnotations(newAnnotations);
  };

  const createRenderableTextSegments = (paragraphIndex, textLabels) => {
    const { tokens } = paragraphs[paragraphIndex];
    const paragraphAnnotations = annotations[paragraphIndex];
    let lastEnd = 0;
    const textSegments = [];

    const sortedAnnotations = sortBy(paragraphAnnotations, (o) => o.start);
    sortedAnnotations.forEach((annotation) => {
      if (textLabels[annotation.textLabelName]) {
        const { start, end } = annotation;

        // add all tokens between the previous and the current annotation
        if (lastEnd < start) {
          for (let idx = lastEnd; idx < start; idx += 1) {
            textSegments.push({
              annotation: null,
              idx,
              text: tokens[idx].text,
              hasWhitespace: tokens[idx].hasWhitespace,
            });
          }
        }

        // add the current annotation
        let text = '';
        tokens.slice(start, end + 1).forEach((token) => {
          text += token.text;
          if (token.hasWhitespace) {
            text += ' ';
          }
        });
        text.replace(/\s+$/gm, '');

        textSegments.push({
          annotation,
          text,
          hasWhitespace: tokens[end].hasWhitespace,
        });
        lastEnd = end + 1;
      }
    });

    // add the remaining tokens after the last annotation
    for (let idx = lastEnd; idx < tokens.length; idx += 1) {
      textSegments.push({
        annotation: null,
        idx,
        text: tokens[idx].text,
        hasWhitespace: tokens[idx].hasWhitespace,
      });
    }

    return textSegments;
  };

  return {
    fetchStatus,
    isLoading,
    annotations,
    paragraphs,
    hasCrIds, // TODO: Not needed anymore?
    undoActionName,
    redoActionName,
    predictedSentenceStarts,
    sentenceStartIdsToRemove,
    createRenderableTextSegments,
    annotateAllUnmarked,
    findAndRemoveAnnotation,
    changeTextLabelNameOfAllAnnotationsWithSameText,
    setCrIdAndPseudonymOfAnnotation,
    removeAllAnnotationsWithSameTextLabelAndText,
    removeAllAnnotationsWithSameText,
    addAnnotation,
    removeAllAnnotationsWithTextLabel,
    changeTextLabelNameOfAnnotation,
    changeCrIdOfAnnotation,
    changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
    changeCrIdAndPseudonymOfMatchingAnnotations,
    undo,
    redo,
    changeTextLabelOfAllAnnotationsWithSameTextLabel,
  };
};

export default useTextData;
