import React from 'react';
import DocCapeLogo from '../../../assets/DocCape_Logo_white.png';
/**
 * Special conatainer which has a 50% gradient and a 50% white background
 * This container is used for pages like the login page
 */
const GradientContainer = ({ children }) => {
  return (
    <>
      <img className="absolute left-20 top-8" width="200" src={DocCapeLogo} alt="DocCape" />
      <div className="flex h-1/2 flex-1 flex-col justify-center bg-gradient-to-r from-blue-2 to-white px-6 py-12 lg:px-8" />
      <div className="absolute left-1/2 top-1/2 z-10 w-full -translate-x-1/2 -translate-y-1/2">
        {children}
      </div>
      <div className="h-1/2 bg-white lg:h-1/3" />
    </>
  );
};

export default GradientContainer;
