import React from 'react';

/**
 * The layout of the settings
 * @param {React.ReactNode} settingsSidebar - The sidebar of the settings
 * @param {React.ReactNode} content - The content of the settings
 *
 * @example
 * <SettingsLayout settingsSidebar={<SettingsSidebar
 *                                    navigation={sidebarElements}
 *                                    handleItemClick={handleItemClick}
 *                                    onDelete={handleProjectDeletion}
 *                                    />}
 *                  content={renderSidebarContent()}
 * />
 */
const SettingsLayout = ({ settingsSidebar, content }) => {
  return (
    <div>
      {/* Sidebar */}
      <div className="mx-auto min-h-full max-w-7xl rounded-xl bg-white p-6 pt-4 lg:flex lg:gap-x-16 lg:px-8">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-blue-2/10 bg-white sm:border-b sm:py-10 lg:block lg:w-56 lg:flex-none lg:border-0 lg:border-r">
          {settingsSidebar}
        </div>

        {/* Content of sidebar views */}
        <main className="w-full px-4 py-16 sm:px-6 lg:px-0 lg:py-10">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-4 lg:max-w-none">
            <div>{content}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SettingsLayout;
