import { Button, Center, Flex, TagRightIcon, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoPrimitiveDot } from 'react-icons/go';
import Tag from '../../atoms/Tag';
import Popover from '../../molecules/Popover';

/**
 * Renders a textLabel for a text label
 * @param {Object} props The props of TextLabel
 * @param {string} props.textLabeleName
 * @param {Object} props.textLabel
 */
const TextLabel = ({
  textLabelName,
  textLabel,
  removeAllAnnotationsWithTextLabel,
  hasTextLabelExtendedReplacement,
  selectTextLabelIfValid,
  isSelected,
}) => {
  const { t } = useTranslation();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  /**
   * This workaround is required due to the usage of chakra components. Those componenents do not
   * 'understand' the color codes of tailwind and need hex codes to function properly
   * can be deleted after upgrading the editor to tailwind components
   */
  const getColorCodeByName = (colorName) => {
    switch (colorName) {
      case 'lightPurple':
        return '#BCABFF';
      case 'purple':
        return '#7551FF';
      case 'deepPurple':
        return '#D251FF';
      case 'deepPink':
        return '#FF5487';
      case 'pink':
        return '#FFA0C5';
      case 'lightPink':
        return '#FFB9B2';
      case 'deepOrange':
        return '#FB7B05';
      case 'orange':
        return '#FBBC05';
      case 'green':
        return '#01B574';
      case 'deepGreen':
        return '#3EA101';
      case 'lightGreen':
        return '#92D25F';
      case 'teal':
        return '#01AAB5';
      case 'lightTeal':
        return '#70E6D9';
      case 'blue':
        return '#86ABE3';
      case 'lightBlue':
        return '#77A9F4';
      case 'deepBlue':
        return '#003EB9';
      case 'lightGrey':
        return '#7D95B3';
      case 'grey':
        return '#626188';
      case 'black':
        return '#0A1A39';
      case 'brown':
        return '#823408';

      // returns black as default so we can ensure that everything is readable
      default:
        return '#0A1A39';
    }
  };

  return (
    <Popover
      returnFocusOnClose
      isOpen={isPopoverOpen}
      onClose={() => {
        setIsPopoverOpen(false);
      }}
      onClickCloseButton={() => {
        setIsPopoverOpen(false);
      }}
      placement="bottom"
      closeOnBlur
      triggerContent={
        <Tooltip hasArrow label={textLabel.description}>
          <Flex>
            <Tag
              width="100%"
              bg={isSelected ? getColorCodeByName(textLabel.color) : 'white'}
              color={isSelected ? 'white' : getColorCodeByName(textLabel.color)}
              borderWidth={isSelected ? 0 : 1}
              borderColor={isSelected ? null : getColorCodeByName(textLabel.color)}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
                fontSize: '16px',
              }}
              onClick={() => {
                selectTextLabelIfValid(textLabelName);
              }}
              onContextMenu={(event) => {
                event.preventDefault();
                setIsPopoverOpen(true);
              }}
            >
              {textLabelName}
              {hasTextLabelExtendedReplacement(textLabel) ? (
                <TagRightIcon as={GoPrimitiveDot} size="2px" />
              ) : null}
            </Tag>
          </Flex>
        </Tooltip>
      }
      withAnchor
      headerContent={t('textLabelSelection.options')}
      bodyContent={
        <Center>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => removeAllAnnotationsWithTextLabel(textLabelName)}
          >
            {t('textLabelSelection.deleteAllMarksPrefix')}
            {textLabelName}
            {t('textLabelSelection.deleteAllMarksSuffix')}
          </Button>
        </Center>
      }
    />
  );
};

export default TextLabel;
