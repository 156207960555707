import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearAnonymizedDocument,
  fetchAnonymizedDocument,
  selectAnonymizedDocument,
} from '../reducers/anonymizedDocumentSlice';
import { downloadAnonymizedDocument } from '../reducers/documentsSlice';
import {
  clearOriginalDocument,
  fetchOriginalDocument,
  selectOriginalDocument,
} from '../reducers/originalDocumentSlice';

const useDocument = (projectId, documentId) => {
  const dispatch = useDispatch();
  const anonymizedBase64Document = useSelector(selectAnonymizedDocument);
  const originalBase64Document = useSelector(selectOriginalDocument);
  const [isBase64Loading, setIsBase64Loading] = useState(false);

  const initSlice = async () => {
    setIsBase64Loading(true);
    const promises = [];
    promises.push(dispatch(fetchAnonymizedDocument({ projectId, documentId })).unwrap());
    promises.push(dispatch(fetchOriginalDocument({ projectId, documentId })).unwrap());
    await Promise.all(promises);
    setIsBase64Loading(false);
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clears anonymized document state when component unmounts
      dispatch(clearAnonymizedDocument());
      // Clears original document state when component unmounts
      dispatch(clearOriginalDocument());
    };
  }, [documentId]);

  /**
   * Downloads the anonymized document
   */
  const downloadDocument = async () => {
    await downloadAnonymizedDocument(projectId, documentId);
  };

  return {
    anonymizedBase64Document,
    originalBase64Document,
    isBase64Loading,
    downloadDocument,
    initDocument: initSlice,
  };
};

export default useDocument;
