import { Center, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';

/**
 * Renders a help button, which can display a help text
 */
const HelpButton = ({ helpText, size, ...rest }) => {
  return (
    <Tooltip hasArrow label={helpText}>
      <Center {...rest}>
        <BsQuestionCircle size={size} />
      </Center>
    </Tooltip>
  );
};

export default HelpButton;
