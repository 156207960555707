import { useEffect, useState } from 'react';
import { isAnnotation } from '../types/annotation';

const useUiHook = () => {
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [selectedTextLabel, setSelectedTextLabel] = useState(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

  // const settings = useSelector(selectSettings);
  // Wrap states in functions
  const [isUploading, setIsUploading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isMetricsOpen, setIsMetricsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isDisplayingPseudonyms, setIsDisplayingPseudonyms] = useState(true);

  const selectAnnotation = (annotation) => {
    setSelectedAnnotation(annotation);
  }; // Currently in selectedAnnotationSlice

  const deselectAnnotation = () => {
    setSelectedAnnotation(null);
  }; // Currently in selectedAnnotationSlice (former name: clearSelectedAnnotation)

  useEffect(() => {}, []);

  /**
   * Selects an annotation or deselects it, if it was already selected
   *
   * @param {Object} annotation
   */
  const toggleSelectedAnnotation = (annotation) => {
    // Select clicked annotation

    if (
      selectedAnnotation !== null &&
      isAnnotation(selectedAnnotation) &&
      selectedAnnotation.id === annotation.id
    ) {
      // Clicked annotation is already selected => deselect
      deselectAnnotation();
    } else {
      setSelectedAnnotation(annotation);
    }
  };

  const isAnnotationSelected = (annotation) => {
    return (
      selectedAnnotation !== null &&
      isAnnotation(selectedAnnotation) &&
      annotation.id === selectedAnnotation.id
    );
  };

  const isSelectionEmpty = () => {
    return selectedDocumentIds.length === 0;
  };

  const addAllToSelectedDocumentIds = (documents) => {
    const allSelectedDocumentIds = [];
    documents.map((document) => allSelectedDocumentIds.push(document.id));
    setSelectedDocumentIds(allSelectedDocumentIds);
  };

  const addToSelectedDocumentIds = (documentIds) => {
    const newSelectedDocuments = Array.from(new Set([...documentIds, ...selectedDocumentIds]));
    setSelectedDocumentIds(newSelectedDocuments);
  };

  const removeFromSelectedDocumentIds = (documentIds) => {
    setSelectedDocumentIds(selectedDocumentIds.filter((id) => !documentIds.includes(id)));
  };

  const isDocumentSelected = (documentId) => {
    return selectedDocumentIds.includes(documentId);
  };

  const isDocumentArchived = (documentStatus) => {
    return documentStatus === 'ARCHIVED';
  };

  const clearSelectedDocumentIds = () => {
    setSelectedDocumentIds([]);
  };

  // If a new document is opened the ui states need to be reset (selectedParagraph, view, ...)
  // TODO: Create function for this scenario and return it

  // ui states need to be synchronized with loading of document etc. (it is what it is...)

  return {
    selectedTextLabel,
    selectedParagraphIndex,
    selectedAnnotation,
    selectAnnotation,
    deselectAnnotation,
    toggleSelectedAnnotation,
    isAnnotationSelected,
    isUploading,
    isRefreshing,
    selectedFiles,
    isDownloading,
    isMetricsOpen,
    isDeleting,
    setIsUploading,
    setIsRefreshing,
    setSelectedFiles,
    setIsDownloading,
    setIsMetricsOpen,
    setIsDeleting,
    addAllToSelectedDocumentIds,
    addToSelectedDocumentIds,
    removeFromSelectedDocumentIds,
    isDocumentSelected,
    isDocumentArchived,
    selectedDocumentIds,
    clearSelectedDocumentIds,
    isSidebarOpen,
    isSelectionEmpty,
    setIsSidebarOpen,
    setSelectedParagraphIndex,
    isDisplayingPseudonyms,
    setIsDisplayingPseudonyms,
    // isScoresLoading,
    // setIsScoresLoading,
  };
};

export default useUiHook;
