/**
 * Resets the given history to the given initial state and returns it as well as the reset history index
 * @param {Object} initHistory The initial history
 * @returns {Object} The reset history and the reset history index
 */
export const getResetHistory = (initHistory) => {
  // reset history
  const history = [
    {
      ...initHistory,
      prevAction: null,
    },
  ];

  return {
    newHistory: history,
    newCurrentHistoryIdx: 0,
  };
};

/**
 * Updates the given history with the given new history item and returns it as well as the updated history index
 * @param {Object} currentHistory The current history
 * @param {Object} newHistoryItem The new history item
 * @param {number} currentHistoryIdx The current history index
 * @param {string} action The action that was performed
 * @param {Object} newImages The new images
 * @returns {Object} The updated history and the updated history index
 */
export const getUpdatedHistory = (currentHistory, newHistoryItem, currentHistoryIdx, action) => {
  // invalidate the redo history
  const newHistory = currentHistory.slice(0, currentHistoryIdx + 1);

  // add the new state to the history
  newHistory.push({
    ...newHistoryItem,
    prevAction: action,
  });

  return {
    newHistory,
    newCurrentHistoryIdx: currentHistoryIdx + 1,
  };
};
