import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  createProject,
  deleteProject,
  getProject,
  getProjectsData,
  putProject,
} from '../api/project';

const initialState = {
  data: [],
  projectData: [],
  statusProjects: '',
  statusProject: '',
  error: '',
};

export const fetchProjectsData = createAsyncThunk('fetch/projectsData', async (organizationId) => {
  const response = await getProjectsData(organizationId);
  return response.data;
});

export const createProjectDB = createAsyncThunk(
  'create/project',
  async ({ organizationId, projectData }) => {
    const response = await createProject(organizationId, projectData);
    return response.data;
  },
);

export const deleteProjectDB = createAsyncThunk(
  'delete/project',
  async ({ organizationId, projectId }) => {
    const response = await deleteProject(organizationId, projectId);
    return response.data;
  },
);

export const changeProjectData = createAsyncThunk(
  'change/projectData',
  async ({ organizationId, projectId, projectData }) => {
    const response = await putProject(organizationId, projectId, projectData);
    return response.data;
  },
);

export const fetchProjectData = createAsyncThunk(
  'fetch/projectData',
  async ({ organizationId, projectId }) => {
    const response = await getProject(organizationId, projectId);
    return response.data;
  },
);

/**
 * The slice of the document data
 */
export const projectsDataSlice = createSlice({
  name: 'projectsData',
  initialState,
  reducers: {
    /**
     * Clears the project data data state by setting it to the initial state
     */
    clearProjectsData() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectsData.pending, (state) => {
        state.statusProjects = 'pending';
      })
      .addCase(fetchProjectsData.fulfilled, (state, action) => {
        state.statusProjects = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchProjectsData.rejected, (state, action) => {
        state.statusProjects = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchProjectData.pending, (state) => {
        state.statusProject = 'pending';
      })
      .addCase(fetchProjectData.fulfilled, (state, action) => {
        state.statusProject = 'succeeded';
        state.projectData = action.payload;
      })
      .addCase(fetchProjectData.rejected, (state, action) => {
        state.statusProject = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectProjectsData = createSelector(
  (state) => state.projectsData,
  (projectsData) => projectsData.data,
);
export const selectProjectData = createSelector(
  (state) => state.projectsData,
  (projectsData) => projectsData.projectData,
);
export const selectProjectsDataStatus = createSelector(
  (state) => state.projectsData,
  (projectsData) => projectsData.statusProjects,
);
export const selectProjectDataStatus = createSelector(
  (state) => state.projectsData,
  (projectsData) => projectsData.statusProject,
);
export const selectProjectsDataError = createSelector(
  (state) => state.projectsData,
  (projectsData) => projectsData.error,
);

export const { clearProjectsData } = projectsDataSlice.actions;

export default projectsDataSlice.reducer;
