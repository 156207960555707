import React, { useEffect } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import AppToaster from '../components/molecules/AppToaster';
import Dropzone from '../components/organisms/Dropzone';
import DocumentsTable from '../components/organisms/documentSelection/DocumentsTable';
import MenuBar from '../components/organisms/documentSelection/MenuBar';
import ScoresDialog from '../components/organisms/scores/ScoresDialog';
import { transformToCSVData } from '../utils/csvUtils';
// import { selectSettings, setIsShown } from '../js/slices/settingsSlice';
import NoDocumentsInfo from '../components/molecules/documentSelection/NoDocumentsInfo';
import useBlacklist from '../hooks/useBlacklist';
import useDocuments from '../hooks/useDocuments';
import useImageLabels from '../hooks/useImageLabels';
import useLayout from '../hooks/useLayout';
import useProjectEvents from '../hooks/useProjectEvents';
import useProjectSettings from '../hooks/useProjectSettings';
import useScores from '../hooks/useScores';
import useSettings from '../hooks/useSettings';
import useTextLabels from '../hooks/useTextLabels';
import useUiHook from '../hooks/useUiHook';
import useUsersOrganization from '../hooks/useUsersOrganization';
import useUsersProject from '../hooks/useUsersProject';
import useWhitelist from '../hooks/useWhitelist';

/**
 * Renders the document selection
 */
const DocumentSelection = () => {
  const { t } = useTranslation();
  const { projectId, organizationId } = useParams();
  const navigate = useNavigate();

  const { mainMenuHeight } = useLayout();
  const {
    documents,
    acceptedExtensions,
    isDocumentPreprocessed,
    isDocumentFinalized,
    onDownloadDocuments,
    initDocuments,
    onDownloadDocument,
    onDeleteDocuments,
    onUploadFiles,
    onResetDocument,
    onUnfinalizeDocument,
    onPriorizePreprocessing,
    onDisableDocument,
    onEnableDocument,
    updateDocuments,
  } = useDocuments(projectId);
  const {
    isUploading,
    isRefreshing,
    selectedFiles,
    isDownloading,
    isMetricsOpen,
    isDeleting,
    setIsUploading,
    setIsRefreshing,
    setIsDownloading,
    setIsMetricsOpen,
    setIsDeleting,
    addToSelectedDocumentIds,
    removeFromSelectedDocumentIds,
    isDocumentSelected,
    selectedDocumentIds,
    clearSelectedDocumentIds,
    addAllToSelectedDocumentIds,
    removeAllFromSelectedDocumentIds,
  } = useUiHook();

  const { computeScores, scores, isScoresLoading } = useScores(projectId);
  const {
    textLabels,
    textLabelHiddenColor,
    allDateLocales,
    addTextLabel,
    hasTextLabelExtendedReplacement,
    saveTextLabels,
    changeTextLabelName,
    changeExtendedReplacement,
    setTextLabelAttribute,
    deleteTextLabel,
  } = useTextLabels(projectId);

  const {
    imageLabels,
    imageAnonymizationTypes,
    setImageAnonymizationType,
    setLabelAnonymizationType: setImageLabelAnonymizationType,
    setLabelIsEnabled: setImageLabelIsEnabled,
    saveImageLabels,
  } = useImageLabels(projectId);

  const { projectSettings, setIsImageAnonymizationEnabled } = useProjectSettings(projectId);

  const {
    isDisabled,
    setIsDisabled,
    isShown,
    setIsShown,
    activeTextLabelName,
    setActiveTextLabelName,
    nameInputByTextLabelName,
    setNameInputByTextLabelName,
  } = useSettings();

  const {
    whitelist,
    isCSVImporting: isWhitelistCSVImporting,
    saveWhitelist,
    addWhitelistItem,
    removeWhitelistItem,
    updateWhitelistItem,
    removeAllWhitelistItems,
    importWhitelistItems,
  } = useWhitelist(projectId);

  const {
    blacklist,
    isCSVImporting: isBlacklistCSVImporting,
    saveBlacklist,
    addBlacklistItem,
    removeBlacklistItem,
    updateBlacklistItem,
    removeAllBlacklistItems,
    importBlacklistItems,
  } = useBlacklist(projectId);

  const { users: organizationUsers } = useUsersOrganization(organizationId);

  const {
    roles,
    selectedRole,
    setSelectedRole,
    invitedUserId,
    setInvitedUserId,
    organizationUsersNotInProject,
    isShown: isShownInviteProject,
    setIsShown: setIsShownInviteProject,
    onAddUser,
    setErrorMsg,
  } = useUsersProject(projectId, organizationId, organizationUsers);

  /**
   * On events object that specifies the actions to be taken
   * when an event from the server is received
   */
  const onEvents = {
    DOCUMENT_UPDATED: () => {
      initDocuments();
    },
    DOCUMENT_COMPLETED: () => {
      initDocuments();
    },
  };
  useProjectEvents(projectId, onEvents);

  const onDownloadSelectedDocuments = async (documentIds) => {
    setIsDownloading(true);
    await onDownloadDocuments(documentIds);
    setIsDownloading(false);
  };

  const onUpload = async (files) => {
    setIsUploading(true);
    await onUploadFiles(files);
    setIsUploading(false);
  };

  const onDelete = async (documentIds) => {
    setIsDeleting(true);
    try {
      onDeleteDocuments(documentIds);
    } catch (error) {
      AppToaster({
        description: t('documentSelection.deleteFailed'),
        status: 'error',
      });
    }
    setIsDeleting(false);
  };

  const onReset = (documentId) => {
    setIsUploading(true);
    onResetDocument(documentId);
    setIsUploading(false);
  };

  const onUnfinalize = async (documentId) => {
    setIsUploading(true);
    try {
      onUnfinalizeDocument(documentId);
    } catch (error) {
      AppToaster({
        description: t('documentSelection.unfinalizeFailed'),
        status: 'error',
      });
    }
    setIsUploading(false);
  };

  const onPriorize = (documentId) => {
    setIsUploading(true);
    onPriorizePreprocessing(documentId);
    setIsUploading(false);
  };

  const onDisable = (documentId) => {
    setIsUploading(true);
    onDisableDocument(documentId);
    setIsUploading(false);
  };

  const onEnable = (documentId) => {
    setIsUploading(true);
    onEnableDocument(documentId);
    setIsUploading(false);
  };

  const onRefresh = async () => {
    setIsRefreshing(true);
    await updateDocuments();
    setIsRefreshing(false);
  };

  const navigateToDocument = (documentId) => {
    navigate(`document/${documentId}`);
  };

  const areAllDocumentsSelected = () => {
    return selectedDocumentIds.length > 0 && documents.length === selectedDocumentIds.length;
  };

  const areSelectedDocumentsPreprocessed = () => {
    return documents
      .filter((document) => selectedDocumentIds.includes(document.id))
      .every((document) => isDocumentPreprocessed(document));
  };

  const areSelectedDocumentsFinalized = () => {
    return documents
      .filter((document) => selectedDocumentIds.includes(document.id))
      .every((document) => isDocumentFinalized(document));
  };

  useEffect(() => {
    clearSelectedDocumentIds();
  }, [documents]);

  /**
   * Retrieves the document data in array format to export as csv
   * @returns AN object with header and data attributes
   */
  const getDocumentsCSVExportData = () => {
    let documentsToExport = [];
    // Check if single documents are selected
    if (selectedDocumentIds.length > 0) {
      documents.forEach((document) => {
        if (selectedDocumentIds.includes(document.id)) {
          documentsToExport.push(document);
        }
      });
    } else {
      // Export all documents if no document is manually selected
      documentsToExport = documents;
    }

    return transformToCSVData(documentsToExport);
  };

  /**
   *   Open dialog to invite the user
   */
  const openModal = () => {
    setIsShownInviteProject(true);
  };

  /**
   * Resets all correspondiong states when closing the dialog without saving
   */
  const resetStates = () => {
    setIsShownInviteProject(false);
    setErrorMsg('');
  };

  /**
   *  Close Dialog when clicking close icon or outside of the dialog without saving
   */
  const closeModalWithoutSaving = async () => {
    resetStates();
  };

  /**
   * Assign a user to a project
   */
  const addUserToProject = async () => {
    // Map the email input to the corresponding user id
    const succesful = await onAddUser(invitedUserId, selectedRole);
    setIsShownInviteProject(!succesful);
  };

  /**
   * Saves and add the user to project when the email is valid
   */
  const closeAndSaveModal = () => {
    addUserToProject();
  };

  const handleUserEmailInput = (userId) => {
    setInvitedUserId(userId);
  };

  return (
    <>
      {/* TODO: size of the flex that contains the table */}
      <Flex flexDir="column" height="100%">
        {/* height={`calc(${contentHeight} - ${mainMenuHeight})`}> */}
        <MenuBar
          height={`calc(${mainMenuHeight})`}
          documents={documents}
          isUploading={isUploading}
          isRefreshing={isRefreshing}
          selectedFiles={selectedFiles}
          isDownloading={isDownloading}
          setIsMetricsOpen={setIsMetricsOpen}
          selectedDocumentIds={selectedDocumentIds}
          getDocumentsCSVExportData={getDocumentsCSVExportData}
          onUpload={onUpload}
          onDownload={onDownloadSelectedDocuments}
          areSelectedDocumentsPreprocessed={areSelectedDocumentsPreprocessed}
          areSelectedDocumentsFinalized={areSelectedDocumentsFinalized}
          onDelete={onDelete}
          isDeleting={isDeleting}
          onRefresh={onRefresh}
          acceptedExtensions={acceptedExtensions}
          isShown={isShown}
          setIsShown={setIsShown}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          computeScores={computeScores}
          textLabels={textLabels}
          addTextLabel={addTextLabel}
          hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
          textLabelHiddenColor={textLabelHiddenColor}
          activeTextLabelName={activeTextLabelName}
          setActiveTextLabelName={setActiveTextLabelName}
          nameInputByTextLabelName={nameInputByTextLabelName}
          setNameInputByTextLabelName={setNameInputByTextLabelName}
          imageLabels={imageLabels}
          imageAnonymizationTypes={imageAnonymizationTypes}
          setImageAnonymizationType={setImageAnonymizationType}
          setImageLabelAnonymizationType={setImageLabelAnonymizationType}
          setImageLabelIsEnabled={setImageLabelIsEnabled}
          saveImageLabels={saveImageLabels}
          isImageAnonymizationEnabled={projectSettings.isImageAnonymizationEnabled}
          setIsImageAnonymizationEnabled={setIsImageAnonymizationEnabled}
          whitelist={whitelist}
          isWhitelistCSVImporting={isWhitelistCSVImporting}
          saveWhitelist={saveWhitelist}
          addWhitelistItem={addWhitelistItem}
          removeWhitelistItem={removeWhitelistItem}
          updateWhitelistItem={updateWhitelistItem}
          removeAllWhitelistItems={removeAllWhitelistItems}
          importWhitelistItems={importWhitelistItems}
          blacklist={blacklist}
          isBlacklistCSVImporting={isBlacklistCSVImporting}
          saveBlacklist={saveBlacklist}
          addBlacklistItem={addBlacklistItem}
          removeBlacklistItem={removeBlacklistItem}
          updateBlacklistItem={updateBlacklistItem}
          removeAllBlacklistItems={removeAllBlacklistItems}
          importBlacklistItems={importBlacklistItems}
          saveTextLabels={saveTextLabels}
          changeTextLabelName={changeTextLabelName}
          changeExtendedReplacement={changeExtendedReplacement}
          setTextLabelAttribute={setTextLabelAttribute}
          deleteTextLabel={deleteTextLabel}
          allDateLocales={allDateLocales}
          isScoresLoading={isScoresLoading}
          openModal={openModal}
          isShownInviteProject={isShownInviteProject}
          closeModalWithoutSaving={closeModalWithoutSaving}
          closeAndSaveModal={closeAndSaveModal}
          handleUserEmailInput={handleUserEmailInput}
          roles={roles}
          setSelectedRole={setSelectedRole}
          selectableUsers={organizationUsersNotInProject}
        />
        <Box flex="1" bg="gray.200" p={2}>
          <Dropzone
            bg="white"
            rounded={5}
            pl={2}
            pr={2}
            pb={2}
            pt={0}
            height="100%"
            width="100%"
            overflow="auto"
            acceptedExtensions={acceptedExtensions}
            onDropAccepted={onUpload}
          >
            {documents.length > 0 ? (
              <DocumentsTable
                documents={documents}
                onDownloadDocument={onDownloadDocument}
                onUpload={onUpload}
                onReset={onReset}
                onDeleteDocument={(documentId) => onDelete([documentId])}
                onUnfinalize={onUnfinalize}
                onPriorize={onPriorize}
                onDisable={onDisable}
                onEnable={onEnable}
                addToSelectedDocumentIds={addToSelectedDocumentIds}
                removeFromSelectedDocumentIds={removeFromSelectedDocumentIds}
                isDocumentSelected={isDocumentSelected}
                navigateToDocument={navigateToDocument}
                selectedDocumentIds={selectedDocumentIds}
                areAllDocumentsSelected={areAllDocumentsSelected}
                addAllToSelectedDocumentIds={addAllToSelectedDocumentIds}
                removeAllFromSelectedDocumentIds={removeAllFromSelectedDocumentIds}
              />
            ) : (
              <NoDocumentsInfo />
            )}
          </Dropzone>
        </Box>
      </Flex>
      <ScoresDialog
        scores={scores}
        showDialog={isMetricsOpen}
        onClose={() => setIsMetricsOpen(false)}
      />
    </>
  );
};

export default DocumentSelection;
