import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../atoms/tailwind/Button';
import Dropdown from '../../../../atoms/tailwind/Dropdown';
import Input from '../../../../atoms/tailwind/Input';
import TextArea from '../../../../atoms/tailwind/TextArea';
import LabelEditArea from '../../../../molecules/tailwind/LabelEditArea';
import DateReplacement from './DateReplacement';
import PartialReplacement from './PartialReplacement';

const TextLabelEditArea = ({
  isSaving,
  textLabel,
  nameValidityByTextLabelName,
  nameInputByTextLabelName,
  defaultReplacementValidityByTagName,
  changeName,
  onBlurChangeName,
  changeDefaultReplacement,
  onDelete,
  onSave,
  changeDescription,
  changePartialReplacementStart,
  changePartialReplacementStop,
  changeUseNumericPartialReplacement,
  changeExtendedReplacement,
  changeReplaceDay,
  changeReplaceMonth,
  changeReplaceYear,
  changeShowWeekday,
  changeDateLocale,
  allDateLocales,
  changeUseSuppression,
  changeSuppressionLength,
  preview,
  setPreview,
  pseudonymizedPreview,
  setHasUnsavedChanges,
}) => {
  const { t } = useTranslation();

  const replacementOptions = [
    {
      id: 'pseudonym',
      name: t('settings.textLabels.replacementOptions.pseudonym'),
      value: 'pseudonym',
    },
    {
      id: 'rule',
      name: `${t('settings.textLabels.replacementOptions.rule')} \u2022`,
      value: 'rule',
    },
    {
      id: 'suppression',
      name: t('settings.textLabels.replacementOptions.suppression'),
      value: 'useSuppression',
    },
  ];
  const [selectedReplacementOption, setSelectedReplacementOption] = React.useState(null);

  const ruleOptions = [
    {
      id: 'partialReplacement',
      name: t('settings.textLabels.ruleOptions.partialReplacement'),
      value: 'usePartialReplacement',
    },
    {
      id: 'dateReplacement',
      name: t('settings.textLabels.ruleOptions.dateReplacement'),
      value: 'useDatePartialReplacement',
    },
  ];

  const ruleComponents = {
    usePartialReplacement: {
      title: t('settings.textLabels.partialReplacementTitle'),
      component: (
        <PartialReplacement
          textLabel={textLabel}
          changePartialReplacementStart={changePartialReplacementStart}
          changePartialReplacementStop={changePartialReplacementStop}
          toggleUseNumericPartialReplacement={() => {
            changeUseNumericPartialReplacement(!textLabel.useNumericPartialReplacement);
          }}
        />
      ),
    },
    useDatePartialReplacement: {
      title: t('settings.textLabels.datePartialReplacementTitle'),
      component: (
        <DateReplacement
          textLabel={textLabel}
          toggleReplaceDay={() => {
            changeReplaceDay(!textLabel.replaceDay);
          }}
          toggleReplaceMonth={() => {
            changeReplaceMonth(!textLabel.replaceMonth);
          }}
          toggleReplaceYear={() => {
            changeReplaceYear(!textLabel.replaceYear);
          }}
          toggleShowWeekday={() => {
            changeShowWeekday(!textLabel.showWeekday);
          }}
          changeDateLocale={changeDateLocale}
          allDateLocales={allDateLocales}
        />
      ),
    },
  };

  const [selectedRuleOption, setSelectedRuleOption] = useState(
    ruleOptions.find((option) => option.value === 'usePartialReplacement'),
  );

  /**
   * Initializes the states of the component depending on the values of the text label
   */
  const initStates = () => {
    if (!textLabel) {
      return;
    }

    if (textLabel.usePartialReplacement || textLabel.useNumericPartialReplacement) {
      setSelectedReplacementOption(replacementOptions.find((option) => option.value === 'rule'));
      setSelectedRuleOption(ruleOptions.find((option) => option.value === 'usePartialReplacement'));
      return;
    }

    if (textLabel.useDatePartialReplacement) {
      setSelectedRuleOption(
        ruleOptions.find((option) => option.value === 'useDatePartialReplacement'),
      );
      return;
    }

    if (textLabel.useSuppression) {
      setSelectedReplacementOption(
        replacementOptions.find((option) => option.value === 'useSuppression'),
      );
      return;
    }

    // Pseudonym is the default replacement strategy for a given tag
    setSelectedReplacementOption(replacementOptions.find((option) => option.value === 'pseudonym'));
  };
  useEffect(() => initStates(), [textLabel]);

  const labelName = textLabel?.name;

  if (!nameValidityByTextLabelName.current || !defaultReplacementValidityByTagName.current) {
    return null;
  }
  const isNameValid = nameValidityByTextLabelName.current[labelName];
  const nameInvalidFormMessage = nameValidityByTextLabelName.current[labelName]?.formMessage;
  const isDefaultReplacementValid = defaultReplacementValidityByTagName.current[labelName];
  const defaultReplacementInvalidFormMessage =
    defaultReplacementValidityByTagName.current[labelName]?.formMessage;

  if (!textLabel || !isNameValid || !isDefaultReplacementValid) {
    return (
      <div className="h-[34rem] rounded-xl bg-grey-0 p-8">
        <LabelEditArea editingRows={[]} />
      </div>
    );
  }

  /**
   * Sets the selected rule option state and changes the extended replacement
   */
  const handleRuleOptionChange = (option) => {
    setSelectedRuleOption(option);
    changeExtendedReplacement(option.value);
  };

  /**
   * Handles the change of the replacement option by setting the selected replacement option
   * state and setting the extended replacement if `rule` is selected.
   * TODO: This needs a fix - good for now but needs to be addressed in the future
   */
  const handleReplacementOptionChange = (option) => {
    setSelectedReplacementOption(option);
    if (option.value === 'pseudonym') {
      changeExtendedReplacement('pseudonym');
    }
    if (option.value === 'rule') {
      changeExtendedReplacement('usePartialReplacement');
    }
    if (option.value === 'useSuppression') {
      changeExtendedReplacement('useSuppression');
    }
  };

  const editingRows = [
    {
      title: t('settings.textLabels.name'),
      children: (
        <>
          <Input
            type="text"
            value={nameInputByTextLabelName[labelName]}
            placeholder={t('settings.textLabels.maxNameChars')}
            onChange={(event) => {
              setHasUnsavedChanges(true);
              changeName(event.target.value);
            }}
            disabled={textLabel.fromModel}
            isValid={isNameValid.valid}
            onBlur={onBlurChangeName}
          />
          {nameInvalidFormMessage}
        </>
      ),
      isVisible: true,
    },
    {
      title: t('settings.textLabels.description'),
      children: (
        <TextArea
          value={textLabel.description}
          onChange={(event) => {
            setHasUnsavedChanges(true);
            changeDescription(event.target.value);
          }}
          // className="block w-full resize-none rounded-xl border-0 p-1.5 text-base text-blue-2 shadow-sm ring-1 ring-inset ring-babyBlue-1 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:leading-6"
        />
      ),
      isVisible: true,
    },
    {
      title: t('settings.textLabels.replaceWith'),
      children: (
        <Dropdown
          selectedItem={selectedReplacementOption}
          items={replacementOptions}
          onChange={(item) => {
            setHasUnsavedChanges(true);
            handleReplacementOptionChange(item);
          }}
        />
      ),
      isVisible: true,
    },
    {
      title: t('settings.textLabels.replacementOptions.pseudonym'),
      children: (
        <>
          <Input
            type="text"
            value={textLabel.defaultReplacement}
            onChange={(event) => {
              setHasUnsavedChanges(true);
              changeDefaultReplacement(event.target.value);
            }}
            isValid={isDefaultReplacementValid.valid}
            placeholder={t('settings.textLabels.isRequired')}
          />
          {defaultReplacementInvalidFormMessage}
        </>
      ),
      isVisible: selectedReplacementOption && selectedReplacementOption.value !== 'useSuppression',
    },
    {
      title: t('settings.textLabels.ruleSettings'),
      children: (
        <Dropdown
          selectedItem={selectedRuleOption}
          items={ruleOptions}
          onChange={(item) => {
            setHasUnsavedChanges(true);
            handleRuleOptionChange(item);
          }}
        />
      ),
      isVisible: selectedReplacementOption && selectedReplacementOption.value === 'rule',
    },
    {
      title: '',
      children: selectedRuleOption && ruleComponents[selectedRuleOption.value].component,
      isVisible: selectedReplacementOption && selectedReplacementOption.value === 'rule',
    },
    {
      title: t('settings.textLabels.replacementOptions.suppression'),
      children: (
        <>
          {/* // TODO: Change to tailwind */}
          <NumberInput
            id="from"
            size="sm"
            maxW="100px"
            maxH="32px"
            min={4}
            value={textLabel.suppressionLength}
            onChange={(number) => {
              setHasUnsavedChanges(true);
              changeSuppressionLength(number);
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </>
      ),
      isVisible: selectedReplacementOption && selectedReplacementOption.value === 'useSuppression',
    },
    {
      title: t('settings.textLabels.preview'),
      children: (
        <div className="flex items-center">
          <div className="w-1/2">
            <Input
              type="text"
              value={preview}
              placeholder={t('settings.textLabels.previewPlaceholder')}
              onChange={(event) => setPreview(event.target.value)}
            />
          </div>
          <p className="ml-4 w-1/2 truncate text-left text-violet-1">{pseudonymizedPreview}</p>
        </div>
      ),
      isVisible: true,
    },
    {
      title: null,
      children: (
        <div className="grid grid-cols-2 gap-2">
          <Button
            buttonText={t('settings.textLabels.delete')}
            buttonAction={onDelete}
            disabled={textLabel.fromModel || isSaving}
            color="red"
          />
          <Button
            buttonText={t('settings.save')}
            buttonAction={() => {
              setHasUnsavedChanges(false);
              onSave();
            }}
            color="blue"
            disabled={!isDefaultReplacementValid.valid || isSaving || !isNameValid.valid}
            isLoading={isSaving}
          />
        </div>
      ),
      isVisible: true,
    },
  ];

  return (
    <div className="h-[34rem] rounded-xl bg-grey-0 p-8">
      <LabelEditArea editingRows={editingRows} />
    </div>
  );
};

export default TextLabelEditArea;
