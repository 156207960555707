import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeProjectData,
  clearProjectsData,
  fetchProjectData,
  selectProjectData,
  selectProjectDataStatus,
  selectProjectsDataError,
} from '../reducers/projectDataSlice';

const useProject = (organizationId, projectId) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isValidProjectName, setIsValidProjectName] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [projectNameError, setProjectNameError] = useState(false);
  const [projectData, setProjectData] = useState({});

  const sliceProjectData = useSelector(selectProjectData);
  const fetchStatus = useSelector(selectProjectDataStatus); // TODO: change naming for all different slices
  const fetchError = useSelector(selectProjectsDataError);

  const initSlice = () => {
    dispatch(fetchProjectData({ organizationId, projectId }));
  };

  const onChangeProjectData = async (newProjectData) => {
    try {
      await dispatch(
        changeProjectData({ organizationId, projectId, projectData: newProjectData }),
      ).unwrap();
      setIsValidProjectName(true);
      return true;
    } catch (error) {
      setProjectNameError(true);
      setIsValidProjectName(false);
      return false;
    } finally {
      await dispatch(fetchProjectData({ organizationId, projectId })).unwrap();
    }
  };

  const transformSliceData = () => {
    if (fetchStatus === 'pending') {
      setIsLoading(true);
    }
    if (fetchStatus === 'succeeded') {
      setProjectData(sliceProjectData);
      setIsLoading(false);
    }
    if (fetchStatus === 'failed') {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clears project data state when component unmounts
      dispatch(clearProjectsData());
    };
  }, [organizationId, projectId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceProjectData]);

  return {
    projectData,
    setProjectData,
    onChangeProjectData,
    isValidProjectName,
    setIsValidProjectName,
    errorMsg,
    setErrorMsg,
    projectNameError,
    setProjectNameError,
    isLoading,
  };
};

export default useProject;
