import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renameObjectKey } from '../../../../../services/utils';
import AddIconSmall from '../../../../atoms/icons/AddIconSmall';
import Label from '../../../../molecules/tailwind/Label';
import LabelSelectionArea from '../../../../molecules/tailwind/LabelSelectionArea';
import ImageLabelsEditArea from './ImageLabelsEditArea';

const ImageLabelsSettings = ({
  imageLabels,
  allImageLabels,
  setLabelIsEnabled,
  setLabelName,
  isSaving,
  saveImageLabels,
  canAddImageLabels,
  deleteImageLabel,
  addImageLabel,
  setHasUnsavedChanges,
  handleImageLabelChange,
}) => {
  const { t } = useTranslation();

  const [nameInputByImageLabelName, setNameInputByImageLabelName] = useState({});
  const [activeImageLabelName, setActiveImageLabelName] = useState(imageLabels[0]?.name);
  const nameValidityByImageLabelName = useRef(null);

  /**
   * Initializes the name validity and input objects.
   */
  useEffect(() => {
    const initnameValidityByImageLabelName = {};
    const initnameInputByImageLabelName = {};
    // Uses allImageLabels for validation
    allImageLabels.forEach((label) => {
      const labelName = label.name;
      initnameValidityByImageLabelName[labelName] = {
        valid: true,
        formMessage: null,
      };
      initnameInputByImageLabelName[labelName] = labelName;
    });

    setNameInputByImageLabelName(initnameInputByImageLabelName);
    nameValidityByImageLabelName.current = initnameValidityByImageLabelName;
  }, []);

  /**
   * Validates the name input and sets a flag and a error message.
   * The flag prevents saving the text label.
   *
   * @param {string} labelName
   * @param {string} name
   */
  const setIsNameValid = (labelName, name) => {
    if (name === '') {
      nameValidityByImageLabelName.current[labelName] = {
        valid: false,
        formMessage: (
          <div className="py-1 text-red-3">{t('settings.imageAnonymization.isRequired')}</div>
        ),
      };
    } else if (
      // Uses allImageLabels for validation
      Object.keys(allImageLabels).find(
        (otherTagName) => otherTagName !== labelName && otherTagName === name,
      )
    ) {
      nameValidityByImageLabelName.current[labelName] = {
        valid: false,
        formMessage: (
          <div className="py-1 text-red-3">{t('settings.imageAnonymization.alreadyUsed')}</div>
        ),
      };
    } else {
      nameValidityByImageLabelName.current[labelName] = {
        valid: true,
        formMessage: null,
      };
    }
  };

  /**
   * Handles changes in the name field triggered by the keyboard presses.
   * Limits the length of the name field to 11! chars.
   * Additionally it initiates the validation of the input.
   *
   * @param {string} labelName - The labelName of the text label
   * @param {string} newName - The new name
   */
  const handleChangeName = (labelName, newName) => {
    const editedName = newName.slice(0, 11);
    setIsNameValid(labelName, editedName);
    setNameInputByImageLabelName({
      ...nameInputByImageLabelName,
      [labelName]: editedName,
    });
  };

  /**
   * Updates the labelName in the state variables
   * This is necessary because the labelName is used as a key in the state variables.
   *
   * @param {string} oldTagName - The old labelName
   * @param {string} newTagName - The new labelName
   */
  const updateLabelNameInStateVariables = (oldTagName, newTagName) => {
    // update the labelName in this component
    const refs = [nameValidityByImageLabelName];
    const tmp = refs;
    refs.forEach((ref, index) => {
      tmp[index].current = renameObjectKey(ref.current, oldTagName, newTagName);
    });

    setNameInputByImageLabelName(
      renameObjectKey(nameInputByImageLabelName, oldTagName, newTagName),
    );
  };

  /**
   * Saves a valid new name of a text label to the image labels state.
   *
   * @param {string} labelName
   */
  const handleSaveName = (labelName) => {
    const newName = nameInputByImageLabelName[labelName];
    if (labelName !== newName && nameValidityByImageLabelName.current[labelName].valid) {
      // update state variables in this component
      updateLabelNameInStateVariables(labelName, newName); // Check
      setLabelName(labelName, newName);
      setActiveImageLabelName(newName);
    }
  };

  /**
   * Handles the deletion of an image label with the given label name by selecting the new active label name,
   * updating the validation states and deleting the label name from the hook and the database.
   *
   * @param {string} labelName - The label name of the image label to delete
   */
  const handleDelete = (labelName) => {
    // set active Tag to left neighbor if possible, else to right neighbor, else to none
    const activeLabelIndex = imageLabels.findIndex((label) => label.name === labelName);
    const isNotFirstLabel = activeLabelIndex !== 0;
    const isNotLastLabel = activeLabelIndex !== imageLabels.length - 1;
    if (isNotFirstLabel) {
      setActiveImageLabelName(imageLabels[activeLabelIndex - 1].name);
    } else if (isNotLastLabel) {
      setActiveImageLabelName(imageLabels[activeLabelIndex + 1].name);
    } else {
      setActiveImageLabelName(null);
    }

    // remove labelName from nameInput and nameValidity objects
    delete nameValidityByImageLabelName.current[labelName];
    const newnameInputByImageLabelName = { ...nameInputByImageLabelName };
    delete newnameInputByImageLabelName[labelName];
    setNameInputByImageLabelName(newnameInputByImageLabelName);

    deleteImageLabel(labelName);
  };

  /**
   * Creates a new unique text label name.
   * @returns the new text label name
   */
  const generateUniqueImageLabelName = () => {
    if (
      !imageLabels.find(
        (imageLabel) => imageLabel.name === t('settings.imageAnonymization.newImageLabel'),
      )
    ) {
      return t('settings.imageAnonymization.newImageLabel');
    }
    // append a unique number to new label name
    for (let i = 1; i < Object.keys(imageLabels).length + 1; i += 1) {
      if (
        !imageLabels.find(
          (imageLabel) =>
            imageLabel.name === `${t('settings.imageAnonymization.newImageLabel')}-${i}`,
        )
      ) {
        return `${t('settings.imageAnonymization.newImageLabel')}-${i}`;
      }
    }
    return null;
  };

  /**
   * Handles adding a new text label
   */
  const handleAddImageLabel = async () => {
    const labelName = generateUniqueImageLabelName();

    const isSuccessful = await addImageLabel(labelName);
    if (!isSuccessful) {
      return;
    }

    nameValidityByImageLabelName.current[labelName] = {
      valid: true,
      formMessage: null,
    };

    setNameInputByImageLabelName({ ...nameInputByImageLabelName, [labelName]: labelName });
    handleImageLabelChange(setActiveImageLabelName, labelName);
  };

  // selects the active label from the array of label objects depending on the active label name
  const activeImageLabel = imageLabels.find((label) => label.name === activeImageLabelName);

  const imageLabelColumn = (
    <>
      <li className="col-span-1 flex w-fit flex-col justify-center">
        <p className="w-fit text-xl text-blue-2">
          {t('settings.imageAnonymization.selectionTitle')}
        </p>
        <div className="mt-4 h-[34rem] w-fit">
          <LabelSelectionArea
            labels={imageLabels}
            activeLabelName={activeImageLabelName}
            setActiveLabelName={(value) => handleImageLabelChange(setActiveImageLabelName, value)}
            canAddLabel={canAddImageLabels}
            isImageLabel // TODO: This is only a temporary solution!! As soon as shorter label names are enforced, this can be removed
          />
          {canAddImageLabels ? (
            <div className="mt-2 flex h-[2rem] justify-center">
              <Label
                leftIcon={<AddIconSmall />}
                active
                color="grey"
                hoverColor="grey"
                onClick={handleAddImageLabel}
              />
            </div>
          ) : null}
        </div>
      </li>
      <li className="col-span-2 flex w-full flex-col justify-start">
        <p className="w-full text-xl text-blue-2">{t('settings.imageAnonymization.editing')}</p>
        <div className=" mt-4">
          <ImageLabelsEditArea
            isSaving={isSaving}
            imageLabel={activeImageLabel}
            setLabelIsEnabled={setLabelIsEnabled}
            nameValidityByImageLabelName={nameValidityByImageLabelName}
            nameInputByImageLabelName={nameInputByImageLabelName}
            changeName={(value) => handleChangeName(activeImageLabelName, value)}
            onBlurChangeName={() => handleSaveName(activeImageLabelName)}
            onDelete={() => handleDelete(activeImageLabelName)}
            onSave={() => saveImageLabels(allImageLabels)}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </div>
      </li>
    </>
  );

  return (
    <div className="h-full w-full">
      <ul className="mt-3 grid grid-cols-1 gap-4 py-2 md:grid-cols-3 lg:gap-1">
        {imageLabelColumn}
      </ul>
    </div>
  );
};

export default ImageLabelsSettings;
