/**
 * This File contains Workarounds that should be addressed in the future
 */

/**
 * This workaround is required due to the usage of chakra components. Those componenents do not
 * 'understand' the color codes of tailwind and need hex codes to function properly
 * can be deleted after upgrading the editor to tailwind components
 *
 * @param {*} colorName name of the color defined for the labels
 * @param {*} opacity value [0;1] of the opacity
 * @returns hex String with the corresponding color and opacity information
 */
const getColorCodeByNameWithOpacity = (colorName, opacity) => {
  let colorHexValue = '';
  switch (colorName) {
    case 'lightPurple':
      colorHexValue = '#BCABFF';
      break;
    case 'purple':
      colorHexValue = '#7551FF';
      break;
    case 'deepPurple':
      colorHexValue = '#D251FF';
      break;
    case 'deepPink':
      colorHexValue = '#FF5487';
      break;
    case 'pink':
      colorHexValue = '#FFA0C5';
      break;
    case 'lightPink':
      colorHexValue = '#FFB9B2';
      break;
    case 'deepOrange':
      colorHexValue = '#FB7B05';
      break;
    case 'orange':
      colorHexValue = '#FBBC05';
      break;
    case 'green':
      colorHexValue = '#01B574';
      break;
    case 'deepGreen':
      colorHexValue = '#3EA101';
      break;
    case 'lightGreen':
      colorHexValue = '#92D25F';
      break;
    case 'teal':
      colorHexValue = '#01AAB5';
      break;
    case 'lightTeal':
      colorHexValue = '#70E6D9';
      break;
    case 'blue':
      colorHexValue = '#86ABE3';
      break;
    case 'lightBlue':
      colorHexValue = '#77A9F4';
      break;
    case 'deepBlue':
      colorHexValue = '#003EB9';
      break;
    case 'lightGrey':
      colorHexValue = '#7D95B3';
      break;
    case 'grey':
      colorHexValue = '#626188';
      break;
    case 'black':
      colorHexValue = '#0A1A39';
      break;
    case 'brown':
      colorHexValue = '#823408';
      break;

    // returns black as default so we can ensure that everything is readable
    default:
      colorHexValue = '#0A1A39';
      break;
  }

  // calculate opacity value
  const opacityValue = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);

  return colorHexValue + opacityValue.toString(16).toUpperCase();
};

export default getColorCodeByNameWithOpacity;
