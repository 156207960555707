import { Box, Center, Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const navBarHeight = '50px';

const NavBar = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  return (
    <Flex bg="#0a4683" w="100%" h={navBarHeight} p="2" color="#ffffff" align="center">
      {/* Vertical alignment copied from here:
          https://chakra-templates.dev/navigation/navbar */}
      <Flex flex={{ base: 1, md: 'auto' }}>
        <Center xs={{}}>
          <Box
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => navigate(`/organizations/${organizationId}/projects`)}
          >
            <b>DocCape</b>
          </Box>
        </Center>
      </Flex>
    </Flex>
  );
};

export default NavBar;
