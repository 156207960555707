/**
 * Slice which provides reducer and state of the user state
 */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getUserData, putUserData } from '../api/user';

const initialState = {
  data: {},
  status: 'idle',
  error: null,
};

/**
 * Retrieves user data
 */
export const fetchUserData = createAsyncThunk('user/data', async () => {
  const response = await getUserData();
  return response.data;
});

/**
 * Update user data
 */
export const updateUserData = createAsyncThunk(
  'user/updateData',
  async ({ firstName, lastName }) => {
    const response = await putUserData(firstName, lastName);
    return response.data;
  },
);

/**
 * The slice of the user state
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /**
     * Clears the user state by setting it to the initial state
     */
    clearUserData() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'fetching';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = 'succesful';
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.data.authenticated = false;
      })
      .addCase(updateUserData.fulfilled, (state, action) => {
        const { firstName, lastName } = action.meta.arg;
        state.data.firstName = firstName;
        state.data.lastName = lastName;
      });
  },
});

export const selectUserData = createSelector(
  (state) => state.user,
  (user) => user.data,
);

export const { clearUserData } = userSlice.actions;

export default userSlice.reducer;
