/**
 * Based on Martin Camacho's react-text-annotate
 * https://github.com/mcamac/react-text-annotate
 */
import React from 'react';
import { selectionIsBackwards, selectionIsEmpty } from '../../../utils/uiUtils';
import VScrollable from '../../molecules/VScrollable';
import AnnotationMark from './AnnotationMark';

const WrapperToken = ({ text, index, hasWhitespace }) => {
  return (
    <span data-i={index}>
      {text}
      {hasWhitespace ? ' ' : ''}
    </span>
  );
};

const TokenAnnotator = ({
  textLabels,
  textSegments,
  onAnnotationAdd,
  findAndRemoveAnnotation,
  annotateAllUnmarked,
  toggleSelectedAnnotation,
  changeTextLabelNameOfAllAnnotationsWithSameText,
  removeAllAnnotationsWithSameTextLabelAndText,
  removeAllAnnotationsWithSameText,
  selectedParagraphIndex,
  isPopoverOpen,
  closePopover,
  openPopover,
  setIsPopoverOpen,
  setCrIdAndPseudonymOfAnnotation,
  isAnnotationSelected,
  changeTextLabelNameOfAnnotation,
  changeCrIdOfAnnotation,
  changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
  isDisplayingPseudonyms,
}) => {
  // TODO: Maybe move into hook?
  const handleMouseUp = () => {
    if (!onAnnotationAdd) return;

    const selection = window.getSelection();

    if (selectionIsEmpty(selection)) return;

    if (
      !selection.anchorNode.parentElement.hasAttribute('data-i') ||
      !selection.focusNode.parentElement.hasAttribute('data-i')
    ) {
      window.getSelection().empty();
      return;
    }

    let start = parseInt(selection.anchorNode.parentElement.getAttribute('data-i'), 10);
    let end = parseInt(selection.focusNode.parentElement.getAttribute('data-i'), 10);

    if (selectionIsBackwards(selection)) {
      [start, end] = [end, start];
    }

    const newAnnotation = onAnnotationAdd(start, end);

    openPopover(newAnnotation.id);
    window.getSelection().empty();
  };

  const onRemoveMark = (annotation) => {
    findAndRemoveAnnotation(selectedParagraphIndex, annotation.start, annotation.end);
  };

  const changeTextLabelName = (annotation, textLabel) => {
    changeTextLabelNameOfAnnotation(annotation, textLabel);
  };

  return (
    <VScrollable
      height="100%"
      overflowY="auto"
      role="button"
      tabIndex={0}
      onMouseUp={handleMouseUp}
    >
      {textSegments.map((textSegment, splitIdx) => {
        const { annotation, idx, text, hasWhitespace } = textSegment;
        if (annotation !== null) {
          return (
            <AnnotationMark
              annotation={annotation}
              textLabels={textLabels}
              selectedParagraphIndex={selectedParagraphIndex}
              changeTextLabelNameOfAllAnnotationsWithSameText={
                changeTextLabelNameOfAllAnnotationsWithSameText
              }
              isPopoverAddAnnotationsOpen={isPopoverOpen[annotation.id]}
              setIsPopoverAddAnnotationsOpen={setIsPopoverOpen}
              closeAddAnnotationsPopover={() => closePopover(annotation.id)}
              openAddAnnotationsPopover={() => openPopover(annotation.id)}
              color={textLabels[annotation.textLabelName].color}
              selectAnnotation={toggleSelectedAnnotation}
              onRemove={() => onRemoveMark(annotation)}
              onClick={toggleSelectedAnnotation}
              deleteAllAnnotationsWithSameTextLabelAndText={
                removeAllAnnotationsWithSameTextLabelAndText
              }
              deleteAllAnnotationsWithSameText={removeAllAnnotationsWithSameText}
              annotateAllUnmarked={annotateAllUnmarked}
              setCrIdAndPseudonym={setCrIdAndPseudonymOfAnnotation}
              isSelected={isAnnotationSelected(annotation)}
              changeTextLabelName={changeTextLabelName}
              changeCrId={(crId) => changeCrIdOfAnnotation(annotation, crId)}
              changeCrIdOfAllAnnotationsWithSameTextLabelAndText={
                changeCrIdOfAllAnnotationsWithSameTextLabelAndText
              }
              isDisplayingPseudonyms={isDisplayingPseudonyms}
            />
          );
        }
        return (
          <WrapperToken
            // eslint-disable-next-line react/no-array-index-key
            key={`Split-${splitIdx}`}
            index={idx}
            text={text}
            hasWhitespace={hasWhitespace}
          />
        );
      })}
    </VScrollable>
  );
};

export default TokenAnnotator;
