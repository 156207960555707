import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoadStatus, selectLoadStatus } from '../reducers/systemLoadStatusSlice';

/**
 * Provides the system load status
 * @param {boolean} trigger - Trigger to fetch the load status
 */
const useSystemLoadStatus = (trigger) => {
  const dispatch = useDispatch();
  const loadStatus = useSelector(selectLoadStatus);

  useEffect(() => {
    dispatch(fetchLoadStatus());
  }, [trigger]);

  return { loadStatus };
};

export default useSystemLoadStatus;
