/**
 *
 * @param {*} arrayOfObjects Array with any number of objects {key: string, value: []}:
 * Takes the value and create for every entry a object with the key and corresponding value
 * @returns {array of new objects}: [{key: string, value[0]}, {key: string, value[1]} , ... ]
 */
export const transformData = (arrayOfObjects) => {
  if (arrayOfObjects.length === 0) return [];
  // Takes the first object of the array
  const firstObject = arrayOfObjects[0];
  // Get array of values
  const { values } = firstObject;

  // Add for every entry in values the key of the original object
  return values.map((value, index) => ({
    key: firstObject.key,
    value: value.toString(),
  }));
};
