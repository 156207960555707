import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateApiKey } from '../../../../api/authentication';
import { getBaseURL } from '../../../../api/axios';
import Button from '../../../atoms/tailwind/Button';
import CopyInput from '../../../atoms/tailwind/CopyInput';
import AppToaster from '../../../molecules/AppToaster';

/**
 * Renders the api manager section of the user settings
 * The api manager allows the user to generate an api key which can be used to access the api
 */
const ApiManager = () => {
  const [apiKey, setApiKey] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  /**
   * Executes the api call to generate an api key and sets the api key in the state
   */
  const createApiKey = () => {
    setIsCopied(false);
    generateApiKey().then((response) => {
      setApiKey(response.data.apiKey);
    });
  };

  /**
   * Copies the api key to the clipboard and shows a success message
   */
  const copyApiKey = () => {
    if (apiKey !== '') {
      navigator.clipboard.writeText(apiKey);
    }
    AppToaster({
      description: t('userSettings.apiManager.copiedToClipboard'),
      status: 'success',
      position: 'bottom',
      duration: 1000,
    });
  };

  return (
    <div className="">
      <div>
        <p>
          <a
            className="text-base text-grey-1 underline"
            href={`${getBaseURL()}/docs`}
            target="_blank"
            rel="noreferrer"
          >
            {t('userSettings.apiManager.documentation')}.
          </a>
        </p>
        <p className="text-base text-grey-1">{t('userSettings.apiManager.description')}</p>
      </div>
      <br />
      <div className="flex w-2/3 flex-col items-center space-y-4">
        <div className="flex justify-center">
          <Button
            buttonText={t('userSettings.apiManager.generateApiKey')}
            buttonAction={createApiKey}
          />
        </div>
        <div className="w-full">
          <CopyInput
            id="apiKey"
            name="apiKey"
            type="text"
            placeholder={t('userSettings.apiManager.apiKey')}
            value={apiKey}
            onClick={copyApiKey}
            disabled={apiKey === ''}
            isCopied={isCopied}
            setIsCopied={setIsCopied}
          />
        </div>
      </div>
      {apiKey ? (
        <>
          <p className="text-base text-grey-1">{t('userSettings.apiManager.copyNotice')}</p>
          <p className="text-base text-grey-1">{t('userSettings.apiManager.generationNotice')}</p>
        </>
      ) : null}
    </div>
  );
};

export default ApiManager;
