import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAnonymizedBase64 } from '../api/document';

const initialState = {
  data: null,
  status: '',
  error: '',
};

/**
 * Fetches the documents
 */
export const fetchAnonymizedDocument = createAsyncThunk(
  'fetch/anonymizedDocument',
  async ({ projectId, documentId }) => {
    const response = await getAnonymizedBase64(projectId, documentId);
    return response.data;
  },
);

/**
 * The slice of the document state
 */
export const anonymizedDocumentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    /**
     * Clears the anonymized document state by setting it to the initial state
     */
    clearAnonymizedDocument() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAnonymizedDocument.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchAnonymizedDocument.fulfilled, (state, action) => {
        return { ...state, status: 'succeeded', data: action.payload };
      })
      .addCase(fetchAnonymizedDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectAnonymizedDocument = createSelector(
  (state) => state.anonymizedDocument,
  (anonymizedDocument) => anonymizedDocument.data,
);
export const selectAnonymizedDocumentStatus = createSelector(
  (state) => state.anonymizedDocument,
  (anonymizedDocument) => anonymizedDocument.status,
);
export const selectAnonymizedDocumentError = createSelector(
  (state) => state.anonymizedDocument,
  (anonymizedDocument) => anonymizedDocument.error,
);

export const { clearAnonymizedDocument } = anonymizedDocumentSlice.actions;

export default anonymizedDocumentSlice.reducer;
