import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resendEmailInvitationByAdmin } from '../api/authentication';
import AppToaster from '../components/molecules/AppToaster';
import {
  addUserToOrganization,
  clearUsersOfOrganization,
  fetchUsersByOrganization,
  removeUserFromOrganization,
  selectUsersOfOrganizationData,
  selectUsersOfOrganizationError,
  selectUsersOfOrganizationStatus,
  updateUserRole,
} from '../reducers/usersOfOrganizationSlice';

const useUsersOrganization = (orgId) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [users, setUsers] = useState([]);

  const sliceUsersData = useSelector(selectUsersOfOrganizationData);
  const fetchStatus = useSelector(selectUsersOfOrganizationStatus);
  const fetchError = useSelector(selectUsersOfOrganizationError);

  const initSlice = () => {
    setIsLoading(true);
    dispatch(fetchUsersByOrganization(orgId)).unwrap();
  };

  const transformSliceData = () => {
    if (fetchStatus === 'pending') {
      setIsLoading(true);
    }
    if (fetchStatus === 'succeeded') {
      setUsers(sliceUsersData);
      setIsLoading(false);
    }
    if (fetchStatus === 'failed') {
      setIsLoading(true);
    }
  };

  /**
   * Add user to organization
   * @param {string} email - The email of the user to invite.
   * @returns {boolean} - True if the user was invited successfully, false otherwise.
   */
  const onAddUser = async (email) => {
    let isSuccessful = false;
    try {
      await dispatch(addUserToOrganization({ orgId, email })).unwrap();
      AppToaster({
        description: t('organizationMngmt.invitationUser.success'),
        status: 'success',
      });
      setIsValidEmail(true);
      isSuccessful = true;
    } catch (error) {
      setIsValidEmail(false);
      isSuccessful = false;
      if (error.status === 409) {
        setErrorMsg(t('organizationMngmt.invitationUser.errorMsg.duplicateUser'));
      }
    }
    await dispatch(fetchUsersByOrganization(orgId)).unwrap();
    return isSuccessful;
  };

  /**
   * Invites a user to the organization using the corresponding email.
   * @param {string} email - The email of the user to invite.
   * @returns {boolean} - True if the user was invited successfully, false otherwise.
   */
  const inviteUserToOrganization = async (email) => {
    return onAddUser({
      email,
    });
  };

  /**
   * Refreshes the token by sending a new one to the user's email through an admin
   * @param {number} orgId id of the organization the user was invited to
   * @param {number} userId Id of the user to be invited
   */
  const refreshInvitationTokenByAdmin = async (userId) => {
    let isSuccessful = false;
    try {
      await resendEmailInvitationByAdmin(orgId, userId);
      AppToaster({
        description: t('organizationMngmt.userMngmt.resendEmailInvitation.success'),
        status: 'success',
      });
      isSuccessful = true;
    } catch (err) {
      return false;
    }
    return isSuccessful;
  };

  const onRemoveUserFromOrganization = async (userId) => {
    let isSuccessful = false;

    try {
      await dispatch(removeUserFromOrganization({ orgId, userId })).unwrap();
      AppToaster({
        description: t('organizationMngmt.userMngmt.deleteUser.success'),
        status: 'success',
      });
      isSuccessful = true;
    } catch (error) {
      return false;
    }
    await dispatch(fetchUsersByOrganization(orgId)).unwrap();
    return isSuccessful;
  };

  const updateUserRoleOrganization = async (user, organizationRole) => {
    if (user.organizationRole === organizationRole) return; // No change in role
    await dispatch(updateUserRole({ orgId, userId: user.id, organizationRole })).unwrap();
    AppToaster({
      description: t('organizationMngmt.userMngmt.changeUserRole.success'),
      status: 'success',
    });
    initSlice();
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clear users organization state when unmounting
      dispatch(clearUsersOfOrganization());
    };
  }, [orgId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceUsersData]);

  return {
    users,
    isLoading,
    onAddUser,
    isShown,
    setIsShown,
    isValidEmail,
    setIsValidEmail,
    errorMsg,
    setErrorMsg,
    inviteUserToOrganization,
    onRemoveUserFromOrganization,
    updateUserRole: updateUserRoleOrganization,
    refreshInvitationTokenByAdmin,
  };
};

export default useUsersOrganization;
