import { getLocale } from '../translations/utils';
import { deepCopy } from './utils';

/**
 * Converts from the displayed value to the value we need to perform the slice operation.
 *
 * In the UI, 0 and 1 mark the beginning of the text.
 * All other positive and negative values are meant including that position.
 * Later we will extract the prefix as follows:
 *   prefix = text.slice(0, convertedStart)
 * The end of the slice opearation is exclusive.
 *
 * Mappings:
 * 0, 1 -> 0
 * 2, ... -> 1, ...
 * -1, ... -> -1, ...
 *
 * @param {*} start
 * @returns
 */
export const convertStartFromDisplayedValue = (start) => {
  if (start === 0 || start === 1) {
    return 0;
  }
  if (start > 1) {
    return start - 1;
  }
  return start;
};

/**
 * Transforms a given textLabels object to an array
 *
 * @param {Object} textLabelsObject The textLabels as attributes os an object {textLabelName1: textLabel1, textLabelName2: textLabel2, ...}
 * @returns The textLabels as an array [textLabel1, textLabel2, ...]
 */
export const transformTextLabelsObjectToArray = (textLabelsObject) => {
  // Creates an array of the structure: [[textLabelName, textLabelObject], [textLabelName, textLabelObject], ...]
  const entries = Object.entries(textLabelsObject);

  // Only the second entry is needed, because textLabelName is included in the textLabelObject
  const textLabelsAsArray = entries.map((textLabelArray) => textLabelArray[1]);
  return textLabelsAsArray;
};

/**
 * Converts from the displayed value to the value we need to perform the slice operation.
 *
 * In the UI, 0 and -1 mark the end of the text.
 * All other positive and negative values are meant including that position.
 * Later we will extract the suffix as follows:
 *   suffix = text.slice(convertedStop, Number.MAX_SAFE_INTEGER)
 * The start of the slice operation is inclusive.
 *
 * Mappings:
 * 0, -1 -> Number.MAX_SAFE_INTEGER
 * 1, ... -> 1, ...
 * -2, ... -> -1, ...
 *
 * @param {*} start
 * @returns
 */
export const convertStopFromDisplayedValue = (stop) => {
  if (stop === 0 || stop === -1) {
    return Number.MAX_SAFE_INTEGER;
  }
  if (stop < -1) {
    return stop + 1;
  }
  return stop;
};

/**
 * Returns the color corresponding to the specified textLabel index
 * @param {number} textLabelIndex The index of the textLabel
 * @returns The color as string
 */
export const createTextLabelColor = (textLabelIndex) => {
  const colors = [
    'lightPurple',
    'purple',
    'deepPurple',
    'deepPink',
    'pink',
    'lightPink',
    'deepOrange',
    'orange',
    'green',
    'deepGreen',
    'lightGreen',
    'teal',
    'lightTeal',
    'blue',
    'lightBlue',
    'deepBlue',
    'lightGrey',
    'grey',
    'black',
    'brown',
  ];
  const colorID = textLabelIndex % colors.length;
  return colors[colorID];
};

/**
 * Used in when the textLabel marked as hidden
 */
export const textLabelHiddenColor = 'gray.400';

/**
 * Extracts the relevant attributes of a I18n object of a slice text label and returns the relevant attributes
 *
 * @param sliceTextLabelI18n The I18n object of a slice text label
 * @return Object with relevant attributes
 */
export const extractRelevantAttributesSliceTextLabelI18n = (sliceTextLabelI18n) => {
  // Extracts attributes from sliceTextLabelI18n and copies the remaining attributes into textLabelI18n
  const { id, version, projectId, lastModified, createdAt, ...textLabelI18n } = sliceTextLabelI18n;
  return textLabelI18n;
};

/**
 * Finds the correct i18n object for a slice text label
 * @param {*} sliceTextLabelI18n
 * @param {*} locale
 * @returns
 */
const findCorrectI18n = (sliceTextLabelI18n, locale) => {
  let sliceTextLabelI18nLocale = sliceTextLabelI18n.find((i18n) => i18n.language === locale);
  if (!sliceTextLabelI18nLocale) {
    if (sliceTextLabelI18n.length > 0) {
      // use 'en' as fallback
      sliceTextLabelI18nLocale = sliceTextLabelI18n.find((i18n) => i18n.language === 'en');
      if (!sliceTextLabelI18nLocale) {
        // if no 'en' exists, use the first locale as fallback
        sliceTextLabelI18nLocale = sliceTextLabelI18n[0];
      }
    } else {
      // no locale found, create an empty one
      sliceTextLabelI18nLocale = { language: 'en', version: 0, description: '' };
    }
  }
  return sliceTextLabelI18nLocale;
};

/**
 * Extracts the relevant attributes of a slice text label and returns an object with the relevant attributes
 *
 * @param {Object} sliceTextLabel The slice text label
 * @param {String} locale The current locale
 * @return Object with relevant attributes
 */
export const extractRelevantAttributesSliceTextLabel = (sliceTextLabel, locale) => {
  // Extracts attributes from sliceTextLabel and copies the remaining attributes into textLabel
  const {
    id,
    version,
    projectId,
    textLabelI18n: sliceTextLabelI18n,
    lastModified,
    createdAt,
    ...textLabel
  } = sliceTextLabel;
  const sliceTextLabelI18nLocale = findCorrectI18n(sliceTextLabelI18n, locale);
  const textLabelI18n = extractRelevantAttributesSliceTextLabelI18n(sliceTextLabelI18nLocale);
  return { ...textLabel, ...textLabelI18n };
};

/**
 * Handles changing an attribute of a tag.
 * Synchronizes the change with the store.
 *
 * @param {string} labelName References the tag.
 * @param {string} attributeName References the attribute.
 * @param {string} value New value at the attribute.
 */
export const changeTextLabelAttribute = (textLabels, labelName, attributeName, value) => {
  const label = { ...textLabels[labelName] };
  label[attributeName] = value;
  const updatedTextLabels = { ...textLabels, [labelName]: label };
  return updatedTextLabels;
};

/**
 * Config for extended replacement options.
 * This array contains all extended replacement options (= attributes of the textlabel object) which can be selected.
 * Each option has a value and a list of attributes, which depend on the option
 * (e.g. usePartialReplacement has partialReplacementStart and partialReplacementStop and they are only relevant, if usePartialReplacement is true).
 *
 */
const extendedReplacementOptions = [
  {
    value: 'usePartialReplacement',
    extendedReplacementAttributes: [
      { value: 'partialReplacementStart', default: 0 },
      { value: 'partialReplacementStop', default: 1 },
    ],
  },
  {
    value: 'useDatePartialReplacement',
    extendedReplacementAttributes: [
      { value: 'replaceDay', default: false },
      { value: 'replaceMonth', default: false },
      { value: 'replaceYear', default: false },
      { value: 'showWeekday', default: false },
      { value: 'dateLocale', default: 'AUTO' },
    ],
  },
  {
    value: 'useNumericPartialReplacement',
    extendedReplacementAttributes: [
      { value: 'partialReplacementStart', default: 0 },
      { value: 'partialReplacementStop', default: 1 },
    ],
  },
  {
    value: 'useSuppression',
    extendedReplacementAttributes: [{ value: 'suppressionLength', default: 4 }],
  },
];

/**
 * Handles changing an extended replacement attribute of a tag.
 * It sets all attributes depending on an option to false, if the option is not selected one.
 * @param {Array} textLabels List of text labels
 * @param {Object} labelName Name of the text label
 * @param {string} selectedOption Name of the selected option
 * @returns The updated text labels
 */
export const changeTextLabelExtendedReplacement = (textLabels, labelName, selectedOption) => {
  // set all options to false but the selected one
  const updatedTextLabel = { ...textLabels[labelName] };
  extendedReplacementOptions.forEach((option) => {
    // for each option, reset all attributes depending on the option (if not selected)
    if (option.value !== selectedOption) {
      option.extendedReplacementAttributes.forEach((attribute) => {
        updatedTextLabel[attribute.value] = attribute.default;
      });
    }
    updatedTextLabel[option.value] = option.value === selectedOption;
  });
  const updatedTextLabels = { ...textLabels, [labelName]: updatedTextLabel };
  return updatedTextLabels;
};

/**
 * All i18n attribute of a slice text label
 */
const i18nAttributes = ['description'];

/**
 * Creates a new i18n object for a slice text label
 * from a text label from the hook
 *
 * @param {*} language The language of the i18n object
 * @param {*} textLabel The text label from the hook
 * @returns The i18n object of the slice text label
 */
export const createI18nSliceObject = (language, textLabel) => {
  const i18n = {
    language,
    version: 0,
  };

  i18nAttributes.forEach((attribute) => {
    i18n[attribute] = textLabel[attribute];
  });
  return i18n;
};

/**
 * Creates a new slice text label from a text label from the hook
 * @param {*} textLabel The text label from the hook
 * @param {*} projectId The project id
 * @returns The new slice text label
 */
const createNewSliceTextLabel = (textLabel, projectId) => {
  const sliceTextLabel = {
    version: 0,
    projectId,
    textLabelI18n: [createI18nSliceObject(getLocale(), textLabel)],
  };
  Object.entries(textLabel).forEach(([key, value]) => {
    if (!i18nAttributes.includes(key) && key !== 'color') {
      sliceTextLabel[key] = value;
    }
  });
  return sliceTextLabel;
};

/**
 * Updates a slice text label with a text label from the hook
 *
 * @param {*} textLabel The text label from the hook
 * @param {*} sliceTextLabel The slice text label
 * @param {*} excludeAttributes The attributes that should not be updated
 * @returns The updated slice text label
 */
const updateSliceTextLabel = (textLabel, sliceTextLabel, excludeAttributes = []) => {
  const updatedSliceTextLabel = { ...sliceTextLabel };
  const updatedSliceTextLabelI18n = [...updatedSliceTextLabel.textLabelI18n];
  // update i18n
  const idxOfLocale = updatedSliceTextLabelI18n.findIndex((i18n) => i18n.language === getLocale());
  if (idxOfLocale === -1) {
    updatedSliceTextLabelI18n.push(createI18nSliceObject(getLocale(), textLabel));
  } else {
    i18nAttributes.forEach((attribute) => {
      if (!excludeAttributes.includes(attribute)) {
        updatedSliceTextLabelI18n[idxOfLocale][attribute] = textLabel[attribute];
      }
    });
  }
  // update other attributes
  Object.entries(textLabel).forEach(([attribute, value]) => {
    if (attribute in updatedSliceTextLabel && !excludeAttributes.includes(attribute)) {
      updatedSliceTextLabel[attribute] = value;
    }
  });
  return { ...updatedSliceTextLabel, textLabelI18n: updatedSliceTextLabelI18n };
};

/**
 * Updates the slice text labels from model with the text labels from the hook.
 *
 * @param {*} sliceTextLabels The slice text labels
 * @param {*} textLabels The text labels from the hook
 * @returns The updated slice text labels
 */
const updateSliceTextLabelsFromModel = (sliceTextLabels, textLabels) => {
  const excludedAttributes = ['name', 'fromModel'];
  return sliceTextLabels.map((sliceTextLabel) => {
    if (sliceTextLabel.fromModel) {
      const textLabel = textLabels[sliceTextLabel.name];
      const updatedSliceTextLabel = updateSliceTextLabel(
        textLabel,
        sliceTextLabel,
        excludedAttributes,
      );
      return updatedSliceTextLabel;
    }
    return sliceTextLabel;
  });
};

/**
 * Removes deleted slice text labels that are not from model
 *
 * @param {*} sliceTextLabels The slice text labels
 * @param {*} textLabels The text labels from the hook
 * @returns The updated slice text labels
 */
const removeDeletedSliceTextLabelsNotFromModel = (sliceTextLabels, textLabels) => {
  return sliceTextLabels.filter((sliceTextLabel) => {
    return sliceTextLabel.fromModel || sliceTextLabel.name in textLabels;
  });
};

const addNewSliceTextLabelsNotFromModel = (sliceTextLabels, textLabels) => {
  const addedLabels = [];
  const { projectId } = sliceTextLabels[0];
  Object.values(textLabels).forEach((textLabel) => {
    const isNewLabel =
      !sliceTextLabels.find((sliceTextLabel) => sliceTextLabel.name === textLabel.name) &&
      !textLabel.fromModel;
    if (isNewLabel) {
      addedLabels.push(textLabel.name);
      const newSliceTextLabel = createNewSliceTextLabel(textLabel, projectId);
      sliceTextLabels.push(newSliceTextLabel);
    }
  });
  return {
    sliceTextLabels,
    addedLabels,
  };
};

/**
 * Updates the slice text labels that are not from model
 *
 * @param {*} sliceTextLabels The slice text labels
 * @param {*} textLabels The text labels from the hook
 * @param {*} labelsToExclude The labels to exclude
 * @returns The updated slice text labels
 */
const updateSliceTextLabelsNotFromModel = (sliceTextLabels, textLabels, labelsToExclude = []) => {
  const excludedAttributes = ['fromModel'];
  return sliceTextLabels.map((sliceTextLabel) => {
    if (!sliceTextLabel.fromModel && !labelsToExclude.includes(sliceTextLabel.name)) {
      const textLabel = textLabels[sliceTextLabel.name];
      const updatedSliceTextLabel = updateSliceTextLabel(
        textLabel,
        sliceTextLabel,
        excludedAttributes,
      );
      return updatedSliceTextLabel;
    }
    return sliceTextLabel;
  });
};

/**
 * Merges the slice text labels that are not from model
 *
 * @param {*} sliceTextLabels The slice text labels
 * @param {*} textLabels The text labels from the hook
 * @returns The updated slice text labels
 */
const mergeSliceTextLabelsNotFromModel = (sliceTextLabels, textLabels) => {
  // Remove deleted text labels that are not from model
  let updatedSliceTextLabels = removeDeletedSliceTextLabelsNotFromModel(
    sliceTextLabels,
    textLabels,
  );

  // Add new text labels that are not from model
  let addedLabels = [];
  ({ sliceTextLabels: updatedSliceTextLabels, addedLabels } = addNewSliceTextLabelsNotFromModel(
    updatedSliceTextLabels,
    textLabels,
  ));

  // Update text labels that are not new and not from model
  updatedSliceTextLabels = updateSliceTextLabelsNotFromModel(
    updatedSliceTextLabels,
    textLabels,
    addedLabels,
  );

  return updatedSliceTextLabels;
};

/**
 * Transforms the textLabels from hook format to slice format
 * by updating a copy of the sliceTextLabels with the textLabels:
 *
 *  - Updates i18n in text labels from model
 *  - Adds new text labels that are not from model
 *  - Removes deleted text labels that are not from model
 *  - Updates text labels that are not new and not from model
 *
 * @param {*} textLabels The text labels from the hook
 * @param {*} sliceTextLabels The slice text labels
 * @returns The updated slice text labels
 */
export const transformToSliceData = (textLabels, sliceTextLabels) => {
  let updatedSliceTextLabels = deepCopy(sliceTextLabels);

  // Update text labels that are from model
  updatedSliceTextLabels = updateSliceTextLabelsFromModel(updatedSliceTextLabels, textLabels);

  // Update text labels that are not from model
  updatedSliceTextLabels = mergeSliceTextLabelsNotFromModel(updatedSliceTextLabels, textLabels);

  return updatedSliceTextLabels;
};
