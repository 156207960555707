export const createImageLabel = ({
  name,
  isEnabled = true,
  fromModel = false,
  anonymizationParameters = '{}',
  anonymizationType = 'MASK',
  color = 'red',
}) => {
  return {
    name,
    isEnabled,
    fromModel,
    anonymizationParameters,
    anonymizationType,
    color,
  };
};
