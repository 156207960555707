import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../utils/tailwindUtils';

/**
 * "Input" field for displaying a copyable value.
 * The input is clickable but not editable. Clicking on it will copy the value to the clipboard.
 * @param {string} id The id of the input field
 * @param {string} name The name of the input field
 * @param {string} placeholder The placeholder of the input field
 * @param {boolean} disabled Whether the input field is disabled
 * @param {function} onClick The function to execute when the input field is clicked
 *
 */
const CopyInput = ({
  id,
  name,
  placeholder,
  disabled,
  isCopied,
  setIsCopied,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleCopy = (event) => {
    onClick(event);
    setIsCopied(true);
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        name={name}
        id={id}
        onClick={handleCopy}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={(event) => event.target.blur()}
        className={classNames(
          'block w-full pl-3 rounded-xl border-0 py-1.5 text-blue-2 text-base shadow-sm ring-1 ring-inset ring-babyBlue-1 placeholder:text-grey-3 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:leading-6 selection-none',
          disabled ? 'cursor-not-allowed opacity-30' : 'hover:cursor-pointer ',
        )}
        {...rest}
      />
      <button
        type="button"
        onClick={handleCopy}
        className={classNames(
          `absolute inset-y-0 right-0 flex w-14 items-center justify-center rounded-r-xl bg-babyBlue-1 px-2 text-white`,
          disabled ? 'cursor-not-allowed opacity-30' : '',
        )}
        disabled={disabled}
      >
        {isCopied ? t('general.copied') : t('general.copy')}
      </button>
    </div>
  );
};

export default CopyInput;
