import { Alert, AlertIcon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { postResetPassword } from '../api/authentication';
import doccapeLogo from '../assets/DocCape_Favicon.svg';
import Button from '../components/atoms/tailwind/Button';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import CustomForm from '../components/molecules/tailwind/CustomForm';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';

const ResetPasswordRequestMail = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { token } = useParams();

  const minPasswordLength = 8;

  /**
   * Validates the given passwords and sets it when valid
   */
  const onSubmit = () => {
    if (password === confirmPassword && String(password).length >= minPasswordLength) {
      postResetPassword(token, password)
        .then(() => {
          setErrorMessage('');

          // clear state for security reasons
          setPassword('');
          setConfirmPassword('');
          navigate('/login');
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setErrorMessage(t('resetPassword.tokenInvalid'));
          }
          if (error.response.status === 422) {
            setErrorMessage(t('resetPassword.passwordTooShort'));
          }
          if (error.response.status === 500) {
            setErrorMessage(t('resetPassword.serverError'));
          }
        });
    } else {
      if (password !== confirmPassword) {
        setErrorMessage(t('resetPassword.passwordsDoNotMatch'));
      }
      if (String(password).length < minPasswordLength) {
        setErrorMessage(t('resetPassword.passwordTooShort'));
      }
    }
  };

  // useEffect for handling the enter key press
  useEffect(() => {
    const handleEnterKeyPress = (event) => {
      if (event.key === 'Enter') {
        onSubmit();
      }
    };
    document.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [password, confirmPassword]);

  return (
    <ExternalViewLayout>
      <CustomCard
        image={
          <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
        }
        title={t('resetPassword.setNewPasswordTitle')}
        subtitle={null}
        buttonText={t('resetPassword.send')}
        buttonAction={onSubmit}
        additionalButton={
          <Button
            fullWidth
            color="white-large"
            buttonText={t('resetPassword.cancel')}
            buttonAction={() => navigate('/login')}
          />
        }
        content={
          <CustomForm
            inputFields={[
              {
                id: 'password',
                name: t('resetPassword.setNewPassword'),
                type: 'password',
                value: password,
                onChange: (event) => setPassword(event.target.value),
                required: true,
              },
              {
                id: 'confirmPassword',
                name: t('resetPassword.confirmNewPassword'),
                type: 'password',
                value: confirmPassword,
                onChange: (event) => setConfirmPassword(event.target.value),
                required: true,
              },
            ]}
            additionalContent={
              <div>
                {errorMessage !== '' ? (
                  // TODO: Implement tailwind alert after design is finalized
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                ) : null}
              </div>
            }
          />
        }
      />
    </ExternalViewLayout>
  );
};

export default ResetPasswordRequestMail;
