import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import NavBar, { navBarHeight } from '../molecules/NavBar';

export const contentHeight = `calc(100vh - ${navBarHeight})`;

const Layout = (props) => {
  const { children } = props;

  return (
    <Grid
      templateRows={`${navBarHeight} ${contentHeight}`}
      templateColumns="100%"
      bg="gray.200"
      width="100%"
      height="100%"
    >
      <GridItem>
        <NavBar />
      </GridItem>
      <GridItem>{children}</GridItem>
    </Grid>
  );
};

export default Layout;
