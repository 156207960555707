import React from 'react';

const MoreIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m13,18.41c-.39,0-.72-.12-1-.38-.28-.25-.42-.6-.42-1.05,0-.38.13-.71.4-.98.27-.28.6-.41.99-.41s.73.14,1.01.41.42.6.42.99c0,.44-.14.79-.42,1.05s-.6.38-.98.38Z" />
      <path d="m19.01,18.41c-.39,0-.72-.12-1-.38-.28-.25-.42-.6-.42-1.05,0-.38.13-.71.4-.98.27-.28.6-.41.99-.41s.73.14,1.01.41.42.6.42.99c0,.44-.14.79-.42,1.05s-.6.38-.98.38Z" />
      <path d="m25.03,18.41c-.39,0-.72-.12-1-.38-.28-.25-.42-.6-.42-1.05,0-.38.13-.71.4-.98.27-.28.6-.41.99-.41s.73.14,1.01.41.42.6.42.99c0,.44-.14.79-.42,1.05s-.6.38-.98.38Z" />
    </svg>
  );
};

export default MoreIcon;
