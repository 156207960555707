import { convertStartFromDisplayedValue, convertStopFromDisplayedValue } from './textLabelService';
import { getDateAndLocale, getWeekday } from './utils';

/**
 * Computes the pseudonym according to the textLabel's partial repalcement settings.
 *
 * @param {*} annotation
 * @param {*} textLabel
 * @param {*} defaultPseudonym
 * @returns The partially replaced string.
 */
export const getPartialReplacement = (annotation, textLabel, defaultPseudonym) => {
  const start = convertStartFromDisplayedValue(textLabel.partialReplacementStart);
  const stop = convertStopFromDisplayedValue(textLabel.partialReplacementStop);
  if (!textLabel.useNumericPartialReplacement) {
    const prefix = annotation.text.slice(0, start);
    const toReplace = annotation.text.slice(start, stop);
    const suffix = annotation.text.slice(stop);
    if (toReplace !== '') {
      // only replace when interval is not empty
      return `${prefix}${prefix.length > 0 ? ' ' : ''}${defaultPseudonym}${
        suffix.length > 0 ? ' ' : ''
      } ${suffix}`;
    }
    return annotation.text;
  }
  const numericString = annotation.text.replace(/\D/g, '');
  const prefixLen = numericString.slice(0, start).length;
  const toReplaceLen = numericString.slice(start, stop).length;
  let pseudonym = annotation.text;
  let skippedNums = 0;
  let replacedNums = 0;
  for (let charIdx = 0; charIdx < pseudonym.length; charIdx += 1) {
    if (replacedNums === toReplaceLen) {
      // stop loop when all nums are replaced
      break;
    } else if (skippedNums === prefixLen && /\d/.test(pseudonym[charIdx])) {
      // replace number
      pseudonym = `${pseudonym.substring(0, charIdx)}*${pseudonym.substring(charIdx + 1)}`;
      replacedNums += 1;
    } else if (skippedNums < prefixLen && /\d/.test(pseudonym[charIdx])) {
      // skip numbers until we find the start of the replacement
      skippedNums += 1;
    }
  }
  return pseudonym;
};

/**
 * Tries to parse the date string. When successfull it will normalize and
 * partially replace the date.
 * It will try to parse the date in the order of the following languages: de, en.
 *
 * @param {*} annotation
 * @param {*} textLabel
 * @param {*} locale
 * @returns The partially replaced date or an empty string if it could not parse the date
 */
export const getDatePartialReplacement = (annotation, textLabel, language = 'auto') => {
  const dateAndLocale = getDateAndLocale(annotation.text, language);
  const { dateObj, locale } = dateAndLocale;
  const dayReplaceStr = `**`;
  const monthReplaceStr = `**`;
  const yearReplaceStr = `****`;

  if (dateObj === null) {
    // could not parse the date string
    // eslint-disable-next-line no-console
    console.warn('Could not parse: ', annotation.text);
    return '';
  }

  // could parse the date string
  let day = String(dateObj.getDate());
  let month = String(dateObj.getMonth() + 1); // getMonth() is zero-based
  let year = String(dateObj.getFullYear());
  let outStr = '';
  if (language === 'de' || (language === 'auto' && locale === 'de')) {
    day = textLabel.replaceDay ? dayReplaceStr : day.padStart(2, '0');
    month = textLabel.replaceMonth ? monthReplaceStr : month.padStart(2, '0');
    year = textLabel.replaceYear ? yearReplaceStr : year;
    outStr = `${day}.${month}.${year}`;
  } else if (language === 'en' || (language === 'auto' && locale === 'en')) {
    day = textLabel.replaceDay ? dayReplaceStr : day;
    month = textLabel.replaceMonth ? monthReplaceStr : month;
    year = textLabel.replaceYear ? yearReplaceStr : year;
    outStr = `${month}/${day}/${year}`;
  } else if (language === 'iso' || (language === 'auto' && locale === 'iso')) {
    day = textLabel.replaceDay ? dayReplaceStr : day.padStart(2, '0');
    month = textLabel.replaceMonth ? monthReplaceStr : month.padStart(2, '0');
    year = textLabel.replaceYear ? yearReplaceStr : year;
    outStr = `${year}-${month}-${day}`;
  } else if (language === 'auto' && locale === 'isoSlash') {
    day = textLabel.replaceDay ? dayReplaceStr : day.padStart(2, '0');
    month = textLabel.replaceMonth ? monthReplaceStr : month.padStart(2, '0');
    year = textLabel.replaceYear ? yearReplaceStr : year;
    outStr = `${year}/${month}/${day}`;
  } else {
    throw new Error(`Language ${language} is not supported!`);
  }

  if (textLabel.showWeekday) {
    outStr = `${outStr}(${getWeekday(dateAndLocale, language)})`;
  }
  return outStr;
};

/**
 * Replaces any given Text with the defined number of '*' chars
 * @param {number} replacementLength number of '*' that should be displayed instead of the text [4;inf]
 * @returns {string} string with the defined number of '*'
 */
export const getSuppression = (replacementLength) => {
  if (replacementLength > 4) {
    return '*'.repeat(replacementLength);
  }
  return '*'.repeat(4);
};

/**
 * Creates pseodonym from annotation and current textLabels settings.
 * The annotations crId and text attributes must be set.
 * @param {*} annotation
 * @returns pseudonym or "" if any annotation has undefined text or crId
 */
export const createPseudonymFromAnnotation = (annotation, textLabel) => {
  const defaultPseudonym = `${textLabel.defaultReplacement}-${annotation.crId}`;

  if (typeof annotation.text === 'undefined' && typeof annotation.crId === 'undefined') {
    return '';
  }

  if (textLabel.usePartialReplacement) {
    return getPartialReplacement(annotation, textLabel, defaultPseudonym);
  }
  if (textLabel.useDatePartialReplacement) {
    const language = textLabel.dateLocale.toLowerCase();
    const datePartialReplacement = getDatePartialReplacement(annotation, textLabel, language);
    return datePartialReplacement.length > 0 ? datePartialReplacement : defaultPseudonym;
  }
  if (textLabel.useSuppression) {
    return getSuppression(textLabel.suppressionLength);
  }
  return defaultPseudonym;
};

/**
 * Creates pseodonym from a string and current textLabels settings.
 * Instead of a real crId it will use 1 by default.
 *
 * @param {string} text The text to pseudonymize
 * @param {Object} textLabel The textLabel object used for pseudonymization
 * @returns pyeuodnym or "" if text is undefined
 */
export const createPseudonymFromText = (text, textLabel) => {
  const defaultPseudonym = `${textLabel.defaultReplacement}`;

  if (typeof text === 'undefined') {
    return '';
  }
  if (textLabel.usePartialReplacement) {
    return getPartialReplacement({ text }, textLabel, defaultPseudonym);
  }
  if (textLabel.useDatePartialReplacement) {
    const language = textLabel.dateLocale.toLowerCase();
    const datePartialReplacement = getDatePartialReplacement({ text }, textLabel, language);
    return datePartialReplacement.length > 0 ? datePartialReplacement : defaultPseudonym;
  }
  if (textLabel.useSuppression) {
    return getSuppression(textLabel.suppressionLength);
  }
  return defaultPseudonym;
};
