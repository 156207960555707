import React from 'react';
import {
  Popover as ChakraPopover,
  PopoverTrigger,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverContent,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react';

const Popover = ({
  returnFocusOnClose,
  placement,
  closeOnBlur,
  triggerContent,
  headerContent,
  bodyContent, // TODO: Maybe children?
  isOpen,
  onClose,
  onClickCloseButton,
  withAnchor,
}) => {
  return (
    <ChakraPopover
      returnFocusOnClose={returnFocusOnClose}
      placement={placement}
      closeOnBlur={closeOnBlur}
      isOpen={isOpen}
      onClose={onClose}
    >
      {withAnchor ? (
        <PopoverTrigger>
          <PopoverAnchor>{triggerContent}</PopoverAnchor>
        </PopoverTrigger>
      ) : (
        <PopoverTrigger>{triggerContent}</PopoverTrigger>
      )}
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton onClick={onClickCloseButton || null} />
        <PopoverHeader>{headerContent}</PopoverHeader>
        <PopoverBody>{bodyContent}</PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  );
};

export default Popover;
