/**
 * Slice where all configuration data is stored as well as the current version
 */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAvailableFeatures } from '../api/system';

const initialState = {
  data: {
    registrationEnabled: false,
    pdfSummaryEnabled: false,
  },
  status: '',
  error: '',
};

export const fetchAvailableFeatures = createAsyncThunk('fetch/availableFeatures', async () => {
  const response = await getAvailableFeatures();
  return response.data;
});

export const systemSlice = createSlice({
  name: 'systemFeatures',
  initialState,
  reducers: {
    /**
     * Clears the systemFeatures state by setting it to the initial state
     */
    clearSystemFeatures() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAvailableFeatures.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchAvailableFeatures.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAvailableFeatures.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const selectAvailableFeatures = createSelector(
  (state) => state.systemFeatures,
  (systemFeatures) => systemFeatures.data,
);
export const selectAvailableFeaturesStatus = createSelector(
  (state) => state.systemFeatures,
  (systemFeatures) => systemFeatures.status,
);

export const { clearSystemFeatures } = systemSlice.actions;

export default systemSlice.reducer;
