import React from 'react';

const Icon = () => {
  return (
    <svg
      className="h-5 w-5 shrink-0 text-blue-2"
      fill="currentColor"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
    </svg>
  );
};

export default Icon;
