import React from 'react';

const SettingsIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m26.98,19.03v-4.05h-1.38c-.13-.42-.31-.82-.51-1.2l.99-.99-2.86-2.86-.98.98c-.38-.2-.78-.38-1.2-.51v-1.38h-4.05v1.38c-.42.13-.82.31-1.2.51l-.98-.98-2.86,2.86.99.99c-.2.38-.38.78-.51,1.2h-1.38v4.05h1.38c.13.42.31.82.51,1.2l-.99.99,2.86,2.86.98-.98c.38.2.78.38,1.2.51v1.38h4.05v-1.38c.42-.13.82-.31,1.2-.51l.98.98,2.86-2.86-.99-.99c.2-.38.38-.78.51-1.2h1.38Zm-11.93-2.03c0-2.18,1.77-3.95,3.95-3.95s3.95,1.77,3.95,3.95-1.77,3.95-3.95,3.95-3.95-1.77-3.95-3.95Z" />
    </svg>
  );
};

export default SettingsIcon;
