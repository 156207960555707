import React from 'react';

/**
 * Custom select input element
 */
const Select = ({ children, onChange, ...rest }) => {
  return (
    <select
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset focus:ring-2 sm:text-sm sm:leading-6"
      onChange={onChange}
      {...rest}
    >
      {children}
    </select>
  );
};

export default Select;
