import React from 'react';
import AnalyzeIcon from '../icons/AnalyzeIcon';

const Searchbar = (props) => {
  const { type, name, id, placeholder, onChange, onBlur = null } = props;

  return (
    <div className="flex justify-center rounded-xl border border-babyBlue-6 bg-white px-2 text-xs font-medium tracking-tighter shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
      <div className="relative flex flex-1">
        <input
          id={id}
          className="block h-full border-0 py-0 pr-4 text-sm text-babyBlue-6 placeholder:text-babyBlue-6 focus:text-blue-2 focus:ring-0 focus:placeholder:text-blue-2 sm:text-sm"
          placeholder={placeholder}
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
        />
        <div className="pointer-events-none inset-y-0 right-0 flex items-center text-blue-2">
          <AnalyzeIcon />
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
