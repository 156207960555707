import { Grid, GridItem, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { getBackendVersion } from '../../../../api/system';

const About = () => {
  const { t } = useTranslation();
  //   const navigate = useNavigate();
  const [doccapeVersion, setDoccapeVersion] = useState('');
  const [dasVersion, setDasVersion] = useState('');
  const [dasImgVersion, setDasImgVersion] = useState('');

  useEffect(() => {
    getBackendVersion().then((response) => {
      setDoccapeVersion(response.data.doccape);
      setDasVersion(response.data.das);
      setDasImgVersion(response.data.dasImg);
    });
    //       .catch(() => {
    //         AppToaster({
    //           description: t('settings.about.fetching_version_failed_toast'),
    //           status: 'error',
    //         });
    //       });
    //   }).catch(() => {
    //     AppToaster({
    //       description: t('app.authorization_error_toast'),
    //       status: 'error',
    //     });
    // navigate('/login');
  }, []);

  return (
    <>
      <Heading as="h4" size="sm">
        {t('settings.about.system_header')}
      </Heading>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem textAlign="right">Doccape:</GridItem>
        <GridItem>{doccapeVersion}</GridItem>
        <GridItem textAlign="right">Doccape AI Text Backend:</GridItem>
        <GridItem>{dasVersion}</GridItem>
        <GridItem textAlign="right">Doccape AI Image Backend:</GridItem>
        <GridItem>{dasImgVersion}</GridItem>
      </Grid>
    </>
  );
};

export default About;
