import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpButton from '../../../../molecules/HelpButton';
import VScrollable from '../../../../molecules/VScrollable';
import AddListingItemButton from './AddListingItemButton';
import ListItem from './ListItem';
import ListingImportButton from './ListingImportButton';
import ListingRemoveAllButton from './ListingRemoveAllButton';

/**
 * Renders the white and blacklist for pseudonomyzation
 */
const ListingSettings = ({
  // whitelist
  whitelist,
  isWhitelistCSVImporting,
  addWhitelistItem,
  removeWhitelistItem,
  updateWhitelistItem,
  removeAllWhitelistItems,
  importWhitelistItems,
  // blacklist
  blacklist,
  isBlacklistCSVImporting,
  addBlacklistItem,
  removeBlacklistItem,
  updateBlacklistItem,
  removeAllBlacklistItems,
  importBlacklistItems,
  // text labels
  textLabels,
}) => {
  const { t } = useTranslation();

  /**
   * Checks if the given `value` is already in the given `list` or if it is empty
   * @param {string} value The value that should be checked
   * @param {Object[]} list The black- or whitelist
   * @param {boolean} withLabelNameUnique If true, the labelName must also be the same to be invalid
   * @param {string} labelName The labelName that should be checked if `checkLabels` is true (optional, default: null)
   * @returns True if the value is already in the list or if it is empty
   */
  const isItemInvalidInList = (value, list, withLabelNameUnique = false, labelName = null) => {
    if (withLabelNameUnique) {
      // If the labelName must be unique, check if the value is already in the list with the same labelName
      // or if the labelName is '*' (which means that the value is in the list with any labelName)
      return (
        list.filter(
          (item) =>
            item.value === value &&
            (item.labelName === labelName || item.labelName === '*' || labelName === ''),
        ).length > 0
      );
    }
    return value === '' || list.filter((item) => item.value === value).length > 0;
  };

  /**
   * Splits given `value` by ';' into multiple items with the specified `labelName`
   * @param {string} value The value that should be split
   * @param {string} labelName The labelName for the splitted Items
   * @param {Object[]} list The black- or whitelist
   * @returns A list of splitted items
   */
  const splitItems = (value, labelName, list) => {
    // TODO: Rework, probably doesn't work as expected
    let splittedItems = [];

    const valuesToAdd = value.split(';');
    valuesToAdd.forEach((valueToAdd) => {
      const trimmedValue = valueToAdd.trim();
      if (
        isItemInvalidInList(trimmedValue, list) ||
        isItemInvalidInList(trimmedValue, splittedItems)
      )
        return;

      const item = { value: trimmedValue, labelName };
      splittedItems = [...splittedItems, item];
    });

    return splittedItems;
  };

  /**
   * Saves the blacklist item with value and labelName
   * @param {string} value The value of the item
   * @param {string} labelName The labelName of the item
   */
  const handleAddBlacklistItem = (value, labelName) => {
    if (labelName === '') return;

    const newBlacklistItems = splitItems(value, labelName, blacklist);
    newBlacklistItems.forEach((item) => {
      addBlacklistItem(item);
    });
  };

  /**
   * Saves the whitelist item with value and labelName
   * @param {string} value The value of the item
   * @param {string} labelName The labelName of the item
   */
  const handleAddWhitelistItem = (value, labelName) => {
    let tmpLabelName = labelName;
    if (tmpLabelName === '') {
      tmpLabelName = '*';
    }

    const newWhitelistItems = splitItems(value, tmpLabelName, whitelist);
    newWhitelistItems.forEach((item) => {
      addWhitelistItem(item);
    });
  };

  /**
   * Handles the update of the value of an existing blacklist item
   * @param {string} value The updated value
   * @param {string} labelName The labelName
   * @param {number} listIdx The index of the blacklist item in the list
   */
  const handleUpdateBlacklistItemValue = (value, labelName, listIdx) => {
    const valueIsEmpty = value === '';
    const blacklistContainsValue = blacklist.filter((item) => item.value === value).length > 0;
    if (valueIsEmpty || blacklistContainsValue) return;

    const updatedBlacklistItem = { value, labelName };
    updateBlacklistItem(updatedBlacklistItem, listIdx);
  };

  /**
   * Handles the update of the labelName of an existing blacklist item
   * @param {string} value The value
   * @param {string} labelName The updated labelName
   * @param {number} listIdx The index of the exisiting blacklist item
   */
  const handleUpdateBlacklistItemLabel = (value, labelName, listIdx) => {
    if (labelName === '') return;

    const updatedBlacklistItem = { value, labelName };
    updateBlacklistItem(updatedBlacklistItem, listIdx);
  };

  /**
   * Handles the update of the value of an existing whitelist item
   * @param {string} value The updated value
   * @param {string} labelName The labelName
   * @param {number} listIdx The index of the exisiting whitelist item
   */
  const handleUpdateWhitelistItemValue = (value, labelName, listIdx) => {
    if (whitelist.filter((item) => item.value === value || value === '').length > 0) return;

    const updatedWhitelistItem = { value, labelName };
    updateWhitelistItem(updatedWhitelistItem, listIdx);
  };

  /**
   * Handles the update of the labelName of an existing whitelist item
   * @param {string} value The value
   * @param {string} labelName The updated labelName
   * @param {number} index The index of the exisiting whitelist item
   */
  const handleUpdateWhitelistItemLabel = (value, labelName, listIdx) => {
    const updatedWhitelistItem = { value, labelName };
    updateWhitelistItem(updatedWhitelistItem, listIdx);
  };

  /**
   * Handles the removal of a blacklist item
   * @param {number} listIdx The idx of the blacklist item that should be removed
   */
  const handleRemoveBlacklistItem = async (listIdx) => {
    await removeBlacklistItem(listIdx);
  };

  /**
   * Handles the removal of a whitelist item
   * @param {string} listIdx The idx of the whitelist item that should be removed
   */
  const handleRemoveWhitelistItem = async (listIdx) => {
    await removeWhitelistItem(listIdx);
  };

  return (
    <Accordion allowMultiple>
      <AccordionItem pb={1}>
        <>
          <h2>
            <AccordionButton>
              <Flex width="full">
                <Box>Whitelist</Box>
                <HelpButton ml={1} helpText={t('settings.listing.whitelistHelpText')} />
                <Spacer />
                <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel fontSize="sm" pb={2}>
            <Flex direction="column" width="100%">
              <HStack md="2" p="1">
                <HelpButton size={20} mr={2} helpText={t('settings.listing.importTooltip')} />
                <ListingImportButton
                  importListItems={importWhitelistItems}
                  isImporting={isWhitelistCSVImporting}
                />
                <ListingRemoveAllButton removeAllListItems={removeAllWhitelistItems} />
              </HStack>
              <VScrollable p={1} height={200}>
                {whitelist.map((item, idx) => (
                  <ListItem
                    key={`whitelist-${item.labelName}-${item.value}`}
                    id={item.id}
                    listIdx={idx}
                    value={item.value}
                    labelName={item.labelName}
                    onLabelUpdate={(value, labelName) =>
                      handleUpdateWhitelistItemLabel(value, labelName, idx)
                    }
                    onValueUpdate={(value, labelName) =>
                      handleUpdateWhitelistItemValue(value, labelName, idx)
                    }
                    onRemove={() => handleRemoveWhitelistItem(idx)}
                    type="whitelist"
                    labels={textLabels}
                  />
                ))}
                <AddListingItemButton
                  addButtonTooltip={t('settings.listing.addWhitelistItemTooltip')}
                  onAdd={handleAddWhitelistItem}
                  placeholder={t('settings.listing.whitelistItemPlaceholder')}
                  labels={textLabels}
                  checkIfExist={(value, labelName) =>
                    isItemInvalidInList(value, whitelist, true, labelName)
                  }
                />
              </VScrollable>
            </Flex>
          </AccordionPanel>
        </>
      </AccordionItem>
      <AccordionItem pb={1}>
        <>
          <h2>
            <AccordionButton>
              <Flex width="full">
                <Box>Blacklist</Box>
                <HelpButton ml={1} helpText={t('settings.listing.blacklistHelpText')} />
                <Spacer />
                <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel fontSize="sm" pb={2}>
            <Flex direction="column" width="100%">
              <HStack md="2" p="1">
                <HelpButton size={20} mr={2} helpText={t('settings.listing.importTooltip')} />
                <ListingImportButton
                  importListItems={importBlacklistItems}
                  p={1}
                  isImporting={isBlacklistCSVImporting}
                />
                <ListingRemoveAllButton removeAllListItems={removeAllBlacklistItems} />
              </HStack>
              <VScrollable p={1} height={200}>
                {blacklist.map((item, idx) => (
                  <ListItem
                    key={`blacklist-${item.labelName}-${item.value}`}
                    id={item.id}
                    value={item.value}
                    labelName={item.labelName}
                    onLabelUpdate={(value, labelName) =>
                      handleUpdateBlacklistItemLabel(value, labelName, idx)
                    }
                    onValueUpdate={(value, labelName) =>
                      handleUpdateBlacklistItemValue(value, labelName, idx)
                    }
                    onRemove={() => handleRemoveBlacklistItem(idx)}
                    type="blacklist"
                    labels={textLabels}
                  />
                ))}
                <AddListingItemButton
                  addButtonTooltip={t('settings.listing.addBlacklistItemTooltip')}
                  onAdd={handleAddBlacklistItem}
                  placeholder={t('settings.listing.blacklistItemPlaceholder')}
                  checkLabels
                  labels={textLabels}
                  checkIfExist={(value) => isItemInvalidInList(value, blacklist)}
                />
              </VScrollable>
            </Flex>
          </AccordionPanel>
        </>
      </AccordionItem>
    </Accordion>
  );
};

export default ListingSettings;
