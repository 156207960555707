import React from 'react';

const InfoIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 10 10">
      <circle fill="#a3b2dd" cx="5" cy="5" r="5" />
      <path
        fill="white"
        d="m5.54,2.18c0,.3-.21.54-.56.54-.32,0-.53-.24-.53-.54s.22-.55.55-.55.54.24.54.55Zm-.98,6.2V3.54h.88v4.84h-.88Z"
      />
    </svg>
  );
};

export default InfoIcon;
