import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppToaster from '../components/molecules/AppToaster';
import {
  clearTextLabels,
  fetchTextLabels,
  selectTextLabels,
  selectTextLabelsError,
  selectTextLabelsStatus,
  updateTextLabels,
} from '../reducers/textLabelsSlice';
import {
  changeTextLabelAttribute,
  changeTextLabelExtendedReplacement,
  createTextLabelColor,
  extractRelevantAttributesSliceTextLabel,
  textLabelHiddenColor,
  transformToSliceData,
} from '../services/textLabelService';
import { renameObjectKey } from '../services/utils';
import { getLocale } from '../translations/utils';
import { createTextLabel } from '../types/textLabel';

const useTextLabels = (projectId) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [textLabels, setTextLabels] = useState({});
  const [selectedTextLabelName, setSelectedTextLabelName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const sliceTextLabels = useSelector(selectTextLabels);
  const fetchStatus = useSelector(selectTextLabelsStatus);
  const fetchError = useSelector(selectTextLabelsError);

  // TOOD: Naming
  const initSlice = () => {
    setIsLoading(true);
    dispatch(fetchTextLabels({ projectId }));
  };

  const transformSliceData = () => {
    if (fetchStatus === 'succeeded') {
      setIsLoading(true);
      const locale = getLocale();
      const transformedTextLabels = {};
      sliceTextLabels.forEach((sliceTextLabel, cnt) => {
        const textLabel = extractRelevantAttributesSliceTextLabel(sliceTextLabel, locale);
        transformedTextLabels[textLabel.name] = createTextLabel({
          color: createTextLabelColor(cnt),
          ...textLabel,
        });
      });
      setTextLabels(transformedTextLabels);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clears text labels state when component unmounts
      dispatch(clearTextLabels());
    };
  }, [projectId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceTextLabels]);

  // TODO Sidebar: Move into useTextLabels hook???? Problem: setSelectedTextLabelName is more of a UIHook function...
  /**
   * Selects the first textLabel on the first render
   */
  useEffect(() => {
    if (Object.keys(textLabels).length > 0) {
      setSelectedTextLabelName(Object.keys(textLabels)[0]);
    }
  }, [textLabels]);

  // TODO sidebar: Move into service?
  const hasTextLabelExtendedReplacement = (textLabel) => {
    return textLabel.usePartialReplacement || textLabel.useDatePartialReplacement;
  };

  /**
   * Selects a textLabel when it is valid
   * @param {string} labelName
   */
  const selectTextLabelIfValid = (labelName) => {
    // Is the selected textLabel valid and did it change?
    if (labelName in textLabels && labelName !== selectedTextLabelName) {
      // Update selected textLabel
      setSelectedTextLabelName(labelName);
    }
  };

  const saveTextLabels = async (newTextLabels) => {
    setIsSaving(true);
    const newSliceTextLabels = transformToSliceData(newTextLabels, sliceTextLabels);
    await dispatch(updateTextLabels({ projectId, textLabels: newSliceTextLabels })).unwrap();
    AppToaster({
      description: t('settings.textLabels.updatingTextLabelsSuccessToast'),
      status: 'success',
    });

    setIsSaving(false);
  };

  /**
   * Adds a new text label to the state and immediately(!) saves it to the backend
   * @param {string} labelName - Name of the new text label
   */
  const addTextLabel = async (labelName) => {
    const newTextLabel = createTextLabel({
      name: labelName,
      defaultReplacement: labelName,
      description: '',
      color: createTextLabelColor(Object.keys(textLabels).length),
    });
    const updatedTextLabels = { ...textLabels, [labelName]: newTextLabel };
    await saveTextLabels(updatedTextLabels);
    setTextLabels(updatedTextLabels);
  };

  /**
   * Deletes a text label from the state and immediately(!) saves the deletion
   * @param {string} labelName - Name of the text label to delete
   */
  const deleteTextLabel = async (labelName) => {
    if (!textLabels[labelName].fromModel) {
      const updatedTextLabels = { ...textLabels };
      delete updatedTextLabels[labelName];
      await saveTextLabels(updatedTextLabels);
      setTextLabels(updatedTextLabels);
    } else {
      throw new Error('Cannot delete text label from model!');
    }
  };

  const changeTextLabelName = (oldName, newName) => {
    if (!textLabels[oldName].fromModel) {
      const updatedTextLabels = renameObjectKey(textLabels, oldName, newName);
      updatedTextLabels[newName].name = newName;
      setTextLabels(updatedTextLabels);
    } else {
      throw new Error('Cannot rename text label from model!');
    }
  };

  /**
   * Sets the Attributes of the corresponding text label
   * @param {*} labelName
   * @param {*} attributeName
   * @param {*} value
   */
  const setTextLabelAttribute = (labelName, attributeName, value) => {
    const updatedTextLabels = changeTextLabelAttribute(textLabels, labelName, attributeName, value);
    setTextLabels(updatedTextLabels);
  };

  /**
   * TODO: Handle all changes with one function
   * @param {*} labelName
   * @param {*} selectedOption
   */
  const changeExtendedReplacement = (labelName, selectedOption) => {
    const updatedTextLabels = changeTextLabelExtendedReplacement(
      textLabels,
      labelName,
      selectedOption,
    );
    setTextLabels(updatedTextLabels);
  };

  /**
   * Returns an object with all text labels which are from the model or not depending on the parameter `fromModal`
   * @param {boolean} fromModal - If true, only text labels from the model are returned, otherwise only text labels not from the model (default: true)
   * @returns {object} - Object with text labels filtered by the parameter `fromModal`
   */
  const getFilteredTextLabels = (fromModal = true) => {
    // get all filtered attributes
    const filteredTextLabelNames = Object.keys(textLabels).filter(
      (textLabel) => textLabels[textLabel].fromModel === fromModal,
    );
    // Create object with filtered text labels
    const filteredTextLabels = {};
    filteredTextLabelNames.forEach((textLabel) => {
      filteredTextLabels[textLabel] = textLabels[textLabel];
    });

    return filteredTextLabels;
  };

  const allDateLocales = ['AUTO', 'EN', 'DE', 'ISO'];

  return {
    isSaving,
    isLoading,
    textLabels,
    selectedTextLabelName,
    textLabelHiddenColor,
    getFilteredTextLabels,
    addTextLabel,
    hasTextLabelExtendedReplacement,
    selectTextLabelIfValid,
    saveTextLabels,
    changeTextLabelName,
    changeExtendedReplacement,
    setTextLabelAttribute,
    deleteTextLabel,
    allDateLocales,
  };
};

export default useTextLabels;
