import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';
import Button from '../../atoms/tailwind/Button';

/**
 * Dialog component that for displaying the content of the summary of a document
 * @param {String} title - Title of the dialog
 * @param {String} subtitle - Subtitle of the dialog
 * @param {JSX} content - Content of the dialog
 * @param {String} buttonText - Text of the button
 * @param {Function} buttonAction - Action of the button
 */
const DocumentSummaryDialogContent = ({
  title,
  subtitle,
  content,
  buttonText,
  buttonAction,
  isLoading,
}) => {
  return (
    <div className={classNames(`${isLoading ? 'text-center' : ''} py-2 px-2`)}>
      {/* 
        Header section of the card
      */}
      <p className="mb-2 text-2xl text-blue-2">{title}</p>
      <p className="text-3xl font-extrabold text-blue-2">{subtitle}</p>

      <br />

      {/* 
        Content section of the card
      */}
      <div className="text-base text-grey-1">{content}</div>

      <br />

      {/* 
        Action section of the card
      */}
      <div className="h-8 px-24">
        <Button buttonText={buttonText} fullWidth buttonAction={buttonAction} />
      </div>
    </div>
  );
};

export default DocumentSummaryDialogContent;
