import React from 'react';
import { useTranslation } from 'react-i18next';
import doccapeLogo from '../../../assets/DocCape_Favicon.svg';
import CustomContainer from '../../molecules/tailwind/CustomContainer';
import Footer from '../../molecules/tailwind/Footer';
import GradientContainer from '../../molecules/tailwind/GradientContainer';

/**
 * External view layout for e.g. login, registration and password reset
 * @param {Object} children - The children of the component
 * @param {string} width - The width of the container [md, lg] (default: md)
 */
const ExternalViewLayout = ({ children, width = 'md' }) => {
  const { t } = useTranslation();

  /**
   * Footer items (links) for the footer of the login page
   */
  const footerItems = [
    // TODO:
    // * Outsource items for footer, navbar, breadcrumbs etc?
    /* { name: t('footer.termsAndConditions'), href: `https://doccape.de/agb` }, */
    { name: t('footer.privacyNotice'), href: `https://doccape.de/datenschutz` },
    { name: 'Website', href: 'https://doccape.de/' },
  ];

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  return (
    <>
      <GradientContainer>
        <CustomContainer width={width}>{children}</CustomContainer>
      </GradientContainer>
      <Footer copyrightText={t('login.footer.copyright')} items={footerItems} image={logoImage} />
    </>
  );
};

export default ExternalViewLayout;
