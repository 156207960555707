/**
 * Deletes a bounding box from the image
 * @param {*} images The images array
 * @param {*} imageIdx The index of the image
 * @param {*} labelIdx The index of the label
 * @param {*} bboxIdx The index of the bounding box
 * @returns The updated images array
 */
const deleteBbox = (images, imageIdx, labelIdx, bboxIdx) => {
  // deletes one bounding box at idx
  images[imageIdx].detections[labelIdx].bboxs.splice(bboxIdx, 1);
  // deletes scores of the corresponding bounding box at idx
  images[imageIdx].detections[labelIdx].scores.splice(bboxIdx, 1);
  return images;
};

// TODO: Can this be used???
const addBbox = (images, imageIdx, labelIdx, labelName, bbox, score, color) => {
  // add box and score to label
  if (labelIdx === -1) {
    const newIdx = images[imageIdx].detections.length;
    images[imageIdx].detections.push({
      label: labelName,
      bboxs: [bbox],
      scores: [score],
      color,
    });
  } else {
    images[imageIdx].detections[labelName].bboxs.push(bbox);
    images[imageIdx].detections[labelName].scores.push(score);
  }
};

export default deleteBbox;
