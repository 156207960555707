import React from 'react';

/**
 * Custom container that wrapps the content of views e.g. project overview or document overview.
 * This container should be used in combination with CustomLayoutContent.jsx as an inner container
 * to display the content in a structured way
 *
 *   <CustomBasicContainerForContent>
 *      <CustomLayoutContent
 *        breadCrumbs={<BreadCrumbs pages={pages} />}
 *        title={'Projektübersicht'}
 *        buttonGroup={buttons}
 *        content={contentProjectOverview}
 *      />
 *   </CustomBasicContainerForContent>
 */
const CustomBasicContainerForContent = ({ children }) => {
  return (
    <div className="min-h-full w-full items-center bg-grey-4">
      <div className="mx-auto flex max-w-7xl flex-col px-4 py-8 sm:px-6 lg:px-8">{children}</div>
    </div>
  );
};

export default CustomBasicContainerForContent;
