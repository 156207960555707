import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TextLabelPartialReplacement = ({
  textLabel,
  changePartialReplacementStart,
  changePartialReplacementStop,
  toggleUseNumericPartialReplacement,
}) => {
  const { t } = useTranslation();
  const [isPartialReplacementError, setIsPartialReplacementError] = useState(false);

  /**
   * Checks if the passed range is valid.
   * Range is valid when either start or stop is 0 and none of the following is present:
   *   1. signum of start and stop is equal and start is greater than stop, e.g. (5, 2) or (-1, -2)
   *   2. positive values for stop while start is negative, e.g. (-2, 1)
   * @param {*} start
   * @param {*} stop
   * @returns
   */
  const isRangeValid = (start, stop) => {
    return (
      (!(start > stop && Math.sign(start) === Math.sign(stop)) &&
        !(start < stop && Math.sign(start) === -1 && Math.sign(stop) > 0)) ||
      start === 0 ||
      stop === 0
    );
  };

  const [partialReplacementStart, setPartialReplacementStart] = useState(
    textLabel.partialReplacementStart,
  );
  const [partialReplacementStop, setPartialReplacementStop] = useState(
    textLabel.partialReplacementStop,
  );

  /**
   * Sets a valid value in the store when leaving the focus of the input field.
   */
  const onBlurPartialReplacementStart = () => {
    if (partialReplacementStart !== '' && !isPartialReplacementError) {
      changePartialReplacementStart(parseInt(partialReplacementStart, 10));
    } else if (partialReplacementStart === '' && !isPartialReplacementError) {
      setPartialReplacementStart(1);
      changePartialReplacementStart(1);
    }
  };

  /**
   * Sets a valid value in the store when leaving the focus of the input field.
   */
  const onBlurPartialReplacementStop = () => {
    if (partialReplacementStop !== '' && !isPartialReplacementError) {
      changePartialReplacementStop(parseInt(partialReplacementStop, 10));
    } else if (partialReplacementStop === '' && !isPartialReplacementError) {
      setPartialReplacementStop(0);
      changePartialReplacementStop(0);
    }
  };

  /**
   * Sets the value in the state when changing the input field
   * and sets the error flag in case of an invalid value.
   * @param {*} value
   */
  const onChangePartialReplacementStart = (value) => {
    setPartialReplacementStart(value);
    if (value !== '' && !isRangeValid(parseInt(value, 10), parseInt(partialReplacementStop, 10))) {
      setIsPartialReplacementError(true);
    } else {
      setIsPartialReplacementError(false);
    }
  };

  /**
   * Sets the value in the state when changing the input field
   * and sets the error flag in case of an invalid value.
   * @param {*} value
   */
  const onChangePartialReplacementStop = (value) => {
    setPartialReplacementStop(value);
    if (value !== '' && !isRangeValid(parseInt(partialReplacementStart, 10), parseInt(value, 10))) {
      setIsPartialReplacementError(true);
    } else {
      setIsPartialReplacementError(false);
    }
  };

  return (
    <Box w="300px">
      <FormControl
        as={SimpleGrid}
        columns={{ base: 2, lg: 2 }}
        spacingY="5px"
        isInvalid={isPartialReplacementError}
      >
        <FormLabel htmlFor="from" fontWeight="normal">
          <Tooltip label={t('settings.textLabels.partialReplacementStartDescription')}>
            <Text
              sx={{
                '&:hover': {
                  cursor: 'help',
                },
              }}
            >
              {t('settings.textLabels.partialReplacementStart')}:
            </Text>
          </Tooltip>
        </FormLabel>
        <NumberInput
          id="from"
          size="sm"
          maxW="100px"
          value={partialReplacementStart}
          onChange={onChangePartialReplacementStart}
          onBlur={onBlurPartialReplacementStart}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <FormLabel htmlFor="to" fontWeight="normal">
          <Tooltip label={t('settings.textLabels.partialReplacementStopDescription')}>
            <Text
              sx={{
                '&:hover': {
                  cursor: 'help',
                },
              }}
            >
              {t('settings.textLabels.partialReplacementStop')}:
            </Text>
          </Tooltip>
        </FormLabel>
        <NumberInput
          size="sm"
          id="to"
          maxW="100px"
          isRequired={false}
          value={partialReplacementStop}
          onChange={onChangePartialReplacementStop}
          onBlur={onBlurPartialReplacementStop}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        {isPartialReplacementError ? (
          <FormErrorMessage>{t('settings.textLabels.partialReplacementError')}</FormErrorMessage>
        ) : null}
        <FormLabel htmlFor="useNumericPartialReplacement" fontWeight="normal">
          <Tooltip label={t('settings.textLabels.useNumericPartialReplacementDescription')}>
            <Text
              sx={{
                '&:hover': {
                  cursor: 'help',
                },
              }}
            >
              {t('settings.textLabels.useNumericPartialReplacement')}:
            </Text>
          </Tooltip>
        </FormLabel>
        <Switch
          isChecked={textLabel.useNumericPartialReplacement}
          onChange={toggleUseNumericPartialReplacement}
        />
      </FormControl>
    </Box>
  );
};

export default TextLabelPartialReplacement;
