import React from 'react';

const EditorIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m19.02,23.08c.42,0,.65-.29.7-.62.02-.07.05-.15.05-.23v-9.82h5.55v4.38c0,.39.34.77.75.75.41-.02.75-.33.75-.75v-5.03c0-.12-.04-.23-.09-.33-.08-.28-.3-.52-.69-.52h-14.13c-.38,0-.6.23-.68.51-.05.1-.08.21-.08.34v10.47c0,.13.03.24.08.34.08.28.31.51.69.51h7.11Zm-6.38-1.5v-9.17h5.63v9.17h-5.63Z" />
      <path d="m27.61,19.63c-.73-1.22-2.14-1.81-3.53-1.56-1.55.28-2.65,1.72-2.67,3.27,0,.59.17,1.15.45,1.63-1.17.99-2.34,1.99-3.51,2.98-.19.16-.37.32-.56.48-.3.25-.28.8,0,1.06.32.29.74.27,1.06,0,1.31-1.11,2.63-2.23,3.94-3.34.03-.03.06-.05.09-.08.23.16.47.3.74.4,1.26.48,2.81.07,3.66-.98.89-1.09,1.06-2.63.33-3.86Zm-1.04,1.93s-.02.09-.03.14c-.03.11-.06.22-.1.33,0,.03,0,.03-.01.04,0,0,0,0-.02.03-.03.06-.06.12-.1.18-.03.05-.06.09-.09.14,0,.01-.03.03-.04.05-.07.08-.14.16-.22.23,0,0-.05.04-.08.07-.03.02-.09.07-.09.06-.11.08-.23.14-.35.2,0,0,0,0-.01,0-.07.02-.13.05-.2.06-.04.01-.09.02-.13.03,0,0-.07.01-.11.02-.04,0-.12.01-.11.01-.06,0-.12,0-.18,0-.06,0-.12,0-.18,0-.03,0-.03,0-.04,0,0,0-.01,0-.04-.01-.14-.03-.27-.07-.4-.11-.02,0-.02,0-.03,0,0,0,0,0-.01,0-.07-.04-.14-.07-.21-.11-.04-.02-.08-.05-.11-.07-.02-.02-.05-.03-.07-.05,0,0-.02-.01-.05-.04-.08-.08-.16-.16-.24-.24-.02-.02-.02-.02-.03-.03,0,0,0,0-.02-.03-.04-.06-.08-.11-.11-.17-.03-.06-.06-.12-.1-.18-.01-.02-.02-.03-.02-.03,0,0,0-.01-.01-.04-.04-.13-.08-.26-.11-.4,0,0,0-.02,0-.04,0-.09-.01-.17-.01-.26,0-.07,0-.14,0-.22,0,0,0-.01,0-.02.02-.11.04-.22.08-.33.02-.06.04-.12.06-.18,0,0,0-.01.01-.02.07-.12.13-.23.21-.35,0-.01,0-.01.01-.02,0,0,0,0,.01-.01.04-.04.07-.08.11-.12.03-.03.06-.06.1-.09,0,0,.07-.06.09-.08.08-.06.16-.11.25-.16.04-.02.08-.04.12-.06.03-.01.04-.02.05-.02.02,0,.04-.01.05-.02.11-.04.22-.07.33-.09.02,0,.07-.01.09-.02.04,0,.09,0,.13,0,.12,0,.24,0,.36,0,.01,0,0,0,.01,0,.01,0,.01,0,.03,0,.07.02.14.03.2.05.07.02.13.04.2.06,0,0,.01,0,.02,0,.1.05.2.1.29.16.05.03.09.06.14.1,0,0,0,0,0,0,0,0,0,0,.02.03.1.09.19.18.28.29,0,0,0,0,0,.01.04.06.08.11.11.17.02.04.05.08.07.12,0,0,.04.08.05.11.04.09.07.19.09.29.01.05.02.09.03.14,0,.03,0,.03,0,.05,0,.02,0,.05,0,.07,0,.11,0,.22,0,.32,0,.02-.03.25,0,.08Z" />
    </svg>
  );
};

export default EditorIcon;
