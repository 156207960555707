import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearLogoData,
  fetchOrganizationLogo,
  selectOrganizationLogo,
} from '../reducers/organizationLogoSlice';

const useOrganizationLogo = (organizationId) => {
  const dispatch = useDispatch();

  const organizationLogo = useSelector(selectOrganizationLogo);

  useEffect(() => {
    dispatch(fetchOrganizationLogo(organizationId));

    return () => {
      // Clears project data state when component unmounts
      dispatch(clearLogoData());
    };
  }, []);

  return {
    organizationLogo,
  };
};

export default useOrganizationLogo;
