import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useUsersOrganization from '../../../../hooks/useUsersOrganization';
import useUsersProject from '../../../../hooks/useUsersProject';

import AddIcon from '../../../atoms/icons/AddIcon';
import IconButton from '../../../atoms/tailwind/IconButton';
import UserTable from '../../../molecules/UserTable';
import CustomButtonGroup from '../../../molecules/tailwind/CustomButtonGroup';
import AddUserToProjDialog from '../../projectManagement/documentSelection/AddUserToProjDialog';

const UserMngmt = () => {
  const { t } = useTranslation();
  const { organizationId, projectId } = useParams();
  const { users } = useUsersProject(projectId, organizationId);
  const { users: organizationUsers } = useUsersOrganization(organizationId);

  const {
    roles,
    selectedRole,
    setSelectedRole,
    invitedUserId,
    setInvitedUserId,
    organizationUsersNotInProject,
    isShown: isShownInviteProject,
    setIsShown: setIsShownInviteProject,
    onAddUser,
    onDeleteProjectUser,
    updateUserProjectRole,
    setErrorMsg,
    isLoading,
  } = useUsersProject(projectId, organizationId, organizationUsers);

  const projectRoles = [
    { id: 1, name: 'Project-Admin', value: 'ADMIN' },
    { id: 2, name: 'User', value: 'USER' },
  ];

  /**
   * Resets all correspondiong states when closing the dialog without saving
   */
  const resetStates = () => {
    setIsShownInviteProject(false);
    setErrorMsg('');
  };

  /**
   *  Close Dialog when clicking close icon or outside of the dialog without saving
   */
  const closeModalWithoutSaving = async () => {
    resetStates();
  };

  const handleUserEmailInput = (userId) => {
    setInvitedUserId(userId);
  };

  /**
   * Assign a user to a project
   */
  const addUserToProject = async () => {
    // Map the email input to the corresponding user id
    const succesful = await onAddUser(invitedUserId, selectedRole);
    setIsShownInviteProject(!succesful);
  };

  /**
   * Saves and add the user to project when the email is valid
   */
  const closeAndSaveModal = () => {
    addUserToProject();
  };

  return (
    <div className="w-full">
      <br />
      <CustomButtonGroup>
        <IconButton
          buttonText={t('organizationMngmt.invitationUser.inviteUser')}
          icon={<AddIcon />}
          color="blue"
          buttonAction={() => {
            setIsShownInviteProject(true);
          }}
        />
      </CustomButtonGroup>
      <br />
      <AddUserToProjDialog
        open={isShownInviteProject}
        closeModalWithoutSaving={closeModalWithoutSaving}
        closeAndSaveModal={closeAndSaveModal}
        handleUserEmailInput={handleUserEmailInput}
        roles={roles}
        setSelectedRole={setSelectedRole}
        selectableUsers={organizationUsersNotInProject}
      />
      <UserTable
        orgUsers={users}
        userRoles={projectRoles}
        updateUserRole={updateUserProjectRole}
        deleteUser={onDeleteProjectUser}
        isLoadingUsers={isLoading}
        canAdminResendInvitation={false}
      />
    </div>
  );
};

export default UserMngmt;
