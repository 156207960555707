import React from 'react';
import DotIcon from '../../../../../atoms/icons/DotIcon';
import Label from '../../../../../molecules/tailwind/Label';

const WhitelistSelectionArea = ({
  textLabels,
  selectedTextElement,
  hasTextLabelExtendedReplacement,
  changeWhitelistLabel,
  isLabelNameSelected,
  blacklist,
}) => {
  const handleLabelClick = (labelName) => {
    changeWhitelistLabel(selectedTextElement, labelName);
  };

  /**
   * Returns the selected blacklist label name
   * @returns {String | null} selectedBlacklistedLabel - The selected blacklist label name
   */
  const getSelectedBlacklistLabelName = () => {
    const selectedBlacklistedLabels = blacklist.filter(
      (item) => item.value === selectedTextElement,
    );
    return Array.isArray(selectedBlacklistedLabels) && selectedBlacklistedLabels.length > 0
      ? selectedBlacklistedLabels[0].labelName
      : null;
  };

  return (
    <div className="col-span-1 flex h-fit w-full flex-col rounded-xl border border-babyBlue-1 bg-white p-2 shadow">
      <ul className="grid grid-cols-3 gap-2">
        {Object.entries(textLabels).map(([labelName, textLabel]) => {
          return (
            <Label
              name={labelName}
              rightIcon={hasTextLabelExtendedReplacement(textLabel) ? <DotIcon /> : null}
              active={isLabelNameSelected(selectedTextElement, labelName)}
              color="grey"
              activeColor={textLabel.color}
              hoverColor={textLabel.color}
              onClick={() => handleLabelClick(labelName)}
              disabled={labelName === getSelectedBlacklistLabelName()}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default WhitelistSelectionArea;
