/* eslint-disable import/no-cycle */
/*
 * Contains the redux store for providing global states
 */
import { configureStore } from '@reduxjs/toolkit';
import activeAnnotationViewReducer from './activeAnnotationViewSlice';
import activeSidebarContentAreaReducer from './activeSidebarContentAreaSlice';
import anonymizedDocumentReducer from './anonymizedDocumentSlice';
import authReducer from './authSlice';
import blacklistReducer from './blacklistSlice';
import documentDataReducer from './documentDataSlice';
import documentsReducer from './documentsSlice';
import documentsTagsWithValuesReducer from './documentsTagsWithValuesSlice';
import imageAnnotationReducer from './imageAnnotationSlice';
import imageLabelsReducer from './imageLabelsSlice';
import organizationLogoReducer from './organizationLogoSlice';
import organizationsReducer from './organizationsSlice';
import originalDocumentReducer from './originalDocumentSlice';
import projectsDataReducer from './projectDataSlice';
import projectSettingsReducer from './projectSettingsSlice';
import systemFeaturesReducer from './systemFeaturesSlice';
import loadStatusReducer from './systemLoadStatusSlice';
import systemPublicFeaturesReducer from './systemPublicFeaturesSlice';
import systemVersionReducer from './systemVersionSlice';
import textLabelsReducer from './textLabelsSlice';
import userReducer from './userSlice';
import usersOfOrganizationReducer from './usersOfOrganizationSlice';
import usersOfProjectReducer from './usersOfProjectSlice';
import whitelistReducer from './whitelistSlice';

/*
 * Contains all reducers and is exported for easier testing
 */
export const reducer = {
  documentData: documentDataReducer,
  auth: authReducer,
  systemVersion: systemVersionReducer,
  systemFeatures: systemFeaturesReducer,
  systemPublicFeatures: systemPublicFeaturesReducer,
  user: userReducer,
  textLabels: textLabelsReducer,
  imageLabels: imageLabelsReducer,
  documents: documentsReducer,
  originalDocument: originalDocumentReducer,
  anonymizedDocument: anonymizedDocumentReducer,
  activeAnnotationView: activeAnnotationViewReducer,
  activeSidebarContentArea: activeSidebarContentAreaReducer,
  projectSettings: projectSettingsReducer,
  whitelist: whitelistReducer,
  blacklist: blacklistReducer,
  imageAnnotation: imageAnnotationReducer,
  projectsData: projectsDataReducer,
  organizations: organizationsReducer,
  usersOfOrganization: usersOfOrganizationReducer,
  usersOfProject: usersOfProjectReducer,
  organizationLogo: organizationLogoReducer,
  systemLoadStatus: loadStatusReducer,
  documentsTagsWithValues: documentsTagsWithValuesReducer,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production', // disable dev tools in production
});
