import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocumentsTagsWithValuesDB,
  selectDocumentsTagsWithValues,
  selectDocumentsTagsWithValuesError,
  selectDocumentsTagsWithValuesStatus,
} from '../reducers/documentsTagsWithValuesSlice';

const useDocumentsTagsWithValues = (projectId) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [documentTagsWithValues, setDocumentTagsWithValues] = useState([]);
  const [placeholderDocumentTag, setPlaceholderDocumentTag] = useState();

  const fetchStatus = useSelector(selectDocumentsTagsWithValuesStatus);
  const fetchError = useSelector(selectDocumentsTagsWithValuesError);

  const sliceDocumentsTagsWithValues = useSelector(selectDocumentsTagsWithValues);

  const initSlice = () => {
    dispatch(fetchDocumentsTagsWithValuesDB(projectId));
  };

  const transformSliceData = () => {
    setIsLoading(true);
    if (fetchStatus === 'succeeded') {
      setDocumentTagsWithValues(sliceDocumentsTagsWithValues);
      const placeholderDocumentTag = sliceDocumentsTagsWithValues && sliceDocumentsTagsWithValues.length > 0
        ? sliceDocumentsTagsWithValues[0].key
        : '-';
      setPlaceholderDocumentTag(placeholderDocumentTag);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initSlice();
  }, []);

  useEffect(() => {
    transformSliceData();
  }, [sliceDocumentsTagsWithValues]);

  return { documentTagsWithValues, placeholderDocumentTag, isLoading };
};

export default useDocumentsTagsWithValues;
