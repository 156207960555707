import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiHighlight } from 'react-icons/bi';
import { BsImage } from 'react-icons/bs';
import { HiTable } from 'react-icons/hi';
import annotationViewTypes from '../../../types/annotationView';
import ContentArea from '../../molecules/ContentArea';
import ImageSelection from './ImageSelection';
import Navigator from './Navigator';
import PseudonymizationView from './PseudonymizationView';
import TextLabelSelection from './TextLabelSelection';

/**
 * Sidebar component
 * @returns {JSX.Element}
 *
 */
const SidebarMain = ({
  activeSidebarContentArea,
  activeAnnotationView,
  changeActiveSidebarView,
  annotations,
  images,
  activeImageId,
  setActiveImageId,
  sortImagesByPageId,
  textLabels,
  selectedTextLabelName,
  selectTextLabelIfValid,
  hasTextLabelExtendedReplacement,
  textLabelHiddenColor,
  removeAllAnnotationsWithTextLabel,
  selectedParagraphIndex,
  changeCrIdOfAnnotation,
  isOpen,
  setIsOpen,
  isImagesLoading,
}) => {
  const { t } = useTranslation();

  const views = {
    textLabelSelection: {
      icon: <BiHighlight size={30} />,
      component: (
        <TextLabelSelection
          selectedTextLabelName={selectedTextLabelName}
          textLabels={textLabels}
          textLabelHiddenColor={textLabelHiddenColor}
          hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
          selectTextLabelIfValid={selectTextLabelIfValid}
          removeAllAnnotationsWithTextLabel={removeAllAnnotationsWithTextLabel}
        />
      ),
      activeAnnotationView: annotationViewTypes.textAnnotation,
      isDisabled: false,
      tooltip: t('sidebar.views.textLabelSelection.tooltip'),
    },
    pseudonymization: {
      icon: <HiTable size={30} />,
      component: (
        <PseudonymizationView
          textLabels={textLabels}
          annotations={annotations}
          selectedParagraphIndex={selectedParagraphIndex}
          changeCrIdOfAnnotation={(annotation, newCrId) =>
            changeCrIdOfAnnotation(annotation, newCrId, textLabels)
          }
        />
      ),
      activeAnnotationView: annotationViewTypes.textAnnotation,
      isDisabled:
        annotations === null ||
        annotations === undefined ||
        textLabels === null ||
        textLabels === undefined ||
        Object.keys(textLabels).length === 0,
      tooltip: t('sidebar.views.pseudonymization.tooltip'),
    },
    imageAnonymization: {
      icon: <BsImage size={25} />,
      component: (
        <ImageSelection
          images={images}
          activeImageId={activeImageId}
          setActiveImageId={setActiveImageId}
          sortImagesByPageId={sortImagesByPageId}
        />
      ),
      activeAnnotationView: annotationViewTypes.imageAnnotation,
      isDisabled: !images || images.length === 0 || isImagesLoading || false, // TODO: Remove false
      // TODO: !imageAnonymizationSettings.isEnabled,
      tooltip: (() => {
        if (images && images.length === 0) {
          return t('sidebar.views.imageAnonymization.tooltipWithoutImgs');
        }
        // if (!imageAnonymizationSettings.isEnabled) {
        //   return t('sidebar.views.imageAnonymization.tooltipSettingsDisabled');
        // }
        return t('sidebar.views.imageAnonymization.tooltip');
      })(),
    },
  };

  return (
    <Flex flexDir="row" height="100%">
      <div width="60px" height="100%">
        <Navigator
          views={views}
          isSidebarOpen={isOpen}
          setIsSidebarOpen={setIsOpen}
          activeSidebarContentArea={activeSidebarContentArea}
          activeAnnotationView={activeAnnotationView}
          changeActiveSidebarView={changeActiveSidebarView}
        />
      </div>
      <ContentArea isOpen={isOpen}>
        {activeSidebarContentArea !== null && views[activeSidebarContentArea].component}
      </ContentArea>
    </Flex>
  );
};

export default SidebarMain;
