import { Center, chakra, Icon, Tag, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAnnotation } from '../../../types/annotation';
import Box from '../../atoms/Box';
import Button from '../../atoms/Button';
import Popover from '../../molecules/Popover';
import AnnotationMarkRightClickMenu from './AnnotationMarkRightClickMenu';

const TextLabelCloseIcon = (props) => (
  <Icon verticalAlign="inherit" viewBox="0 0 512 512" {...props}>
    <path
      fill="white"
      d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"
    />
  </Icon>
);

const AnnotationMark = ({
  annotation,
  textLabels,
  changeTextLabelNameOfAllAnnotationsWithSameText,
  isPopoverAddAnnotationsOpen,
  closeAddAnnotationsPopover,
  openAddAnnotationsPopover,
  color,
  selectAnnotation,
  onRemove,
  onClick,
  deleteAllAnnotationsWithSameTextLabelAndText,
  deleteAllAnnotationsWithSameText,
  annotateAllUnmarked,
  setCrIdAndPseudonym,
  isSelected,
  changeTextLabelName,
  changeCrId,
  changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
  isDisplayingPseudonyms,
}) => {
  const { t } = useTranslation();
  const popoverTriggeredByRightClick = useRef(false);

  const [isPopoverDeleteAnnotationOpen, setIsPopoverDeleteAnnotationOpen] = useState(false);
  const [isHoverTooltipOpen, setIsHoverTooltipOpen] = useState(false);

  /**
   * Resets the popoverTriggeredByRightClick flag when the popover is closed.
   */
  useEffect(() => {
    if (!isPopoverAddAnnotationsOpen) {
      popoverTriggeredByRightClick.current = false;
    }
  }, [isPopoverAddAnnotationsOpen, popoverTriggeredByRightClick]);

  // Set text and tooltip of the annotation
  const text =
    isDisplayingPseudonyms && annotation.crId !== null ? annotation.pseudonym : annotation.text;

  const hoverText =
    !isDisplayingPseudonyms && annotation.crId != null ? annotation.pseudonym : annotation.text;

  /**
   * This workaround is required due to the usage of chakra components. Those componenents do not
   * 'understand' the color codes of tailwind and need hex codes to function properly
   * can be deleted after upgrading the editor to tailwind components
   */
  const getColorCodeByName = (colorName) => {
    switch (colorName) {
      case 'lightPurple':
        return '#BCABFF';
      case 'purple':
        return '#7551FF';
      case 'deepPurple':
        return '#D251FF';
      case 'deepPink':
        return '#FF5487';
      case 'pink':
        return '#FFA0C5';
      case 'lightPink':
        return '#FFB9B2';
      case 'deepOrange':
        return '#FB7B05';
      case 'orange':
        return '#FBBC05';
      case 'green':
        return '#01B574';
      case 'deepGreen':
        return '#3EA101';
      case 'lightGreen':
        return '#92D25F';
      case 'teal':
        return '#01AAB5';
      case 'lightTeal':
        return '#70E6D9';
      case 'blue':
        return '#86ABE3';
      case 'lightBlue':
        return '#77A9F4';
      case 'deepBlue':
        return '#003EB9';
      case 'lightGrey':
        return '#7D95B3';
      case 'grey':
        return '#626188';
      case 'black':
        return '#0A1A39';
      case 'brown':
        return '#823408';

      // returns black as default so we can ensure that everything is readable
      default:
        return '#0A1A39';
    }
  };

  const renderAnnotationTextLabel = () => {
    return (
      <Tag
        bg={getColorCodeByName(color)}
        p={isSelected ? 0 : '2px'}
        borderWidth={isSelected ? '2px' : 0}
        borderStyle={isSelected ? 'dotted' : ''}
        borderColor={isSelected ? 'white' : ''}
        m={0.5}
        onClick={() => onClick(annotation)}
        onKeyPress={() => onClick(annotation)}
        onContextMenu={(event) => {
          selectAnnotation(annotation);
          event.preventDefault();
          popoverTriggeredByRightClick.current = true;
          openAddAnnotationsPopover();
        }}
        onMouseOver={() => {
          if (!isPopoverDeleteAnnotationOpen) {
            setIsHoverTooltipOpen(true);
          }
        }}
        onMouseLeave={() => setIsHoverTooltipOpen(false)}
      >
        <Tooltip hasArrow label={hoverText} isOpen={isHoverTooltipOpen}>
          <Center>
            {/*
          Not exactly sure why we need the Box, however, without it won't work.
          Warning says:
          Function components cannot be given refs. Attempts to access this ref will fail.
          Did you mean to use React.forwardRef()?
        */}
            <Box
              onClick={(e) => {
                // we do not want to trigger the onClick event of the TextLabel (onAnnotationClick())
                e.stopPropagation();
                if (!isSelected) {
                  selectAnnotation(annotation);
                }
              }}
            >
              <Popover
                isOpen={isPopoverDeleteAnnotationOpen}
                returnFocusOnClose
                placement="right"
                closeOnBlur
                triggerContent={
                  <chakra.button
                    size="sm"
                    aria-label="close"
                    __css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      outline: '0',
                    }}
                    onClick={() => {
                      selectAnnotation(annotation);
                      setIsHoverTooltipOpen(false);
                      setIsPopoverDeleteAnnotationOpen(true);
                    }}
                  >
                    <TextLabelCloseIcon />
                  </chakra.button>
                }
                onClickCloseButton={() => {
                  setIsPopoverDeleteAnnotationOpen(false);
                }}
                onClose={() => {
                  setIsPopoverDeleteAnnotationOpen(false);
                }}
                headerContent={t('annotation.delete')}
                bodyContent={
                  <Center>
                    <VStack>
                      <Tooltip hasArrow label={t('annotation.deleteHereDescription')}>
                        <Button
                          width="100%"
                          size="sm"
                          colorScheme="blue"
                          onClick={() => {
                            onRemove();
                          }}
                        >
                          {t('annotation.deleteHere')}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label={t('annotation.deleteAllTextLabelAndTextDescription')}
                      >
                        <Button
                          width="100%"
                          size="sm"
                          onClick={() => {
                            deleteAllAnnotationsWithSameTextLabelAndText(annotation);
                          }}
                        >
                          {t('annotation.deleteAllTextLabelAndText')}
                        </Button>
                      </Tooltip>
                      <Tooltip hasArrow label={t('annotation.deleteAllTextDescription')}>
                        <Button
                          width="100%"
                          size="sm"
                          colorScheme="red"
                          onClick={() => {
                            deleteAllAnnotationsWithSameText(annotation);
                          }}
                        >
                          {t('annotation.deleteAllText')}
                        </Button>
                      </Tooltip>
                    </VStack>
                  </Center>
                }
              />
            </Box>
            <Text fontSize="16px" color="white">
              {text}
            </Text>
            <Text paddingLeft="1" fontSize="14px" color="white">
              {annotation.textLabelName}
            </Text>
          </Center>
        </Tooltip>
      </Tag>
    );
  };

  return isAnnotation(annotation) ? (
    <Popover
      returnFocusOnClose
      isOpen={isPopoverAddAnnotationsOpen}
      onClose={() => {
        closeAddAnnotationsPopover();
      }}
      placement="right"
      closeOnBlur
      triggerContent={renderAnnotationTextLabel()}
      headerContent={
        !popoverTriggeredByRightClick.current ? t('annotation.create') : t('annotation.options')
      }
      onClickCloseButton={() => {
        if (!popoverTriggeredByRightClick.current) {
          onRemove();
        }
        closeAddAnnotationsPopover();
      }}
      bodyContent={
        !popoverTriggeredByRightClick.current ? (
          <Center>
            <VStack>
              <Tooltip hasArrow label={t('annotation.addHereDescription')}>
                <Button
                  width="100%"
                  size="sm"
                  colorScheme="blue"
                  onClick={() => {
                    closeAddAnnotationsPopover();
                  }}
                >
                  {t('annotation.addHere')}
                </Button>
              </Tooltip>
              <Tooltip hasArrow label={t('annotation.addAllTextDescription')}>
                <Button
                  width="100%"
                  size="sm"
                  onClick={() => {
                    annotateAllUnmarked(annotation);
                    closeAddAnnotationsPopover();
                  }}
                >
                  {t('annotation.addAllText')}
                </Button>
              </Tooltip>
            </VStack>
          </Center>
        ) : (
          <AnnotationMarkRightClickMenu
            textLabels={textLabels}
            changeTextLabelName={changeTextLabelName}
            annotation={annotation}
            setCrIdAndPseudonym={setCrIdAndPseudonym}
            annotateAllUnmarked={annotateAllUnmarked}
            changeTextLabelNameOfAllAnnotationsWithSameText={
              changeTextLabelNameOfAllAnnotationsWithSameText
            }
            changeCrId={changeCrId}
            closeAddAnnotationsPopover={closeAddAnnotationsPopover}
            changeCrIdOfAllAnnotationsWithSameTextLabelAndText={
              changeCrIdOfAllAnnotationsWithSameTextLabelAndText
            }
          />
        )
      }
    />
  ) : null;
};

export default AnnotationMark;
