import * as chrono from 'chrono-node';
import { saveAs } from 'file-saver';
import _ from 'lodash';

/**
 * Pushes items to an array at given index.
 *
 * @param {*} array
 * @param {*} index
 * @param {*} items
 */
export const pushArray = (array, index, items) => [
  ...array.slice(0, index),
  ...items,
  ...array.slice(index + 1),
];

/**
 * Pushes itmes to a 2D array at given index.
 * Creates a real copy of the array.
 * @param {*} array
 * @param {*} index
 * @param {*} items
 * @returns
 */
export const push2DArray = (array, index, items) => [
  ...array.slice(0, index),
  [...array[index], ...items],
  ...array.slice(index + 1),
];

/**
 * Sets item at `index1` and `index2` in a 2D array
 * Creates a real copy of the array
 *
 * @param {*} array
 * @param {*} index1
 * @param {*} index2
 * @param {*} item
 * @returns
 */
export const setItem2DArray = (array, index1, index2, item) => [
  ...array.slice(0, index1),
  [...array[index1].slice(0, index2), item, ...array[index1].slice(index2 + 1)],
  ...array.slice(index1 + 1),
];

/**
 * Deep clones the given value
 * @param {*} value
 * @returns
 */
export const deepCopy = (value) => {
  // Clones all nested values!
  // If performance issues arise try to use cloneDeepWith (https://lodash.com/docs/4.17.15#cloneDeepWith)
  return _.cloneDeep(value);
};

/**
 * Sets items to a 2D array at given index.
 *
 * @param {*} array
 * @param {*} index
 * @param {*} items
 * @returns
 */
export const setItemAtIndex = (array, index, items) => {
  if (index >= array.length) {
    throw new Error(`Index out of bounds ${index} >= ${array.length}}`);
  }
  return [...array.slice(0, index), items, ...array.slice(index + 1)];
};

/**
 * Inserts an item into an array at given index.
 *
 * @param {*} array The array to insert into
 * @param {*} index The index to insert at
 * @param {*} item The item to insert
 * @returns
 */
export const insertItemIntoArray = (array, index, item) => {
  if (index > array.length) {
    throw new Error(`Index out of bounds ${index} >= ${array.length}}`);
  }
  return array.splice(index, 0, item);
};

/**
 * Remove an item from an array at given index.
 */
export const removeItemFromArray = (array, index) => {
  if (index >= array.length) {
    throw new Error(`Index out of bounds ${index} >= ${array.length}}`);
  }
  if (index < 0) {
    throw new Error(`Index out of bounds ${index} < 0`);
  }
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

/**
 * Parses a date string if the format is correct.
 * @param {string} dateString - The date string to parse
 * @param {string} formatString - The format string the date string should be in
 * @returns
 */
const parseDateIfFormatCorrect = (dateString, formatString) => {
  // Map format tokens to their respective regex and date parts
  const formatTokens = {
    '%Y': { regex: '(\\d{4})', getter: (date) => date.getFullYear() },
    '%m': { regex: '(\\d{2})', getter: (date) => date.getMonth() + 1 },
    '%d': { regex: '(\\d{2})', getter: (date) => date.getDate() },
  };

  // Escape special characters in the format string and build the regex pattern
  let regexPattern = formatString.replace(/([.^$*+?()[{\\|\]-])/g, '\\$1');
  Object.entries(formatTokens).forEach(([token, { regex }]) => {
    regexPattern = regexPattern.replace(token, regex);
  });

  const dateRegex = new RegExp(`^${regexPattern}$`);

  // Check if the string matches the format
  if (!dateRegex.test(dateString)) {
    return null;
  }

  // Extract date parts
  const match = dateString.match(dateRegex);
  let year = null;
  let month = null;
  let day = null;
  let matchIndex = 1;

  if (formatString.includes('%Y')) {
    year = parseInt(match[(matchIndex += 1)], 10);
  }
  if (formatString.includes('%m')) {
    month = parseInt(match[(matchIndex += 1)], 10);
  }
  if (formatString.includes('%d')) {
    day = parseInt(match[matchIndex], 10);
  }

  // Construct a date and verify each part
  const date = new Date(year, month - 1, day);
  if (
    (year !== null && year !== date.getFullYear()) ||
    (month !== null && month !== date.getMonth() + 1) ||
    (day !== null && day !== date.getDate())
  ) {
    return null;
  }

  return date;
};

/**
 * Tries to parse the dateString in the given languages.
 * When parse is successful, the dateObj and locale in the output dict are not null.
 *
 * @param {string} dateString
 * @param {string} language
 * @returns
 */
export const getDateAndLocale = (dateString, language) => {
  const supportedLanguages = ['de', 'en', 'iso', 'auto'];
  const parserFormatByLanguage = {
    iso: '%Y-%m-%d',
    isoSlash: '%Y/%m/%d',
  };
  const parserOrderByLanguage = {
    de: ['de', 'en', 'iso', 'isoSlash'],
    en: ['en', 'de', 'iso', 'isoSlash'],
    iso: ['iso', 'isoSlash', 'de', 'en'],
    isoSlash: ['isoSlash', 'iso', 'de', 'en'],
    auto: ['iso', 'isoSlash', 'de', 'en'],
  };

  if (!supportedLanguages.includes(language)) {
    // If the language is not 'auto' and not supported, throw an error
    throw new Error(`Language ${language} is not supported!`);
  }
  const detectLanguages = parserOrderByLanguage[language];

  let dateObj = null;
  let matchingLocale = null;
  // test locales until a match is found
  detectLanguages.some((lang) => {
    // Check if lang is supported by parserFormatByLanguage
    if (Object.keys(parserFormatByLanguage).includes(lang)) {
      dateObj = parseDateIfFormatCorrect(dateString, parserFormatByLanguage[lang]);
    } else if (Object.keys(chrono).includes(lang)) {
      // Check if lang is supported by chrono parser
      dateObj = chrono[lang].strict.parseDate(dateString);
    } else {
      throw new Error(`Language ${lang} is not supported by chrono!`);
    }

    if (dateObj !== null) {
      matchingLocale = lang;
      return true;
    }
    return false;
  });
  return { dateObj, locale: matchingLocale };
};

/**
 * Computes the weekday and return the desired translation.
 *
 * @param {*} dateAndLocale the output of getDateAndLocale()
 * @param {*} translation "de" or "en", fallback is "en"
 * @returns
 */
export const getWeekday = (dateAndLocale, language = 'auto') => {
  const { dateObj, locale } = dateAndLocale;
  const translations = [
    ['Sun', 'So'],
    ['Mon', 'Mo'],
    ['Tue', 'Di'],
    ['Wed', 'Mi'],
    ['Thu', 'Do'],
    ['Fri', 'Fr'],
    ['Sat', 'Sa'],
  ];

  if (dateObj === null) {
    throw new Error(`Could not parse string to date object: ${dateAndLocale}!`);
  }

  // could parse the date string
  const weekday = dateObj.getDay(); // 0 ... 6 where 0 is Sunday
  const en = translations[weekday][0];
  const de = translations[weekday][1];
  let translatedDay = en;
  if (language === 'de' || (language === 'auto' && locale === 'de')) {
    translatedDay = de;
  }
  return `${translatedDay}`;
};

export function isEmpty(array) {
  return Array.isArray(array) && (array.length === 0 || array.every(isEmpty));
}

/**
 * Renames an objects key while preserving the ordering of the keys.
 * See: https://stackoverflow.com/questions/48082071/js-rename-an-object-key-while-preserving-its-position-in-the-object
 * @param {*} object
 * @param {*} oldKey
 * @param {*} newKey
 * @returns
 */
export const renameObjectKey = (object, oldKey, newKey) => {
  const keys = Object.keys(object);
  const newObj = keys.reduce((acc, val) => {
    const temp = acc;
    if (val === oldKey) {
      temp[newKey] = object[oldKey];
    } else {
      temp[val] = object[val];
    }
    return temp;
  }, {});

  return newObj;
};

/**
 * Converts hex string to an array of rgb values, each in the range [0, 255]
 */
export const convertHexToRGB = (hexStr) => {
  let cleanedHexStr = hexStr;
  if (hexStr[0] === '#') cleanedHexStr = hexStr.slice(1);
  if (cleanedHexStr.length !== 6) {
    throw Error('Invalid Argument: Only six-digit hex colors are allowed.');
  }
  const aRgbHex = cleanedHexStr.match(/.{1,2}/g);
  const aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
  return aRgb;
};

/**
 * Get filename from content disposition header
 *
 * @param {str} contentDisposition content disposition header value
 * @returns filename
 */
export const getFilenameFromContentDispositionHeader = (contentDisposition) => {
  let filename = /filename\*?=([^']*'')?"?([^;"]*)?"?/.exec(contentDisposition)[2];
  filename = decodeURIComponent(filename);
  return filename;
};

/**
 * Converts the provided `data` to a blob and saves the blob to disk using the provided `headerLine` to extract the filename.
 * @param {*} data The file data to save (can be a single file or a zip file)
 * @param {string} headerLine The header line of the response containing the filename
 */
export const saveFile = (data, headerLine) => {
  // get filename from headerLine
  const filename = getFilenameFromContentDispositionHeader(headerLine);
  // get content
  const blob = new Blob([data]);
  // open save dialog
  saveAs(blob, filename);
};

/**
 * Converts a given number of bytes into a human readable representation.
 *
 * @param {*} bytes
 * @param {*} decimals number of decimals in the output
 * @returns
 */
export const createHumanReadableRepresentationBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0  B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [' B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Converts the ISO date string to a readable format
 * @param {string} date string in the format YYYY-MM-DDTHH:MM:SS.SSS`
 * @param {boolean} withoutTime if true, the time is not included in the output
 */
export const createHumanReadableRepresentationDate = (date, withoutTime = false) => {
  if (!date) {
    return '-';
  }

  const dateObject = new Date(date);

  // Check date is not valid, throw an error
  if (Number.isNaN(dateObject.getTime())) {
    throw new Error(`Invalid date string: ${date}`);
  }

  if (withoutTime) {
    return dateObject.toLocaleDateString().replace(',', '');
  }

  return dateObject.toLocaleString().replace(',', '');
};

/**
 * Creates an array of given length and fills it with the given value.
 *
 * @param {*} length
 * @param {*} value
 * @param {*} deepCopyValue if false, the value is not deep copied, default is false
 * @returns
 */
export const createArray = (length, value = null, deepCopyValue = false) =>
  deepCopyValue
    ? Array.from({ length }, () => deepCopy(value))
    : Array.from({ length }, () => value);
