import { Box, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import OptionTableRow from './OptionTableRow';

/*
 * Generates a Table with styling as displayed in the Options section.
 *
 * Params:
 *  header: Array of column contents in the header
 *  body: 2d row first array of contents in each cell entry.
 */
const OptionTable = ({ header, body, name }) => {
  return (
    <Table size="sm" variant="simple">
      <Thead>
        <Tr key={`${name}-header`}>
          {header.map((columnTitle) => {
            return (
              <Td pl="1px" pr="1px" key={`${name}-header-col-${columnTitle}`}>
                <Box
                  fontWeight="medium"
                  style={{
                    fontSize: 'sm',
                    textTransform: 'uppercase',
                  }}
                >
                  {columnTitle}
                </Box>
              </Td>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {body.map((rowEntries, rowNum) => {
          return (
            <OptionTableRow
              entries={rowEntries}
              name={`${name}-body-row-${rowNum}`}
              // eslint-disable-next-line react/no-array-index-key
              key={`${name}-body-row-${rowEntries[0]}-${rowNum}`}
            />
          );
        })}
      </Tbody>
    </Table>
  );
};

export default OptionTable;
