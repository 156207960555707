import { Alert, AlertIcon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postRequestResetPasswort } from '../api/authentication';
import doccapeLogo from '../assets/DocCape_Favicon.svg';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import CustomForm from '../components/molecules/tailwind/CustomForm';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';

const ResetPasswordRequestMail = () => {
  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLinkSentSuccessful, setIsLinkSentSuccessful] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  // TODO: email validation
  const isValidLogin = (emailToVerify) => {
    return emailToVerify !== '';
  };

  /**
   * Validates the email address and requests a password reset mail
   */
  const onSubmit = () => {
    if (isValidLogin(email)) {
      postRequestResetPasswort(email)
        .then(() => {
          setShowError(false);
          setIsLinkSentSuccessful(true);
        })
        .catch(() => {
          setShowError(true);
        });
    } else {
      setShowError(true);
    }
  };

  // useEffect for handling the enter key press
  useEffect(() => {
    const handleEnterKeyPress = (event) => {
      if (event.key === 'Enter') {
        onSubmit();
      }
    };
    document.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [email]);

  return (
    <ExternalViewLayout>
      <CustomCard
        image={
          <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
        }
        title={t('resetPassword.headline')}
        subtitle={
          isLinkSentSuccessful === false
            ? t('resetPassword.headerText')
            : t('resetPassword.resetSuccess')
        }
        buttonText={
          isLinkSentSuccessful === false
            ? t('resetPassword.buttonText')
            : t('resetPassword.backToLoginButton')
        }
        buttonAction={isLinkSentSuccessful === false ? onSubmit : () => navigate('/login')}
        content={
          isLinkSentSuccessful === false ? (
            <CustomForm
              inputFields={[
                {
                  id: 'email',
                  name: t('resetPassword.email'),
                  type: 'email',
                  value: email,
                  onChange: (event) => setEmail(event.target.value),
                  required: true,
                },
              ]}
              additionalContent={
                <div>
                  {showError === true && isLinkSentSuccessful === false ? (
                    // TODO: Implement tailwind alert after design is finalized
                    <Alert status="error">
                      <AlertIcon />
                      {t('resetPassword.emailNotValid')}
                    </Alert>
                  ) : null}
                </div>
              }
            />
          ) : (
            <p className="text-center text-base text-grey-1">
              {t('resetPassword.resetSuccessSubtext')}
            </p>
          )
        }
      />
    </ExternalViewLayout>
  );
};

export default ResetPasswordRequestMail;
