import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import doccapeLogo from '../assets/DocCape_Favicon.svg';
import ThumbsUp from '../assets/DocCape_Hand_thumbsup_yellow.png';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';

/**
 * Renders the success page if the user verification was successful
 */
const VerifyEmailSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  return (
    <ExternalViewLayout>
      <CustomCard
        image={logoImage}
        title={t('verifyEmail.verifyEmailSuccess.heading')}
        subtitle={t('verifyEmail.verifyEmailSuccess.subHeading')}
        buttonText={t('verifyEmail.verifyEmailSuccess.toLoginButton')}
        buttonAction={() => navigate('/login')}
        content={
          <div className="flex w-full items-center justify-center">
            <img className="w-20" src={ThumbsUp} alt="Thumbs Icon" />
          </div>
        }
      />
    </ExternalViewLayout>
  );
};

export default VerifyEmailSuccess;
