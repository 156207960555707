import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import AddIcon from '../components/atoms/icons/AddIcon';
import IconButton from '../components/atoms/tailwind/IconButton';
import UserTable from '../components/molecules/UserTable';
import BreadCrumbs from '../components/molecules/tailwind/BreadCrumbs';
import CustomBasicContainerForContent from '../components/molecules/tailwind/CustomBasicContainerForContent';
import CustomButtonGroup from '../components/molecules/tailwind/CustomButtonGroup';
import CustomContainer from '../components/molecules/tailwind/CustomContainer';
import ViewLayout from '../components/molecules/tailwind/ViewLayout';
import AddUserToOrgDialog from '../components/organisms/organizationManagement/AddUserToOrgDialog';
import ForbiddenAlert from '../components/organisms/tailwind/ForbiddenAlert';
import useOrganizations from '../hooks/useOrganizations';
import useUsersOrganization from '../hooks/useUsersOrganization';
import { selectUserData } from '../reducers/userSlice';
import { checkOrgRole } from '../services/authService';

const UserOverviewOrganization = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { users, updateUserRole, refreshInvitationTokenByAdmin } =
    useUsersOrganization(organizationId);
  const {
    isShown,
    setIsShown,
    isValidEmail,
    setIsValidEmail,
    errorMsg,
    setErrorMsg,
    inviteUserToOrganization,
    onRemoveUserFromOrganization,
    isLoading,
  } = useUsersOrganization(organizationId);
  const location = useLocation();
  const { getOrgById } = useOrganizations(organizationId);
  const user = useSelector(selectUserData);

  const urlNames = {
    organizations: getOrgById(organizationId)?.name || '',
  };

  const roles = [
    { id: 1, name: 'Admin', value: 'ADMIN' },
    { id: 2, name: 'User', value: 'USER' },
  ];

  const contentUserOverview = () => (
    <>
      <div className="space-y-4">
        <CustomButtonGroup searchbar={false}>
          <IconButton
            buttonText={t('organizationMngmt.invitationUser.inviteUser')}
            icon={<AddIcon />}
            color="blue"
            buttonAction={() => {
              setIsShown(true);
            }}
          />
        </CustomButtonGroup>
      </div>
      <div className="mx-auto w-full">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full px-8 py-2 align-middle ">
              <div className="rounded-lg bg-white px-10 pb-10 pt-5 shadow ring-1 ring-white/5">
                <UserTable
                  orgUsers={users}
                  userRoles={roles}
                  updateUserRole={updateUserRole}
                  deleteUser={onRemoveUserFromOrganization}
                  isLoadingUsers={isLoading}
                  refreshInvitationTokenByAdmin={refreshInvitationTokenByAdmin}
                  canAdminResendInvitation
                />
                <AddUserToOrgDialog
                  open={isShown}
                  setOpen={setIsShown}
                  inviteUserToOrganization={inviteUserToOrganization}
                  errorMsg={errorMsg}
                  setErrorMsg={setErrorMsg}
                  isValidEmail={isValidEmail}
                  setIsValidEmail={setIsValidEmail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <CustomBasicContainerForContent>
      {user && checkOrgRole(user, organizationId, 'ADMIN') ? (
        <ViewLayout
          breadCrumbs={<BreadCrumbs urlNames={urlNames} urlPath={location.pathname} />}
          title={t('organizationMngmt.userMngmt.heading')}
          content={contentUserOverview()}
        />
      ) : (
        <CustomContainer>
          <ForbiddenAlert organizationId={organizationId} />
        </CustomContainer>
      )}
    </CustomBasicContainerForContent>
  );
};

export default UserOverviewOrganization;
