import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentImage } from '../api/document';
import {
  selectDocumentDataError,
  selectDocumentDataStatus,
  selectPageData,
} from '../reducers/documentDataSlice';
import deleteBbox from '../services/documentImageService';
import { getResetHistory, getUpdatedHistory } from '../services/historyService';
import { createLabelColor } from '../services/imageLabelService';
import { deepCopy } from '../services/utils';

const useDocumentImages = (documentId) => {
  // ------------- SLICE ACCESS
  const pageData = useSelector(selectPageData);
  const fetchStatus = useSelector(selectDocumentDataStatus);
  const fetchError = useSelector(selectDocumentDataError);

  // ------------- STATES
  const [documentImages, setDocumentImages] = useState(null);
  const [activeImageId, setActiveImageId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [oldDocumentId, setOldDocumentId] = useState(null);

  const history = useRef([]); // holds the history of the annotations and sentence starts (for undo/redo)
  const [currentHistoryIdx, setCurrentHistoryIdx] = useState(0);
  const undoActionName = history.current[currentHistoryIdx]?.prevAction;
  const redoActionName = history.current[currentHistoryIdx + 1]?.prevAction;

  /**
   * Resets the history to the initial state
   * @param {*} images The images to reset the history to
   * @param {*} imageId The id of the image to set as active image
   */
  const resetHistory = (images, imageId) => {
    // reset history
    const { newHistory, newCurrentHistoryIdx } = getResetHistory({ images, imageId });
    history.current = newHistory;
    setCurrentHistoryIdx(newCurrentHistoryIdx);
    setOldDocumentId(documentId);
  };

  const transformSliceData = async () => {
    // TODO: Integrate createRenderableBbox and createRenderableDetections
    // TODO: Check after integration if the logic in ImageAnnotationMain can be moved
    const images = [];
    let imgCnt = 0;
    const getImagePromises = [];
    pageData.forEach((page) => {
      page.imagesData.forEach((image) => {
        const imageUrl = image.imageUrl.substring(
          image.imageUrl.indexOf('api') + 4,
          image.imageUrl.length,
        );

        const getImagePromise = getDocumentImage(imageUrl)
          .then((response) => {
            const imageData = {
              ...image,
              id: imgCnt, // TODO: Backend should send id of image
              pageNum: page.pageNum,
              base64: response.data,
            };
            images.push(imageData);
            imgCnt += 1; // TODO: possible race condition??
          })
          .catch((error) => {
            console.log(error);
          });
        getImagePromises.push(getImagePromise);
      });
    });
    await Promise.all(getImagePromises);
    images.sort((img1, img2) => img1.pageNum - img2.pageNum);
    setIsLoading(false);
    return images;
  };

  useEffect(() => {
    const initImageHook = async () => {
      const images = await transformSliceData();
      setDocumentImages(images);
      const initActiveImageId = images[0].id;
      setActiveImageId(initActiveImageId);

      if (documentId !== oldDocumentId && images.length > 0) {
        resetHistory(images, initActiveImageId);
      }
    };
    if (fetchStatus === 'succeeded') {
      initImageHook();
    }
  }, [pageData, documentId]);

  /**
   * Updates the history and the current state
   * @param {string} action The action that was performed
   * @param {object} newImages The new images
   * @param {number} imageId The id of the image that was changed
   */
  const updateImagesHistory = (action, newImages, imageId) => {
    const { newHistory, newCurrentHistoryIdx } = getUpdatedHistory(
      history.current,
      { images: newImages, imageId },
      currentHistoryIdx,
      action,
    );
    history.current = newHistory;
    setCurrentHistoryIdx(newCurrentHistoryIdx);
    setDocumentImages(newImages);
  };

  /**
   * Undo the last action by setting the current state to the previous state saved in the history
   */
  const undo = () => {
    if (currentHistoryIdx > 0) {
      // Get the image id of the current state
      // (setting this as the active iamge id prevents changing the image when undoing a change in the same image)
      const { imageId } = history.current[currentHistoryIdx];

      const newCurrentHistoryIdx = currentHistoryIdx - 1;
      const { images: previousImages } = history.current[newCurrentHistoryIdx];

      setDocumentImages(previousImages);
      setActiveImageId(imageId);
      setCurrentHistoryIdx(newCurrentHistoryIdx);
    }
  };

  /**
   * Redo the last action by setting the current state to the next state saved in the history
   */
  const redo = () => {
    if (currentHistoryIdx < history.current.length - 1) {
      const newCurrentHistoryIdx = currentHistoryIdx + 1;
      const { images: nextImages, imageId } = history.current[newCurrentHistoryIdx];
      setDocumentImages(nextImages);
      setActiveImageId(imageId);
      setCurrentHistoryIdx(newCurrentHistoryIdx);
    }
  };

  // ------------- HOOK FUNCTIONS

  /**
   * Sorts and clusters images for each page
   */
  const sortImagesByPageId = () => {
    const sorted = {};
    documentImages?.forEach((image) => {
      const pageId = image.pageNum.toString();
      if (!(pageId in sorted)) {
        sorted[pageId] = [];
      }
      sorted[pageId].push({
        imageId: image.id,
        imageBase64: image.base64,
        isSelected: image.id === activeImageId,
      });
    });
    return sorted;
  };

  const getImageById = (id) => {
    if (documentImages === null) {
      return null;
    }
    const image = documentImages.find((element) => element.id === id);
    return image;
  };

  const findImageIdxById = (documentImgs, id) => {
    const imageIdx = documentImgs.findIndex((image) => image.id === id);
    return imageIdx;
  };

  const getLabelIdxInImage = (image, labelName) => {
    const labelIdx = image.detections.findIndex((label) => label.label === labelName);
    return labelIdx;
  };

  const deleteBboxInImage = (labelIdx, idx) => {
    const imageIdx = findImageIdxById(documentImages, activeImageId);

    let newDocumentImages = deepCopy(documentImages);
    newDocumentImages = deleteBbox(newDocumentImages, imageIdx, labelIdx, idx);
    updateImagesHistory('deleteBboxInImage', newDocumentImages, activeImageId);
    // setDocumentImages(newDocumentImages);
  };

  const addBbox = (bbox, label) => {
    const imageIdx = findImageIdxById(documentImages, activeImageId);
    const newDocumentImages = deepCopy(documentImages);

    // find idx of label
    const labelIdx = getLabelIdxInImage(newDocumentImages[imageIdx], label.name);
    if (labelIdx === -1) {
      // If there is no label, this is the first bbox with this label
      newDocumentImages[imageIdx].detections.push({
        label: label.name,
        bboxs: [bbox],
        scores: [1.0],
        color: label.color,
      });
    } else {
      newDocumentImages[imageIdx].detections[labelIdx].bboxs.push(bbox);
      newDocumentImages[imageIdx].detections[labelIdx].scores.push(1.0);
    }
    updateImagesHistory('addBbox', newDocumentImages, activeImageId);
    // setDocumentImages(newDocumentImages);
  };

  const updateBbox = (bbox, labelIdx, bboxIdx) => {
    const imageIdx = findImageIdxById(documentImages, activeImageId);
    const newDocumentImages = deepCopy(documentImages);

    newDocumentImages[imageIdx].detections[labelIdx].bboxs[bboxIdx] = bbox;

    updateImagesHistory('updateBbox', newDocumentImages, activeImageId);
    // setDocumentImages(newDocumentImages);
  };

  const changeLabelOfBbox = (bboxIdx, labelIdx, newLabelName) => {
    const imageIdx = findImageIdxById(documentImages, activeImageId);
    let newDocumentImages = deepCopy(documentImages);
    const bbox = newDocumentImages[imageIdx].detections[labelIdx].bboxs[bboxIdx];
    const score = newDocumentImages[imageIdx].detections[labelIdx].scores[bboxIdx];

    // remove the bbox and score at the old label
    newDocumentImages = deleteBbox(newDocumentImages, imageIdx, labelIdx, bboxIdx);

    // find idx of new label
    const newLabelIdx = getLabelIdxInImage(newDocumentImages[imageIdx], newLabelName);

    if (newLabelIdx === -1) {
      // If there is no label, this is the first bbox with this label
      const newIdx = newDocumentImages[imageIdx].detections.length;
      newDocumentImages[imageIdx].detections.push({
        label: newLabelName,
        bboxs: [bbox],
        scores: [score],
        color: createLabelColor(newIdx), // TODO: Change after changing transformSliceData structure
      });
    } else {
      newDocumentImages[imageIdx].detections[newLabelIdx].bboxs.push(bbox);
      newDocumentImages[imageIdx].detections[newLabelIdx].scores.push(score);
    }
    updateImagesHistory('changeLabelOfBbox', newDocumentImages, activeImageId);
    // setDocumentImages(newDocumentImages);
  };

  /**
   * Returns the active image object
   *
   * @returns {object | null} active image object if found or null
   */
  const findActiveImage = () => {
    if (activeImageId !== null) {
      return documentImages.find((element) => element.id === activeImageId);
    }
    return null;
  };

  /**
   * Returns the number of bounding boxes of a given label
   *
   * @param {Object} label The given label
   * @returns The number of bounding boxes of the given label
   */
  const getNumberOfBboxesOfLabel = (label, detections) => {
    return typeof detections !== 'undefined' && typeof detections[label.name] !== 'undefined'
      ? detections[label.name].bboxs.length
      : 0;
  };

  const createRenderableDetections = (image) => {
    if (image === null) {
      return {};
    }
    const { detections } = image;
    if (detections === undefined) {
      return {};
    }
    const renderableDetections = {};
    detections.forEach((labelDetections, labelIdx) => {
      renderableDetections[labelDetections.label] = {
        color: createLabelColor(labelIdx), // TODO: Change
        bboxs: labelDetections.bboxs,
      };
    });
    return renderableDetections;
  };

  /**
   * Creates renderable bounding boxes for the given (active) image
   * @param {object} image The image object to create renderable bounding boxes for
   * @param {object} image.detections The detections of the image containing an object for each label containing the bounding boxes
   * @param {object[]} labels The image labels
   * @returns An array of renderable bounding boxes of the image
   */
  const createRenderableBboxs = (image, labels) => {
    const { detections } = image;
    if (detections === undefined || labels.length === 0) {
      return [];
    }
    const renderableBboxs = [];
    detections.flat().forEach((labelDetections, labelIdx) => {
      // Find the corresponding label
      let labelOfBboxs = null;
      for (let i = 0; i < labels.length; i += 1) {
        if (labels[i].name === labelDetections.label) {
          labelOfBboxs = labels[i];
          break;
        }
      }
      if (labelOfBboxs === null) {
        return;
      }
      if (!labelOfBboxs.isEnabled || labelOfBboxs.isHidden) {
        return;
      }
      labelDetections.bboxs.forEach((bbox, bboxIdx) => {
        renderableBboxs.push({
          id: { labelIdx, bboxIdx },
          label: labelDetections.label,
          bbox,
          color: labelOfBboxs.color !== null ? labelOfBboxs.color : createLabelColor(labelIdx),
        });
      });
    });
    return renderableBboxs;
  };

  return {
    images: documentImages,
    activeImageId,
    isLoading,
    undoActionName,
    redoActionName,
    sortImagesByPageId,
    getImageById,
    addBbox,
    updateBbox,
    deleteBboxInImage,
    changeLabelOfBbox,
    findActiveImage,
    setActiveImageId,
    createRenderableDetections,
    createRenderableBboxs,
    getNumberOfBboxesOfLabel,
    redo,
    undo,
  };
};

export default useDocumentImages;
