import React from 'react';

const ArrowUpIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m12.05,19.65c2.16-2.16,4.32-4.32,6.48-6.48.15-.15.29-.29.44-.44,2.01,2.01,4.03,4.03,6.04,6.04.31.31.62.62.94.94.71.71,1.81-.39,1.1-1.1-2.19-2.19-4.39-4.39-6.58-6.58-.31-.31-.62-.62-.94-.94-.28-.28-.61-.27-.87-.13-.11.03-.21.09-.31.19-2.16,2.16-4.32,4.32-6.48,6.48-.31.31-.61.61-.92.92-.71.71.39,1.81,1.1,1.1Z" />
    </svg>
  );
};

export default ArrowUpIcon;
