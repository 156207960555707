import React from 'react';
import doccapeLogo from '../../../../assets/DocCape_Favicon.svg';
import ThumbsUp from '../../../../assets/DocCape_Hand_thumbsup_yellow.png';
import CustomCard from '../../../molecules/tailwind/CustomCard';

/**
 * Component for the card that is shown when the user tries to use an invalid token
 * @param {string} title The title of the card
 * @param {string} subtitle The subtitle of the card
 * @param {string} info The info text of the card
 */
const NotValidTokenCard = ({ title, subtitle, info }) => {
  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  return (
    <CustomCard
      image={logoImage}
      title={title}
      subtitle={subtitle}
      buttonText={null}
      buttonAction={null}
      content={
        <>
          <div className="flex w-full items-center justify-center">
            <img className="w-20 rotate-180" src={ThumbsUp} alt="Thumbs Icon" />
          </div>
          <p className="mt-3 text-center text-sm text-grey-1">{info}</p>
        </>
      }
    />
  );
};

export default NotValidTokenCard;
