import React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

/**
 * Hook to get the pixel precise bounding client rect of a target ref.
 * Needed to measure on screen sizes of elements in the dom.
 * Updates the size on any resize.
 *
 * @param {*} target The ref to the target element
 * @returns bouding client rect object
 */
const useSize = (target) => {
  const [size, setSize] = React.useState();

  React.useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default useSize;
