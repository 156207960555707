import React from 'react';

const ButtonLink = (props) => {
  const { name, onClick } = props;

  return (
    <div className="text-xl font-medium hover:cursor-pointer" onClick={onClick}>
      {name}
    </div>
  );
};

export default ButtonLink;
