import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCheck } from 'react-icons/hi';
import { MdOutlineHourglassEmpty } from 'react-icons/md';
import { RiCheckDoubleLine } from 'react-icons/ri';
import { processingStatus } from '../../../types/processingStatus';
import IconWithTooltip from '../IconWithTooltip';

const ProcessStatusIcon = ({ processStatus }) => {
  const { t } = useTranslation();
  switch (processStatus) {
    case processingStatus.UNPROCESSED:
      return (
        <IconWithTooltip
          tooltiplabel={t('documentSelection.status.unprocessed')}
          icon={<MdOutlineHourglassEmpty size={20} />}
        />
      );
    case processingStatus.PROCESSING:
      return (
        <IconWithTooltip
          tooltiplabel={t('documentSelection.status.preprocessing')}
          icon={<Spinner />}
        />
      );
    case processingStatus.COMPLETED:
      return (
        <IconWithTooltip
          tooltiplabel={t('documentSelection.status.completed')}
          icon={<HiOutlineCheck size={20} />}
        />
      );
    case processingStatus.FINALIZED:
      return (
        <IconWithTooltip
          tooltipLabel={t('documentSelection.status.finalized')}
          icon={<RiCheckDoubleLine color="green" size={20} />}
        />
      );
    default:
      return null;
  }
};

export default ProcessStatusIcon;
