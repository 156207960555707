import { createSelector, createSlice } from '@reduxjs/toolkit';
import annotationViewTypes from '../types/annotationView';

const initialState = annotationViewTypes.textAnnotation;

export const activeAnnotationViewSlice = createSlice({
  name: 'activeAnnotationView',
  initialState,
  reducers: {
    setActiveAnnotationView(state, action) {
      return action.payload;
    },
    clearActiveAnnotationView() {
      return initialState;
    },
  },
});

export const selectActiveAnnotationView = createSelector(
  (state) => state.activeAnnotationView,
  (activeAnnotationView) => activeAnnotationView,
);

export const { setActiveAnnotationView, clearActiveAnnotationView } =
  activeAnnotationViewSlice.actions;

export default activeAnnotationViewSlice.reducer;
