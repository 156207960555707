import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';

const IconWithTooltip = ({ icon, tooltipLabel }) => {
  return (
    <Tooltip label={tooltipLabel}>
      <Box>{icon}</Box>
    </Tooltip>
  );
};
export default IconWithTooltip;
