import * as React from 'react';
import { Box, Spacer, Flex } from '@chakra-ui/react';

const ContentArea = (props) => {
  const { children, isOpen } = props;
  return isOpen ? (
    <Box bg="gray.300" width="22vw" height="100%" p={2}>
      <Flex height="100%" alignItems="flex-start" flexDirection="row">
        <Spacer />
        {children}
        <Spacer />
      </Flex>
    </Box>
  ) : null;
};

export default ContentArea;
