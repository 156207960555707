import { Box, Flex, HStack, Input, Spacer, Spinner } from '@chakra-ui/react';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { FaFileCsv, FaUsers } from 'react-icons/fa';
import { HiCog, HiDownload, HiOutlineDocumentAdd, HiOutlineRefresh } from 'react-icons/hi';
import { MdDeleteOutline } from 'react-icons/md';
import { SiSimpleanalytics } from 'react-icons/si';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonWithTooltip from '../../molecules/ButtonWithTooltip';
import AddUserToProjDialog from '../projectManagement/documentSelection/AddUserToProjDialog';
import SettingsMain from '../settings/SettingsMain';

const MenuBar = ({
  height,
  isUploading,
  isRefreshing,
  selectedFiles,
  isDownloading,
  setIsMetricsOpen,
  selectedDocumentIds,
  getDocumentsCSVExportData,
  onUpload,
  onDownload,
  areSelectedDocumentsPreprocessed,
  areSelectedDocumentsFinalized,
  onDelete,
  isDeleting,
  onRefresh,
  acceptedExtensions,
  isShown,
  setIsShown,
  isDisabled,
  setIsDisabled,
  textLabels,
  addTextLabel,
  hasTextLabelExtendedReplacement,
  textLabelHiddenColor,
  activeTextLabelName,
  setActiveTextLabelName,
  nameInputByTextLabelName,
  setNameInputByTextLabelName,
  imageLabels,
  imageAnonymizationTypes,
  setImageAnonymizationType,
  setImageLabelAnonymizationType,
  setImageLabelIsEnabled,
  saveImageLabels,
  isImageAnonymizationEnabled,
  setIsImageAnonymizationEnabled,
  whitelist,
  isWhitelistCSVImporting,
  saveWhitelist,
  addWhitelistItem,
  removeWhitelistItem,
  updateWhitelistItem,
  removeAllWhitelistItems,
  importWhitelistItems,
  blacklist,
  isBlacklistCSVImporting,
  saveBlacklist,
  addBlacklistItem,
  removeBlacklistItem,
  updateBlacklistItem,
  removeAllBlacklistItems,
  importBlacklistItems,
  saveTextLabels,
  changeTextLabelName,
  changeExtendedReplacement,
  setTextLabelAttribute,
  deleteTextLabel,
  allDateLocales,
  computeScores,
  isScoresLoading,
  openModal,
  isShownInviteProject,
  closeModalWithoutSaving,
  closeAndSaveModal,
  handleUserEmailInput,
  roles,
  setSelectedRole,
  selectableUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId, projectId } = useParams();

  return (
    <Box width="100%" height={`calc(${height})`} bg="gray.300" padding="1">
      <Flex flexDir="row">
        <HStack>
          {/* TODO: Fix pointer with dropzone? */}
          {/* TODO: Separate upload button, new component */}
          <ButtonWithTooltip
            tooltipLabel={t('documentSelection.upload')}
            isDisabled={isUploading}
            leftIcon={isUploading ? <Spinner size="sm" /> : <HiOutlineDocumentAdd />}
            colorScheme="blue"
          >
            <Input
              type="file"
              multiple
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden
              accept={acceptedExtensions.join(',')}
              onChange={(event) => {
                onUpload(event.target.files);
              }}
              value={selectedFiles}
              pointerEvents={isUploading ? 'none' : 'all'}
            />
            {t('documentSelection.uploadButton')}
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipLabel={t('documentSelection.refresh')}
            onClick={onRefresh}
            isDisabled={isRefreshing}
            leftIcon={isRefreshing ? <Spinner size="sm" /> : <HiOutlineRefresh />}
          >
            {t('documentSelection.refreshButton')}
          </ButtonWithTooltip>
          <CSVLink
            // TODO: dont call getDocumentsCSVExportData() twice
            headers={getDocumentsCSVExportData().headers}
            data={getDocumentsCSVExportData().data}
            filename="documents.csv"
            separator=";"
          >
            <ButtonWithTooltip
              tooltipLabel={t('documentSelection.export')}
              isDisabled={isUploading}
              leftIcon={isUploading ? <Spinner size="sm" /> : <FaFileCsv />}
            >
              {t('documentSelection.exportButton')}
            </ButtonWithTooltip>
          </CSVLink>
          {selectedDocumentIds.length > 0 && (
            <>
              <ButtonWithTooltip
                tooltipLabel={
                  areSelectedDocumentsPreprocessed()
                    ? t('documentSelection.downloadSelectedTooltip')
                    : t('documentSelection.cannotDownloadSelectedTooltip')
                }
                onClick={() => {
                  onDownload(selectedDocumentIds);
                }}
                isDisabled={!areSelectedDocumentsPreprocessed()}
                isLoading={isDownloading}
                leftIcon={isRefreshing ? <Spinner size="sm" /> : <HiDownload />}
              >
                {t('documentSelection.downloadButton')}
              </ButtonWithTooltip>
              <ButtonWithTooltip
                tooltipLabel={t('documentSelection.deleteSelectedTooltip')}
                onClick={() => {
                  onDelete(selectedDocumentIds);
                }}
                leftIcon={isRefreshing ? <Spinner size="sm" /> : <MdDeleteOutline />}
                isLoading={isDeleting}
              >
                {t('documentSelection.deleteSelectedButton')}
              </ButtonWithTooltip>
              <ButtonWithTooltip
                tooltipLabel={
                  areSelectedDocumentsFinalized()
                    ? t('documentSelection.showModelQualitySelectedTooltip')
                    : t('documentSelection.cannotShowModelQualitySelectedTooltip')
                }
                isDisabled={!areSelectedDocumentsFinalized() || isScoresLoading}
                onClick={async () => {
                  await computeScores(selectedDocumentIds);
                  setIsMetricsOpen(true);
                }}
                leftIcon={
                  isRefreshing || isScoresLoading ? <Spinner size="sm" /> : <SiSimpleanalytics />
                }
                isLoading={isDeleting}
              >
                {t('documentSelection.showModelQualityloadSelectedButton')}
              </ButtonWithTooltip>
            </>
          )}
        </HStack>
        <Spacer />
        <HStack>
          <ButtonWithTooltip
            tooltipLabel={t('projectSettings.settings')}
            onClick={() =>
              navigate(`/organizations/${organizationId}/projects/${projectId}/settings`)
            }
            leftIcon={<FaUsers size={15} />}
          >
            {t('projectSettings.settings')}
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipLabel={t('documentSelection.inviteUserTooltip')}
            onClick={openModal}
            leftIcon={<FaUsers size={15} />}
          >
            {t('documentSelection.inviteUserButton')}
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipLabel={t('documentSelection.userMngmtTooltip')}
            onClick={() => {
              navigate(`/organizations/${organizationId}/projects/${projectId}/users`);
            }}
            leftIcon={<FaUsers size={15} />}
          >
            {t('documentSelection.userMngmtButton')}
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipLabel={t('sidebar.clickables.settings.tooltip')}
            onClick={() => setIsShown(true)}
            isDisabled={isDisabled}
            leftIcon={<HiCog size={15} />}
          >
            {t('documentSelection.settings')}
          </ButtonWithTooltip>
        </HStack>
        <SettingsMain
          setIsShown={setIsShown}
          isShown={isShown}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          textLabels={textLabels}
          addTextLabel={addTextLabel}
          hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
          textLabelHiddenColor={textLabelHiddenColor}
          activeTextLabelName={activeTextLabelName}
          setActiveTextLabelName={setActiveTextLabelName}
          nameInputByTextLabelName={nameInputByTextLabelName}
          setNameInputByTextLabelName={setNameInputByTextLabelName}
          imageLabels={imageLabels}
          imageAnonymizationTypes={imageAnonymizationTypes}
          setImageAnonymizationType={setImageAnonymizationType}
          isImageAnonymizationEnabled={isImageAnonymizationEnabled}
          setIsImageAnonymizationEnabled={setIsImageAnonymizationEnabled}
          setImageLabelAnonymizationType={setImageLabelAnonymizationType}
          setImageLabelIsEnabled={setImageLabelIsEnabled}
          saveImageLabels={saveImageLabels}
          whitelist={whitelist}
          isWhitelistCSVImporting={isWhitelistCSVImporting}
          saveWhitelist={saveWhitelist}
          addWhitelistItem={addWhitelistItem}
          removeWhitelistItem={removeWhitelistItem}
          updateWhitelistItem={updateWhitelistItem}
          removeAllWhitelistItems={removeAllWhitelistItems}
          importWhitelistItems={importWhitelistItems}
          blacklist={blacklist}
          isBlacklistCSVImporting={isBlacklistCSVImporting}
          saveBlacklist={saveBlacklist}
          addBlacklistItem={addBlacklistItem}
          removeBlacklistItem={removeBlacklistItem}
          updateBlacklistItem={updateBlacklistItem}
          removeAllBlacklistItems={removeAllBlacklistItems}
          importBlacklistItems={importBlacklistItems}
          saveTextLabels={saveTextLabels}
          changeTextLabelName={changeTextLabelName}
          changeExtendedReplacement={changeExtendedReplacement}
          setTextLabelAttribute={setTextLabelAttribute}
          deleteTextLabel={deleteTextLabel}
          allDateLocales={allDateLocales}
        />
      </Flex>
      <AddUserToProjDialog
        open={isShownInviteProject}
        closeModalWithoutSaving={closeModalWithoutSaving}
        closeAndSaveModal={closeAndSaveModal}
        handleUserEmailInput={handleUserEmailInput}
        roles={roles}
        setSelectedRole={setSelectedRole}
        selectableUsers={selectableUsers}
      />
    </Box>
  );
};

export default MenuBar;
