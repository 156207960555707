import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppToaster from '../components/molecules/AppToaster';
import {
  addUserToProject,
  clearUsersOfProject,
  fetchUsersByProject,
  removeUserFromProject,
  selectUsersOfProjectData,
  selectUsersOfProjectError,
  selectUsersOfProjectStatus,
} from '../reducers/usersOfProjectSlice';

/**
 * Custom hook to manage the users of a project
 * @param {*} projectId The id of the project the users belong to
 * @param {*} orgId The id of the organization the project belongs to
 * @param {*} organizationUsers The users of the organization - only users that are part of the organization can be added to a project
 */
const useUsersProject = (projectId, orgId, organizationUsers) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // users of the project
  const [users, setUsers] = useState([]);
  // users of the organization that are not part of the project (suitable for adding to the project)
  const [organizationUsersNotInProject, setOrganizationUsersNotInProject] = useState([]);

  const sliceUsersData = useSelector(selectUsersOfProjectData);
  const fetchStatus = useSelector(selectUsersOfProjectStatus);
  const fetchError = useSelector(selectUsersOfProjectError);

  // Roles that a user can have in a project
  // TODO: This should be fetched from the backend to increase loose coupling
  const roles = [{ name: 'USER' }, { name: 'ADMIN' }, { name: 'VIEWER' }];

  const [selectedRole, setSelectedRole] = useState(roles[0].name);
  const [invitedUserId, setInvitedUserId] = useState(null);

  const initSlice = async () => {
    setIsLoading(true);
    await dispatch(fetchUsersByProject({ orgId, projectId })).unwrap();
  };

  const transformSliceData = () => {
    if (fetchStatus === 'pending') {
      setIsLoading(true);
    }
    if (fetchStatus === 'succeeded') {
      setUsers(sliceUsersData);
      setIsLoading(false);
    }
    if (fetchStatus === 'failed') {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    initSlice();

    return () => {
      // Clear users of project state when unmounting
      dispatch(clearUsersOfProject());
    };
  }, [orgId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceUsersData]);

  // Use Effect for initializing the users of the organization that are not part of the project
  useEffect(() => {
    if (!users || !organizationUsers) {
      return;
    }

    const newOrganizationUsersNotInProject = organizationUsers.filter(
      (organizationUser) => !users.some((projectUser) => projectUser.id === organizationUser.id),
    );
    setOrganizationUsersNotInProject(newOrganizationUsersNotInProject);
  }, [organizationUsers, users]);

  // Use Effect for initializing the invited user to the
  // first user in the list of users of the organization that are not part of the project
  useEffect(() => {
    if (organizationUsersNotInProject && organizationUsersNotInProject.length > 0) {
      // Sets the invited user to the first user in the list as default
      setInvitedUserId(organizationUsersNotInProject[0].id);
    }
  }, [organizationUsersNotInProject]);

  /**
   * Add user to project and returns true if it is succesful, false otherwise
   * @param {string} userId The id of the user to add to the project
   * @param {string} projectRole The role of the user
   */
  const onAddUser = async (userId, projectRole) => {
    try {
      await dispatch(addUserToProject({ orgId, projectId, userId, projectRole })).unwrap();
      AppToaster({
        description: t('projectMngmt.userMngmt.addUserDialog.success'),
        status: 'success',
      });
      await dispatch(fetchUsersByProject({ orgId, projectId })).unwrap();
      return true;
    } catch (error) {
      return false;
    }
  };

  /**
   * Delete Project User
   * @param {integer} userId id of the user which should be removed from the project
   */
  const onDeleteProjectUser = async (userId) => {
    try {
      await dispatch(removeUserFromProject({ orgId, projectId, userId })).unwrap();
      AppToaster({
        description: t('projectMngmt.userMngmt.deleteUser.success'),
        status: 'success',
      });
      await dispatch(fetchUsersByProject({ orgId, projectId })).unwrap();
      return true;
    } catch (error) {
      return false;
    }
  };

  /**
   * Update the role of a project user
   * @param {*} userId
   * @param {*} projectRole
   */
  const updateUserProjectRole = async (user, projectRole) => {
    if (user.projectRole === projectRole) return true; // No change in role
    try {
      await dispatch(addUserToProject({ orgId, projectId, userId: user.id, projectRole })).unwrap();
      AppToaster({
        description: t('projectMngmt.userMngmt.changeUserRole.success'),
        status: 'success',
      });
      await dispatch(fetchUsersByProject({ orgId, projectId })).unwrap();
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    roles,
    selectedRole,
    invitedUserId,
    organizationUsersNotInProject,
    isLoading,
    users,
    isShown,
    errorMsg,
    setIsShown,
    setSelectedRole,
    setInvitedUserId,
    setErrorMsg,
    onAddUser,
    onDeleteProjectUser,
    updateUserProjectRole,
  };
};

export default useUsersProject;
