import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Custom hook to handle unsaved changes. It takes a object of react state setters to wrap them with a function that handles the case of unsaved changes.
 */
const useUnsavedChangesHandler = (
  // Object of react state setters to wrap them with a function that handles the case of unsaved changes
  stateSetters,
) => {
  const { t } = useTranslation();

  // State to track if there are unsaved changes
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [wrappedStateSetters, setWrappedStateSetters] = useState({});

  /**
   * Handles the navigation to another page. if there are unsaved changes, the user is asked if he wants to leave the page.
   * Acts as a wrapper for the react-router-dom navigate function.
   *
   * @param {Function} navigateFunc - Function to navigate to another page
   */
  const handleNavigation = (navigateFunc) => {
    if (hasUnsavedChanges) {
      const userConfirmed = window.confirm(t('app.unsavedChangesHandler.navigationWarning'));
      if (userConfirmed) {
        setHasUnsavedChanges(false);
        navigateFunc();
      }
    } else {
      navigateFunc();
    }
  };

  /**
   * Handles the change of a state. if there are unsaved changes, the user is asked if he wants to leave the page.
   * @param {Function} stateSetter - React state setter
   * @param {*} value - Value to set the state to
   */
  const handleStateChange = (stateSetter, value) => {
    if (hasUnsavedChanges) {
      const userConfirmed = window.confirm(t('app.unsavedChangesHandler.stateChangeWarning'));
      if (userConfirmed) {
        setHasUnsavedChanges(false);
        stateSetter(value);
      }
    } else {
      stateSetter(value);
    }
  };

  // UseEffect that wraps the given state setters with the handleStateChange function
  useEffect(() => {
    const wrappedStateSettersObject = {};
    Object.keys(stateSetters).forEach((stateSetterKey) => {
      wrappedStateSettersObject[stateSetterKey] = (value) => {
        handleStateChange(stateSetters[stateSetterKey], value);
      };
    });
    setWrappedStateSetters(wrappedStateSettersObject);
  }, [hasUnsavedChanges]);

  /**
   * Handles the before unload event. If there are unsaved changes, the user is asked if he wants to leave the page.
   * Used as event listener for the beforeunload event.
   *
   * @param {Object} event - Event object
   * @returns {String} - Message to display if the user wants to leave the page (only for some older browsers necessary)
   */
  const handleBeforeUnload = (event) => {
    if (hasUnsavedChanges) {
      const message = 'You have unsaved changes. Are you sure you want to leave this page?'; // TODO: Add translations
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    }
  };

  // UseEffect that prevents the user from leaving the page if there are unsaved changes
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return {
    setHasUnsavedChanges,
    handleNavigation,
    handleStateChange,
    wrappedStateSetters,
  };
};

export default useUnsavedChangesHandler;
