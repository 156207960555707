import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveAnnotationView,
  setActiveAnnotationView,
} from '../reducers/activeAnnotationViewSlice';
import {
  selectActiveSidebarContentArea,
  setActiveSidebarContentArea,
} from '../reducers/activeSidebarContentAreaSlice';

const useSidebar = () => {
  const dispatch = useDispatch();
  const activeSidebarContentArea = useSelector(selectActiveSidebarContentArea);
  const activeAnnotationView = useSelector(selectActiveAnnotationView);

  const changeActiveSidebarView = (newAnnotationView, viewName) => {
    if (newAnnotationView !== activeAnnotationView) {
      dispatch(setActiveAnnotationView(newAnnotationView));
    }
    dispatch(setActiveSidebarContentArea(viewName));
  };

  return { activeSidebarContentArea, activeAnnotationView, changeActiveSidebarView };
};

export default useSidebar;
