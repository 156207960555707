import React from 'react';
import { useDropzone } from 'react-dropzone';
import mime from 'mime';
import { Box } from '@chakra-ui/react';

/**
 * Wraps children component inside a customizable box than can handle drag and drop events.
 * @param {*} onDropAccepted Method called when valid files are dropped
 * @param {*} acceptedExtensions A list of accepted file extensions
 * @param {*} noKeyboard If true, disables SPACE/ENTER to open the native file selection dialog.
 * @param {*} noClick If true, disables click to open the native file selection dialog
 * @param {*} children The children rendered inside the wrapping box
 * @param {*} props Props to customize the box
 * @returns
 */
const Dropzone = ({
  onDropAccepted,
  acceptedExtensions,
  multiple = true,
  noKeyboard = true,
  noClick = true,
  children,
  ...props
}) => {
  /**
   * Creates the accept object for the react-dropzone
   * from a list of accepted file extensions
   * @param {Array[String]} extensions
   * @returns
   */
  const createAcceptForDropzone = (extensions) => {
    const accept = {};
    extensions.forEach((extension) => {
      accept[mime.getType(extension)] = [extension];
    });
    return accept;
  };

  /**
   * Create the dropzone
   */
  const { getRootProps: getDropzoneProps, getInputProps: getDropzoneInputProps } = useDropzone({
    accept: createAcceptForDropzone(acceptedExtensions),
    noClick,
    noKeyboard,
    multiple,
    onDropAccepted,
  });

  return (
    <Box {...props} {...getDropzoneProps()}>
      <input {...getDropzoneInputProps()} />
      {children}
    </Box>
  );
};

export default Dropzone;
