import React from 'react';
import GotItHand from '../../../assets/DocCape_Hand_gotit_yellow.svg';
import InfoIcon from '../icons/InfoIcon';

/**
 * Icon with Tooltip component which displays an "i" icon and when hovered is expanded to a hand which
 * points to the icon and a text bubble with helpful information
 *
 * @param {string} tooltipText text which is displayed within the bubble
 */
const InfoTooltipIcon = ({ tooltipText, children }) => {
  return (
    <div className="flex flex-row items-center">
      <div className="whitespace-nowrap">{children}</div>
      <div className="group relative ml-1 flex min-w-fit cursor-pointer flex-row items-center">
        <div className="max-h-[10px] min-h-[10px] min-w-[10px] max-w-[10px]">
          <InfoIcon />
        </div>
        <div className="z-50 flex w-0 flex-row items-center overflow-visible opacity-0 group-hover:w-fit group-hover:opacity-100">
          <img className="ml-0 h-[45px] w-[45px] pt-5" src={GotItHand} alt="Got It Hand" />
          <div className="pointer-events-none absolute ml-10 mt-10 w-fit min-w-[120px] max-w-[180px] rounded-lg bg-babyBlue-6 px-3 py-2 text-center text-xs text-white">
            <p className="whitespace-normal">{tooltipText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTooltipIcon;
