import { Box, Center, Divider, Flex, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ImageSelection = ({ images, activeImageId, setActiveImageId, sortImagesByPageId }) => {
  const { t } = useTranslation();

  const handleClickEventImage = (image) => {
    setActiveImageId(image.imageId);
  };

  const sortedImages = sortImagesByPageId(images, activeImageId);
  return (
    images &&
    activeImageId !== null && (
      <Box width={350} bg="white" p={5} rounded="5">
        {Object.keys(sortedImages).map((pageId) => (
          <div key={`SelectionImage-${pageId}`}>
            <Text fontSize="16px">
              {t('sidebar.views.imageAnonymization.page')}: {parseInt(pageId, 10)}
            </Text>
            <Divider />
            <Flex gap="2" p={3} flexDirection="row" flexWrap="wrap">
              {sortedImages[pageId].map((img, imgIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Center key={`SelectionImageCenter-${pageId}-${imgIdx}`}>
                  <VStack>
                    <Image
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          border: img.isSelected ? '2px' : '1px',
                          _active: { color: '#0a4683' },
                          _hover: { color: '#0a4683' },
                        },
                      }}
                      border={img.isSelected ? '2px' : '1px'}
                      borderColor={img.isSelected ? '#0a4683' : 'gray.200'}
                      rounded={5}
                      p={1}
                      src={`data:image/*;base64,${img.imageBase64}`}
                      boxSize={12}
                      onClick={() => handleClickEventImage(img)}
                    />
                  </VStack>
                </Center>
              ))}
            </Flex>
          </div>
        ))}
      </Box>
    )
  );
};

export default ImageSelection;
