import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  computeCenteringScaleFactor,
  computeCenteringTranslationVector,
  toImageFrame,
  toStageFrame,
} from '../services/imageAnnotationService';
import { pushArray } from '../services/utils';

const useImageAnnotation = (activeImageId, images) => {
  // ------------- STATES
  const [isCreatingBbox, setIsCreatingBbox] = useState(false);
  const [isEditingBbox, setIsEditingBbox] = useState(false);
  const [isMovingImageButtonMode, setIsMovingImageButtonMode] = useState(false);
  const [activeLabel, setActiveLabel] = useState(null);
  const [activeBbox, setActiveBbox] = useState(null);

  const [pressedMouseButton, setPressedMouseButton] = useState(null);
  const [position, setPosition] = useState([0, 0]);

  // The drawn key points are the key points as drawn in the image (possibly moved and zoomed in)
  const [drawnKeyPoints, setDrawnKeyPoints] = useState([]);
  // The transformed key points are the positions of the bbox in the original image
  const [transformedKeyPoints, setTransformedKeyPoints] = useState([]);
  // The flattened points are the area between the key points (the area of the polygon)
  const [flattenedPoints, setFlattenedPoints] = useState();

  const [centeringScaleFactor, setCenteringScaleFactor] = useState(1.0);
  const [centeringTranslationVector, setCenteringTranslationVector] = useState([0, 0]);
  const [scaleFactor, setScaleFactor] = useState(1.0);
  const [translationVector, setTranslationVector] = useState([0, 0]);
  const [stageWidth, setStageWidth] = useState(0);
  const [stageHeight, setStageHeight] = useState(0);

  const isPolyComplete = drawnKeyPoints.length === 4;
  const zoomDelta = 0.1;

  // ------------- HOOK FUNCTIONS

  const initAnnotatorStage = (imageWidth, imageHeight) => {
    const computedCenteringScaleFactor = computeCenteringScaleFactor(
      stageWidth,
      stageHeight,
      imageWidth,
      imageHeight,
    );
    const computedCenteringTranslationVector = computeCenteringTranslationVector(
      stageWidth,
      stageHeight,
      imageWidth,
      imageHeight,
      computedCenteringScaleFactor,
    );
    setScaleFactor(computedCenteringScaleFactor);
    setTranslationVector(computedCenteringTranslationVector);
    setCenteringScaleFactor(computedCenteringScaleFactor);
    setCenteringTranslationVector(computedCenteringTranslationVector);
  };

  const centerView = () => {
    setScaleFactor(centeringScaleFactor);
    setTranslationVector(centeringTranslationVector);
  };

  /**
   * Sets the existing labels of the active image
   */
  const getLabelsOfImage = (image) => {
    if (image === null || typeof image === 'undefined') {
      return [];
    }
    const uniqueLabels = _.uniqBy(image.detections, 'label').map((obj) => obj.label);
    return uniqueLabels;
  };

  // Not used?
  // const setCenteringScaleAndTranslation = (newScaleFactor, newTranslationVector) => {
  //   setCenteringScaleFactor(newScaleFactor);
  //   setCenteringTranslationVector(newTranslationVector);
  // };
  // const setScaleAndTranslation = (newScaleFactor, newTranslationVector) => {
  //   setScaleFactor(newScaleFactor);
  //   setTranslationVector(newTranslationVector);
  // };

  const zoomAtStagePos = (stagePos, delta) => {
    const newScaleFactor = scaleFactor + delta;
    const pixelAtMousePosInImageFrame = toImageFrame(stagePos, translationVector, scaleFactor);
    const pixelAtMousePosAfterZoom = [
      pixelAtMousePosInImageFrame[0] * newScaleFactor + translationVector[0],
      pixelAtMousePosInImageFrame[1] * newScaleFactor + translationVector[1],
    ];
    const mouseDiff = [
      stagePos[0] - pixelAtMousePosAfterZoom[0],
      stagePos[1] - pixelAtMousePosAfterZoom[1],
    ];

    const newTranslation = [
      translationVector[0] + mouseDiff[0],
      translationVector[1] + mouseDiff[1],
    ];

    setScaleFactor(newScaleFactor);
    setTranslationVector(newTranslation);
  };

  const handleZoomOut = () => {
    const newScaleFactor = scaleFactor - zoomDelta;
    const limitedZoomDelta =
      newScaleFactor > centeringScaleFactor ? -zoomDelta : centeringScaleFactor - scaleFactor;
    zoomAtStagePos([0.5 * stageWidth, 0.5 * stageHeight], limitedZoomDelta);
  };

  const handleZoomIn = () => {
    zoomAtStagePos([0.5 * stageWidth, 0.5 * stageHeight], zoomDelta);
  };

  /**
   * Clear all corresponding states when finished drawing a bounding box
   */
  const clearBboxData = () => {
    setDrawnKeyPoints([]);
    setTransformedKeyPoints([]);
    setFlattenedPoints();
  };

  const updateKeyPoints = (pos) => {
    // Because the bbox coordinates are the original positions transform the coordinates to the drawn frames
    setDrawnKeyPoints([
      toStageFrame(pos[0], translationVector, scaleFactor),
      toStageFrame(pos[1], translationVector, scaleFactor),
      toStageFrame(pos[2], translationVector, scaleFactor),
      toStageFrame(pos[3], translationVector, scaleFactor),
    ]);
    // Because the bbox coordinates are the original positions they can be assgined to the transformedKeyPoints
    setTransformedKeyPoints([pos[0], pos[1], pos[2], pos[3]]);
  };

  /**
   * Get the current position of the mouse
   * @param {Object} stageObj The stage object holding the pointer position
   * @returns The current position of the mouse
   */
  const getMousePos = (stageObj) => {
    return [stageObj.getPointerPosition().x, stageObj.getPointerPosition().y];
  };

  /**
   * Handles the stage move
   * @param {Object} event
   */
  const handleStageMove = (event) => {
    // If a bbox is selected prevent stage moving
    if (activeBbox !== null) return;

    const isStrgKeyPressed = event.evt.ctrlKey;
    const isMacCommandKeyPressed = event.evt.metaKey;
    const isLeftMouseButtonPressed = pressedMouseButton === 0;
    if (
      (isMovingImageButtonMode || isStrgKeyPressed || isMacCommandKeyPressed) &&
      isLeftMouseButtonPressed
    ) {
      // eslint-disable-next-line no-param-reassign
      event.target.getStage().container().style.cursor = 'move';
      const { movementX, movementY } = event.evt;
      setTranslationVector([translationVector[0] + movementX, translationVector[1] + movementY]);
    }
  };

  /**
   * Handles the zoom in
   * @param {Object} event
   */
  const handleZoomIntoMousePointer = (event) => {
    event.evt.preventDefault();

    const stageObj = event.target.getStage();
    const mousePos = getMousePos(stageObj);
    const delta = event.evt.deltaY * -0.0003;
    const newScaleFactor = scaleFactor + delta;

    if (newScaleFactor > centeringScaleFactor) {
      zoomAtStagePos(mousePos, delta);
    }
  };

  /**
   * Sets the position to the current mouse position
   * @param {Object} event
   */
  const handleMoveKeypoint = (event) => {
    const stageObj = event.target.getStage();
    const mousePos = getMousePos(stageObj);

    setPosition(mousePos);
  };

  /**
   * Handles the movement of the mouse and changes the cursor style
   * @param {Object} event
   */
  const handleMouseMove = (event) => {
    // Change cursor style when drawing a bounding box
    const container = event.target.getStage().container();
    if (isCreatingBbox) {
      container.style.cursor = 'crosshair';
      handleMoveKeypoint(event);
    } else {
      if (isMovingImageButtonMode) {
        container.style.cursor = 'move';
      } else if (!isEditingBbox) {
        container.style.cursor = 'default';
      }
      handleStageMove(event);
    }
  };

  /**
   * Bounds the dragging of a shape to the stage size
   * @param {number} stageW The width of the stage
   * @param {number} stageH The height of the stage
   * @param {number} vertexRadius The radius of the vertices of the shape
   * @param {Object} pos The position in stage frame
   * @returns The bounded position
   */
  const pointDragBoundFunc = (stageW, stageH, vertexRadius, pos) => {
    let { x, y } = pos;

    if (x + vertexRadius > stageW) x = stageW;
    if (x - vertexRadius < 0) x = 0;
    if (y + vertexRadius > stageH) y = stageH;
    if (y - vertexRadius < 0) y = 0;
    return { x, y };
  };

  /**
   * Handles the dragging of the key point (when editing)
   * @param {Object} event
   */
  const handlePointDragMove = (event) => {
    const index = event.target.index - 1;
    // eslint-disable-next-line no-underscore-dangle
    const pos = [event.target._lastPos.x, event.target._lastPos.y];

    // Sets the drawn key points to the moved drawn key points
    const movedDrawnKeyPoints = pushArray(drawnKeyPoints, index, [pos]);
    setDrawnKeyPoints(movedDrawnKeyPoints);

    // Sets the transformed key points to the moved transformed key points
    const movedKeyPoint = toImageFrame(pos, translationVector, scaleFactor);
    const transformedMovedKeyPoints = pushArray(transformedKeyPoints, index, [movedKeyPoint]);
    setTransformedKeyPoints(transformedMovedKeyPoints);
  };

  /**
   * Computes the dragged key points and sets the drawn and transformed key points
   * Also returns the transformed key points for further use
   * @param {*} event The event of the drag move
   * @returns The transformed key points
   */
  const computeDraggedKeyPoints = (event) => {
    const drawnResult = [];
    const transformedResult = [];
    const copyPoints = [...drawnKeyPoints];

    copyPoints.forEach((point) => {
      const newPoint = [point[0] + event.target.x(), point[1] + event.target.y()];
      drawnResult.push(newPoint);
      transformedResult.push(toImageFrame(newPoint, translationVector, scaleFactor));
    });
    event.target.position({ x: 0, y: 0 });

    setDrawnKeyPoints(drawnResult);
    setTransformedKeyPoints(transformedResult);
    return transformedResult;
  };

  /**
   * Adds a key point to the drawn and transformed key points
   * Also returns the transformed key points for further use
   * @param {*} event The event of the drag move
   * @returns The transformed key points with the new key point
   */
  const addKeyPoint = (event) => {
    const stageObj = event.target.getStage();
    const mousePos = getMousePos(stageObj);

    // The position of the mouse in the original image frame
    const mousePosInImageFrame = toImageFrame(mousePos, translationVector, scaleFactor);

    // The drawn key points with the new key point in the position of the mouse
    const newDrawnKeyPoints = [...drawnKeyPoints, mousePos];

    // The transformed key points with the new key point in the position of the mouse in the original image
    const newTransformedPoints = [...transformedKeyPoints, mousePosInImageFrame];

    setDrawnKeyPoints(newDrawnKeyPoints);
    setTransformedKeyPoints(newTransformedPoints);
    return newTransformedPoints;
  };

  // Calculates the flattened points when the drawn key points change
  useEffect(() => {
    setFlattenedPoints(
      drawnKeyPoints.concat(isPolyComplete ? [] : position).reduce((a, b) => a.concat(b), []),
    );
  }, [drawnKeyPoints, isPolyComplete, position]);

  const cancelDrawing = () => {
    setIsCreatingBbox(false);
    setActiveLabel(null);
  };

  const beginEditingBbox = () => {
    setIsEditingBbox(true);
  };

  const cancelEditingBbox = () => {
    setIsEditingBbox(false);
  };

  const clearActiveBbox = () => {
    setActiveBbox(null);
  };

  /**
   * Sets the active bounding box to the clicked on bounding box
   */
  const setBboxActive = (bbox) => {
    if (activeBbox !== null && typeof activeBbox !== 'undefined' && activeBbox.id === bbox.id) {
      setActiveBbox(null);
    } else {
      setActiveBbox(bbox);
    }
  };

  /**
   * Checks if the given label is currently active (meaning a bounding box with this label is drawn)
   * @param {Object} label The given label
   * @returns True if the given label is currently active
   */
  const isLabelActive = (label) => {
    return (
      activeLabel !== null && typeof activeLabel !== 'undefined' && label.name === activeLabel.name
    );
  };

  /**
   * Handles the click on the plus of a label accordion to create a box with the specific label
   * @param {string} labelName The name of the label
   */
  const handleCreateBbox = (label) => {
    if (label.isEnabled) {
      setIsCreatingBbox(true);
      setActiveLabel(label);

      // A new Box can only be drawn, when there is no active bounding box
      setActiveBbox(null);
    }
  };

  /**
   * Checks if the bounding box is active
   * @returns True if the active bounding box id is the same as the bounding box
   */
  const isBboxActive = (bbox) => {
    return activeBbox !== null && typeof activeBbox !== 'undefined' && activeBbox.id === bbox.id;
  };

  // If the active bbox is deselected clear bbox data
  useEffect(() => {
    if (activeBbox === null && !isEditingBbox) {
      clearBboxData();
    }
  }, [activeBbox, isEditingBbox]);

  /* Set drawn key points and transformed key points when activating a bounding box */
  useEffect(() => {
    if (activeBbox !== null) {
      const bboxCoordinates = activeBbox.bbox;
      updateKeyPoints(bboxCoordinates);
    }
  }, [activeBbox]);

  /*
   * When the images change, update the active bbox
   * -> this is necessary because the coordinates of the activeBbox are not updated automatically when the image changes
   */
  useEffect(() => {
    // Basic Idea of this workaround:
    // 1. Use activeImageId to get the active image from the changed images
    // 2. Use the id of the activeBbox to get the updated bbox coordinates from the active image (bbox id is labelIdx and bboxIdx)
    // 3. Build an updated renderable bbox object with the updated bbox coordinates (This step can be removed if the TODOS in transformSliceData of useDocumentImages are implemented)
    // 3. Update the activeBbox with the updated renderable bbox object
    if (activeBbox === null) return;
    // Find image with activeImageId
    images.forEach((image) => {
      if (image.id === activeImageId) {
        // Find bbox with activeBbox.id
        const updatedBbox = image.detections[activeBbox.id.labelIdx].bboxs[activeBbox.id.bboxIdx];
        const updatedActiveBbox = {
          id: activeBbox.id,
          label: image.detections[activeBbox.id.labelIdx].label,
          bbox: updatedBbox,
          // Use the "old" label color, because when editing a bbox the label cannot be changed
          // -> Prior of changing the label the bbox is deleted and a new one is created
          color: activeBbox.color,
        };
        // Update bbox with new bbox coordinates
        setActiveBbox(updatedActiveBbox);
      }
    });
  }, [images]);

  // When isCreatingBbox is set to false (e.g. by the cancelDrawing Button in ImageAnnotationTopLeftMenu) clear bbox data
  useEffect(() => {
    if (isCreatingBbox === false) clearBboxData();
  }, [isCreatingBbox]);

  return {
    isCreatingBbox,
    isEditingBbox,
    isMovingImageButtonMode,
    activeLabel,
    activeBbox,
    centeringScaleFactor,
    scaleFactor,
    translationVector,
    stageWidth,
    stageHeight,
    drawnKeyPoints,
    transformedKeyPoints,
    flattenedPoints,
    isPolyComplete,
    zoomAtStagePos,
    initAnnotatorStage,
    getLabelsOfImage,
    toStageFrame,
    toImageFrame,
    setStageWidth,
    setStageHeight,
    handlePointDragMove,
    handleMouseMove,
    setActiveBbox,
    setActiveLabel,
    cancelDrawing,
    beginEditingBbox,
    cancelEditingBbox,
    clearActiveBbox,
    setIsCreatingBbox,
    setIsMovingImageButtonMode,
    setBboxActive,
    centerView,
    clearBboxData,
    handleZoomIn,
    isLabelActive,
    isBboxActive,
    handleZoomOut,
    pointDragBoundFunc,
    setPressedMouseButton,
    handleZoomIntoMousePointer,
    computeDraggedKeyPoints,
    addKeyPoint,
    handleCreateBbox,
  };
};

export default useImageAnnotation;
