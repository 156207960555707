import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';
import SpinnerIcon from '../icons/SpinnerIcon';

const Button = ({
  buttonText,
  buttonAction,
  color = 'blue',
  fullWidth = false,
  disabled,
  isLoading,
  ...rest
}) => {
  const colorVariants = {
    blue: 'bg-blue-3 text-white text-sm hover:bg-white border-blue-3 hover:border-blue-3 hover:text-blue-3 focus-visible:outline-blue-3',
    'blue-large':
      'bg-blue-3 text-white text-xl hover:bg-white border-blue-3 hover:border-blue-3 hover:text-blue-3 focus-visible:outline-blue-3',
    blueNav:
      'bg-blue-2 text-white text-sm hover:bg-white  border-white hover:border-white hover:text-blue-2 focus-visible:outline-blue-2',
    white:
      'bg-white text-blue-3 text-sm hover:bg-blue-3 border-babyBlue-1 hover:border-blue-3 hover:text-white focus-visible:outline-white',
    'white-large':
      'bg-white text-blue-3 text-xl hover:bg-blue-3 border-babyBlue-1 hover:border-blue-3 hover:text-white focus-visible:outline-white',
    red: 'bg-red-3 text-white text-sm hover:bg-white border-red-3 hover:border-red-3 hover:red-3 hover:text-red-3 focus-visible:outline-white',
    orange:
      'bg-orange-1 text-white text-sm hover:bg-white border-orange-1 hover:border-orange-1 hover:text-orange-1 focus-visible:outline-white',
  };

  const disabledColorVariants = {
    blue: 'bg-babyBlue-4 text-sm text-white border-babyBlue-4 hover:cursor-not-allowed',
    'blue-large': 'bg-babyBlue-4 text-xl text-white border-babyBlue-4 hover:cursor-not-allowed',
    white: 'bg-grey-5 text-white text-sm border-grey-5 hover:cursor-not-allowed',
    red: 'bg-red-4 text-white text-sm border-red-4 hover:cursor-not-allowed',
  };

  const fullWidthStyle = 'w-full';

  return (
    <button
      type="button"
      className={classNames(
        `${disabled ? disabledColorVariants[color] : colorVariants[color]}  ${
          fullWidth ? fullWidthStyle : ''
        } flex items-center max-h-9 justify-center shadow-sm border rounded-xl px-4 py-2 tracking-tighter font-medium leading-2 hover:border-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`,
      )}
      onClick={buttonAction}
      disabled={disabled}
      {...rest}
    >
      {isLoading ? (
        <div className="w-6">
          <SpinnerIcon isSpinning />
        </div>
      ) : (
        buttonText
      )}
    </button>
  );
};

export default Button;
