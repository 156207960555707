import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CustomCard from '../components/molecules/tailwind/CustomCard';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';

import doccapeLogo from '../assets/DocCape_Favicon.svg';
import useOrganizations from '../hooks/useOrganizations';

/**
 * Unauthorized view, which is rendered if a 403 is returned from the backend.
 * This view is also shown to the user when a 400 with a INVALID_PATH_PARAM
 * problem code is returned. This happens when the user enteres chars instead
 * of integers for ids.
 * The navigation to this view happens in the interceptor in apiService.js.
 */
const UnauthorizedErrorPage = () => {
  const { t } = useTranslation();
  const { organizations } = useOrganizations();
  const navigate = useNavigate();

  // selects the first organization of the user to route back
  const orgID = organizations[0]?.id;

  return (
    <ExternalViewLayout>
      <CustomCard
        image={
          <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
        }
        title={t('forbiddenAlert.title')}
        subtitle={t('forbiddenAlert.subtitle')}
        buttonText={t('forbiddenAlert.button')}
        buttonAction={() => navigate(`/organizations/${orgID}/projects`)}
        content={null}
      />
    </ExternalViewLayout>
  );
};

export default UnauthorizedErrorPage;
