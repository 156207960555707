import React from 'react';

const ReturnIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m24.95,14.62c-1.24-1.72-3.42-2.44-5.43-2.75-1.17-.18-2.38-.27-3.58-.24.54-.54,1.08-1.08,1.62-1.62.14-.14.28-.28.42-.42.59-.59-.33-1.51-.92-.92-.99.99-1.99,1.99-2.98,2.98-.14.14-.28.28-.42.42-.25.25-.25.67,0,.92.99.99,1.99,1.99,2.98,2.98.14.14.28.28.42.42.59.59,1.51-.33.92-.92l-2.27-2.27c.86-.04,1.72-.02,2.58.06,1.6.15,3.65.57,4.85,1.64,1.24,1.11,1.47,3.16.47,4.42-.52.66-1.29,1.12-2.03,1.4-.87.34-1.8.54-2.73.67-1.07.14-2.5.21-3.71.11-1-.08-.99,1.48,0,1.56,1.18.1,2.38.05,3.55-.09,2.03-.23,4.27-.74,5.75-2.25,1.56-1.59,1.83-4.29.51-6.12Z" />
    </svg>
  );
};

export default ReturnIcon;
