/**
 * Check if a email is a valid one
 * @param {*} email
 * @returns true if it is a valid email
 */
export const isValidEmailCheck = (email) => {
  // Regular expression for email validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Check if give email is a valid one
  return emailRegex.test(email);
};

/**
 * Checks if the passwords match
 */
export const validatePasswordMatch = (password, repeatPassword) => {
  return password === repeatPassword;
};
