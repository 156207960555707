import { Alert, AlertIcon } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getOrganizations } from '../api/organization';
import doccapeLogo from '../assets/DocCape_Favicon.svg';
import Button from '../components/atoms/tailwind/Button';
import AppToaster from '../components/molecules/AppToaster';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import CustomForm from '../components/molecules/tailwind/CustomForm';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';
import useSystemPublicFeatures from '../hooks/useSystemPublicFeatures';
import { login } from '../reducers/authSlice';

const Login = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [showPassword, setShowPassword] = useState(false); // TODO: Implement show password if design is finalized
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { availablePublicFeatures } = useSystemPublicFeatures();

  // TODO: email validation
  const isValidLogin = (emailToVerify, passwordToVerify) => {
    return emailToVerify !== '' && passwordToVerify.length !== '';
  };

  /**
   * Validates the login credentials and login the user if the credentials are valid
   */
  const onSubmit = () => {
    if (isValidLogin(email, password)) {
      dispatch(login({ email, password }))
        .then(unwrapResult)
        .then(() => {
          getOrganizations().then((res) => {
            if (res.data.length === 0) {
              AppToaster({
                description: t('errors.orgs.get./me.400'),
                status: 'error',
              });
            }
            // Always navigate to the first organization (TODO: Implement organization selection if design is finalized)
            const organizationId = res.data[0]?.id;
            navigate(`/organizations/${organizationId}/projects`);
          });
          setShowError(false);
        })
        .catch(() => {
          setShowError(true);
        });
    } else {
      setShowError(true);
    }
  };

  // useEffect for handling the enter key press
  useEffect(() => {
    const handleEnterKeyPress = (event) => {
      if (event.key === 'Enter') {
        onSubmit();
      }
    };
    document.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [email, password]);

  /**
   * Input fields for the login form
   */
  const loginInputFields = [
    {
      id: 'email',
      name: t('login.email'),
      type: 'email',
      value: email,
      onChange: (event) => setEmail(event.target.value),
      required: true,
    },
    {
      id: 'password',
      name: t('login.password'),
      type: 'password',
      value: password,
      onChange: (event) => setPassword(event.target.value),
      required: true,
    },
  ];

  /**
   * Additional content for the login form: remember me checkbox and forgot password link
   */
  const loginAdditionalContent = (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="h-5 w-5 rounded border-babyBlue-1 text-blue-2 focus:ring-blue-1"
          />
          <label htmlFor="remember-me" className="ml-3 block text-lg leading-6 text-blue-2">
            {t('login.rememberMe')}
          </label>
        </div>

        <div className="text-lg leading-6">
          <a href="#/forgotpassword" className="text-blue-2/70 hover:text-blue-1">
            {t('login.forgotpw')}
          </a>
        </div>
      </div>
      {showError ? (
        // TODO: Implement tailwind alert after design is finalized
        <Alert status="error">
          <AlertIcon />
          {t('login.alert')}
        </Alert>
      ) : null}
    </>
  );

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  const loginForm = (
    <CustomForm inputFields={loginInputFields} additionalContent={loginAdditionalContent} />
  );

  const registrationButton = () => {
    return availablePublicFeatures.registrationEnabled ? (
      <Button
        buttonText={t('login.registration')}
        color="white-large"
        fullWidth
        buttonAction={() => navigate('/registration')}
      />
    ) : null;
  };

  return (
    <ExternalViewLayout>
      <CustomCard
        image={logoImage}
        title={t('login.header')}
        buttonText={t('login.login')}
        buttonAction={onSubmit}
        content={loginForm}
        additionalButton={registrationButton()}
      />
    </ExternalViewLayout>
  );
};

export default Login;
