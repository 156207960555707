import React from 'react';

/**
 * Spinner icon component
 * @param {boolean} isSpinning - Whether the spinner should be spinning or not
 */
const SpinnerIcon = ({ isSpinning }) => {
  return (
    <svg
      className={isSpinning ? 'animate-spin' : ''}
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="34"
      viewBox="0 0 38 34"
      fill="currentColor"
    >
      <path d="m27.5,14.79c-.35-.18-.83-.1-1.03.27-.07.13-.14.27-.22.4-.39-1.87-1.49-3.52-3.11-4.64-2.05-1.41-4.79-1.76-7.1-.81-1.55.63-2.77,1.74-3.72,3.1-.55.79.74,1.54,1.3.76,1.66-2.37,4.48-3.5,7.26-2.53,2.01.7,3.42,2.38,3.86,4.37-.11-.11-.21-.21-.32-.32-.28-.28-.79-.3-1.06,0-.27.3-.3.76,0,1.06.56.56,1.12,1.11,1.68,1.67.19.19.41.36.71.32.26-.03.52-.05.71-.24.08-.08.11-.13.15-.22.2-.38.4-.76.61-1.13.18-.34.37-.69.55-1.03.18-.35.1-.83-.27-1.03Z" />
      <path d="m24.39,20.13c-1.66,2.37-4.48,3.5-7.26,2.53-2.11-.74-3.55-2.54-3.92-4.65.1.09.19.18.29.27.28.27.78.3,1.06,0,.27-.29.3-.77,0-1.06-.56-.53-1.12-1.07-1.68-1.6-.03-.03-.07-.06-.1-.09-.24-.22-.47-.25-.78-.2-.29.04-.54.16-.68.44-.19.38-.38.75-.57,1.13-.17.34-.34.68-.52,1.02-.18.35-.1.83.27,1.03.34.18.84.1,1.03-.27.07-.13.13-.26.2-.39.34,1.97,1.45,3.73,3.15,4.9,2.05,1.41,4.79,1.76,7.1.81,1.55-.63,2.77-1.74,3.72-3.1.55-.79-.74-1.54-1.3-.76Z" />
    </svg>
  );
};

export default SpinnerIcon;
