import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScoresDialogBody from './ScoresDialogBody';

const ScoresDialog = ({ showDialog, onClose, scores }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={showDialog} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('annotation.scoresHeader')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ScoresDialogBody scores={scores} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ScoresDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ScoresDialog;
