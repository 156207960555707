import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';

const Input = (props) => {
  const { isValid, type, name, id, autoComplete, onChange, onBlur, disabled, ...rest } = props;

  return (
    <input
      type={type}
      name={name}
      id={id}
      autoComplete={autoComplete}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      className={classNames(
        // if valid is undefined style the input field as valid (= normal)
        (isValid !== undefined && isValid) || isValid === undefined
          ? 'block w-full pl-3 rounded-xl border-0 py-1.5 text-blue-2 text-base shadow-sm ring-1 ring-inset ring-babyBlue-1 placeholder:text-grey-3 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:leading-6'
          : 'border-red-3 pl-3 block w-full rounded-xl border-0 py-1-5 text-blue-2 focus:border-red-3 focus:ring-red-3 ring-red-3 ring-1 ring-inset focus:ring-1 focus:ring-inset sm:leading-6',
        disabled ? 'cursor-not-allowed opacity-30' : '',
      )}
      {...rest}
    />
  );
};

export default Input;
