import {
  Button,
  chakra,
  Checkbox,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDoubleUp, HiDownload, HiLockOpen, HiStop } from 'react-icons/hi';
import { MdDeleteOutline } from 'react-icons/md';
import { RxReset } from 'react-icons/rx';
import { VscOpenPreview, VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { createHumanReadableRepresentationBytes } from '../../../services/utils';
import { processingStatus } from '../../../types/processingStatus';
import ProcessStatusIcon from '../../molecules/documentSelection/ProcessStatusIcon';
import IconButtonWithTooltip from '../../molecules/IconButtonWithTooltip';

const DocumentsTable = ({
  documents,
  onDownloadDocument,
  onReset,
  onDeleteDocument,
  onUnfinalize,
  onPriorize,
  onDisable,
  onEnable,
  navigateToDocument,
  addToSelectedDocumentIds,
  removeFromSelectedDocumentIds,
  isDocumentSelected,
  selectedDocumentIds,
  areAllDocumentsSelected,
  addAllToSelectedDocumentIds,
  removeAllFromSelectedDocumentIds,
}) => {
  const { t } = useTranslation();

  const onChangeDocumentSelection = (documentId, isChecked) => {
    if (isChecked) {
      addToSelectedDocumentIds([documentId]);
    } else {
      removeFromSelectedDocumentIds([documentId]);
    }
  };

  // TODO: Encapsulate jsx table of documents
  /**
   * The table row data.
   * Memo which keeps the table from constantly rerendering.
   */
  const rowsData = React.useMemo(
    () =>
      documents.map((document) => ({
        name: document.original, // TODO: rename to name in backend
        size: document.fileSize, // TODO: add size of document to backend
        lastModified: new Date(document.lastModified),
        status: <ProcessStatusIcon processStatus={document.processingStatus} />,
        actions: (
          <HStack>
            {document.processingStatus !== processingStatus.PROCESSING &&
            document.processingStatus !== processingStatus.UNPROCESSED ? (
              <Popover closeOnBlur>
                <PopoverTrigger>
                  <div>
                    <IconButtonWithTooltip
                      tooltipLabel={t('documentSelection.reset')}
                      icon={<RxReset />}
                      colorScheme="red"
                    />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader fontWeight="medium">
                    {t('documentSelection.resetPopoverHeader')}
                  </PopoverHeader>
                  <PopoverBody>{t('documentSelection.resetPopoverContent')}</PopoverBody>
                  <PopoverFooter d="flex" justifyContent="flex-end">
                    <Button
                      size="sm"
                      onClick={() => {
                        onReset(document.id);
                      }}
                      colorScheme="red"
                    >
                      Ok
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            ) : null}
            {document.processingStatus !== processingStatus.PROCESSING ? (
              <Popover closeOnBlur>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <div>
                        <IconButtonWithTooltip
                          tooltipLabel={t('documentSelection.delete')}
                          icon={<MdDeleteOutline />}
                          colorScheme="red"
                        />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader fontWeight="medium">
                        {t('documentSelection.deletePopoverHeader')}
                      </PopoverHeader>
                      <PopoverBody>{t('documentSelection.deletePopoverContent')}</PopoverBody>
                      <PopoverFooter d="flex" justifyContent="flex-end">
                        <Button
                          size="sm"
                          onClick={() => {
                            onClose();
                            onDeleteDocument(document.id);
                          }}
                          colorScheme="red"
                        >
                          Ok
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            ) : null}
            {(() => {
              switch (document.processingStatus) {
                case processingStatus.UNPROCESSED:
                  return (
                    <>
                      <IconButtonWithTooltip
                        onClick={() => onPriorize(document.id)}
                        tooltipLabel={t('documentSelection.priorizePreprocessing')}
                        icon={<HiChevronDoubleUp />}
                        colorScheme="teal"
                      />
                      <IconButtonWithTooltip
                        onClick={() => onDisable(document.id)}
                        tooltipLabel={t('documentSelection.disablePreprocessing')}
                        icon={<HiStop />}
                        colorScheme="teal"
                      />
                    </>
                  );
                case processingStatus.FINALIZED:
                  return (
                    <>
                      <IconButtonWithTooltip
                        tooltipLabel={t('documentSelection.download')}
                        onClick={() => onDownloadDocument(document.id)}
                        icon={<HiDownload />}
                        colorScheme="green"
                      />
                      <IconButtonWithTooltip
                        tooltipLabel={t('documentSelection.unfinalize')}
                        onClick={() => onUnfinalize(document.id)}
                        icon={<HiLockOpen />}
                        colorScheme="blue"
                      />
                    </>
                  );
                case processingStatus.COMPLETED:
                  return (
                    <>
                      {' '}
                      <Popover placement="left-start" closeOnBlur={false}>
                        {({ onClose }) => (
                          <>
                            <PopoverTrigger>
                              <div>
                                <IconButtonWithTooltip
                                  tooltipLabel={t('documentSelection.download')}
                                  icon={<HiDownload />}
                                  colorScheme="green"
                                />
                              </div>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader fontWeight="medium">
                                {t('documentSelection.downloadPopoverHeader')}
                              </PopoverHeader>
                              <PopoverBody>
                                {t('documentSelection.downloadPopoverContent')}
                              </PopoverBody>
                              <PopoverFooter d="flex" justifyContent="flex-end">
                                <Button
                                  size="sm"
                                  onClick={() => {
                                    onDownloadDocument(document.id);
                                    onClose();
                                  }}
                                  colorScheme="green"
                                >
                                  Ok
                                </Button>
                              </PopoverFooter>
                            </PopoverContent>
                          </>
                        )}
                      </Popover>
                      <IconButtonWithTooltip
                        tooltipLabel={t('documentSelection.startReview')}
                        icon={<VscOpenPreview />}
                        onClick={() => navigateToDocument(document.id)}
                        colorScheme="blue"
                      />
                    </>
                  );
                default:
                  return (
                    <IconButtonWithTooltip
                      tooltipLabel={t('documentSelection.disablePreprocessing')}
                      icon={<HiStop />}
                      onClick={() => onDisable(document.id)}
                      colorScheme="teal"
                    />
                  );
              }
            })()}
          </HStack>
        ),
        selection: (
          <Checkbox
            isChecked={isDocumentSelected(document.id)}
            onChange={(event) => {
              onChangeDocumentSelection(document.id, event.target.checked);
            }}
          />
        ),
      })),
    [documents, selectedDocumentIds],
  );

  /**
   * The column names of the document selection table.
   * A memo which keeps the table from constantly rerendering.
   * Recommended by react-table.
   */
  const columns = React.useMemo(
    () => [
      {
        Header: (
          <Checkbox
            isChecked={areAllDocumentsSelected()}
            onChange={(event) => {
              if (event.target.checked) {
                addAllToSelectedDocumentIds(documents);
              } else {
                removeAllFromSelectedDocumentIds();
              }
            }}
          />
        ),
        accessor: 'selection',
        defaultCanSort: false,
        disableSortBy: true,
      },
      {
        Header: t('documentSelection.header.name'),
        accessor: 'name',
      },
      {
        Header: t('documentSelection.header.size'),
        accessor: 'size',
        Cell: ({ cell: { value } }) =>
          createHumanReadableRepresentationBytes(parseInt(value, 10), 1),
        isNumeric: true,
      },
      {
        Header: t('documentSelection.header.lastModified'),
        accessor: 'lastModified',
        Cell: ({ cell: { value } }) => <div>{value.toLocaleString().replace(',', '')}</div>, // eslint-disable-line react/no-unstable-nested-components
        sortType: 'datetime',
      },
      {
        Header: t('documentSelection.header.status'),
        accessor: 'status',
        defaultCanSort: false,
      },
      {
        Header: '',
        accessor: 'actions',
        defaultCanSort: false,
        disableSortBy: true,
      },
    ],
    [selectedDocumentIds],
  );

  /**
   * Create the react-table data.
   */
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: rowsData,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
  );

  /**
   * Renders the arrow Icon of the columns
   * @param {*} isSorted Is the column sorted
   * @param {*} isSortedDesc Is the column sorted in descending order
   * @returns Arrow icon or null if the column is not sorted
   */
  const renderColumnArrow = (isSorted, isSortedDesc) => {
    if (isSorted) {
      if (isSortedDesc) {
        return <VscTriangleDown aria-label="sorted descending" />;
      }
      return <VscTriangleUp aria-label="sorted ascending" />;
    }
    return null;
  };

  /**
   * Create the jsx of the table
   */
  return (
    <Table {...getTableProps()} variant="striped" size="sm">
      <Thead
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}
        bg="white"
      >
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                isNumeric={column.isNumeric}
              >
                {column.render('Header')}
                <chakra.span pl="4">{renderColumnArrow()}</chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()} overflow="auto">
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default DocumentsTable;
