import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import CustomDialog from './CustomDialog';

import { selectIsAuthenticated } from '../../../reducers/authSlice';

import packageJson from '../../../../package.json';
import PerfectHand from '../../../assets/DocCape_Hand_perfect_yellow.svg';

/**
 * Footer component which displays items for navigation, copyright text and an image
 * @param {String} copyrightText - Text to be displayed in the footer
 * @param {Array} items - Array of objects with the following properties:
 * - name: name of the item
 * - href: href of the item
 * @param {JSX} image - Image to be displayed in the footer
 * @param {Object | undefined} backendVersion - Object with the following properties:
 * - doccapeVersion: DocCape version
 * - preprocessorVersion: Preprocessor version
 * - textAiVersion: Text AI version
 * - imageAiVersion: Image AI version
 * @example
 * const footerItems = [
 *  { name: 'Support', href: '#' },
 *  { name: 'License', href: '#' },
 * ]
 *
 * const logoImage = (
 *   <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
 * );
 *
 * <Footer
 *   copyrightText="This ia a copyright text"
 *   items={footerItems}
 *   image={logoImage}
 *   backendVersion={backendVersion}
 * />
 */
const Footer = ({ copyrightText, items, image, backendVersion }) => {
  if (!items) {
    console.error('Property items is required for component Footer!');
    return null;
  }

  const [versionModalOpen, setVersionModalOpen] = useState(false);

  const { t } = useTranslation();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-14 md:flex md:items-center md:justify-between lg:px-1">
        <div className="flex items-center justify-center space-x-6 md:order-2">
          {items.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              className="inline-block align-middle text-base uppercase text-blue-2 hover:text-blue-1"
              rel="noreferrer"
            >
              <p className="align-middle">{item.name}</p>
            </a>
          ))}
          {isAuthenticated && backendVersion ? (
            <div
              className="inline-block cursor-pointer align-middle text-base uppercase text-blue-2 hover:text-blue-1"
              onClick={() => setVersionModalOpen(true)}
            >
              {t('footer.version.link')}
            </div>
          ) : null}

          <CustomDialog isOpen={versionModalOpen} onClose={() => setVersionModalOpen(false)}>
            <p className="ml-8 font-futura-fat text-3xl text-blue-2">Über DocCape</p>
            <div className="my-4 flex flex-col items-center">
              <div className="my-1 flex w-[80%] flex-row justify-between text-base text-blue-2">
                <p>{t('footer.version.doccape')}:</p>
                <p>{backendVersion?.doccapeVersion}</p>
              </div>
              <div className="my-1 flex w-[80%] flex-row justify-between text-base text-blue-2">
                <p>{t('footer.version.preprocessor')}:</p>
                <p>{backendVersion?.preprocessorVersion}</p>
              </div>
              <div className="my-1 flex w-[80%] flex-row justify-between text-base text-blue-2">
                <p>{t('footer.version.textAi')}:</p>
                <p>{backendVersion?.textAiVersion}</p>
              </div>
              <div className="my-1 flex w-[80%] flex-row justify-between text-base text-blue-2">
                <p>{t('footer.version.imageAi')}:</p>
                <p>{backendVersion?.imageAiVersion}</p>
              </div>
              <div className="my-1 flex w-[80%] flex-row justify-between text-base text-blue-2">
                <p>{t('footer.version.frontend')}:</p>
                <p>{packageJson?.version}</p>
              </div>
              <img className="mt-6 h-[15%] w-[15%]" src={PerfectHand} alt="Perfect Hand" />
            </div>
          </CustomDialog>
          <div className="hidden md:block">{image}</div>
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center align-middle text-base leading-5 text-blue-2">
            {copyrightText !== undefined ? <>&copy; {copyrightText}</> : null}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
