import { HStack, Select, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LabelSettings = ({
  label,
  isDisabled,
  imageAnonymizationTypes,
  setImageAnonymizationType,
}) => {
  const { t } = useTranslation();

  const handleOnChangeAnonymizationType = (value) => {
    setImageAnonymizationType(label.name, value);
  };

  return (
    <VStack alignItems="left" pl={1}>
      <HStack>
        <Text>{t('settings.imageAnonymization.anonymizationType')}:</Text>
        <Select
          size="sm"
          width="25%"
          value={label.anonymizationType}
          onChange={(event) => {
            handleOnChangeAnonymizationType(event.target.value);
          }}
          // eslint-disable-next-line react/destructuring-assignment
          isDisabled={isDisabled}
        >
          {Object.entries(imageAnonymizationTypes).map(([name, option]) => (
            <option key={`Option-${name}`} value={name}>
              {t(option.translationKey)}
            </option>
          ))}
        </Select>
      </HStack>
    </VStack>
  );
};

export default LabelSettings;
