import React, { useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';

import { classNames } from '../../../utils/tailwindUtils';

/**
 * Password input field with toggleable visibility
 * @param {boolean} valid Whether the input field is valid
 * @param {function} onChange Function to be called when the input field changes
 */
const PasswordInput = ({ valid, onChange }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="relative w-full">
      <input
        type={passwordVisible ? 'text' : 'password'}
        className={classNames(
          // if valid is undefined style the input field as valid (= normal)
          (valid !== undefined && valid) || valid === undefined
            ? 'block w-full rounded-xl border-0 py-1.5 text-blue-2 text-base shadow-sm ring-1 ring-inset ring-babyBlue-1 placeholder:text-gray-3 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:leading-6'
            : 'border-red-3 block w-full rounded-xl border-2 px-3.5 py-2 text-blue-2 text-base focus:border-red-3 focus:ring-red-3 focus:ring-1 sm:leading-6',
        )}
        onChange={onChange}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 flex items-center px-4"
        onClick={togglePasswordVisibility}
      >
        {passwordVisible ? <HiEye className="text-blue-3" /> : <HiEyeOff className="text-blue-3" />}
      </button>
    </div>
  );
};

export default PasswordInput;
