import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getTextLabels, putTextLabels } from '../api/project';

const initialState = {
  data: [],
  status: '',
  error: '',
};

/**
 * Fetches the textLabels
 */
export const fetchTextLabels = createAsyncThunk('textLabels/fetch', async ({ projectId }) => {
  const response = await getTextLabels(projectId);
  return response.data;
});

/**
 * Updates the textLabels
 */
export const updateTextLabels = createAsyncThunk(
  'textLabels/update',
  async ({ projectId, textLabels }) => {
    const response = await putTextLabels(projectId, textLabels);
    return response.data;
  },
);

/**
 * The slice of the textLabels state
 */
export const textLabelsSlice = createSlice({
  name: 'textLabels',
  initialState,
  reducers: {
    setTextLabels(state, action) {
      return action.payload;
    },
    /**
     * Clears the textLabels state by setting it to the initial state
     */
    clearTextLabels() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTextLabels.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchTextLabels.fulfilled, (state, action) => {
        // Save fetched textLabels in store
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTextLabels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectTextLabels = createSelector(
  (state) => state.textLabels,
  (textLabels) => textLabels.data,
);
export const selectTextLabelsStatus = createSelector(
  (state) => state.textLabels,
  (textLabels) => textLabels.status,
);
export const selectTextLabelsError = createSelector(
  (state) => state.textLabels,
  (textLabels) => textLabels.error,
);

export const { clearTextLabels } = textLabelsSlice.actions;

export default textLabelsSlice.reducer;
