/**
 * Slice where the data for the load status of the system is stored
 */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getLoadStatus } from '../api/system';

const initialState = {
  data: {},
  status: '',
  error: '',
};

export const fetchLoadStatus = createAsyncThunk('fetch/loadStatus', async () => {
  const response = await getLoadStatus();
  return response.data;
});

export const systemLoadStatusSlice = createSlice({
  name: 'loadStatus',
  initialState,
  reducers: {
    /**
     * Clears the loadStatus state by setting it to the initial state
     */
    clearSystemLoadStatus() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLoadStatus.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchLoadStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLoadStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const selectLoadStatus = createSelector(
  (state) => state.systemLoadStatus,
  (systemLoadStatus) => systemLoadStatus.data,
);
export const selectLoadStatusFetchStatus = createSelector(
  (state) => state.systemLoadStatus,
  (systemLoadStatus) => systemLoadStatus.status,
);

export const { clearSystemLoadStatus } = systemLoadStatusSlice.actions;

export default systemLoadStatusSlice.reducer;
