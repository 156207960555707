import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearBlacklist,
  fetchBlacklist,
  selectBlacklist,
  selectBlacklistStatus,
  updateBlacklist,
} from '../reducers/blacklistSlice';
import {
  clearWhitelist,
  fetchWhitelist,
  selectWhitelist,
  selectWhitelistStatus,
  updateWhitelist,
} from '../reducers/whitelistSlice';
import { getIdxOfBlacklistItem, transformToSliceData } from '../services/listingService';
import { removeItemFromArray } from '../services/utils';

const useBlackWhitelist = (projectId) => {
  const dispatch = useDispatch();

  const [textElements, setTextElements] = useState([]);
  const [blacklistedLabels, setBlacklistedLabels] = useState([]);
  const [whitelistedLabels, setWhitelistedLabels] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const sliceBlacklist = useSelector(selectBlacklist);
  const sliceWhitelist = useSelector(selectWhitelist);

  const fetchBlacklistStatus = useSelector(selectBlacklistStatus);
  const fetchWhitelistStatus = useSelector(selectWhitelistStatus);

  const initSlices = () => {
    setIsLoading(true);
    dispatch(fetchBlacklist({ projectId }));
    dispatch(fetchWhitelist({ projectId }));
  };

  const transformSliceData = () => {
    if (fetchBlacklistStatus === 'succeeded' && fetchWhitelistStatus === 'succeeded') {
      setIsLoading(true);
      // There can only be one blacklist item per text element
      const valuesFromBlacklist = sliceBlacklist.map((item) => item.value);

      const combinedValues = valuesFromBlacklist;
      // There can be multiple whitelist items per text element
      sliceWhitelist.forEach((item) => {
        if (!combinedValues.includes(item.value)) {
          combinedValues.push(item.value);
        }
      });

      setTextElements(combinedValues);
      setBlacklistedLabels(sliceBlacklist);
      setWhitelistedLabels(sliceWhitelist);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlices();
    return () => {
      // Clears blacklist state when component unmounts
      dispatch(clearBlacklist());
      // Clears whitelist state when component unmounts
      dispatch(clearWhitelist());
    };
  }, [projectId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceWhitelist, sliceBlacklist]);

  // --------------------- FILTER --------------------- \\

  /**
   * Filter the text elements based on the given search string
   * @param {string} textElementSearch - The search string
   * @returns {string[]} - The filtered text elements
   */
  const getFilteredTextElements = (textElementSearch) => {
    if (textElementSearch === '') {
      return textElements;
    }
    const filteredTextElements = textElements.filter((item) =>
      item.toLowerCase().includes(textElementSearch.toLowerCase()),
    );
    return filteredTextElements;
  };

  // --------------------- WHITELIST --------------------- \\

  /**
   * Check if the label is already selected for the given text element
   * @param {string} selectedTextElement - The text element that is selected
   * @param {string} labelName - The label name that is selected
   * @returns
   */
  const isLabelNameSelected = (selectedTextElement, labelName) => {
    return (
      whitelistedLabels.filter(
        (item) =>
          item.value === selectedTextElement &&
          (item.labelName === labelName || item.labelName === '*'),
      ).length > 0
    );
  };

  const saveWhitelist = async (updatedWhitelist) => {
    const updatedSliceWhitelist = transformToSliceData(updatedWhitelist, projectId);
    await dispatch(updateWhitelist({ projectId, whitelist: updatedSliceWhitelist })).unwrap();
  };

  const addWhitelistItem = (textElement, label) => {
    const updatedWhitelist = [...whitelistedLabels, { value: textElement, labelName: label }];
    setWhitelistedLabels(updatedWhitelist);
    return updatedWhitelist;
  };

  const removeWhitelistItem = (textElement, label) => {
    // Retrieve the index of the item to be removed
    const idx = whitelistedLabels.findIndex(
      (item) => item.value === textElement && item.labelName === label,
    );
    const updatedWhitelist = removeItemFromArray(whitelistedLabels, idx);
    setWhitelistedLabels(updatedWhitelist);
    return updatedWhitelist;
  };

  /**
   * Change the label for the given text element. Removes the label if it is already selected.
   * @param {string} selectedTextElement - The text element that is selected
   * @param {string} labelName - The label name that is selected
   */
  const changeWhitelistLabel = async (selectedTextElement, labelName) => {
    // check if the label is already in the whitelist
    if (isLabelNameSelected(selectedTextElement, labelName)) {
      const updatedWhitelist = removeWhitelistItem(selectedTextElement, labelName);
      saveWhitelist(updatedWhitelist);
      return;
    }
    const updatedWhitelist = addWhitelistItem(selectedTextElement, labelName);
    saveWhitelist(updatedWhitelist);
  };

  // --------------------- BLACKLIST --------------------- \\

  const saveBlacklist = async (updatedBlacklist) => {
    const updatedSliceBlacklist = transformToSliceData(updatedBlacklist, projectId);
    await dispatch(updateBlacklist({ projectId, blacklist: updatedSliceBlacklist })).unwrap();
  };

  const addBlacklistItem = (textElement, oldLabel, newLabel) => {
    let updatedBlacklist = blacklistedLabels;
    if (oldLabel !== null) {
      // Retrieve the index of the item to be removed
      const idx = getIdxOfBlacklistItem(blacklistedLabels, textElement, oldLabel);
      updatedBlacklist = removeItemFromArray(blacklistedLabels, idx);
    }
    updatedBlacklist = [...updatedBlacklist, { value: textElement, labelName: newLabel }];
    setBlacklistedLabels(updatedBlacklist);
    return updatedBlacklist;
  };

  const removeBlacklistItem = (textElement, label) => {
    // Retrieve the index of the item to be removed
    const idx = getIdxOfBlacklistItem(blacklistedLabels, textElement, label);
    const updatedBlacklist = removeItemFromArray(blacklistedLabels, idx);
    setBlacklistedLabels(updatedBlacklist);
    return updatedBlacklist;
  };

  /**
   * Change the label for the given text element. Removes the label if it is already selected.
   * @param {string} selectedTextElement - The text element that is selected
   * @param {string} oldLabelName - The label name that is selected
   * @param {string} newLabelName - The "new" label name that should be selected
   */
  const changeBlacklistLabel = async (selectedTextElement, oldLabelName, newLabelName) => {
    // check if the label is already in the blacklist
    if (oldLabelName === newLabelName) {
      const updatedBlacklist = removeBlacklistItem(selectedTextElement, newLabelName);
      await saveBlacklist(updatedBlacklist);
      return;
    }
    const updatedBlacklist = addBlacklistItem(selectedTextElement, oldLabelName, newLabelName);
    await saveBlacklist(updatedBlacklist);
  };

  // --------------------- TEXT ELEMENTS --------------------- \\

  const addNewTextElement = (textElement) => {
    const updatedTextElements = [...textElements, textElement];
    setTextElements(updatedTextElements);
    return updatedTextElements;
  };

  const removeTextElement = (textElement) => {
    // Delete whitelist items for the text element
    const updatedWhitelist = whitelistedLabels.filter((item) => item.value !== textElement);
    setWhitelistedLabels(updatedWhitelist);
    saveWhitelist(updatedWhitelist);

    // Delete blacklist items for the text element
    const updatedBlacklist = blacklistedLabels.filter((item) => item.value !== textElement);
    setBlacklistedLabels(updatedBlacklist);
    saveBlacklist(updatedBlacklist);

    // Delete text element
    const updatedTextElements = textElements.filter((item) => item !== textElement);
    setTextElements(updatedTextElements);
  };

  return {
    textElements,
    blacklist: blacklistedLabels,
    whitelist: whitelistedLabels,
    changeBlacklistLabel,
    changeWhitelistLabel,
    isLabelNameSelected,
    addNewTextElement,
    removeTextElement,
    getFilteredTextElements,
    isLoading,
  };
};

export default useBlackWhitelist;
