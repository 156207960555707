/**
 * Extract query parameters from query string of the url
 * @param {string} queryString - The query string from url
 * @returns {object} query parameters: email and utm parameter
 */
const extractQueryParamsFromURL = (queryString) => {
  const searchParams = new URLSearchParams(queryString);
  const email = searchParams.get('email');

  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');
  const utmTerm = searchParams.get('utm_term');
  const utmContent = searchParams.get('utm_content');

  const userData = {
    email,
    utmParameters: {
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
    },
  };

  return userData;
};

export default extractQueryParamsFromURL;
