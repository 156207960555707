import { Tag as ChakraTag } from '@chakra-ui/react';
import React from 'react';

const Tag = (props) => {
  const {
    bg,
    p,
    width,
    color,
    sx,
    borderWidth,
    borderStyle,
    borderColor,
    m,
    onClick,
    onKeyPress,
    onContextMenu,
    onMouseOver,
    onMouseLeave,
    children,
  } = props;

  return (
    <ChakraTag
      bg={bg}
      p={p}
      width={width}
      color={color}
      sx={sx}
      borderWidth={borderWidth}
      borderStyle={borderStyle}
      borderColor={borderColor}
      m={m}
      onClick={onClick}
      onKeyPress={onKeyPress}
      onContextMenu={onContextMenu}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </ChakraTag>
  );
};

export default Tag;
