import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEmailCheck } from '../../../services/registrationService';
import CustomDialog from '../../molecules/tailwind/CustomDialog';
import CustomDialogContent from '../../molecules/tailwind/CustomDialogContent';
import CustomForm from '../../molecules/tailwind/CustomForm';

const AddUserToOrgDialog = ({
  open,
  setOpen,
  inviteUserToOrganization,
  errorMsg,
  setErrorMsg,
  isValidEmail,
  setIsValidEmail,
}) => {
  const { t } = useTranslation();

  const [emailInvitedUser, setEmailInvitedUser] = useState('');

  const resetStates = () => {
    setEmailInvitedUser('');
    setOpen(false);
    setIsValidEmail(true);
    setErrorMsg('');
  };

  const closeModalWithoutSaving = () => {
    resetStates();
  };

  const handleEmail = (email) => {
    setEmailInvitedUser(email);
  };

  const closeAndSaveModal = async () => {
    if (!isValidEmailCheck(emailInvitedUser)) {
      setErrorMsg(t('organizationMngmt.invitationUser.errorMsg.invalidEmail'));
      setOpen(true);
      setIsValidEmail(false);
      return;
    }
    const isSuccesful = await inviteUserToOrganization(emailInvitedUser);
    if (isSuccesful) {
      setOpen(false);
      setEmailInvitedUser('');
    }
  };

  const addUserInputFields = [
    {
      id: 'eMail',
      name: t('organizationMngmt.invitationUser.email'),
      type: 'text',
      value: emailInvitedUser,
      onChange: (event) => handleEmail(event.target.value),
      valid: isValidEmail,
      errorMsg,
      required: true,
    },
  ];

  return (
    <CustomDialog isOpen={open} onClose={closeModalWithoutSaving}>
      <CustomDialogContent
        title={t('organizationMngmt.invitationUser.inviteUser')}
        subtitle={t('organizationMngmt.invitationUser.subtitle')}
        buttonText={t('organizationMngmt.invitationUser.inviteUser')}
        buttonAction={closeAndSaveModal}
        content={<CustomForm inputFields={addUserInputFields} />}
      />
    </CustomDialog>
  );
};

export default AddUserToOrgDialog;
