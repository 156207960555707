import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';
import AlreadyUsedTokenCard from '../components/organisms/tailwind/registration/AlreadyUsedTokenCard';
import ExpiredTokenCard from '../components/organisms/tailwind/registration/ExpiredTokenCard';
import NonExistingOrOutdatedTokenCard from '../components/organisms/tailwind/registration/NotExistingOrOutdatedTokenCard';
import NotValidTokenCard from '../components/organisms/tailwind/registration/NotValidTokenCard';
import UnknownErrorCard from '../components/organisms/tailwind/registration/UnknownErrorCard';
import useRegistrationUser from '../hooks/useRegistrationUser';

/**
 * Renders the page the user is redirected to after clicking the verification link in the email
 * It displays depending on the state of the verification token (valid, expired, already used) different messages
 */
const VerifyUserAfterRegistration = () => {
  const { verifyEmailToken } = useParams();
  const { t } = useTranslation();
  const {
    isVerifyEmailTokenNotValid,
    isVerifyEmailTokenExpired,
    isVerifyEmailTokenAlreadyUsed,
    doesoesVerifyEmailTokenNotExistOrIsOutdated,
    isUnknownError,
    verifyToken,
    refreshVerifyEmailToken,
  } = useRegistrationUser();

  const [isLinkSentSuccesful, setIsLinkSentSuccesful] = React.useState(false);

  /**
   * Verifies the token in the url
   */
  const handleVerification = async () => {
    await verifyToken(verifyEmailToken);
  };

  useEffect(() => {
    handleVerification();
  }, []);

  /**
   * Refreshes the verify token by sending a new one to the user's email
   */
  const handleRefreshToken = async () => {
    const succesful = await refreshVerifyEmailToken(verifyEmailToken);
    if (succesful) {
      setIsLinkSentSuccesful(true);
    }
  };

  const renderContent = () => {
    if (isVerifyEmailTokenNotValid) {
      return (
        <NotValidTokenCard
          title={t('verifyEmail.emailVerificationNotValidToken.heading')}
          subtitle={t('verifyEmail.emailVerificationNotValidToken.subHeading')}
          info={t('verifyEmail.emailVerificationNotValidToken.infoText')}
        />
      );
    }

    if (isVerifyEmailTokenExpired) {
      return (
        <ExpiredTokenCard
          title={t('verifyEmail.emailVerificationExpiredToken.heading')}
          sendingLinkHeading={t(
            'verifyEmail.emailVerificationExpiredToken.sendingLinkSuccesfulHeading',
          )}
          sendLinkInfo={t('verifyEmail.emailVerificationExpiredToken.sendLinkInfo')}
          handleRefreshToken={handleRefreshToken}
          isLinkSentSuccesful={isLinkSentSuccesful}
        />
      );
    }

    if (isVerifyEmailTokenAlreadyUsed) {
      return (
        <AlreadyUsedTokenCard
          title={t('verifyEmail.emailVerificationAlreadyUsedToken.heading')}
          subtitle={t('verifyEmail.emailVerificationAlreadyUsedToken.subHeading')}
        />
      );
    }

    if (doesoesVerifyEmailTokenNotExistOrIsOutdated) {
      return (
        <NonExistingOrOutdatedTokenCard
          title={t('verifyEmail.emailVerificationNonExistingTokenOrOutdated.heading')}
          subtitle={t('verifyEmail.emailVerificationNonExistingTokenOrOutdated.subHeading')}
          info={t('verifyEmail.emailVerificationNonExistingTokenOrOutdated.infoText')}
        />
      );
    }

    if (isUnknownError) {
      return <UnknownErrorCard />;
    }

    return (
      <CustomCard
        content={
          <p className="text-center text-sm">{t('organizationMngmt.tokenVerification.loading')}</p>
        }
      />
    );
  };

  return <ExternalViewLayout>{renderContent()}</ExternalViewLayout>;
};

export default VerifyUserAfterRegistration;
