import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getOriginalBase64 } from '../api/document';

const initialState = {
  data: null,
  status: '',
  error: '',
};

/**
 * Fetches the documents
 */
export const fetchOriginalDocument = createAsyncThunk(
  'fetch/originalDocument',
  async ({ projectId, documentId }) => {
    const response = await getOriginalBase64(projectId, documentId);
    return response.data;
  },
);

/**
 * The slice of the document state
 */
export const originalDocumentSlice = createSlice({
  name: 'originalDocument',
  initialState,
  reducers: {
    /**
     * Clears the original document state by setting it to the initial state
     */
    clearOriginalDocument() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOriginalDocument.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchOriginalDocument.fulfilled, (state, action) => {
        return { ...state, status: 'succeeded', data: action.payload };
      })
      .addCase(fetchOriginalDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectOriginalDocument = createSelector(
  (state) => state.originalDocument,
  (originalDocument) => originalDocument.data,
);
export const selectOriginalDocumentStatus = createSelector(
  (state) => state.originalDocument,
  (originalDocument) => originalDocument.status,
);
export const selectOriginalDocumentError = createSelector(
  (state) => state.originalDocument,
  (originalDocument) => originalDocument.error,
);

export const { clearOriginalDocument } = originalDocumentSlice.actions;

export default originalDocumentSlice.reducer;
