/**
 * Transforms the data from the frontend to the format that the backend expects
 * @param {Array} list - The list of items to be transformed
 * @param {number | string} projectId - The id of the project
 * @returns
 */
export const transformToSliceData = (list, projectId) => {
  const sliceData = list.map((item) => ({
    projectId,
    version: 0,
    ...item,
  }));
  return sliceData;
};

/**
 * Retrieves the index of the blacklist item that matches the given text element and blacklist label
 * @param {Array} blacklist - The list of blacklist items
 * @param {string} textElement - The text element to be searched for
 * @param {string} blacklistLabel - The blacklist label to be searched for
 * @returns
 */
export const getIdxOfBlacklistItem = (blacklist, textElement, blacklistLabel) => {
  return blacklist.findIndex(
    (item) => item.value === textElement && item.labelName === blacklistLabel,
  );
};
