import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import TrashCanIcon from '../../../atoms/icons/TrashCanIcon';
import IconButton from '../../../atoms/tailwind/IconButton';
import CustomDialog from '../../../molecules/tailwind/CustomDialog';
import CustomDialogContent from '../../../molecules/tailwind/CustomDialogContent';
import CustomForm from '../../../molecules/tailwind/CustomForm';

const GeneralSettings = ({
  projectName,
  projectDescription,
  onDelete,
  onChangeProjectName,
  onChangeProjectDescription,
  projectNameError,
  saveProjectData,
  isValidProjectName,
  errorMsg,
}) => {
  const { t } = useTranslation();

  /**
   * Temporary implementation
   */
  const [openDialog, setOpenDialog] = useState(false);

  /**
   * Temporary implementation
   */
  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  /**
   * Temporary implementation
   */
  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const projectInputFields = [
    {
      id: 'projectName',
      name: t('projectMngmt.overview.createProjectModal.projectName'),
      type: 'text',
      value: projectName,
      onChange: (event) => onChangeProjectName(event.target.value),
      onBlur: saveProjectData,
      valid: isValidProjectName && !projectNameError,
      errorMsg,
      required: true,
    },
    {
      id: 'projectDescription',
      name: t('projectMngmt.overview.createProjectModal.projectDescription'),
      type: 'textarea',
      value: projectDescription,
      onChange: (event) => onChangeProjectDescription(event.target.value),
      onBlur: saveProjectData,
      rows: 4,
      required: true,
    },
  ];

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    const handleNavigation = () => {
      if (unsavedChanges) {
        const confirmNavigation = window.confirm(
          'You have unsaved changes. Are you sure you want to leave?',
        );
        if (!confirmNavigation) {
          navigate(location.pathname); // Reset the URL to the current location
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges, navigate, location.pathname]);

  // usePrompt('You have unsaved changes. Are you sure you want to leave?', hasUnsavedChanges);

  return (
    <>
      <div className="max-w-md ">
        <CustomForm inputFields={projectInputFields} />
        <div className="m-auto mt-5">
          <IconButton
            buttonText={t('projectSettings.sidebar.deleteProject')}
            icon={<TrashCanIcon />}
            color="red"
            buttonAction={onOpenDialog}
          />
        </div>
        <CustomDialog isOpen={openDialog} onClose={onCloseDialog}>
          <CustomDialogContent
            title={t('projectSettings.sidebar.confirmationDeleteProject')}
            buttonText={t('projectSettings.sidebar.deleteProject')}
            buttonAction={onDelete}
          />
        </CustomDialog>
      </div>
      <CustomDialog isOpen={openDialog} onClose={onCloseDialog}>
        <CustomDialogContent
          title={t('projectSettings.sidebar.confirmationDeleteProject')}
          buttonText={t('projectSettings.sidebar.deleteProject')}
          buttonAction={onDelete}
        />
      </CustomDialog>
    </>
  );
};

export default GeneralSettings;
