import React from 'react';

const AnalyzeIcon = () => {
  return (
    <svg fill="currentColor" width="38" height="34" viewBox="0 0 38 34">
      <path d="m26.16,12.15c-1.04-1.75-3.08-2.62-5.06-2.26-2.25.41-3.86,2.47-3.89,4.72.01.98.32,1.89.83,2.66-1.92,1.63-3.85,3.27-5.77,4.9-.29.25-.59.5-.88.75-.31.26-.27.79,0,1.06.31.31.75.26,1.06,0,2.06-1.75,4.13-3.51,6.19-5.26.14-.12.27-.23.41-.35.39.31.83.57,1.31.75,1.86.71,4.08.11,5.34-1.43,1.28-1.56,1.5-3.8.45-5.55Zm-1.29,4.16c-.69,1.16-2.08,1.81-3.42,1.56-1.58-.29-2.71-1.67-2.73-3.27.02-1.36.86-2.64,2.16-3.11,1.34-.48,2.85-.07,3.75,1.04.86,1.07.95,2.6.25,3.77Z" />
    </svg>
  );
};

export default AnalyzeIcon;
