import React from 'react';

/**
 * Text area component
 * @param {Function} onChange - Function to call when the value of the text area changes
 * @param {String} value - Value of the text area
 */
const TextArea = (props) => {
  const { onChange, value, ...rest } = props;

  return (
    <textarea
      type="textarea"
      name="project-description"
      id="project-description"
      value={value}
      onChange={onChange}
      className="focus:ring-blue-1sm:text-sm block w-full resize-none rounded-xl border-0 px-3.5 py-2 text-blue-2 shadow-sm ring-1 ring-inset ring-babyBlue-1 focus:ring-2 focus:ring-inset focus:ring-blue-1 sm:leading-6"
      {...rest}
    />
  );
};

export default TextArea;
