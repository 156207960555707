import { Box, Card, CardBody, HStack, Select, Stack, Text } from '@chakra-ui/react';
import React from 'react';

/**
 * Displays the info card to a bounding box
 */
const BboxCard = ({
  boxName,
  labelName,
  labels,
  color,
  bbox,
  isClickable,
  isBboxActive,
  setBboxActive,
  onChangeLabelName,
}) => {
  return (
    <Card
      maxW="sm"
      mb={1}
      bg={isBboxActive(bbox) ? 'gray.200' : null}
      borderWidth={isBboxActive(bbox) ? 2 : 1}
      borderColor={color}
      sx={{ _hover: { bg: 'gray.50' } }}
    >
      <CardBody pt={2} pb={1}>
        <Stack
          spacing="2"
          onClick={isClickable ? () => setBboxActive(bbox) : null}
          sx={isClickable ? { _hover: { cursor: 'pointer' } } : null}
        >
          <Text as="b">{boxName}</Text>
          <HStack>
            <Text as="i">Label:</Text>
            <Select
              size="sm"
              value={labelName}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => {
                const { labelIdx, bboxIdx } = bbox.id;
                onChangeLabelName(bboxIdx, labelIdx, labelName, event.target.value);
              }}
            >
              {labels.map((label) => (
                // eslint-disable-next-line react/no-array-index-key
                <option key={`SelectLabel-${label.name}`} value={label.name}>
                  {label.name}
                </option>
              ))}
            </Select>
          </HStack>
          <Box
            mb="-20px"
            align="center"
            flexWrap="wrap"
            sx={{
              '& > button': {
                minW: '80px',
                _hover: { bg: 'transparent', color: 'gray.600' },
              },
            }}
          />
        </Stack>
      </CardBody>
    </Card>
  );
};

export default BboxCard;
