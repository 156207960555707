import { Box, HStack, Select, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextLabelDatePartialReplacement from './TextLabelDatePartialReplacement';
import TextLabelPartialReplacement from './TextLabelPartialReplacement';

const TextLabelExtendedReplacements = ({
  textLabel,
  changePartialReplacementStart,
  changePartialReplacementStop,
  changeUseNumericPartialReplacement,
  changeExtendedReplacement,
  changeReplaceDay,
  changeReplaceMonth,
  changeReplaceYear,
  changeShowWeekday,
  changeDateLocale,
  allDateLocales,
}) => {
  const { t } = useTranslation();

  const labelName = textLabel.name;
  const extendedReplacementOptions = {
    none: {
      title: t('settings.textLabels.noExtendedReplacement'),
      component: null,
    },
    usePartialReplacement: {
      title: t('settings.textLabels.partialReplacementTitle'),
      component: (
        <TextLabelPartialReplacement
          textLabel={textLabel}
          changePartialReplacementStart={changePartialReplacementStart}
          changePartialReplacementStop={changePartialReplacementStop}
          toggleUseNumericPartialReplacement={() => {
            changeUseNumericPartialReplacement(!textLabel.useNumericPartialReplacement);
          }}
        />
      ),
    },
    useDatePartialReplacement: {
      title: t('settings.textLabels.datePartialReplacementTitle'),
      component: (
        <TextLabelDatePartialReplacement
          textLabel={textLabel}
          toggleReplaceDay={() => {
            changeReplaceDay(!textLabel.replaceDay);
          }}
          toggleReplaceMonth={() => {
            changeReplaceMonth(!textLabel.replaceMonth);
          }}
          toggleReplaceYear={() => {
            changeReplaceYear(!textLabel.replaceYear);
          }}
          toggleShowWeekday={() => {
            changeShowWeekday(!textLabel.showWeekday);
          }}
          changeDateLocale={changeDateLocale}
          allDateLocales={allDateLocales}
        />
      ),
    },
  };

  const initialOption =
    Object.keys(extendedReplacementOptions).find((key) => key !== 'none' && textLabel[key]) ||
    'none';
  const [selectedOption, setSelectedOption] = useState(initialOption);

  /**
   * Resets the selected option after every tag change
   */
  useEffect(() => {
    setSelectedOption(initialOption);
  }, [labelName, initialOption, setSelectedOption]);

  const selectOption = (key) => {
    changeExtendedReplacement(key);
    setSelectedOption(key);
  };

  return (
    <Stack>
      <HStack>
        <h2>
          <Box flex="1" textAlign="left">
            {t('settings.textLabels.pseudonymizationExtended')}:
          </Box>
        </h2>
        <Select
          size="md"
          width="200px"
          value={selectedOption}
          onChange={(event) => {
            selectOption(event.target.value);
          }}
        >
          {Object.keys(extendedReplacementOptions).map((key) => (
            <option key={`Option-${key}`} value={key}>
              {extendedReplacementOptions[key].title}
            </option>
          ))}
        </Select>
      </HStack>
      {selectedOption !== 'none' ? (
        <Box pl="25px" pt="10px">
          {extendedReplacementOptions[selectedOption].component}
        </Box>
      ) : null}
    </Stack>
  );
};

export default TextLabelExtendedReplacements;
