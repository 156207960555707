import React from 'react';
import Input from './Input';

const IconInput = ({ id, name, type, placeholder, onChange, icon, ...rest }) => {
  return (
    <div className="relative">
      <Input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
      <div className="text-blue-2">{icon}</div>
    </div>
  );
};

export default IconInput;
