import {
  Button,
  Checkbox,
  FormControl,
  HStack,
  Input,
  Stack,
  Table,
  Tbody,
  Td,
  Textarea,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextLabelExtendedReplacements from './TextLabelExtendedReplacements';

const TextLabelSettings = ({
  textLabel,
  nameValidityByTextLabelName,
  nameInputByTextLabelName,
  defaultReplacementValidityByTagName,
  changeName,
  onBlurChangeName,
  changeDefaultReplacement,
  onDelete,
  setCanClose,
  changeDescription,
  changeIsHidden,
  changePartialReplacementStart,
  changePartialReplacementStop,
  changeUseNumericPartialReplacement,
  changeExtendedReplacement,
  changeReplaceDay,
  changeReplaceMonth,
  changeReplaceYear,
  changeShowWeekday,
  changeDateLocale,
  allDateLocales,
}) => {
  const { t } = useTranslation();

  const labelName = textLabel.name;

  if (!nameValidityByTextLabelName.current || !defaultReplacementValidityByTagName.current) {
    return null;
  }
  const isNameValid = nameValidityByTextLabelName.current[labelName];
  const isDefaultReplacementValid = defaultReplacementValidityByTagName.current[labelName];

  if (!isNameValid || !isDefaultReplacementValid) {
    return null;
  }
  return (
    <Table variant="unstyled">
      <Tbody>
        <Tr>
          <Td textAlign="right">{t('settings.textLabels.name')}:</Td>
          <Td>
            <FormControl isInvalid={!isNameValid.valid}>
              <Input
                value={nameInputByTextLabelName[labelName]}
                placeholder={t('settings.textLabels.maxNameChars')}
                onChange={(event) => changeName(event.target.value)}
                onBlur={onBlurChangeName}
                isDisabled={textLabel.fromModel}
              />
              {isNameValid.formMessage}
            </FormControl>
          </Td>
        </Tr>
        <Tr>
          <Td textAlign="right">{t('settings.textLabels.pseudonymization')}:</Td>
          <Td>
            <Stack>
              <FormControl isInvalid={!isDefaultReplacementValid.valid}>
                <Input
                  value={textLabel.defaultReplacement}
                  onChange={(event) => changeDefaultReplacement(event.target.value)}
                />
                {isDefaultReplacementValid.formMessage}
              </FormControl>
              <TextLabelExtendedReplacements
                textLabel={textLabel}
                setCanClose={setCanClose}
                changePartialReplacementStart={changePartialReplacementStart}
                changePartialReplacementStop={changePartialReplacementStop}
                changeUseNumericPartialReplacement={changeUseNumericPartialReplacement}
                changeExtendedReplacement={changeExtendedReplacement}
                changeReplaceDay={changeReplaceDay}
                changeReplaceMonth={changeReplaceMonth}
                changeReplaceYear={changeReplaceYear}
                changeShowWeekday={changeShowWeekday}
                changeDateLocale={changeDateLocale}
                allDateLocales={allDateLocales}
              />
            </Stack>
          </Td>
        </Tr>
        <Tr>
          <Td textAlign="right">{t('settings.textLabels.description')}:</Td>
          <Td>
            <Textarea
              value={textLabel.description}
              onChange={(event) => changeDescription(event.target.value)}
              size="sm"
            />
          </Td>
        </Tr>

        <Tr>
          <Td />
          <Td>
            <Checkbox
              isChecked={textLabel.isHidden}
              onChange={(event) => changeIsHidden(event.target.checked)}
            >
              {t('settings.textLabels.hide')}
            </Checkbox>
          </Td>
        </Tr>
        <Tr>
          <Td />
          <Td>
            <HStack>
              <Button colorScheme="red" onClick={onDelete} isDisabled={textLabel.fromModel}>
                {t('settings.textLabels.delete')}
              </Button>
            </HStack>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default TextLabelSettings;
