import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';

const IconButtonWithTooltip = ({
  size = 'xs',
  tooltipLabel,
  onClick,
  isDisabled,
  icon,
  iconColor = null,
  colorScheme,
}) => {
  return (
    <Tooltip label={tooltipLabel}>
      <Box>
        <IconButton
          size={size}
          onClick={onClick}
          isDisabled={isDisabled}
          icon={icon}
          colorScheme={colorScheme}
          color={iconColor}
        />
      </Box>
    </Tooltip>
  );
};

export default IconButtonWithTooltip;
