import React from 'react';

/**
 * Displays a column of the black and white list
 * @param {String} title - Title of the column
 * @param {String} subtitle - Subtitle of the column
 * @param {JSX} selectionArea - Selection area of the column (e.g. a list of text elements)
 * @example
 * const textElementSelectionArea = (
 *  <div>
 *   <ul>
 *     <li>Item 1</li>
 *     <li>Item 2</li>
 *     <li>Item 3</li>
 *   </ul>
 *  </div>
 * );
 *
 * return (<BlackWhiteListColumn title="Text Elemente" subtitle="Wählen Sie ein Textelement" selectionArea={textElementSelectionArea} />);
 *
 */
const BlackWhiteListColumn = ({ title, subtitle, selectionArea }) => {
  return (
    <>
      <p className="w-full text-xl text-blue-2">{title}</p>
      <p className="mb-4 text-xs text-grey-1">{subtitle}</p>
      {selectionArea}
    </>
  );
};

export default BlackWhiteListColumn;
