import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearProjectSettings,
  fetchProjectSettings,
  selectProjectSettings,
  selectProjectSettingsError,
  selectProjectSettingsStatus,
} from '../reducers/projectSettingsSlice';

const useProjectSettings = (projectId) => {
  const dispatch = useDispatch();

  const [projectSettings, setProjectSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const sliceProjectSettings = useSelector(selectProjectSettings);
  const fetchStatus = useSelector(selectProjectSettingsStatus);
  const fetchError = useSelector(selectProjectSettingsError);

  // TOOD: Naming
  const initSlice = () => {
    setIsLoading(true);
    dispatch(fetchProjectSettings({ projectId }));
  };

  const transformSliceData = () => {
    if (fetchStatus === 'succeeded') {
      setIsLoading(true);
      setProjectSettings(sliceProjectSettings);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initSlice();
    return () => {
      // Clears project settings state when component unmounts
      dispatch(clearProjectSettings());
    };
  }, [projectId]);

  useEffect(() => {
    transformSliceData();
  }, [sliceProjectSettings]);

  const setIsImageAnonymizationEnabled = (isImageAnonymizationEnabled) => {
    setProjectSettings({
      ...projectSettings,
      isImageAnonymizationEnabled,
    });
  };

  return {
    isLoading,
    projectSettings,
    setIsImageAnonymizationEnabled,
  };
};

export default useProjectSettings;
