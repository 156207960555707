import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import doccapeLogo from '../assets/DocCape_Favicon.svg';
import ThumbsUp from '../assets/DocCape_Hand_thumbsup_yellow.png';
import Checkbox from '../components/atoms/tailwind/Checkbox';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import CustomForm from '../components/molecules/tailwind/CustomForm';
import LinkText from '../components/molecules/tailwind/LinkText';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';
import AlreadyUsedInvitationTokenCard from '../components/organisms/tailwind/registration/AlreadyUsedTokenCard';
import ExpiredInvitationTokenCard from '../components/organisms/tailwind/registration/ExpiredTokenCard';
import NonExistingOrOutdatedTokenCard from '../components/organisms/tailwind/registration/NotExistingOrOutdatedTokenCard';
import NotValidInvitationTokenCard from '../components/organisms/tailwind/registration/NotValidTokenCard';
import UnknownErrorCard from '../components/organisms/tailwind/registration/UnknownErrorCard';
import useInvitationUser from '../hooks/useInvitationUser';

/**
 * Renders the registration view after email invitation
 */
const RegisterInvitedUser = () => {
  const navigate = useNavigate();
  const { verifyEmailToken } = useParams();
  const { t } = useTranslation();
  const {
    isValidRepeatPassword,
    isValidPassword,
    isVerifyEmailTokenAlreadyUsed,
    errorMsg,
    password,
    repeatPassword,
    firstName,
    lastName,
    isRegistrationSuccessful,
    isVerifyEmailTokenNotValid,
    isVerifyEmailTokenExpired,
    refreshInvitationToken,
    setPassword,
    setRepeatPassword,
    setFirstName,
    setLastName,
    registerUser,
    doesVerifyInvitationTokenNotExistOrIsOutdated,
    isUnknownError,
    acceptedTerms,
    setAcceptedTerms,
  } = useInvitationUser();

  const [isLinkSentSuccesful, setIsLinkSentSuccesful] = React.useState(false);

  const handlePassword = (pw) => {
    setPassword(pw);
  };

  const handleRepeatPassword = (pw) => {
    setRepeatPassword(pw);
  };

  const handleFirstName = (name) => {
    setFirstName(name);
  };

  const handleLastName = (name) => {
    setLastName(name);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    registerUser(verifyEmailToken);
  };

  const handleRefreshToken = async () => {
    const succesful = await refreshInvitationToken(verifyEmailToken);
    if (succesful) {
      setIsLinkSentSuccesful(true);
    }
  };

  const linkContents = {
    /* linkGTC: { to: 'https://doccape.de/agb', title: 'doccape.de/agb' }, */
    linkPN: { to: 'https://doccape.de/datenschutz', title: 'doccape.de/datenschutz' },
  };

  /**
   * Input fields for the registration form
   */
  const registerInputFields = [
    {
      id: 'firstname',
      name: t('organizationMngmt.registrationUser.userData.firstName'),
      type: 'text',
      value: firstName,
      onChange: (event) => handleFirstName(event.target.value),
      required: true,
    },
    {
      id: 'lastname',
      name: t('organizationMngmt.registrationUser.userData.lastName'),
      type: 'text',
      value: lastName,
      onChange: (event) => handleLastName(event.target.value),
      required: true,
    },
    {
      id: 'password',
      name: t('organizationMngmt.registrationUser.userData.password'),
      type: 'password',
      value: password,
      onChange: (event) => handlePassword(event.target.value),
      required: true,
      valid: isValidPassword,
      errorMsg,
    },
    {
      id: 'repeatPassword',
      name: t('organizationMngmt.registrationUser.userData.repeatPassword'),
      type: 'password',
      value: repeatPassword,
      onChange: (event) => handleRepeatPassword(event.target.value),
      required: true,
      valid: isValidRepeatPassword,
      errorMsg,
    },
  ];

  /**
   * Checkbox for accepting terms and privacy
   */
  const termsAndPrivacyCheckbox = (
    <div>
      <div className="flex items-center">
        <Checkbox
          id="termsAndPrivacy"
          onChange={() => {
            setAcceptedTerms(!acceptedTerms);
          }}
        />
        <p className="ml-2 text-sm text-grey-1">
          {/* Trans component allows to integrate react components into translations */}
          <Trans
            i18nKey="organizationMngmt.registrationUser.userData.infoText"
            components={{
              /* linkGTC: <LinkText to={linkContents.linkGTC.to} title={linkContents.linkGTC.title} />, */
              linkPN: <LinkText to={linkContents.linkPN.to} title={linkContents.linkPN.title} />,
            }}
          />
        </p>
        <div className="mt-1 text-red-3 lg:text-xs">{errorMsg}</div>
      </div>
    </div>
  );

  const registerForm = (
    <CustomForm inputFields={registerInputFields} additionalContent={termsAndPrivacyCheckbox} />
  );

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  /**
   * Renders the content depending on the state of the registration process (successful, expired, already used, not valid)
   */
  const renderRegistraionContent = () => {
    if (isVerifyEmailTokenNotValid) {
      return (
        <NotValidInvitationTokenCard
          title={t('organizationMngmt.invitationUserNotValidToken.heading')}
          subtitle={t('organizationMngmt.invitationUserNotValidToken.subHeading')}
          info={t('organizationMngmt.invitationUserNotValidToken.infoText')}
        />
      );
    }

    if (isVerifyEmailTokenExpired) {
      return (
        <ExpiredInvitationTokenCard
          title={t('organizationMngmt.invitationUserExpiredToken.heading')}
          sendingLinkHeading={t(
            'organizationMngmt.invitationUserExpiredToken.sendingLinkSuccesfulHeading',
          )}
          sendLinkInfo={t('organizationMngmt.invitationUserExpiredToken.sendLinkInfo')}
          handleRefreshToken={handleRefreshToken}
          isLinkSentSuccesful={isLinkSentSuccesful}
        />
      );
    }

    if (isVerifyEmailTokenAlreadyUsed) {
      return (
        <AlreadyUsedInvitationTokenCard
          title={t('organizationMngmt.invitationUserAlreadyUsedToken.heading')}
          subtitle={t('organizationMngmt.invitationUserAlreadyUsedToken.subHeading')}
        />
      );
    }

    if (doesVerifyInvitationTokenNotExistOrIsOutdated) {
      return (
        <NonExistingOrOutdatedTokenCard
          title={t('organizationMngmt.invitationUserNonExistingOrOutdatedToken.heading')}
          subtitle={t('organizationMngmt.invitationUserNonExistingOrOutdatedToken.subHeading')}
          info={t('organizationMngmt.invitationUserNonExistingOrOutdatedToken.infoText')}
        />
      );
    }

    if (isUnknownError) {
      return <UnknownErrorCard />;
    }

    if (isRegistrationSuccessful) {
      return (
        <CustomCard
          image={logoImage}
          title={t('organizationMngmt.registrationUserSuccess.heading')}
          subtitle={t('organizationMngmt.registrationUserSuccess.subHeading')}
          buttonText={t('organizationMngmt.registrationUserSuccess.toLoginButton')}
          buttonAction={() => navigate('/login')}
          content={
            <div className="flex w-full items-center justify-center">
              <img className="w-20" src={ThumbsUp} alt="Thumbs Icon" />
            </div>
          }
        />
      );
    }

    return (
      <CustomCard
        image={logoImage}
        title={t('organizationMngmt.registrationUser.heading')}
        subtitle={t('organizationMngmt.registrationUser.subHeading')}
        buttonText={t('organizationMngmt.registrationUser.createAccountButton')}
        disabledButton={!acceptedTerms}
        buttonAction={onSubmit}
        content={registerForm}
      />
    );
  };

  return <ExternalViewLayout>{renderRegistraionContent()}</ExternalViewLayout>;
};

export default RegisterInvitedUser;
