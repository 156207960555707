import React from 'react';
import { useNavigate } from 'react-router-dom';
import generateBreadcrumbs from '../../../utils/breadCrumbsUtils';
import ButtonLink from '../../atoms/tailwind/ButtonLink';
import Icon from '../../atoms/tailwind/Icon';

/**
 * Renders a breadcrumb navigation for a page
 * @param {Object} urlNames - The names of the urls
 * @param {Object} urlPath - The path of the url of the current page (e.g. /organizations/1/users)
 * @param {Function} wrapper - An **optional** wrapper function that can be used to wrap the navigate function
 * (used e.g. for preventing the user from navigating to a page if there are unsaved changes)
 * @param {Array} overwriteBreadcrumbs - An **optional** object that can be used to overwrite the breadcrumbs for a specific page
 * (e.g. for the user settings page) -> *example [ { name: 'Home', path: '/' }, { name: 'Settings', path: '/settings' } ]*
 */
const BreadCrumbs = ({ urlNames, urlPath, wrapper = null, overwriteBreadcrumbs }) => {
  const navigate = useNavigate();

  let breadcrumbs = [];
  if (overwriteBreadcrumbs) {
    breadcrumbs = overwriteBreadcrumbs;
  } else {
    /**
     * Generated breadcrumbs for the given url
     */
    breadcrumbs = generateBreadcrumbs(urlNames, urlPath);
  }

  /**
   * Handles the click on a breadcrumb link. Uses the wrapper function if it is defined.
   * @param {string} path - The path of the breadcrumb link
   */
  const handleLinkClick = (path) => {
    if (wrapper !== null) {
      wrapper(() => navigate(path));
    } else {
      navigate(path);
    }
  };

  return (
    <nav aria-label="breadcrumbs">
      <ol className="flex items-center space-x-1">
        {breadcrumbs.map((breadcrumb, breadcrumbIdx) => (
          <li key={breadcrumb.name}>
            <div className="flex items-center text-xl text-blue-2">
              {/* The first breadcrum should not have an icon as prefix */}
              {breadcrumbIdx !== 0 ? <Icon /> : null}
              <ButtonLink onClick={() => handleLinkClick(breadcrumb.path)} name={breadcrumb.name} />
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
