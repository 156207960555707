import { createSelector, createSlice } from '@reduxjs/toolkit';
import sidebarContentAreaTypes from '../types/SidebarContentArea';

const initialState = sidebarContentAreaTypes.textLabelSelection;

export const activeSidebarContentAreaSlice = createSlice({
  name: 'activeSidebarContentArea',
  initialState,
  reducers: {
    setActiveSidebarContentArea(state, action) {
      return action.payload;
    },
    clearActiveSidebarContentArea() {
      return initialState;
    },
  },
});

export const selectActiveSidebarContentArea = createSelector(
  (state) => state.activeSidebarContentArea,
  (activeSidebarContentArea) => activeSidebarContentArea,
);

export const { setActiveSidebarContentArea, clearActiveSidebarContentArea } =
  activeSidebarContentAreaSlice.actions;

export default activeSidebarContentAreaSlice.reducer;
