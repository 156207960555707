import { createAnnotation } from '../types/annotation';
import { createPseudonymFromAnnotation } from './pseudonymizationService';
import { insertItemIntoArray, isEmpty, push2DArray, setItemAtIndex } from './utils';

export const createAnnotationFromMark = (paragraph, start, end, textLabelName) => {
  const text = paragraph.tokens
    .slice(start, end + 1)
    .reduce(
      (acc, cur, idx) =>
        acc + cur.text + (start + idx + 1 < end + 1 && cur.hasWhitespace ? ' ' : ''),
      '',
    );
  return createAnnotation({
    start,
    end,
    startChar: paragraph.tokens[start].startChar,
    endChar: paragraph.tokens[end].endChar,
    textLabelName,
    text,
    paragraphId: paragraph.id,
    documentStart: paragraph.start + start,
    documentEnd: paragraph.start + end,
  });
};

/**
 *
 * @param {*} paragraphIdx
 * @param {*} annotations
 * @param {*} predictedSentenceStarts
 * @param {*} sentenceStartIdsToRemove
 * @param {*} annotationIndicesToRemove indices of the annotation in the paragraph to remove
 * @returns
 */
export const removeParagraphAnnotations = (
  paragraphIdx,
  annotations,
  predictedSentenceStarts,
  sentenceStartIdsToRemove,
  annotationIndicesToRemove,
) => {
  // Unset all sentence boundaries the user unnecessarily wanted to remove
  let idsToRemove = [...sentenceStartIdsToRemove[paragraphIdx]];
  if (!isEmpty(sentenceStartIdsToRemove)) {
    annotationIndicesToRemove.forEach((idx) => {
      const { start, end } = annotations[paragraphIdx][idx];

      idsToRemove = idsToRemove.filter((removedId) => {
        const removedStart = predictedSentenceStarts[paragraphIdx][removedId];
        return !(start < removedStart && end >= removedStart);
      });
    });
  }

  const newSentenceStartIdsToRemove = push2DArray(
    sentenceStartIdsToRemove,
    paragraphIdx,
    idsToRemove,
  );

  const paragraphAnnotations = [...annotations[paragraphIdx]];
  annotationIndicesToRemove.forEach((idx, cnt) => {
    paragraphAnnotations.splice(idx - cnt, 1);
  });
  const newAnnotations = setItemAtIndex(annotations, paragraphIdx, paragraphAnnotations);

  return {
    newAnnotations,
    newSentenceStartIdsToRemove,
  };
};

export const addParagraphAnnotations = (
  paragraphIdx,
  annotations,
  predictedSentenceStarts,
  sentenceStartIdsToRemove,
  newParagraphAnnotations,
) => {
  // If an annotation already exists at this place, remove it (prevent annotations with same start)
  const indicesToRemove = [];
  newParagraphAnnotations.forEach((newAnnotation) => {
    for (let i = annotations[paragraphIdx].length - 1; i >= 0; i -= 1) {
      if (newAnnotation.start === annotations[paragraphIdx][i].start) {
        indicesToRemove.push(i);
      }
    }
  });
  let { newAnnotations, newSentenceStartIdsToRemove } = removeParagraphAnnotations(
    paragraphIdx,
    annotations,
    predictedSentenceStarts,
    sentenceStartIdsToRemove,
    indicesToRemove,
  );

  // Insert annotations at correct position corresponding to start and end pos
  // assumes both annotations and newAnnotations are sorted start pos ascending
  const paragraphAnnotations = [...newAnnotations[paragraphIdx]];
  let slotIdx = 0;
  newParagraphAnnotations.forEach((item) => {
    // search for correct slot
    while (
      slotIdx < paragraphAnnotations.length && // stop at last index + 1
      paragraphAnnotations[slotIdx].start < item.start
    ) {
      slotIdx += 1;
    }
    // put there (use splice to insert https://stackoverflow.com/questions/586182/how-to-insert-an-item-into-an-array-at-a-specific-index-javascript)
    insertItemIntoArray(paragraphAnnotations, slotIdx, item);
  });

  // If any user set annotation crosses a sentence boundary -> store that bound for later remove
  const idsToRemove = [];
  newParagraphAnnotations.forEach((newAnnotation) => {
    predictedSentenceStarts[paragraphIdx].forEach((start, idx) => {
      if (newAnnotation.start < start && newAnnotation.end >= start) {
        idsToRemove.push(idx);
      }
    });
  });
  newSentenceStartIdsToRemove = push2DArray(newSentenceStartIdsToRemove, paragraphIdx, idsToRemove);

  // Update annotations for current paragraph
  newAnnotations = setItemAtIndex(newAnnotations, paragraphIdx, paragraphAnnotations);
  return { newAnnotations, newSentenceStartIdsToRemove, annotationIdx: slotIdx };
};

/**
 * Changes the textLabelName of all annotations that match and updates the pseudonyms accordingly.
 * @param {*} matchFn Boolean function that takes an annotation and returns true or false if it matches or not.
 * @param {*} newTextLabelName The textLabelName of the new annotations.
 * @returns
 */
export const changeTextLabelNameOfMatchingAnnotations = (annotations, matchFn, textLabel) => {
  const newAnnotations = annotations.map((paragraphAnnotations) => {
    return paragraphAnnotations.map((annotation) => {
      if (matchFn(annotation)) {
        const newAnnotation = createAnnotation({
          ...annotation,
          textLabelName: textLabel.name,
        });

        const newPseudonym = createPseudonymFromAnnotation(newAnnotation, textLabel);

        return { ...newAnnotation, pseudonym: newPseudonym };
      }
      return annotation;
    });
  });
  // Update the annotations

  return newAnnotations;
};

export const changeCrIdOfMatchingAnnotations = (annotations, matchFn, newCrId, newPseudonym) => {
  const newAnnotations = annotations.map((paragraphAnnotations) => {
    return paragraphAnnotations.map((annotation) => {
      if (matchFn(annotation)) {
        return createAnnotation({
          ...annotation,
          crId: newCrId,
          pseudonym: newPseudonym,
        });
      }
      return annotation;
    });
  });
  // Update the annotations
  return newAnnotations;
};

/**
 * Deletes all annotations that match.
 * @param {*} matchFn Boolean function that takes an annotation and returns true or false if it matches or not.
 */
export const deleteAllMatchingAnnotations = (
  annotations,
  predictedSentenceStarts,
  sentenceStartIdsToRemove,
  matchFn,
) => {
  // Compute indices of to removing annotations
  const newAnnotations = [];
  const newSentenceStartIdsToRemove = [];
  annotations.forEach((paragraphAnnotations, paragraphIdx) => {
    const indicesToRemove = paragraphAnnotations.reduce((acc, val, idx) => {
      if (matchFn(val)) {
        acc.push(idx);
      }
      return acc;
    }, []);

    const {
      newAnnotations: newParagrahAnnotations,
      newSentenceStartIdsToRemove: newParagraphSentenceStartIdsToRemove,
    } = removeParagraphAnnotations(
      paragraphIdx,
      annotations,
      predictedSentenceStarts,
      sentenceStartIdsToRemove,
      indicesToRemove,
    );

    newAnnotations.push(newParagrahAnnotations[paragraphIdx]);
    newSentenceStartIdsToRemove.push(newParagraphSentenceStartIdsToRemove[paragraphIdx]);
  });

  return { newAnnotations, newSentenceStartIdsToRemove };
};

/**
 * Changes the CrId of all annotations that match.
 * @param {*} matchFn Boolean function that takes an annotation and returns true or false if it matches or not.
 * @param {*} newCrId The new Cr Id of the new annotations.
 * @returns
 */
export const changeCrIdAndPseudonymOfMatchingAnnotations = (
  annotations,
  textLabels,
  matchFn,
  newCrId,
) => {
  const newAnnotations = annotations.map((paragraphAnnotations) =>
    paragraphAnnotations.map((annotation) => {
      if (matchFn(annotation)) {
        const newAnnotation = createAnnotation({
          ...annotation,
          crId: newCrId,
        });
        const newPseudonym = createPseudonymFromAnnotation(
          newAnnotation,
          textLabels[newAnnotation.textLabelName],
        );
        newAnnotation.pseudonym = newPseudonym;
        return newAnnotation;
      }
      return annotation;
    }),
  );

  return newAnnotations;
};

/**
 * Finds and annotates all unmarked token sequences that share the same text.
 *
 * @param {*} searchText The text we search for.
 * @param {*} textLabelName The textLabelName of the new annotations.
 * @param {*} pseudonym The Pseudonym of the new annotations.
 * @param {*} score The score of the new annotations.
 * @param {*} crId The crId of the new annotations.
 */
export const findAndAnnotateAllUnmarked = (
  annotations,
  paragraphs,
  searchText,
  textLabelName,
  pseudonym,
  score,
  crId,
) => {
  const addedAnnotations = [];
  paragraphs.forEach((paragraph, paragraphIdx) => {
    // Construct annotations from tokens and check if they match the reference or not
    const addedParagraphAnnotations = [];
    const { tokens } = paragraph;
    const maxTokens = paragraph.tokens.length;
    let currentText = '';
    let currentStart = 0;
    let currentEnd = currentStart;
    while (currentStart < maxTokens) {
      currentText += tokens[currentEnd].text;
      if (!searchText.startsWith(currentText)) {
        // Start with the next token from scratch
        currentStart += 1;
        currentEnd = currentStart;
        currentText = '';
      } else if (searchText === currentText) {
        const start = currentStart;
        const end = currentEnd;
        // We possibly found a new annotation, but it could also be (or be inside) an old one
        const isOldAnnotation = annotations[paragraphIdx].find((oldAnnotation) => {
          return oldAnnotation.start <= start && oldAnnotation.end >= end;
        });
        if (!isOldAnnotation) {
          addedParagraphAnnotations.push(
            createAnnotation({
              start: currentStart,
              end: currentEnd,
              startChar: paragraph.tokens[currentStart].startChar,
              endChar: paragraph.tokens[currentEnd].endChar,
              textLabelName,
              text: currentText,
              paragraphId: paragraph.id,
              pseudonym,
              score,
              documentStart: paragraphs[paragraphIdx].start + currentStart,
              documentEnd: paragraphs[paragraphIdx].start + currentEnd,
              crId,
            }),
          );
        }
        // Skip the annotation, we do not allow nested ones
        currentStart = currentEnd + 1;
        currentEnd = currentStart;
      } else {
        // Add token and try again
        currentText += tokens[currentEnd].hasWhitespace ? ' ' : '';
        currentEnd += 1;
      }
    }
    addedAnnotations.push(addedParagraphAnnotations);
  });
  return addedAnnotations;
};
