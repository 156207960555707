import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import IconButton from '../components/atoms/tailwind/IconButton';
import BreadCrumbs from '../components/molecules/tailwind/BreadCrumbs';
import CustomBasicContainerForContent from '../components/molecules/tailwind/CustomBasicContainerForContent';
import CustomButtonGroup from '../components/molecules/tailwind/CustomButtonGroup';
import CustomProjectContainer from '../components/molecules/tailwind/CustomProjectContainer';
import ViewLayout from '../components/molecules/tailwind/ViewLayout';
import CreateProjectDialog from '../components/organisms/projectManagement/projectSelection/CreateProjectDialog';
import useProjects from '../hooks/useProjects';

import PerfectHand from '../assets/DocCape_Hand_handopen_yellow.svg';
import AddIcon from '../components/atoms/icons/AddIcon';
import LoadingPage from '../components/organisms/LoadingPage';
import useOrganizations from '../hooks/useOrganizations';
import { selectUserData } from '../reducers/userSlice';
import { checkOrgRole } from '../services/authService';

const ProjectSelection = () => {
  const { organizationId } = useParams();
  const {
    sliceProjectsLength,
    projects,
    filterProjects,
    onAddProject,
    isShown,
    isLoading,
    setIsShown,
    isValidProjectName,
    setIsValidProjectName,
    projectNameError,
    setProjectNameError,
    setErrorMsg,
    errorMsg,
  } = useProjects(organizationId);
  const { t } = useTranslation();
  const location = useLocation();
  const { getOrgById } = useOrganizations(organizationId);
  const user = useSelector(selectUserData);

  const urlNames = {
    organizations: getOrgById(organizationId)?.name || '',
  };

  const contentProjectOverview = (
    <div>
      <div className="space-y-4">
        <CustomButtonGroup searchAction={filterProjects}>
          {user && checkOrgRole(user, organizationId, 'ADMIN') ? (
            <IconButton
              buttonText={t('projectMngmt.overview.addProject')}
              icon={<AddIcon />}
              color="blue"
              buttonAction={() => {
                setIsShown(true);
              }}
            />
          ) : null}
        </CustomButtonGroup>
        {
          /*
           * `sliceProjectsLength` is needed to check if there are any projects in the organization
           * (because projects can be filtered, we can't use projects.length)
           */
          isLoading === false ? (
            sliceProjectsLength === 0 ? (
              <div className="flex flex-col items-center">
                <img
                  className="mt-10 h-[3%] w-[3%] rotate-[30deg]"
                  src={PerfectHand}
                  alt="Hand Open"
                />
                {checkOrgRole(user, organizationId, 'ADMIN') === true ? (
                  <div className="flex flex-col items-center">
                    <h3 className="mt-4 font-futura-fat text-3xl text-blue-2">
                      {t('projectMngmt.overview.noProjects.heading')}
                    </h3>
                    <p className="mb-6 mt-4 text-2xl text-blue-2">
                      {t('projectMngmt.overview.noProjects.contentAdmin')}
                    </p>
                    <IconButton
                      buttonText={t('projectMngmt.overview.addProject')}
                      icon={<AddIcon />}
                      buttonAction={() => {
                        setIsShown(true);
                      }}
                    />
                  </div>
                ) : (
                  <div className="text-center">
                    <h3 className="mt-4 font-futura-fat text-4xl text-blue-2">
                      {t('projectMngmt.overview.noProjects.heading')}
                    </h3>
                    <p className="mt-4 text-2xl text-blue-2">
                      {t('projectMngmt.overview.noProjects.content')}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <CustomProjectContainer projects={projects} />
              </div>
            )
          ) : (
            <LoadingPage />
          )
        }
      </div>
    </div>
  );

  return (
    <>
      <CustomBasicContainerForContent>
        <ViewLayout
          breadCrumbs={<BreadCrumbs urlNames={urlNames} urlPath={location.pathname} />}
          title={t('projectMngmt.overview.title')}
          content={contentProjectOverview}
        />
      </CustomBasicContainerForContent>
      <CreateProjectDialog
        open={isShown}
        setOpen={setIsShown}
        onAddProject={onAddProject}
        isValidProjectName={isValidProjectName}
        projectNameError={projectNameError}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        setIsValidProjectName={setIsValidProjectName}
        setProjectNameError={setProjectNameError}
        isLoadingCreateProject={isLoading}
      />
    </>
  );
};
export default ProjectSelection;
