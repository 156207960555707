import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getProjectSettings } from '../api/project';

const initialState = {
  data: {},
  status: '',
  error: '',
};

/**
 * Fetches the projectSettings
 */
export const fetchProjectSettings = createAsyncThunk(
  'projectSettings/fetch',
  async ({ projectId }) => {
    const response = await getProjectSettings(projectId);
    return response.data;
  },
);

/**
 * The slice of the projectSettings state
 */
export const projectSettingsSlice = createSlice({
  name: 'projectSettings',
  initialState,
  reducers: {
    setProjectSettings(state, action) {
      return action.payload;
    },
    /**
     * Clears the projectSettings state by setting it to the initial state
     */
    clearProjectSettings() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectSettings.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchProjectSettings.fulfilled, (state, action) => {
        // Save fetched projectSettings in store
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchProjectSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectProjectSettings = createSelector(
  (state) => state.projectSettings,
  (projectSettings) => projectSettings.data,
);
export const selectProjectSettingsStatus = createSelector(
  (state) => state.projectSettings,
  (projectSettings) => projectSettings.status,
);
export const selectProjectSettingsError = createSelector(
  (state) => state.projectSettings,
  (projectSettings) => projectSettings.error,
);

export const { clearProjectSettings } = projectSettingsSlice.actions;

export default projectSettingsSlice.reducer;
