import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../../../atoms/icons/AddIcon';
import TrashCanIcon from '../../../../atoms/icons/TrashCanIcon';
import IconButton from '../../../../atoms/tailwind/IconButton';
import Input from '../../../../atoms/tailwind/Input';
import BlackWhiteListColumn from './BlackWhiteListColumn';
import BlacklistSelectionArea from './selectionAreas/BlacklistSelectionArea';
import TextElementSelectionArea from './selectionAreas/TextElementSelectionArea';
import WhitelistSelectionArea from './selectionAreas/WhitelistSelectionArea';

const BlackWhiteListContent = ({
  textElements,
  textLabels,
  selectedTextElement,
  setSelectedTextElement,
  blacklist,
  changeBlacklistLabel,
  whitelist,
  changeWhitelistLabel,
  hasTextLabelExtendedReplacement,
  isLabelNameSelected,
  addNewTextElement,
  removeTextElement,
}) => {
  const { t } = useTranslation();
  const [newTextElement, setNewTextElement] = React.useState('');

  /**
   * Checks if the text element already exists by comparing it to all text elements in the list
   * @param {string} textElement
   * @returns true if the text element already exists, false otherwise
   */
  const checkForDuplicateTextElement = (textElement) => {
    return (
      textElements.filter((element) => element.toLowerCase() === textElement.toLowerCase()).length >
      0
    );
  };

  const addTextElement = () => {
    // Check if the text element already exists
    if (checkForDuplicateTextElement(newTextElement)) {
      return;
    }
    addNewTextElement(newTextElement);
    setNewTextElement('');
    setSelectedTextElement(newTextElement);
  };

  const deleteTextElement = () => {
    removeTextElement(selectedTextElement);
    setSelectedTextElement(null);
  };

  const manageTextElements = (
    <div className="flex justify-between py-2">
      <div className="mr-2">
        <Input
          id="input-text"
          name="textelement"
          type="text"
          value={newTextElement}
          onChange={(e) => setNewTextElement(e.target.value)}
          placeholder={t('settings.listing.textelements.placeholder')}
        />
      </div>
      <div className="flex justify-between ">
        <div className="pr-1 ">
          <IconButton
            icon={<AddIcon />}
            color="green"
            disabled={newTextElement === '' || checkForDuplicateTextElement(newTextElement)}
            buttonAction={addTextElement}
          />
        </div>
        <div className="pl-1">
          <IconButton
            icon={<TrashCanIcon />}
            color="red"
            disabled={selectedTextElement === null}
            buttonAction={deleteTextElement}
          />
        </div>
      </div>
    </div>
  );

  return (
    <ul className="mt-3 grid grid-cols-1 justify-items-center gap-4 py-2 md:grid-cols-3 lg:gap-20">
      <li className="col-span-1 flex w-full flex-col justify-start">
        <BlackWhiteListColumn
          title={t('settings.listing.textelements.title')}
          subtitle={t('settings.listing.textelements.subtitle')}
          selectionArea={
            <TextElementSelectionArea
              selectedTextElement={selectedTextElement}
              setSelectedTextElement={setSelectedTextElement}
              textElements={textElements}
            />
          }
        />
        {manageTextElements}
      </li>
      <li className="col-span-1 flex w-full flex-col justify-start">
        <BlackWhiteListColumn
          title={t('settings.listing.whitelist.title')}
          subtitle={t('settings.listing.whitelist.subtitle')}
          selectionArea={
            selectedTextElement !== null && (
              <WhitelistSelectionArea
                textLabels={textLabels}
                selectedTextElement={selectedTextElement}
                blacklist={blacklist}
                hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
                changeWhitelistLabel={changeWhitelistLabel}
                isLabelNameSelected={isLabelNameSelected}
              />
            )
          }
        />
      </li>
      <li className="col-span-1 flex w-full flex-col justify-start">
        <BlackWhiteListColumn
          title={t('settings.listing.blacklist.title')}
          subtitle={t('settings.listing.blacklist.subtitle')}
          selectionArea={
            selectedTextElement !== null && (
              <BlacklistSelectionArea
                textLabels={textLabels}
                selectedTextElement={selectedTextElement}
                whitelist={whitelist}
                blacklist={blacklist}
                hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
                changeBlacklistLabel={changeBlacklistLabel}
              />
            )
          }
        />
      </li>
    </ul>
  );
};

export default BlackWhiteListContent;
